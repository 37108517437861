import { useHistory } from "react-router-dom";
import BaseCardLayout from "../base-card-layout/base-card-layout";
import { Button } from '../button/button';
import logoImage from "../../assets/images/symmio-logos/symmio-logo-text-white-side.png"
import { useEffect, useState } from "react";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { AssessmentsUserMenu } from "../assessments-user-menu/assessments-user-menu";
import { enqueueSnackbar } from "notistack";
import ReportService from "../../utilities/services/report-service";
import UserService from "../../utilities/services/user-service-assessments";
import UserService2 from '../../utilities/services/user-service';
import { DateTime } from "luxon";
import { ReportType } from "../../models/enumerations/report-type";
import { Report } from "../../models/interfaces/report";
import wellnessProgramImg from "../../assets/images/wellness-program.png"
import { ReactComponent as ExerciseIcon } from "../../assets/icons/outline/icon_toe-touch.svg";
import { ReactComponent as LightbulbIcon } from "../../assets/icons/outline/icon_lightbulb.svg";
import { ReactComponent as WellnessScoreIcon } from "../../assets/icons/outline/icon_wellness-score.svg";
import { ReactComponent as FocusAreaIcon } from "../../assets/icons/outline/icon_magnifying-glass.svg";
import AppContentUtil from "../../utilities/app-content-util";
import nflImg from "../../assets/images/fms-clients/nfl.png"
import premierLeagueImg from "../../assets/images/fms-clients/premier-league.png"
import equinoxImg from "../../assets/images/fms-clients/equinox.png"
import cardinalGlassImg from "../../assets/images/fms-clients/cardinal-glass.png"
import mlbImg from "../../assets/images/fms-clients/major-baseball-league.png"
import usArmyImg from "../../assets/images/fms-clients/us-army.png"
import todayImg from "../../assets/images/today-page.png"
import assessmentImg from "../../assets/images/assessments-mobile-app.png"
import { ReactComponent as BehavioralHealthIcon } from "../../assets/icons/solid/behavioral-health.svg";
import { ReactComponent as MovementIcon } from "../../assets/icons/outline/movement.svg";
import { ReactComponent as PhysicalActivityIcon } from "../../assets/icons/solid/activity-level.svg";
import { ReactComponent as BodyCompIcon } from "../../assets/icons/solid/body-comp.svg";
import { ReactComponent as BreathingIcon } from "../../assets/icons/solid/breathing-qual.svg";
import { ReactComponent as InjuryHistoryIcon } from "../../assets/icons/solid/injury-history.svg";
import { ReactComponent as NutritionIcon } from "../../assets/icons/solid/nutrition-aware.svg";
import { ReactComponent as SleepIcon } from "../../assets/icons/solid/sleep-well.svg";
import { ReactComponent as CheckmarkIcon } from "../../assets/icons/solid/check.svg";
import { ReactComponent as CheckmarkWhiteIcon } from "../../assets/icons/solid/check-circle.svg";
import { ReactComponent as PainLocationsIcon } from "../../assets/icons/solid/broken-heart-icon.svg";
import StarIcon from '@mui/icons-material/Star';
import { Modal } from "../modal/modal";
import { ReactComponent as QuestionMarkIcon } from "../../assets/icons/solid/question-mark.svg";
import DonutChart from "../charts/donut-chart";
import { MskScore } from "../../models/interfaces/msk-score";
import FaqAccordion from "../faq-accordion/faq-accordion";
import RiskChartVertical from "../charts/risk-chart-vertical";
import { WarningItem } from "../warning-item/warning-item";
import SubscriptionService from "../../utilities/services/subscription-service";
import OrganizationSubscriptionService from "../../utilities/services/organization-subscription-service";
import { OrganizationSubscription } from "../../models/interfaces/organization-subscription";
import GroupService from "../../utilities/services/group-service";
import { GroupIds } from "../../models/enumerations/group-ids";
import MskScoreService from "../../utilities/services/msk-score-service";
import { SymmioAccessType } from "../../models/enumerations/symmio-access-type";
import qrCodeImg from "../../assets/images/qr-code-mobile-stores.png";
import googlePlayImg from "../../assets/images/google-play-button.png";
import appStoreImg from "../../assets/images/app-store-button.png";
import Card from "../card/card";
import { CardTypes } from "../../models/enumerations/card-types";
import { Loader } from "../loader/loader";
import videoReplacementImg from "../../assets/images/results-page-video-replacement.png"
import QrCodeCard from "../subscription-links/qr-code-card";
import { Utils } from "../../utilities/utils";
import RiskFactorUtil from "../../utilities/risk-factor-util";
import FocusAreaCard from "../focus-areas-card/focus-areas-card";
import GrayFooterCard from "../gray-footer-card/gray-footer-card";
import AssessmentResponse from "../../models/interfaces/assessment-response.entity";
import { AssessmentUtils } from "../../utilities/assessments/assessment-utils";
import MskScoreUtil from "../../utilities/msk-score-util";
import WellnessProgramService from "../../utilities/services/wellness-program-service";
import { WellnessProgram } from "../../models/interfaces/wellness-program";
import moment from "moment";
import UserUtil from "../../utilities/user-util";

export interface AssessmentResultsProps {
    secondScreenVideoUrl?: string;
    secondScreenButtonText?: string;
    showYourWellnessProgramSectionWithoutLicense?: boolean;
    yourWellnessProgramText?: string;
}

const COMPONENT_CLASS = "c-assessment-results";
const QUESTION_MARK_VIDEO_URL = "";
const SECOND_SCREEN_DEFAULT_VIDEO_URL = "";

const AssessmentResults: React.FunctionComponent<AssessmentResultsProps> = (props) => {
    const {
        secondScreenVideoUrl,
        secondScreenButtonText,
        showYourWellnessProgramSectionWithoutLicense,
        yourWellnessProgramText,
    } = props;

    const { state, setState } = useAuthState();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);

    const secondScreenVideoEmbed = AppContentUtil.getVideoEmbedCode(secondScreenVideoUrl ? secondScreenVideoUrl : SECOND_SCREEN_DEFAULT_VIDEO_URL);

    const [showMainScreen, setShowMainScreen] = useState(true);

    const [showQuestionMarkModal, setShowQuestionMarkModal] = useState(false);
    const questionMarkVideoEmbed = AppContentUtil.getVideoEmbedCode(QUESTION_MARK_VIDEO_URL);

    const [showAppContentModal, setShowAppContentModal] = useState(false);
    const [appContent, setAppContent] = useState("");
    const [appContentVideo, setAppContentVideo] = useState("");

    const [mskScore, setMskScore] = useState<MskScore>();
    const [riskChartPercentage, setRiskChartPercentage] = useState(0);
    const [latestAssessmentResponses, setLatestAssessmentResponses] = useState<AssessmentResponse[]>();
    const [lastAssessment, setLastAssessment] = useState<string>("Not Available");
    const [lastAssessmentDateInDays, setLastAssessmentDateInDays] = useState<string>("");
    const [orgSub, setOrgSub] = useState<OrganizationSubscription>();
    const showChargeFailedBanner = state.user?.subscription?.hasChargeError;

    const [showQrCodeModal, setShowQrCodeModal] = useState(false);
    let joinNowButtonAction = () => history.push(`/signup/ath-payment`);
    let joinNowButtonText = "Join Now"

    const [wellnessProgram, setWellnessProgram] = useState<WellnessProgram>();
    const [openRetestModal, setOpenRetestModal] = useState(false);

    if (state.user?.symmioAccess === SymmioAccessType.AppLicense) {
        joinNowButtonAction = () => setShowQrCodeModal(true);
        joinNowButtonText = "Get Started";
    }

    const [faqs, setFaqs] = useState([
        {
            question: "What are the main benefits of Symmio?",
            answer: "Symmio is for everyone—whether you're a weekend warrior or a stay-at-home parent. Regular use can transform your life by enhancing your awareness of health risks and providing insights and exercises to improve mobility, reduce pain, and boost mental and physical health. Our goal is to help you achieve better overall longevity.",
            isOpen: false
        },
        {
            question: "How much time per day do I need to invest in Symmio?",
            answer: "We don’t ask for much, but as little of 12-15 minutes a day can reap huge benefits!",
            isOpen: false
        },
        {
            question: "What will Symmio offer me?",
            answer: "Your Symmio journey is personalized. Based on your functional wellness results, we’ll prioritize two key areas for improvement. Most members receive tailored movement health exercises and are enrolled in wellness pathways that align with their specific needs.",
            isOpen: false
        },
        {
            question: "Do I need any equipment for Symmio?",
            answer: "Like yoga, most Symmio exercises require no equipment, just a space to move. Optional items to enhance your experience include a yoga mat, pillow, towel, or a half foam roll.",
            isOpen: false
        },
        {
            question: "What are the main benefits of Symmio?",
            answer: "Cancel any time before your next billing period to avoid charges, making it easy to manage your subscription. If you purchase the yearly plan, please contact support or refer to our refund policy.",
            isOpen: false
        }
    ]);

    const [showMentalHealthCard, setShowMentalHealthCard] = useState(false);
    const [showPainCard, setShowPainCard] = useState(false);

    let highRiskText = "";

    if (showPainCard && showMentalHealthCard) {
        highRiskText = "Based on your assessment, we recommend that you consult with a professional regarding your pain and mental health.";
    }
    else if (showPainCard) {
        highRiskText = "Based on your assessment, we recommend that you consult with a professional regarding your pain.";
    }
    else if (showMentalHealthCard) {
        highRiskText = "Your recent responses indicate that you might benefit from talking to a mental health professional. Remember, seeking help is a sign of strength, not weakness.";
    }

    useEffect(() => {
        const getMskScore = async () => {
            try {
                if (state.user && state.user.id) {
                    const fetchedScore = await MskScoreService.getBy(
                        [
                            {
                                field: "userId",
                                operator: "==",
                                value: state.user.id,
                            },
                        ],
                        [
                            {
                                field: "created",
                                direction: "desc",
                            },
                        ],
                    )

                    if (fetchedScore && fetchedScore.length > 0) {
                        setMskScore(fetchedScore[0]);
                        setRiskChartPercentage(RiskFactorUtil.convertRiskFactorToDisplayPercent(mskScore?.riskFactor?.percentage) || 0);

                        if (fetchedScore[0]?.created) {
                            setLastAssessment(Utils.formatDate(fetchedScore[0].created.toDate()));
                            setLastAssessmentDateInDays(Utils.getHowManyDaysAgo(fetchedScore[0].created.toDate()));
                        }
                    }
                }
            } catch (err) {
                console.error("Cannot fetch the Wellness Score");
            } finally {
                setIsLoading(false);
            }
        }

        getMskScore();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user, state.user?.id]);

    useEffect(() => {
        const getAssessmentResponses = async () => {
            if (state.user?.id) {
                const assessmentResponses = await AssessmentUtils.fetchAssessmentResponsesById(state.user?.id);
                assessmentResponses && assessmentResponses.length > 0 ? setLatestAssessmentResponses(assessmentResponses) : setLatestAssessmentResponses(undefined);
            }
        }

        getAssessmentResponses();
    }, [state.user?.id])

    useEffect(() => {
        const getOrgSub = async () => {
            try {
                const fetchedOrgSubArray = await OrganizationSubscriptionService.getByOrganizationId(state.organization.id!);

                if (fetchedOrgSubArray && fetchedOrgSubArray.length > 0) {
                    setOrgSub(fetchedOrgSubArray[0]);
                }
            } catch (err) {
                console.error("Cannot fetch the organization subscription", err);
            }
        }

        getOrgSub();

    }, [state.organization.id])

    useEffect(() => {
        const checkIfAppContentHasVideo = async () => {
            const group = state.user?.groupId ? await GroupService.get(state.user.groupId) : null;
            let appContentVideoTemp = "";

            if (showMentalHealthCard && state.organization.appContent.BEHAVIORAL_HEALTH.videoUrl) {
                appContentVideoTemp = state.organization.appContent.BEHAVIORAL_HEALTH.videoUrl;
            }
            else if (showPainCard && state.organization.appContent.PAIN.videoUrl) {
                appContentVideoTemp = state.organization.appContent.PAIN.videoUrl;
            }
            else if (showMentalHealthCard && group?.appContent?.BEHAVIORAL_HEALTH.videoUrl) {
                appContentVideoTemp = group?.appContent?.BEHAVIORAL_HEALTH.videoUrl;
            }
            else if (showPainCard && group?.appContent?.PAIN.videoUrl) {
                appContentVideoTemp = group?.appContent?.PAIN.videoUrl;
            }

            setAppContentVideo(AppContentUtil.getVideoEmbedCode(appContentVideoTemp));
        }

        checkIfAppContentHasVideo();

    }, [showMentalHealthCard, showPainCard, state.organization.appContent.BEHAVIORAL_HEALTH.videoUrl, state.organization.appContent.PAIN.videoUrl, state.user?.groupId])

    useEffect(() => {
        const getAppContentEmbed = async () => {
            try {
                const embedCode = await AppContentUtil.composeAppContentForDisplay(state.user);

                if (embedCode) {
                    setAppContent(embedCode);
                }
            } catch (err) {
                console.error("Cannot fetch the High-Risk Recommendations");
            }
        }

        getAppContentEmbed();
    }, [state.user])

    useEffect(() => {
        const fetchUser = async () => {
            if (state.user && state.user.id) {
                const fetchedUser = await UserService2.get(state.user?.id);

                if (fetchedUser) {
                    setState((state) => ({
                        ...state, ...{ user: fetchedUser }
                    }));

                    const showMentalHealth = fetchedUser.importantMessages?.some(msg => msg.groupId === GroupIds.BEHAVIORAL_HEALTH) || false;
                    const showPain = fetchedUser.importantMessages?.some(msg => msg.groupId === GroupIds.PAIN) || false;

                    setShowMentalHealthCard(showMentalHealth);
                    setShowPainCard(showPain);
                }
            }
        }

        fetchUser();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const getWellnessProgram = async () => {
            if (state.user && state.user.wellnessProgramId) {
                let fetchedWellnessProgram = await WellnessProgramService.get(state.user.wellnessProgramId);

                if (fetchedWellnessProgram) {
                    setWellnessProgram(fetchedWellnessProgram);
                }
            }
        }

        if (!wellnessProgram) {
            getWellnessProgram();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user])

    const handleDownloadReport = async () => {
        try {
            if (!state.user?.id) return;
            const userInFirebase = await UserService.get(state.user.id);
            if (!userInFirebase || !userInFirebase.organizationId) return;

            const latestOrgId = userInFirebase.organizationId;
            const userId = userInFirebase.id;

            if (!userId) {
                return;
            }
            const date = DateTime.utc().toISO();
            const report = await ReportService.getBy(
                [
                    {
                        field: "userId",
                        operator: "==",
                        value: userId,
                    },
                    {
                        field: "organizationId",
                        operator: "==",
                        value: latestOrgId,
                    },
                    {
                        field: "status",
                        operator: "==",
                        value: "complete",
                    },
                    {
                        field: "expiration",
                        operator: ">",
                        value: date,
                    },
                ], [
                {
                    field: "expiration",
                    direction: "desc",
                },
                {
                    field: "updated",
                    direction: "desc",
                }]);
            if (report.length <= 0) {
                enqueueSnackbar("Downloading your Symmio Report. Please wait while we generate it.", { variant: "info" });
                handleGenerateReport(userId, latestOrgId);
                return;
            }

            if (!report[0].id) {
                return;
            }

            if (report[0].emailResults === true) {
                return;
            }

            const reportUnsubscribe = ReportService.getSnapshot(
                report[0].id,
                (r: Report) => {
                    if (r.status === "complete" && r.url) {
                        reportUnsubscribe();

                        window.open(r.url);
                    }
                    if (r.status === "error") {
                        reportUnsubscribe();
                    }
                }
            );
        } catch (err: any) {
            console.error("An error occurred while generating a report: ", err);
            enqueueSnackbar(err);
        }
    };

    const handleGenerateReport = async (userId: string, organizationId: string) => {
        const date = DateTime.now().toISODate();
        const report: Report = {
            dateEnd: date,
            dateStart: date,
            emailResults: false,
            organizationId: organizationId,
            status: "scheduled",
            type: ReportType.Wellness,
            userId: userId,
        };

        const scheduledReport = await ReportService.save(
            report,
            state.user
        );

        if (!scheduledReport.id) {
            return;
        }

        if (scheduledReport.emailResults === true) {
            return;
        }

        const reportUnsubscribe = ReportService.getSnapshot(
            scheduledReport.id,
            (r: Report) => {
                if (r.status === "complete" && r.url) {
                    reportUnsubscribe();

                    //Loads in the System browser
                    window.open(r.url);
                }
                if (r.status === "error") {
                    reportUnsubscribe();
                }
            }
        );
    };

    const downloadReportButton = (
        <Button
            type="default"
            buttonText="Download Your Report"
            onClick={() => handleDownloadReport()} />
    );

    const getConnectedButton =
        <Button
            type="default"
            buttonText="Get Connected"
            onClick={() => setShowAppContentModal(true)} />

    const openSecondScreenButton =
        <Button
            type="default"
            buttonText={secondScreenButtonText ? secondScreenButtonText : "Start Your Program"}
            onClick={() => {
                setShowMainScreen(false);
                window.scrollTo(0, 0);
            }} />

    const joinNowButton = (isBlue: boolean = false) => (
        <Button
            type="default"
            buttonText={joinNowButtonText}
            buttonStyle={isBlue ? "blue" : undefined}
            onClick={() => joinNowButtonAction()} />
    );
    return (
        isLoading
            ?
            <Loader isVisible={isLoading} />
            :
            <div className={`${COMPONENT_CLASS}`}>
                {mskScore && mskScore?.movementScore?.percentage != null && mskScore?.lifestyleScore?.percentage != null ?
                    <>
                        {questionMarkVideoEmbed &&
                            <Modal
                                isOpen={showQuestionMarkModal}
                                isLoading={false}
                                onClose={setShowQuestionMarkModal}>
                                <div className={`${COMPONENT_CLASS}__video-container-modal`}>
                                    <div dangerouslySetInnerHTML={{ __html: questionMarkVideoEmbed }}></div>
                                </div>
                            </Modal>
                        }

                        {appContent &&
                            <Modal
                                isOpen={showAppContentModal}
                                isLoading={false}
                                onClose={setShowAppContentModal}>
                                <div className={`${COMPONENT_CLASS}__video-container-modal`}>
                                    <div dangerouslySetInnerHTML={{ __html: appContent }}></div>
                                </div>
                            </Modal>
                        }

                        <Modal
                            isOpen={showQrCodeModal}
                            isLoading={false}
                            onClose={setShowQrCodeModal}
                        >
                            <div className="flex flex-col justify-center items-center text-center">
                                <div>
                                    <h1 className="text-center">Get Started With Symmio</h1>
                                </div>
                                <div className="w-4/5 flex flex-col gap-8 justify-center">
                                    <div className="text-sm font-light leading-6">
                                        You've unlocked access to your wellness program – your personalized gateway to long-term health and wellness. Discover your full potential while keeping focused on meeting your wellness goals in an easy-to-use app available on any mobile device.
                                    </div>
                                    <QrCodeCard email={state.user?.email} />
                                </div>
                            </div>
                        </Modal>

                        <Modal
                            isOpen={openRetestModal}
                            isLoading={false}
                            onClose={setOpenRetestModal}
                            defaultModalActions={true}
                            onSubmit={() => {
                                if (wellnessProgram && state.user && state.user.id && mskScore) {
                                    UserUtil.handleRetestClick(state.user, wellnessProgram, mskScore).then(() => {
                                        setWellnessProgram(undefined);
                                        history.push("/movement?retake=true");
                                    })
                                }
                            }}
                            onCancel={() => setOpenRetestModal(false)}
                            title="Request Functional Wellness Retest"
                            submitButtonText="Yes"
                            cancelButtonText="Cancel"
                        >
                            <div className="mb-6">
                                <p className="text-sm font-light mb-4">
                                    Retesting your Functional Wellness will <strong>expire your current wellness program.</strong>
                                    <br />
                                    <br />
                                    Are you sure you want to proceed with this retest?
                                </p>
                            </div>
                        </Modal>
                        <div className={`${COMPONENT_CLASS}__user-menu-container`}>
                            {!showMainScreen &&
                                <div className={`${COMPONENT_CLASS}__back`}>
                                    <Button
                                        type="back"
                                        buttonText="Back"
                                        buttonStyle="secondary"
                                        onClick={() => setShowMainScreen(true)} />
                                </div>
                            }

                            <div className="user-menu flex-grow">
                                <AssessmentsUserMenu profileImage={false} />
                            </div>
                        </div>
                        {
                            showMainScreen
                                ?
                                <BaseCardLayout>
                                    <div className="w-full">
                                        <header className="pt-20 px-4 md:px-16">
                                            {showChargeFailedBanner && (
                                                <WarningItem
                                                    iconColor="#E7514F"
                                                    backgroundColor="#FFE9E5"
                                                    title="Payment failed"
                                                    text="There was an error processing your Symmio subscription payment. Make sure your payment information is correct and up to date, or try a different payment method."
                                                    buttonText="Update Payment Details"
                                                    onButtonClick={async () => await SubscriptionService.getCustomerPortalSession(state.user, orgSub, false)}
                                                />
                                            )}
                                            <h1 className="text-center">Hi {state.user?.firstName ?? "..."}</h1>

                                            {state.user?.administeredRetake
                                                ?
                                                <>
                                                    <p className="text-center">
                                                        Your wellness administrator has requested for you to retest your Functional Wellness.  Please complete your retest below
                                                    </p>
                                                    <div className="flex justify-center mx-auto w-max max-w-full mt-6" style={{ minWidth: "66.667%" }}>
                                                        <Card
                                                            imageUrl="public/today/lifestyle-movement.png"
                                                            type={CardTypes.Retake}
                                                            title="Retest Your Functional Wellness Assessment"
                                                            duration={15}
                                                            grow={true}
                                                        />
                                                    </div>

                                                    <hr className="border-black-gray-5 h-px" style={{ margin: "26px 0" }} />

                                                    {mskScore && mskScore.created &&
                                                        <div className="text-center">
                                                            <h2>Your Last Assessment</h2>
                                                            <p>{moment(mskScore.created.seconds * 1000).format("MMM DD, YYYY")}</p>
                                                        </div>
                                                    }

                                                </>
                                                :
                                                <p className="text-center">
                                                    Thank you for completing your assessments!
                                                </p>
                                            }

                                            <div className="py-6 flex flex-wrap justify-center gap-5">
                                                <div className={`${COMPONENT_CLASS}__score-section-card`}>
                                                    <h3 className="mb-4">
                                                        Overall Score
                                                    </h3>
                                                    <div className={`${COMPONENT_CLASS}__donut-chart`}>
                                                        <DonutChart
                                                            percentage={mskScore?.percentage}
                                                            title="Wellness Score"
                                                        />
                                                    </div>
                                                    <div className="text-xs text-center font-light my-4">Average score for 30-35 male is 70</div>
                                                    {(state.user?.isSuperAdmin || !state.user?.wellnessProgramId || !wellnessProgram || !wellnessProgram.isCurrent || state.user.symmioAccess !== SymmioAccessType.AppLicense || state.user?.administeredRetake) &&
                                                        <div>
                                                            <Button
                                                                buttonStyle="secondary"
                                                                buttonText="Retest"
                                                                onClick={() => {
                                                                    if (!state.user?.wellnessProgramId || !wellnessProgram || !wellnessProgram.isCurrent) {
                                                                        history.push("/movement?retake=true");
                                                                    }
                                                                    else {
                                                                        setOpenRetestModal(true);
                                                                    }
                                                                }} />
                                                        </div>
                                                    }
                                                </div>

                                                <div className="flex flex-col gap-5">
                                                    <div className={`${COMPONENT_CLASS}__score-section-card`}>
                                                        <h3 className="flex gap-3 items-center justify-center mb-4">
                                                            MSK Health
                                                            {questionMarkVideoEmbed &&
                                                                <div className='cursor-pointer' onClick={() => setShowQuestionMarkModal(true)}>
                                                                    <QuestionMarkIcon />
                                                                </div>
                                                            }
                                                        </h3>
                                                        <div className="w-full h-64">
                                                            <RiskChartVertical
                                                                heightPercentage={100 - riskChartPercentage}
                                                                score={mskScore?.riskFactor?.percentage}
                                                                breakpoints={[
                                                                    { min: 0, max: 0, label: "Optimal", bgColor: "#8BE3CE" },
                                                                    { min: 1, max: 27, label: "Acceptable", bgColor: "#ADDFFD" },
                                                                    { min: 28, max: 72, label: "Low", bgColor: "#FFC79C" },
                                                                    { min: 73, max: 100, label: "Very Low", bgColor: "#FFA998" },
                                                                ]}
                                                                decimals={false}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className={`${COMPONENT_CLASS}__score-section-card`}>
                                                        <div className="w-fit flex gap-1 items-center justify-center">
                                                            <div>
                                                                <PainLocationsIcon />
                                                            </div>
                                                            <div className="font-bold text-2xl">
                                                                {mskScore && latestAssessmentResponses &&
                                                                    MskScoreUtil.getNumberOfPainLocations(mskScore, latestAssessmentResponses)
                                                                }
                                                            </div>
                                                            <div className="uppercase font-bold text-10">
                                                                Pain Locations<br />Identified
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex flex-col gap-5">
                                                    {mskScore && mskScore.focusAreas && mskScore.focusAreas.length > 0 &&
                                                        <FocusAreaCard focusAreas={mskScore.focusAreas} title="Areas of Focus" />
                                                    }

                                                    <GrayFooterCard mainText={lastAssessment} subText="LAST ASSESSMENT" footerText={lastAssessmentDateInDays} />
                                                </div>
                                            </div>
                                        </header>
                                        <main className="px-4 md:px-16">
                                            <div className={`${COMPONENT_CLASS}__report-section`}>
                                                <p>Download your full report to see your detailed assessment results.</p>
                                                <p className="italic text-center" style={{ fontSize: "12px" }}>Please disable popup blocker to download your report.</p>
                                                <div className="flex items-center justify-center">
                                                    {downloadReportButton}
                                                </div>
                                            </div>
                                            {(showMentalHealthCard || showPainCard) &&
                                                <div>
                                                    <div>
                                                        <h2 className="mt-6 mb-4">Your Next Steps</h2>
                                                        <div className={`${COMPONENT_CLASS}__video-section`}>
                                                            {appContentVideo &&
                                                                <div className="cursor-pointer" style={{ width: "300px", maxWidth: "100%" }} onClick={() => setShowAppContentModal(true)}>
                                                                    <div className={`${COMPONENT_CLASS}__video-container`}>
                                                                        <div dangerouslySetInnerHTML={{ __html: appContentVideo }}></div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className={`${COMPONENT_CLASS}__prof-consultation-text ${appContentVideo ? "" : "items-center text-center"}`}>
                                                                <h3 className="m-0">Professional Consultation</h3>
                                                                <p>
                                                                    {highRiskText}
                                                                </p>
                                                                {appContent &&
                                                                    <div className="mx-auto md:m-0">
                                                                        {getConnectedButton}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </main>
                                        <footer className="pb-20 px-4 md:px-16">
                                            {
                                                state.user && state.user.symmioAccess === SymmioAccessType.AppLicense
                                                    ?
                                                    <>
                                                        <h2 className="mt-6 mb-4">Your Wellness Program</h2>
                                                        <div className={`${COMPONENT_CLASS}__wellness-section`}>
                                                            <div className="max-w-full w-80 flex flex-col justify-center items-center gap-5">
                                                                <img src={wellnessProgramImg} alt="Your Wellness Program" />
                                                                <div>
                                                                    {openSecondScreenButton}
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-col flex-1 justify-center">
                                                                <h3 className="m-0">Start Your Personalized Wellness Journey with Symmio</h3>
                                                                <p className="mt-2 mb-6">
                                                                    {yourWellnessProgramText
                                                                        ?
                                                                        yourWellnessProgramText
                                                                        :
                                                                        <>
                                                                            <strong>{state.organization.name}</strong> has granted access to your wellness program – your personalized gateway to long-term health and wellness. Discover your full potential while keeping focused on meeting your wellness goals in an easy-to-use app available on any mobile device.
                                                                        </>
                                                                    }
                                                                </p>
                                                                <div className="bg-white rounded-xl py-4 px-6">
                                                                    <div className="flex flex-row gap-x-6 gap-y-6 items-center justify-center flex-wrap sm:flex-nowrap">
                                                                        <div style={{ minWidth: "76px", maxWidth: "76px", minHeight: "76px", maxHeight: "76px" }}>
                                                                            <img src={qrCodeImg} alt="QR Code" />
                                                                        </div>
                                                                        <div className="flex flex-col gap-3 justify-center items-center md:items-baseline">
                                                                            <div className="text-center text-xs font-light w-4/5">
                                                                                Scan the QR code or download the Symmio app.
                                                                            </div>

                                                                            <div className="flex flex-row gap-6">
                                                                                <a href="https://apps.apple.com/us/app/symmio/id1605214846" target="__blank"><img src={appStoreImg} alt="App Store" style={{ minWidth: "94px", maxWidth: "94px" }} /></a>
                                                                                <a href="https://play.google.com/store/apps/details?id=com.functionalmovementsystems.symmio&hl=en_CA&gl=US" target="__blank"><img src={googlePlayImg} alt="Google Play" style={{ minWidth: "103px", maxWidth: "103px" }} /></a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="bg-white rounded-xl py-4 px-6 mt-6">
                                                                    <div className="text-sm text-center">
                                                                        Login with
                                                                        <br />
                                                                        <span className="font-bold">{state.user.email}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        {showYourWellnessProgramSectionWithoutLicense &&
                                                            <>
                                                                <h2 className="mt-6 mb-4">Your Wellness Program</h2>
                                                                <div className={`${COMPONENT_CLASS}__wellness-section`}>
                                                                    <div className={`${COMPONENT_CLASS}__wellness-image-wrapper`}>
                                                                        <img src={wellnessProgramImg} alt="Your Wellness Program" />
                                                                    </div>
                                                                    <div className="flex flex-col flex-1 justify-center">
                                                                        <h3 className="m-0">Start Your Personalized Wellness Journey with Symmio</h3>
                                                                        <p className="mt-2 mb-6">
                                                                            We’ve created a Wellness Program tailored to your unique needs based on your assessment. Your custom Symmio Wellness Program delivers daily corrective exercises and expert content to help you unlock your full potential.
                                                                        </p>
                                                                        <div className="bg-white rounded-xl py-4 px-6">
                                                                            <p><b>Your Personal Wellness Program:</b></p>
                                                                            <div className="flex gap-2 my-3">
                                                                                <span className="flex items-center justify-center" style={{ width: "24px" }}><ExerciseIcon /></span><span className="text-sm"><strong>14</strong> Corrective Exercises</span>
                                                                            </div>
                                                                            <div className="flex gap-2">
                                                                                <span className="flex items-center justify-center" style={{ width: "24px" }}><LightbulbIcon /></span><span className="text-sm"><strong>12</strong> Educational Articles</span>
                                                                            </div>
                                                                            <div className="mt-4">
                                                                                {openSecondScreenButton}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                            }

                                        </footer>
                                    </div>
                                </BaseCardLayout >
                                :
                                <>
                                    <BaseCardLayout>
                                        <div className="w-full">
                                            <div className="text-white py-16 px-4 md:px-16" style={{ backgroundColor: "#10151B", borderRadius: "40px 40px 0 0" }}>
                                                <div>
                                                    <img src={logoImage} alt="Symmio Logo" />
                                                </div>
                                                <div className="flex flex-wrap justify-between mt-4 items-center gap-8">
                                                    <div className={`${COMPONENT_CLASS}__header-companion`}>
                                                        <h1 className="text-white">Meet your new health and wellness companion.</h1>
                                                        <p className="mb-4">
                                                            Take the first step towards better health and wellness with Symmio - an easy-to-use app that helps you discover your full potential.
                                                        </p>
                                                        <div className="flex justify-center md:justify-start">
                                                            {joinNowButton(true)}
                                                        </div>
                                                    </div>
                                                    <div className={`${COMPONENT_CLASS}__video-companion`}>
                                                        {secondScreenVideoEmbed
                                                            ?
                                                            <div className={`${COMPONENT_CLASS}__video-wrapper`} dangerouslySetInnerHTML={{ __html: secondScreenVideoEmbed }}></div>
                                                            :
                                                            <img src={videoReplacementImg} alt="Symmio Mobile App" />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="py-4 px-4 md:px-16 bg-black-gray-5 flex flex-col items-center text-center">
                                                <div className="font-medium">Functional Movement Systems is Trusted by</div>
                                                <div className={`${COMPONENT_CLASS}__fms-clients`}>
                                                    <div>
                                                        <img className="max-h-12" src={nflImg} alt="NFL" />
                                                    </div>
                                                    <div>
                                                        <img className="max-h-8" src={premierLeagueImg} alt="Premier League" />
                                                    </div>
                                                    <div>
                                                        <img className="max-h-5" src={equinoxImg} alt="Equinox" />
                                                    </div>
                                                    <div>
                                                        <img className="max-h-4" src={cardinalGlassImg} alt="Cardinal Glass Industries" />
                                                    </div>
                                                    <div>
                                                        <img className="max-h-8" src={mlbImg} alt="Major League Baseball" />
                                                    </div>
                                                    <div>
                                                        <img className="max-h-12" src={usArmyImg} alt="U.S. Army" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="py-16 px-4 md:px-16">
                                                <h2>Start Your Health & Wellness Journey</h2>
                                                <p className="text-center py-6">
                                                    Every body is unique. So are past experiences, current daily habits, and general mental and emotional states. All of that contributes to overall health and well-being. Symmio assesses, prioritizes, and tracks each of the 8 risk factors that determine your functional health and wellness.
                                                </p>
                                                <div className="flex flex-wrap justify-center md:justify-between gap-6">
                                                    <div className={`${COMPONENT_CLASS}__focus-area-vertical`}>
                                                        <div style={{ backgroundColor: "#ADDFFD" }}>
                                                            <span>
                                                                <MovementIcon style={{ backgroundColor: "#ADDFFD" }} />
                                                            </span>
                                                        </div>
                                                        Movement<br />Health
                                                    </div>

                                                    <div className={`${COMPONENT_CLASS}__focus-area-vertical`}>
                                                        <div style={{ backgroundColor: "#8BE3CE" }}>
                                                            <span>
                                                                <BehavioralHealthIcon style={{ backgroundColor: "#8BE3CE" }} />
                                                            </span>
                                                        </div>
                                                        Behavioral<br />Health
                                                    </div>

                                                    <div className={`${COMPONENT_CLASS}__focus-area-vertical`}>
                                                        <div style={{ backgroundColor: "#FFA998" }}>
                                                            <span>
                                                                <InjuryHistoryIcon style={{ backgroundColor: "#FFA998" }} />
                                                            </span>
                                                        </div>
                                                        Injury<br />History
                                                    </div>

                                                    <div className={`${COMPONENT_CLASS}__focus-area-vertical`}>
                                                        <div style={{ backgroundColor: "#ADDFFD" }}>
                                                            <span>
                                                                <BreathingIcon style={{ backgroundColor: "#ADDFFD" }} />
                                                            </span>
                                                        </div>
                                                        Breathing<br />Quality
                                                    </div>

                                                    <div className={`${COMPONENT_CLASS}__focus-area-vertical`}>
                                                        <div style={{ backgroundColor: "#FFA998" }}>
                                                            <span>
                                                                <PhysicalActivityIcon style={{ backgroundColor: "#FFA998" }} />
                                                            </span>
                                                        </div>
                                                        Physical<br />Activity
                                                    </div>

                                                    <div className={`${COMPONENT_CLASS}__focus-area-vertical`}>
                                                        <div style={{ backgroundColor: "#FFC79C" }}>
                                                            <span>
                                                                <BodyCompIcon style={{ backgroundColor: "#FFC79C" }} />
                                                            </span>
                                                        </div>
                                                        Body<br />Composition
                                                    </div>

                                                    <div className={`${COMPONENT_CLASS}__focus-area-vertical`}>
                                                        <div style={{ backgroundColor: "#CEBAF8" }}>
                                                            <span>
                                                                <SleepIcon style={{ backgroundColor: "#CEBAF8" }} />
                                                            </span>
                                                        </div>
                                                        Sleep<br />Wellness
                                                    </div>

                                                    <div className={`${COMPONENT_CLASS}__focus-area-vertical`}>
                                                        <div style={{ backgroundColor: "#8BE3CE" }}>
                                                            <span>
                                                                <NutritionIcon style={{ backgroundColor: "#8BE3CE" }} />
                                                            </span>
                                                        </div>
                                                        Nutritional<br />Awareness
                                                    </div>
                                                </div>
                                                <div className="py-16">
                                                    <h2>How it Works</h2>
                                                    <div className="flex flex-wrap gap-6 mt-6 justify-center">
                                                        <div className={`${COMPONENT_CLASS}__step`}>
                                                            <div className="flex justify-between items-center">
                                                                <div className={`${COMPONENT_CLASS}__card-number`}>1</div>
                                                                <div className={`${COMPONENT_CLASS}__badge`}>
                                                                    <CheckmarkIcon className="w-3 h-auto" />
                                                                    DONE
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h3 className="mt-4 mb-2">Complete Your Wellness Assessment</h3>
                                                                <p>
                                                                    Complete your Movement and Lifestyle assessments to determine how to enhance every aspect of your health and well-being.
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className={`${COMPONENT_CLASS}__step`}>
                                                            <div className="flex justify-between items-center">
                                                                <div className={`${COMPONENT_CLASS}__card-number`}>2</div>
                                                            </div>
                                                            <div>
                                                                <h3 className="mt-4 mb-2">Get Your Personalized Wellness Plan</h3>
                                                                <p>
                                                                    Get daily movement exercises tailored to your unique needs, along with informative content and quick tips.
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className={`${COMPONENT_CLASS}__step`}>
                                                            <div className="flex justify-between items-center">
                                                                <div className={`${COMPONENT_CLASS}__card-number`}>3</div>
                                                            </div>
                                                            <div>
                                                                <h3 className="mt-4 mb-2">Track Your Health & Wellness Progress</h3>
                                                                <p>
                                                                    Track your progress over time and see how your overall health and wellness has improved, and which areas still need attention.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex justify-center items-center">
                                                    {joinNowButton(false)}
                                                </div>
                                                <div className="pt-16">
                                                    <h2>Tailored Wellness Plans for Your Unique Journey</h2>
                                                    <p className="mt-2 mb-6 text-center">
                                                        Experience personalized plans based on your Wellness assessment, with adaptive programs to meet your evolving needs.
                                                    </p>

                                                    <div>
                                                        <div className="flex flex-wrap items-center gap-6 mb-16">
                                                            <div className={`${COMPONENT_CLASS}__card-image-wrapper`}>
                                                                <img className={`${COMPONENT_CLASS}__card-image`} src={todayImg} alt="Mobile App" />
                                                            </div>
                                                            <div className="flex flex-col gap-6 flex-1">
                                                                <div className="flex gap-5">
                                                                    <div style={{ minWidth: "2rem", maxWidth: "2rem" }}>
                                                                        <WellnessScoreIcon />
                                                                    </div>
                                                                    <div className="flex flex-col">
                                                                        <h3 className="mb-1">Wellness Score</h3>
                                                                        <p>
                                                                            Assess how healthy you are by measuring your overall health, movement, and lifestyle behaviors.
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className="flex gap-5">
                                                                    <div style={{ minWidth: "2rem", maxWidth: "2rem" }}>
                                                                        <FocusAreaIcon />
                                                                    </div>
                                                                    <div className="flex flex-col">
                                                                        <h3 className="mb-1">Areas of Focus</h3>
                                                                        <p>
                                                                            Identify your greatest opportunities for improvement.
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className="flex gap-5">
                                                                    <div style={{ minWidth: "2rem", maxWidth: "2rem" }}>
                                                                        <ExerciseIcon />
                                                                    </div>
                                                                    <div className="flex flex-col">
                                                                        <h3 className="mb-1">Corrective Exercises</h3>
                                                                        <p>
                                                                            Get daily movement exercises tailored to your unique needs.
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className="flex gap-5">
                                                                    <div style={{ minWidth: "2rem", maxWidth: "2rem" }}>
                                                                        <LightbulbIcon />
                                                                    </div>
                                                                    <div className="flex flex-col">
                                                                        <h3 className="mb-1">Education & Tips</h3>
                                                                        <p>
                                                                            Get insightful content and targeted suggestions related to your focus areas.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="flex flex-wrap items-center gap-6">
                                                            <div className={`${COMPONENT_CLASS}__card-image-text`}>
                                                                <h2>Research-Backed and Expert-Led to Help You Move and Feel Better Daily</h2>
                                                                <p className="mt-2 mb-6">
                                                                    Gain complete awareness of your health and wellness with Symmio.
                                                                </p>
                                                                <div className="flex flex-col gap-4">
                                                                    <div className="flex gap-5">
                                                                        <div>
                                                                            <CheckmarkWhiteIcon className="rounded-full" style={{ backgroundColor: "#00DFBC", color: "#FFF" }} />
                                                                        </div>
                                                                        <p>
                                                                            Implement immediate correction
                                                                        </p>
                                                                    </div>

                                                                    <div className="flex gap-5">
                                                                        <div>
                                                                            <CheckmarkWhiteIcon className="rounded-full" style={{ backgroundColor: "#00DFBC", color: "#FFF" }} />
                                                                        </div>
                                                                        <p>
                                                                            Unlock long-term health benefits
                                                                        </p>
                                                                    </div>

                                                                    <div className="flex gap-5">
                                                                        <div>
                                                                            <CheckmarkWhiteIcon className="rounded-full" style={{ backgroundColor: "#00DFBC", color: "#FFF" }} />
                                                                        </div>
                                                                        <p>
                                                                            Reduce your overall risk of potential injury
                                                                        </p>
                                                                    </div>

                                                                    <div className="flex gap-5">
                                                                        <div>
                                                                            <CheckmarkWhiteIcon className="rounded-full" style={{ backgroundColor: "#00DFBC", color: "#FFF" }} />
                                                                        </div>
                                                                        <p>
                                                                            Balance your mental, emotional, nutritional, and physical well-being
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={`${COMPONENT_CLASS}__card-image-wrapper`}>
                                                                <img className={`${COMPONENT_CLASS}__card-image`} src={assessmentImg} alt="Mobile App Assessments" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="py-10 px-4 md:px-16 flex flex-col gap-4 items-center justify-center text-center" style={{ backgroundColor: "#ADDFFD" }}>
                                                <h2>Live your everyday life without pain or limitations.</h2>
                                                <p>Bring Symmio with you on any device and seamlessly integrate it into your daily routine.</p>
                                                <div>{joinNowButton(false)}</div>
                                            </div>
                                            <div className="py-16 px-4 md:px-16">
                                                <h2>Don’t take our word for it</h2>
                                                <p className="mt-2 mb-6 text-center">Here’s what other Symmio users are saying.</p>

                                                <div className="flex flex-wrap gap-6 justify-center">
                                                    <div className={`${COMPONENT_CLASS}__review`}>
                                                        <div>
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                        </div>
                                                        <p>
                                                            “I've suffered from low back pain for quite a few years and I haven't been able to touch my toes for the last 30+ years. After 1 week of Symmio I could touch my toes and no longer woke up with low back pain.”
                                                        </p>
                                                        <p style={{ fontSize: "14px" }}>
                                                            <b>Don U.</b>
                                                            <br />
                                                            Symmio User
                                                        </p>
                                                    </div>

                                                    <div className={`${COMPONENT_CLASS}__review`}>
                                                        <div>
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                        </div>
                                                        <p>
                                                            “Very helpful and useful app. Love the resources and articles. The exercises are easy to follow and can be done almost everywhere.”
                                                        </p>
                                                        <p style={{ fontSize: "14px" }}>
                                                            <b>Tiffany W.</b>
                                                            <br />
                                                            Symmio User
                                                        </p>
                                                    </div>

                                                    <div className={`${COMPONENT_CLASS}__review`}>
                                                        <div>
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                        </div>
                                                        <p>
                                                            “The approach taken is wonderful for all levels of health and fitness as it takes time to explain, educate and even showcase videos for understanding and implementing improvement.”
                                                        </p>
                                                        <p style={{ fontSize: "14px" }}>
                                                            <b>Steven D.</b>
                                                            <br />
                                                            Symmio User
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="py-16 px-4 md:px-16">
                                                <h2 className="mb-6">Frequently Asked Questions</h2>
                                                <FaqAccordion faqs={faqs} setFaqs={setFaqs} />
                                            </div>
                                            <div style={{ borderRadius: "0 0 40px 40px" }} className="py-16 px-4 md:px-16 bg-blue-light flex flex-col gap-10 justify-center items-center">
                                                <h2>Unlock your personalized gateway to better health and wellness today.</h2>
                                                <div>
                                                    {joinNowButton(false)}
                                                </div>
                                            </div>
                                        </div>
                                    </BaseCardLayout >
                                </>
                        }
                    </>
                    :
                    <>
                        <div className={`${COMPONENT_CLASS}__user-menu-container`}>
                            <div className="user-menu flex-grow">
                                <AssessmentsUserMenu profileImage={false} />
                            </div>
                        </div>

                        <BaseCardLayout>
                            <div className="w-full">
                                <header className="pt-20 px-4 md:px-16">
                                    <h1 className="text-center">Hi {state.user?.firstName ?? "..."}</h1>
                                    <p className="text-center">
                                        Please complete the following assessments.
                                    </p>
                                </header>

                                <main className="flex flex-wrap justify-center pt-20 pb-32 px-4 md:px-16">
                                    <Card
                                        completedMs={mskScore?.movementScore?.percentage != null ? 1 : undefined}
                                        imageUrl="public/today/movement-assessment.png"
                                        type={CardTypes.Movement}
                                        title="Complete Your Movement Assessment"
                                        duration={15}
                                    />
                                    <Card
                                        completedMs={mskScore?.lifestyleScore?.percentage != null ? 1 : undefined}
                                        imageUrl="public/today/lifestyle-assessment.png"
                                        type={CardTypes.Lifestyle}
                                        title="Complete Your Lifestyle Assessment"
                                        duration={15}
                                    />
                                </main>

                                <footer className="border-t-black-gray-20 pt-12 pb-14 px-12 md:px-24" style={{ borderTopWidth: "1px" }}>
                                    <p className="mb-3">About MSK Health / How this information is going to be used.</p>
                                    <p>
                                        When was the last time you took a look at the mental, emotional, physical, and environmental challenges and decisions that you put your body through day in and day out? These assessments take a look at all of those areas and lets you know how you’re doing and where to focus. (Referencing what’s in the reports.)
                                    </p>
                                </footer>
                            </div>
                        </BaseCardLayout >
                    </>
                }
            </div >
    );
};

export default AssessmentResults;