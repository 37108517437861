/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import InactivityModal from "./inactivity-modal";
import { useAuthState } from "../../utilities/contexts/auth-state-context";

/**
 * Represents buttons on the Inactivity modal.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const Inactivity: React.FC = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { state } = useAuthState();
    const INACTIVITY_TIMEOUT = 45 * 60 * 1000; // 45 minutes
    // Reset activity and update last activity time in localStorage
    const resetActivity = useCallback(() => {
        localStorage.setItem('lastActivity', Date.now().toString());
    }, []);

    // Check for inactivity
    useEffect(() => {
        const interval = setInterval(() => {
            const lastActivity = parseInt(localStorage.getItem('lastActivity') || '0', 10);
            const now = Date.now();
            if (now - lastActivity > INACTIVITY_TIMEOUT) {
                // if user is not logged in, modal will never pop up
                setIsModalOpen(state.authenticated);
            }
        }, 1000); // Check every second

        return () => clearInterval(interval);
    }, [state.authenticated]);

    // Listen for user activity
    useEffect(() => {
        const handleActivity = () => resetActivity();
        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keypress', handleActivity);
        window.addEventListener('click', handleActivity);

        return () => {
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keypress', handleActivity);
            window.removeEventListener('click', handleActivity);

        };
    }, [resetActivity]);

    return (
        <>
            <InactivityModal
                resetActivity={resetActivity}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen} />
        </>

    );
};

export default Inactivity;