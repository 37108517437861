import { QuestionIds } from "../../../models/enumerations/question-ids";
import { ReportAnswer } from "../../../models/enumerations/report-answer";
import AssessmentResponse from "../../../models/interfaces/assessment-response.entity";
import { MovementAnswer } from "../../../models/interfaces/reports/movement-answer";
import { MovementBalanceAnswer } from "../../../models/interfaces/reports/movement-balance-answer";
import { MovementHealthResponse } from "../../../models/interfaces/reports/movement-health-response";
import { ServiceBase } from "../service-base";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AssessmentResponsePathParams {}

export interface AssessmentResponseQueryParams {
    userId: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = `/assessment/response`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const getMovementAssessmentResponses = (movementResponse: AssessmentResponse[]) => {
    // Movement
    let toeTouchReachLeft : MovementAnswer = {itemOne: true, itemTwo: true};
    let toeTouchReachRight : MovementAnswer = {itemOne: true, itemTwo: true};
    let toeTouchQPain : string = ReportAnswer[ReportAnswer.A];
    let shoulderMobilityReachLeft : MovementAnswer = {itemOne: true, itemTwo: true};
    let shoulderMobilityReachRight : MovementAnswer = {itemOne: true, itemTwo: true};
    let shoulderMobilityQPain : string = ReportAnswer[ReportAnswer.A];
    let rotationReachLeft : MovementAnswer = {itemOne: true, itemTwo: true};
    let rotationReachRight : MovementAnswer = {itemOne: true, itemTwo: true};
    let rotationQPain : string = ReportAnswer[ReportAnswer.A];
    let squatLow : MovementAnswer = {itemOne: true, itemTwo: true, itemThree: true};
    let squatQPain : string = ReportAnswer[ReportAnswer.A];
    let firstBalanceReach : MovementAnswer = {itemOne: true, itemTwo: true};
    let secondBalanceReachLeft : MovementBalanceAnswer = {success: true, attempts: 0};
    let secondBalanceReachRight : MovementBalanceAnswer = {success: true, attempts: 0};
    let thirdBalanceReachLeft : MovementBalanceAnswer = {success: true, attempts: 0};
    let thirdBalanceReachRight : MovementBalanceAnswer = {success: true, attempts: 0};
    let balanceQPain : string = ReportAnswer[ReportAnswer.A];
    let shoulderClearingScore = true
    let shoulderClearingQPain: string = ReportAnswer[ReportAnswer.A]
    let spineClearingScore = true;
    let spineClearingQPain: string = ReportAnswer[ReportAnswer.A]
    
    const toeTouchLeftResponse = movementResponse && movementResponse.length > 0 ? movementResponse[0]?.responses.find((response: any) => response.questionId === QuestionIds.TOE_TOUCH_REACH_LEFT) : undefined
    if (toeTouchLeftResponse) {
            if (toeTouchLeftResponse.answerId === ReportAnswer.B) {
                toeTouchReachLeft.itemTwo = false;
            }
            else if (toeTouchLeftResponse.answerId === ReportAnswer.C) {
                toeTouchReachLeft.itemOne = false
                toeTouchReachLeft.itemTwo = false;
            }
        }
    
        const toeTouchRightResponse = movementResponse && movementResponse.length > 0 ? movementResponse[0]?.responses.find((response: any) => response.questionId === QuestionIds.TOE_TOUCH_REACH_RIGHT) : undefined
        if (toeTouchRightResponse) {
            if (toeTouchRightResponse.answerId === ReportAnswer.B) {
                toeTouchReachRight.itemTwo = false;
            }
            else if (toeTouchRightResponse.answerId === ReportAnswer.C) {
                toeTouchReachRight.itemOne = false
                toeTouchReachRight.itemTwo = false;
            }
        }
        const toeTouchQPainResponse = movementResponse && movementResponse.length > 0 ? movementResponse[0]?.responses.find((response: any) => response.questionId === QuestionIds.TOE_TOUCH_QPAIN) : undefined
        if (toeTouchQPainResponse && toeTouchQPainResponse.answerId) {
            toeTouchQPain = toeTouchQPainResponse.answerId
            // if (toeTouchQPainResponse.answerId !== ReportAnswer.A) {
            //     result.movementPain = true
            // }
        }
    
        const shoulderMobilityReachLeftResponse = movementResponse && movementResponse.length > 0 ? movementResponse[0]?.responses.find((response: any) => response.questionId === QuestionIds.SHOULDER_MOBILITY_REACH_LEFT) : undefined
        if (shoulderMobilityReachLeftResponse) {
            if (shoulderMobilityReachLeftResponse.answerId === ReportAnswer.B) {
                shoulderMobilityReachLeft.itemTwo = false
            }
            else if (shoulderMobilityReachLeftResponse.answerId === ReportAnswer.C) {
                shoulderMobilityReachLeft.itemOne = false
                shoulderMobilityReachLeft.itemTwo = false
            }
        }
        const shoulderMobilityReachRightResponse = movementResponse && movementResponse.length > 0 ? movementResponse[0]?.responses.find((response: any) => response.questionId === QuestionIds.SHOULDER_MOBILITY_REACH_RIGHT) : undefined
        if (shoulderMobilityReachRightResponse) {
            if (shoulderMobilityReachRightResponse.answerId === ReportAnswer.B) {
                shoulderMobilityReachRight.itemTwo = false
            }
            else if (shoulderMobilityReachRightResponse.answerId === ReportAnswer.C) {
                shoulderMobilityReachRight.itemOne = false
                shoulderMobilityReachRight.itemTwo = false
            }
        }
    
        const shoulderMobilityQPainResponse = movementResponse && movementResponse.length > 0 ? movementResponse[0]?.responses.find((response: any) => response.questionId === QuestionIds.SHOULDER_MOBILITY_QPAIN) : undefined
        if (shoulderMobilityQPainResponse && shoulderMobilityQPainResponse.answerId) {
            shoulderMobilityQPain = shoulderMobilityQPainResponse.answerId
            // if (shoulderMobilityQPainResponse.answerId !== ReportAnswer.A) {
            //     result.movementPain = true
            // }
        }
    
        const rotationReachLeftResponse = movementResponse && movementResponse.length > 0 ? movementResponse[0]?.responses.find((response: any) => response.questionId === QuestionIds.ROTATION_REACH_LEFT) : undefined
        if (rotationReachLeftResponse) {
            if (rotationReachLeftResponse.answerId === ReportAnswer.B) {
                rotationReachLeft.itemTwo = false
            }
            else if (rotationReachLeftResponse.answerId === ReportAnswer.C) {
                rotationReachLeft.itemOne = false
                rotationReachLeft.itemTwo = false
            }
        }
        const rotationReachRightResponse = movementResponse && movementResponse.length > 0 ? movementResponse[0]?.responses.find((response: any) => response.questionId === QuestionIds.ROTATION_REACH_RIGHT) : undefined
        if (rotationReachRightResponse) {
            if (rotationReachRightResponse.answerId === ReportAnswer.B) {
                rotationReachRight.itemTwo = false
            }
            else if (rotationReachRightResponse.answerId === ReportAnswer.C) {
                rotationReachRight.itemOne = false
                rotationReachRight.itemTwo = false
            }
        }
        const rotationQPainResponse = movementResponse && movementResponse.length > 0 ? movementResponse[0]?.responses.find((response: any) => response.questionId === QuestionIds.ROTATION_QPAIN) : undefined
        if (rotationQPainResponse && rotationQPainResponse.answerId) {
            rotationQPain = rotationQPainResponse.answerId
            // if (rotationQPainResponse.answerId !== ReportAnswer.A) {
            //     result.movementPain = true
            // }
        }
        const squatLowResponse = movementResponse && movementResponse.length > 0 ? movementResponse[0]?.responses.find((response: any) => response.questionId === QuestionIds.SQUAT_LOW) : undefined
        if (squatLowResponse) {
            if (squatLowResponse.answerId === ReportAnswer.B) {
                squatLow.itemThree = false
            }
            else if (squatLowResponse.answerId === ReportAnswer.C) {
                squatLow.itemTwo = false
                squatLow.itemThree = false
            }
            else if (squatLowResponse.answerId === ReportAnswer.D) {
                squatLow.itemOne = false;
                squatLow.itemTwo = false
                squatLow.itemThree = false
            }
        }
        const squatQPainResponse = movementResponse && movementResponse.length > 0 ? movementResponse[0]?.responses.find((response: any) => response.questionId === QuestionIds.SQUAT_QPAIN) : undefined
        if (squatQPainResponse && squatQPainResponse.answerId) {
            squatQPain = squatQPainResponse.answerId
            // if (squatQPainResponse.answerId !== ReportAnswer.A) {
            //     result.movementPain = true
            // }
        }
        const firstBalanceReachResponse = movementResponse && movementResponse.length > 0 ? movementResponse[0]?.responses.find((response: any) => response.questionId === QuestionIds.BALANCE_REACH_1) : undefined
        if (firstBalanceReachResponse) {
            if (firstBalanceReachResponse.answerId === ReportAnswer.B) {
                firstBalanceReach.itemTwo = false
            }
            else if (firstBalanceReachResponse.answerId === ReportAnswer.C) {
                firstBalanceReach.itemOne = false
            }
            else if (firstBalanceReachResponse.answerId === ReportAnswer.D) {
                firstBalanceReach.itemOne = false
                firstBalanceReach.itemTwo = false
            }
        }
        const secondBalanceReachLeftResponse = movementResponse && movementResponse.length > 0 ? movementResponse[0]?.responses.find((response: any) => response.questionId === QuestionIds.BALANCE_REACH_2_LEFT) : undefined
        if (secondBalanceReachLeftResponse) {
            secondBalanceReachLeft.attempts = parseInt(secondBalanceReachLeftResponse.answerResponse)
            if (parseInt(secondBalanceReachLeftResponse.answerResponse) !== 5) {
                secondBalanceReachLeft.success = false
            }
        }
        const secondBalanceReachRightResponse = movementResponse && movementResponse.length > 0 ? movementResponse[0]?.responses.find((response: any) => response.questionId === QuestionIds.BALANCE_REACH_2_RIGHT) : undefined
        if (secondBalanceReachRightResponse) {
            secondBalanceReachRight.attempts = parseInt(secondBalanceReachRightResponse.answerResponse)
            if (parseInt(secondBalanceReachRightResponse.answerResponse) !== 5) {
                secondBalanceReachRight.success = false
            }
        }
        const thirdBalanceReachLeftResponse = movementResponse && movementResponse.length > 0 ? movementResponse[0]?.responses.find((response: any) => response.questionId === QuestionIds.BALANCE_REACH_25_LEFT) : undefined
        if (thirdBalanceReachLeftResponse) {
            thirdBalanceReachLeft.attempts = parseInt(thirdBalanceReachLeftResponse.answerResponse)
            if (parseInt(thirdBalanceReachLeftResponse.answerResponse) !== 5) {
                thirdBalanceReachLeft.success = false
            }
        }
        const thirdBalanceReachRightResponse = movementResponse && movementResponse.length > 0 ? movementResponse[0]?.responses.find((response: any) => response.questionId === QuestionIds.BALANCE_REACH_25_RIGHT) : undefined
        if (thirdBalanceReachRightResponse) {
            thirdBalanceReachRight.attempts = parseInt(thirdBalanceReachRightResponse.answerResponse)
            if (parseInt(thirdBalanceReachRightResponse.answerResponse) !== 5) {
                thirdBalanceReachRight.success = false
            }
        }
        const balanceQPainResponse = movementResponse && movementResponse.length > 0 ? movementResponse[0]?.responses.find((response: any) => response.questionId === QuestionIds.BALANCE_QPAIN) : undefined
        if (balanceQPainResponse && balanceQPainResponse.answerId) {
            balanceQPain = balanceQPainResponse.answerId
            // if (balanceQPainResponse.answerId !== ReportAnswer.A) {
            //     result.movementPain = true
            // }
        }
        const shoulderClearingResponse = movementResponse && movementResponse.length > 0 ? movementResponse[0]?.responses.find((response: any) => response.questionId === QuestionIds.SHOULDER_CLEARING_QPAIN) : undefined
        if (shoulderClearingResponse && shoulderClearingResponse.answerId) {
            shoulderClearingQPain = shoulderClearingResponse.answerId
            if (shoulderClearingResponse.answerId !== ReportAnswer.A) {
                shoulderClearingScore = false
                // result.movementPain = true
            }
        }
        const spineClearingResponse = movementResponse && movementResponse.length > 0 ? movementResponse[0]?.responses.find((response: any) => response.questionId === QuestionIds.SPINE_CLEARING_QPAIN) : undefined
        if (spineClearingResponse && spineClearingResponse.answerId) {
            spineClearingQPain = spineClearingResponse.answerId
            if (spineClearingResponse.answerId !== ReportAnswer.A) {
                spineClearingScore = false
                // result.movementPain = true
            }
        }
        const movementHealth = {
            toeTouchReachLeft: toeTouchReachLeft,
            toeTouchReachRight: toeTouchReachRight,
            toeTouchQPain: toeTouchQPain,
            shoulderMobilityReachLeft: shoulderMobilityReachLeft,
            shoulderMobilityReachRight: shoulderMobilityReachRight,
            shoulderMobilityQPain: shoulderMobilityQPain,
            rotationReachLeft: rotationReachLeft,
            rotationReachRight: rotationReachRight,
            rotationQPain: rotationQPain,
            squatLow: squatLow,
            squatQPain: squatQPain,
            firstBalanceReach: firstBalanceReach,
            secondBalanceReachLeft: secondBalanceReachLeft,
            secondBalanceReachRight: secondBalanceReachRight,
            thirdBalanceReachLeft: thirdBalanceReachLeft,
            thirdBalanceReachRight: thirdBalanceReachRight,
            balanceQPain: balanceQPain,
            shoulderClearingScore: shoulderClearingScore,
            shoulderClearingQPain: shoulderClearingQPain,
            spineClearingScore: spineClearingScore,
            spineClearingQPain: spineClearingQPain,
        } as MovementHealthResponse
    
        return movementHealth;
    }

const AssessmentResponseService = {
    get: ServiceBase.list<AssessmentResponse[], AssessmentResponseQueryParams>(
        baseEndpoint
    ),
    getMovementAssessmentResponses: getMovementAssessmentResponses,
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AssessmentResponseService;

// #endregion Exports
