import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import { CardTypes } from "../../models/enumerations/card-types";
import UserArticle from "../../models/interfaces/articles/user-article";
import { BookmarkResourceType } from "../../models/interfaces/bookmark";
import { CorrectiveExercise } from "../../models/interfaces/corrective-exercises/corrective-exercise";
import { IUser } from "../../models/interfaces/assessment-user";
import SkeletonCardList from "./skeleton-card-list";
import UserRetakeAssessment from "../../models/interfaces/user-retake-assessment";
import FirebaseStorage from "../../utilities/firebase-storage";
import { Utils } from "../../utilities/utils";

const COMPONENT_CLASS = "c-card";

export interface CardProps {
    children?: React.ReactNode;
    /**
     * A timestamp in milliseconds elapsed since January 1, 1970 00:00:00 UTC of
     * exactly when the card was completed, if defined and the timestamp is within
     * today, the card will receive a "done" badge
     */
    completedMs?: number;
    /**
     * The amount of time in minutes the card will take a person to complete
     */
    duration?: number;
    exercise?: CorrectiveExercise;
    imageUrl?: string;
    /**
     * Should the image consume the entire card's background
     */
    fullImage?: boolean;
    /**
     * Used for individual article routing
     * TODO : refactor into resourceUrl
     */
    itemId?: number;
    /**
     * Used for Lifestyle routing
     * TODO : refactor into resourceUrl
     */
    lifestyleGroupId?: string;
    onDismiss?: () => void;
    /**
     * Generic resource url to route to when card is clicked
     */
    resourceUrl?: string;
    subtitle?: string;
    title: string;
    /**
     * The type of card to display determines the header the card will receive
     */
    type?: CardTypes;
    user?: IUser;
    userArticle?: UserArticle;
    userRetake?: UserRetakeAssessment | null;
    resourceType?: BookmarkResourceType;
    cancelRedirect?: boolean;
    hideCursor?: boolean;
    grow?: boolean;
}

const Card: React.FC<CardProps> = (props) => {
    const history = useHistory();
    const storageUtil = new FirebaseStorage();
    let href = "";
    let hasImage = true;
    let componentClass = "";
    let imageClass = "";
    let isComplete = false;
    let fullWidth = false;

    switch (props.type) {
        case CardTypes.Retake:
            href = "/movement?retake=true";
            hasImage = props.imageUrl != null;
            imageClass = "-small -blue";
            break;
        case CardTypes.Error:
            componentClass = " -error";
            fullWidth = true;
            break;
        case CardTypes.Intro:
            componentClass = " -intro";
            fullWidth = true;
            break;
        case CardTypes.Movement:
            href = "/movement";
            hasImage = props.imageUrl != null;
            imageClass = "-small -blue";
            if (props.userRetake?.completedDateMs != null) {
                isComplete = true;
            }
            break;
        case CardTypes.Lifestyle:
            imageClass = "-green";
            if (props.lifestyleGroupId) {
                href = `/lifestyle?groupId=${props.lifestyleGroupId}`;
            } else {
                href = "/lifestyle";
                imageClass += " -small";
            }
            hasImage = props.imageUrl != null;
            if (props.userRetake?.completedDateMs != null) {
                isComplete = true;
            }
            break;
        case CardTypes.Flow:
            if (
                props.exercise ||
                props.resourceType === "corrective-exercise"
            ) {
                href = props.resourceUrl
                    ? props.resourceUrl
                    : "/corrective-flow";
            } else if (props.userArticle || props.resourceType === "article") {
                href = props.resourceUrl
                    ? props.resourceUrl
                    : `/article-flow?index=${props.itemId}`;
            }
            if (props.exercise?.isComplete || props.userArticle?.isComplete) {
                isComplete = true;
            }
            break;
        default:
            if (props.resourceUrl) {
                href = props.resourceUrl;
            }
            break;
    }

    // TODO: What do we do when they've completed it? Let it stay on "Done" state forever?
    if (
        !isComplete &&
        props.completedMs != null &&
        props.completedMs !== undefined
        // &&
        // DateUtil.isToday(props.completedMs)
    ) {
        isComplete = true;
    }

    if (isComplete) {
        componentClass += " -done";
    }

    if (props.fullImage) {
        componentClass += " full-image";
        fullWidth = true;
    }

    const handleCardClick = (): void => {
        if (href === "") {
            return;
        }
        history.push(href);
    };

    return (
        <div
            className={`${COMPONENT_CLASS}${componentClass} ${props.hideCursor ? "" : "cursor-pointer"} ${props.grow ? "grow" : ""}`}
            onClick={() => {
                if (!props.cancelRedirect) {
                    handleCardClick();
                }
            }}
            style={
                props.fullImage
                    ? {
                        backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(${props.imageUrl})`,
                    }
                    : undefined
            }>
            {
                // if
                props.onDismiss != null && (
                    <div className={`${COMPONENT_CLASS}__close`}>
                        <IonIcon
                            icon={closeOutline}
                            onClick={() => props.onDismiss!()}
                        />
                    </div>
                )
            }
            {
                // if
                !fullWidth && (
                    <div
                        className={`${COMPONENT_CLASS}__image ${hasImage ? "" : "-none"
                            } ${imageClass}`}>
                        {props.userArticle?.article?.imageUrl && (
                            <img
                                src={Utils.cleanImageUrl(props.userArticle.article.originalImageUrl)}
                                alt={`Article ${props.userArticle.article.title}`}
                            />
                        )}
                        {props.exercise?.videos &&
                            props.exercise.videos.length > 0 &&
                            props.exercise.videos[0].imageUrl && (
                                <img
                                    src={props.exercise.videos[0].imageUrl}
                                    alt={`Exercise ${props.exercise?.name}`}
                                />
                            )}
                        {props.imageUrl && !props.fullImage && (
                            <img
                                src={storageUtil.getMediaUrl(props.imageUrl)}
                                alt={props.title}
                                className={props.fullImage ? "full-width" : ""}
                            />
                        )}
                    </div>
                )
            }
            <div className={`${COMPONENT_CLASS}__content`}>
                <div className={`${COMPONENT_CLASS}__content__wrapper`}>
                    {
                        // if
                        props.subtitle && (
                            <div
                                className={`${COMPONENT_CLASS}__content__subtitle`}>
                                {props.subtitle}
                            </div>
                        )
                    }
                    <div
                        className={`${COMPONENT_CLASS}__content__title ${props.subtitle ? "-has-subtitle" : ""
                            }`}>
                        {props.title}
                    </div>
                    {
                        // if
                        props.duration != null && (
                            <div
                                className={`${COMPONENT_CLASS}__content__duration`}>
                                {`${props.duration} minute${props.duration === 1 ? "" : "s"
                                    }`}
                            </div>
                        )
                    }
                    {
                        // if
                        isComplete && (
                            <div
                                className={`${COMPONENT_CLASS}__content__complete`}>
                                Done
                            </div>
                        )
                    }
                    {
                        // if
                        props.type === CardTypes.Loading && (
                            <div>
                                <SkeletonCardList />
                            </div>
                        )
                    }
                    {props.children}
                </div>
            </div>
        </div>
    );
};

export default Card;
