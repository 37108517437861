import { useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import { ReactComponent as NotificationsNone } from "../../assets/icons/general-icons/notifications-none.svg";
import { ReactComponent as NotificationsUnread } from "../../assets/icons/general-icons/notifications-unread.svg";
import getFirebase from '../../utilities/firebase';
import { Loader } from '../loader/loader';
import { useHistory } from 'react-router-dom';
import { NotificationItem } from './notification-item';
import { collection, onSnapshot } from 'firebase/firestore';
import { Notification } from '../../models/interfaces/notification';
import NotificationService from '../../utilities/services/notification-service';
import { Button } from '../button/button';


const { db } = getFirebase();

const COMPONENT_CLASS = "c-notification-menu";

export interface NotificationMenuProps {
  profileImage?: boolean;
}

const NotificationMenu: React.FC<NotificationMenuProps> = (props) => {
  const { state, setState } = useAuthState();
  const [isSwitching,] = useState(false);
  const history = useHistory();
  const [mouseOverButton, setMouseOverButton] = useState(false)
  const [mouseOverMenu, setMouseOverMenu] = useState(false)
  const [notifications, setNotifications] = useState<Notification[]>();

  const timeoutDuration = 500
  let timeoutButton: string | number | NodeJS.Timeout | undefined;
  let timeoutMenu: string | number | NodeJS.Timeout | undefined;

  const onMouseEnterButton = () => {
    clearTimeout(timeoutButton)
    setMouseOverButton(true)
  }
  const onMouseLeaveButton = () => {
    timeoutButton = setTimeout(() => setMouseOverButton(false), timeoutDuration)
  }

  const onMouseEnterMenu = () => {
    clearTimeout(timeoutMenu)
    setMouseOverMenu(true)
  }
  const onMouseLeaveMenu = () => {
    timeoutMenu = setTimeout(() => setMouseOverMenu(false), timeoutDuration)
  }

  const onDelete = async (notificationId: string) => {
    await NotificationService.deleteById(notificationId);
    if (state.user && state.user.id && state.organization.id) {
      retrieveNotifications();
    }
  }

  const onRead = async (notificationId: string) => {
    if (state.notifications) {
      const notificationToRead = state.notifications.data.filter((item: any) => item.id === notificationId)[0];
      const readNotification: Notification = {
        ...notificationToRead,
        read: true,
      }
      await NotificationService.update(readNotification, state.user);
      if (state.user && state.user.id && state.organization.id) {
        retrieveNotifications();
      }
      history.push(notificationToRead && notificationToRead.redirectUrl ? notificationToRead?.redirectUrl : '')
    }
  }

  const markAllAsRead = async () => {
    if (state.user && state.user.id && state.organization.id) {
      await NotificationService.markAllAsRead(state.user.id, state.organization.id, state.user);
      retrieveNotifications();
    }
  }

  const clearAll = async () => {
    if (state.user && state.user.id && state.organization.id) {
      await NotificationService.clearAll(state.user.id, state.organization.id, state.user);
      setState((state) => ({ ...state, ...{ notifications: { data: [], count: 0 } } }));
    }
  }


  const show = (mouseOverMenu || mouseOverButton)

  const retrieveNotifications = async () => {
    if (state.user && state.user.id && state.organization.id && state.authenticated) {
      console.log("is this getting called?")
      const notificationsList = await NotificationService.getNotifications(state.user.id, state.organization.id);
      // eslint-disable-next-line array-callback-return
      notificationsList.sort((a: Notification, b: Notification) => {
        if (b.created && a.created) return b.created.toMillis() - a.created.toMillis()
      });
      setState((state) => ({
        ...state, ...{
          notifications: {
            data: notificationsList,
            count: notificationsList.filter((item: any) => item.read === false).length > 99
              ? '99+'
              : notificationsList?.filter((item: any) => item.read === false).length
          }
        }
      }));
    }
  }

  const getNotificationsCount = () => {
    return state.notifications?.count ?? 0;
  }

  // subscribe to changes in FB, if changes occur, retrieve them.
  useEffect(() => {
    const usersCollection = collection(db, 'notifications');
    const unsub = onSnapshot(usersCollection, (snapshot: any) => {
      const latestChange = snapshot.docChanges()[0];
      if (state.user && state.user.id && latestChange && latestChange.type === 'added' && latestChange.doc.data().receiverId === state.user.id) {
        retrieveNotifications();
      }
    });
    return () => unsub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.user]);

  useEffect(() => {
    // if state contains the notifications, or if notifications !== state notifications
    if (state.notifications && state.notifications.data && notifications !== state.notifications.data) {
      setNotifications(state.notifications.data);
    }
  }, [notifications, state.notifications]);

  return (
    <div className={`${COMPONENT_CLASS}`}>
      <Loader
        isVisible={isSwitching} />
      <Menu as="div">
        {({ open }) => (
          <>
            <div onMouseEnter={onMouseEnterButton}
              onMouseLeave={onMouseLeaveButton}
            >
              <Menu.Button className={`${COMPONENT_CLASS}__button`}>

                <span className="sr-only">Open user menu</span>
                <div className={`${COMPONENT_CLASS}__count hide-on-mobile`}>
                  {getNotificationsCount()}
                </div>
                {getNotificationsCount() > 0 ? (
                  <NotificationsUnread />
                ) : (
                  <NotificationsNone />
                )}

              </Menu.Button>
            </div>
            <Transition
              show={show}
            // enter="transition ease-out duration-100"
            // enterFrom="transform opacity-0 scale-95"
            // enterTo="transform opacity-100 scale-100"
            // leave="transition ease-in duration-75"
            // leaveFrom="transform opacity-100 scale-100"
            // leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className={`${COMPONENT_CLASS}__menu`}
                onMouseEnter={onMouseEnterMenu}
                onMouseLeave={onMouseLeaveMenu}
              >
                <Menu.Item>
                  <div
                    style={{ width: '400px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    className={`${COMPONENT_CLASS}__header`}>
                    <p style={{ fontSize: '14px', fontWeight: '400' }}>Notifications</p>
                    <div>
                      <Button
                        type='link'
                        buttonText='Mark all as read'
                        onClick={() => { markAllAsRead() }} />
                      <span style={{ marginLeft: '1rem' }}>
                        <Button
                          type='link'
                          buttonText='Clear all'
                          onClick={() => { clearAll() }} />
                      </span>
                    </div>
                  </div>
                </Menu.Item>
                <div className={(notifications && notifications.length > 4) ? `${COMPONENT_CLASS}__scrollable` : ''}>
                  {notifications && notifications.length > 0 && notifications.map((notification: any) => {
                    return (
                      <Menu.Item>
                        <NotificationItem notification={notification} onDelete={onDelete} onRead={onRead} />
                      </Menu.Item>
                    )
                  })}
                </div>
                {notifications && notifications.length === 0 && (
                  <Menu.Item>
                    <NotificationItem empty={true} />
                  </Menu.Item>
                )}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div >
  );
}

export { NotificationMenu };
