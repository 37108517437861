import { UserMskFocusArticles } from "../models/interfaces/articles/user-msk-focus-articles";
import UserMskArticlesService from "./services/article-service/user-msk-articles-service";
import ArticlesGroupService from "./services/article-service/articles-group-service";
import { ArticlesGroup } from "../models/interfaces/articles/articles-group";
import ArticleApiService from "./services/article-service/article-api-service";
import UserArticle from "../models/interfaces/articles/user-article";
import { UserMskFocusStatus } from "../models/interfaces/articles/user-msk-focus-status";
import UserMskFocusArticlesService from "./services/article-service/user-msk-articles-service";
import DateUtil from "./date-util";
import { Assessment } from "../models/interfaces/scores/assessment";
import { GroupIds } from "../models/enumerations/group-ids";
import UserService from "./services/user-service-assessments";
import { UserMskFocusStatusCompleted } from "../models/interfaces/articles/user-msk-focus-status-completed";
// import TodayService from "./services/today-service/today-service";
import { UserArticleCount } from "../models/interfaces/articles/user-article-count";
import { IUser } from "../models/interfaces/assessment-user";
import UserMskFocusArticlesUtil from "./user-msk-focus-articles-util";
import GroupUtil from "./group-util";
import GlobalStateUtil from "./global-state-util";
import { MskScore } from "../models/interfaces/scores/msk-score";
import DataAssessmentsUtil from "./data-assesments-util";
import Article from "../models/interfaces/articles/article";
import { FirebaseUtils } from "./firebase-utils";
import { ActivityType } from "../models/enumerations/activity-type";
import ArticleError from "./errors/article-error";
import WellnessProgramService from "./services/wellness-program-service";
// import { User } from "../models/interfaces/user";
// import NotificationService from "../models/notification-service";

export default class ArticleUtils {
    public static EXPIRATION_HOURS: number = 24;

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Returns the next articles for the user
     * @param {string} userId - The user to get articles for
     * @returns {Promise<void>}
     */
    public static async finishArticle(
        userId: string,
        groupId: string,
        articleId: number
    ): Promise<void> {
        _log(`finishArticle() groupId=${groupId} articleId=${articleId}`);
        const userMskFocusArticle =
            await UserMskFocusArticlesUtil.getCurrentByUserId(userId).catch(
                (error) => {
                    throw ArticleUtils.logAndCreateError(
                        error,
                        "UserMskFocusArticlesUtil.getCurrentByUserId()",
                        {
                            userId: userId,
                        }
                    );
                }
            );
        if (
            userMskFocusArticle?.focusStatuses == null ||
            userMskFocusArticle.focusStatuses.length === 0
        ) {
            return;
        }

        const focusStatusIndex = userMskFocusArticle.focusStatuses.findIndex(
            (x) => x.mskFocusAreaGroupId === groupId
        );
        if (focusStatusIndex === -1) {
            return;
        }

        // load focus status
        const focusStatus = userMskFocusArticle.focusStatuses[focusStatusIndex];
        let updateRecord = false;

        // add date to array
        if (focusStatus.completedArticles == null) {
            focusStatus.completedArticles = [];
        }
        const articleAlreadyCompleted =
            focusStatus.completedArticles.findIndex(
                (x) => x.articleId === articleId
            ) >= 0;
        if (!articleAlreadyCompleted) {
            // mark completed
            focusStatus.completedArticles.push({
                articleId: articleId,
                completedDateMs: DateUtil.getCurrentMsDate(),
            });
            updateRecord = true;

            // Check if all tasks for the day are completed. if the corrective
            // exercise is completed then the user is done all tasks for the
            // day.
            //     TodayService.getToday(userId)
            //         .then((today) => {
            //             MskScoreUtil.getLatest(userId).then((currentMskScore) => {
            //                 // Checking if user has completed the initial assessments.
            //                 if (today?.correctiveExercise?.isComplete
            //                     && currentMskScore?.lifestyleScore?.percentage != null
            //                     && currentMskScore?.movementScore?.percentage != null) {

            //                     // Clean up the pending notification for current day at 6pm. The
            //                     // repeating notification scheduled for next day at 6pm will
            //                     // still occur.

            //                     NotificationService.ActivityCompletedEvent();
            //                 }
            //             });
            //         });
            // }

            // mark all records as read
            const allArticlesRead =
                !focusStatus.readAllArticles &&
                ArticleUtils._completedFocusAreaArticles(focusStatus);
            _log(`finishArticle() allArticlesRead=${allArticlesRead}`);

            if (allArticlesRead) {
                focusStatus.readAllArticles = true;
                updateRecord = true;
                userMskFocusArticle.focusStatuses[focusStatusIndex] = focusStatus;
                // const shouldRetake = ArticleUtils.shouldRetakeLifestyleAssessments(
                //     userMskFocusArticle.focusStatuses
                // );
                // _log(`finishArticle() shouldRetake=${shouldRetake}`);
                // if (shouldRetake) {
                //     ArticleUtils.retakeLifestyleAssessments(
                //         userId,
                //         userMskFocusArticle.focusStatuses
                //     );
                // }
            }

            // save changes
            _log(`finishArticle() updateRecord=${updateRecord}`);
            if (updateRecord) {
                userMskFocusArticle.focusStatuses[focusStatusIndex] = focusStatus;
                await UserMskFocusArticlesService.update(userMskFocusArticle).catch(
                    (error) => {
                        throw ArticleUtils.logAndCreateError(
                            error,
                            "UserMskFocusArticlesService.update()",
                            {
                                userMskFocusArticleId: userMskFocusArticle?.id,
                            }
                        );
                    }
                );
                // console.log("deleting today")
                // await TodayService.deleteToday(userId).catch((error) => {
                //     throw ArticleUtils.logAndCreateError(
                //         error,
                //         "TodayService.deleteToday()",
                //         {
                //             userId: userId,
                //         }
                //     );
                // });
            }

            // add user activity
            FirebaseUtils.logUserActivity(userId, ActivityType.ArticleCompletion);
        }
    }

    /**
     * Returns the number of completed & total articles for the user
     * @param {string} userId - The user to get articles for
     * @returns {Promise<UserArticleCount>} A promise to get the number of
     * completed articles and total articles
     */
    public static async getCompletedArticlesCount(
        userId: string
    ): Promise<UserArticleCount[]> {
        console.log("getCompletedArticlesCount()")
        const results: UserArticleCount[] = [];

        let userMskArticles = await UserMskFocusArticlesUtil.getCurrentByUserId(
            userId
        ).catch((error) => {
            throw ArticleUtils.logAndCreateError(
                error,
                "UserMskFocusArticlesUtil.getCurrentByUserId()",
                {
                    userId: userId,
                }
            );
        });
        if (userMskArticles == null) {
            return results;
        }

        for (let i = 0; i < userMskArticles.focusStatuses.length; i++) {
            const focusStatus: UserMskFocusStatus =
                userMskArticles.focusStatuses[i];
            results.push({
                groupId: focusStatus.mskFocusAreaGroupId,
                completedArticles: focusStatus.completedArticles?.length || 0,
                totalArticles: focusStatus.numArticles,
            });
        }

        _log(
            `getCompletedArticlesCount(): results:`,
            results,
            "createNewRecord:",
            userMskArticles ?? false
        );
        return results;
    }

    /**
     * Gets the article tag that should be prioritized after the intro article
     */
    public static getTagPriority(mskScore?: MskScore): string[] {
        if (mskScore == null) {
            return [];
        }
        let result: string[] = []
        const hasMovementPain = DataAssessmentsUtil.hasMovementPain(
            mskScore.movementScore
        );
        if (hasMovementPain) {
            result.push("PAIN")
        }
        if (mskScore.movementScore && mskScore.movementScore.focusArea && mskScore.movementScore.focusArea.groupId) {
            result.push(mskScore.movementScore.focusArea.groupId)
        }
        return result;
    }

    /**
     * Returns the next article(s) for the user. Currently only 1 article is
     * returned.
     * @param {string} userId - The user to get articles for
     * @param {string} tagPriority - Case-insensitive tag to prioritize after
     * "intro"
     * @returns {Promise<UserArticle[]>} A promise to get the next user articles
     */
    public static async getTodaysArticles(
        userId: string,
        tagPriority?: string[],
        dayToShow?: number,
    ): Promise<UserArticle[]> {
        console.log("getTodaysArticles()")
        if (userId == null || userId.length === 0) {
            console.log("returning early, no userId");
            return [];
        }

        // get or create userMskArticles
        console.log("getting current mskArticle object")
        let userMskArticles = await UserMskFocusArticlesUtil.getCurrentByUserId(
            userId
        ).catch((error) => {
            throw ArticleUtils.logAndCreateError(
                error,
                "UserMskFocusArticlesUtil.getCurrentByUserId()",
                {
                    userId: userId,
                }
            );
        });
        console.log("mskArticle object we got is", userMskArticles)

        if (userMskArticles == null) {
            // Commenting out for now as we no longer need to create articles on today generation, but rather
            // on assessment completion/retake. Can be removed after tested and user-profile is deployed.
            // userMskArticles = await ArticleUtils.createUserMskArticles(
            //     userId
            // ).catch((error) => {
            //     throw ArticleUtils.logAndCreateError(
            //         error,
            //         "ArticleUtils.createUserMskArticles()",
            //         {
            //             userId: userId,
            //         }
            //     );
            // });
            // if (userMskArticles == null) {
            //     _log("getTodaysArticles(): DID NOT create user articles");
            //     return [];
            // } else {
            //     _log(
            //         "getTodaysArticles(): created userMskArticles:",
            //         userMskArticles
            //     );
            // }
            return [];
        }

        // get articles
        console.log("getting articles from record")
        const userArticles = await ArticleUtils.getTodaysArticlesFromRecord(
            userMskArticles,
            tagPriority,
            dayToShow,
        ).catch((error) => {
            throw ArticleUtils.logAndCreateError(
                error,
                "ArticleUtils.getTodaysArticlesFromRecord()",
                {
                    userMskArticlesId: userMskArticles?.id,
                    tagPriority: tagPriority,
                }
            );
        });
        console.log("articles from record = ", userArticles)

        // mark overall focus as complete
        if (
            userMskArticles?.isCurrent &&
            ArticleUtils._allCompletedBeforeToday(userMskArticles.focusStatuses)
        ) {
            // const hasRetakes = await ArticleUtils.doesUserHaveRetakes(
            //     userId
            // ).catch((error) => {
            //     throw ArticleUtils.logAndCreateError(
            //         error,
            //         "ArticleUtils.doesUserHaveRetakes()",
            //         {
            //             userId: userId,
            //         }
            //     );
            // });
            // Technically this will never happen since we removed the ability for users to have individual retakes
            // if (!hasRetakes) {
            //     userMskArticles.isCurrent = false;
            //     await UserMskArticlesService.update(userMskArticles);
            // }
        }
        console.log("returning userArticles")
        return userArticles!;
    }

    public static wasCompletedToday(
        completedArticles: UserMskFocusStatusCompleted[]
    ): boolean {
        if (completedArticles == null || completedArticles.length === 0) {
            return false;
        }

        const lastCompleted = completedArticles[completedArticles.length - 1];
        return DateUtil.isToday(lastCompleted.completedDateMs);
    }

    public static async createUserMskArticles(
        userId: string,
        mskScore: MskScore,
        user: IUser | null = null
    ): Promise<UserMskFocusArticles | null> {
        if (user == null && userId != null) {
            user = await UserService.get(userId).catch((error) => {
                throw ArticleUtils.logAndCreateError(
                    error,
                    "UserService.get()",
                    {
                        userId: userId,
                    }
                );
            });
        }
        if (user == null) {
            return null;
        }
        if (userId == null) {
            userId = user.id!;
        }

        // delay creating new user articles if there are lifestyles to retake
        // const userRetakeLifestyleAssessments =
        //     user.retakeLifestyleAssessments ?? [];
        // const incompleteLifestyleGroupIds = userRetakeLifestyleAssessments
        //     .filter((x) => x.completedDateMs == null)
        //     .map((x) => x.assessmentGroupId);
        // if (incompleteLifestyleGroupIds.length >= 1) {
        //     return null;
        // }

        // const mskScore = await MskScoreUtil.getLatest(userId).catch((error) => {
        //     throw ArticleUtils.logAndCreateError(
        //         error,
        //         "MskScoreUtil.getLatest()",
        //         {
        //             userId: userId,
        //         }
        //     );
        // });

        const focusAreas = mskScore?.focusAreas ?? [];

        const focusStatuses: UserMskFocusStatus[] = [];
        for (let i = 0; i < focusAreas.length; i++) {
            const focusArea = focusAreas[i];
            if (focusArea.groupId == null) {
                continue;
            }

            const articlesGroup = await ArticleUtils.getCurrentArticlesGroup(
                focusArea.groupId
            ).catch((error) => {
                throw ArticleUtils.logAndCreateError(
                    error,
                    "ArticleUtils.getCurrentArticlesGroup()",
                    {
                        groupId: focusArea?.groupId,
                    }
                );
            });
            if (
                articlesGroup?.articles == null ||
                articlesGroup.articles.length === 0
            ) {
                console.warn(
                    `createUserMskArticles(): no articles for ${focusArea.groupId}`
                );
                continue;
            }

            if (focusArea.groupId === GroupIds.MOVEMENT_HEALTH) {
                const allMovementArticles = this._getAllMovementArticlesCount(mskScore, articlesGroup.articles)
                focusStatuses.push({
                    completedArticles: [],
                    readAllArticles: false,
                    mskFocusAreaGroupId: focusArea.groupId,
                    mskFocusAreaGroupName: focusArea.groupName!,
                    numArticles: allMovementArticles,
                });
            }
            else if (focusArea.groupId === GroupIds.MOVEMENT_HEALTH_BREATHING) {
                const allMovementBreathingArticles = this._getAllMovementBreathingArticlesCount(mskScore, articlesGroup.articles)
                focusStatuses.push({
                    completedArticles: [],
                    readAllArticles: false,
                    mskFocusAreaGroupId: focusArea.groupId,
                    mskFocusAreaGroupName: focusArea.groupName!,
                    numArticles: allMovementBreathingArticles
                });
            }
            else {
                focusStatuses.push({
                    completedArticles: [],
                    readAllArticles: false,
                    mskFocusAreaGroupId: focusArea.groupId,
                    mskFocusAreaGroupName: focusArea.groupName!,
                    numArticles: articlesGroup.articles.length,
                });
            }
        }

        const userMskArticles: UserMskFocusArticles = {
            isCurrent: true,
            focusStatuses: focusStatuses,
            userId: userId,
        };
        return UserMskArticlesService.add(userMskArticles);
    }

    // #endregion Public Methods

    // -----------------------------------------------------------------------------------------
    // #region Private Methods
    // -----------------------------------------------------------------------------------------

    private static logAndCreateError(
        error: any,
        methodName: string,
        data: any
    ): ArticleError {
        console.error(
            "ArticleUtil",
            methodName,
            "error:",
            error,
            "data:",
            data
        );
        return new ArticleError(error, methodName, data);
    }

    private static async getTodaysArticlesFromRecord(
        userMskArticles: UserMskFocusArticles,
        tagPriority?: string[],
        dayToShow?: number,
    ): Promise<UserArticle[]> {
        console.log("getTodaysArticlesFromRecord")
        const wellnessProgram = await WellnessProgramService.getCurrentByUserId(userMskArticles.userId);
        console.log("wellnessProgram", wellnessProgram);

        // Account for missed days
        if (wellnessProgram.length !== 0 && wellnessProgram[0]) {
            let recordedDays = 0;
            console.log("mskarticle", userMskArticles);
            if (userMskArticles.focusStatuses.length === 1) {
                recordedDays = userMskArticles.focusStatuses[0].completedArticles.length * 2
            }
            else {
                for (let i = 0; i < userMskArticles.focusStatuses.length; i++) {
                    recordedDays += userMskArticles.focusStatuses[i].completedArticles.length;
                    if (userMskArticles.focusStatuses[i].completedArticles.length === userMskArticles.focusStatuses[i].numArticles) {
                        let completed = userMskArticles.focusStatuses[i].completedArticles.length * 2
                        if (i % 2 === 0) {
                            completed -= 1;
                        }
                        let even = completed % 2 === 0;
                        for (i = completed + 1; i < wellnessProgram[0].currentDay; i++) {
                            if (even && i % 2 === 0) {
                                recordedDays++;
                            }
                            else if (!even && i % 2 === 1) {
                                recordedDays++;
                            }
                        }
                    }
                }
            }
            console.log("recorded days", recordedDays);
            const missedDaysTotal = wellnessProgram[0].currentDay - 1 - recordedDays;
            console.log("missedDaysTotal", missedDaysTotal);

            if (missedDaysTotal > 0) {
                for (let i = 1; i <= missedDaysTotal; i++) {
                    // example, 1 article
                    // day 1 % 2 = 1: grab 0th focusStatus
                    // day 2 % 2 = 0: skip

                    // example, 2 articles
                    // day 1 % 2 = 1: grab 0th focusStatus
                    // day 2 % 2 = 0: grab 1st index focusStatus

                    // example, 3 articles
                    // day 1 % 3 = 1: grab 0th index focusStatus
                    // day 2 % 3 = 2: grab 1st index focusStatus
                    // day 3 % 3 = 0: grab 2nd index focusStatus

                    if (userMskArticles.focusStatuses.length === 1 && (recordedDays * 2 + i) % 2 === 0) {
                        console.log("continuing in missedDays loop since only 1 focusStatus length")
                        continue;
                    }

                    // (day - 1) % numberOfArticles
                    const focusStatusIndex = (recordedDays + i - 1) % userMskArticles.focusStatuses.length;
                    const mskFocusAreaGroupId = userMskArticles.focusStatuses[focusStatusIndex].mskFocusAreaGroupId;
                    const articlesGroup = await ArticleUtils.getCurrentArticlesGroup(
                        mskFocusAreaGroupId
                    ).catch((error) => {
                        throw ArticleUtils.logAndCreateError(
                            error,
                            "ArticleUtils.getArticlesForFocuses()",
                            {
                                mskFocusAreaGroupId: mskFocusAreaGroupId,
                            }
                        );
                    });
                    console.log("articlesGroup", articlesGroup);

                    if (articlesGroup && articlesGroup.articles) {
                        let nextArticle: Article | null = null;
                        nextArticle = this._getNextArticle(
                            articlesGroup.articles,
                            userMskArticles.focusStatuses[focusStatusIndex].completedArticles,
                            articlesGroup.groupId,
                            tagPriority,
                        );
                        if (nextArticle) {
                            userMskArticles.focusStatuses[focusStatusIndex].completedArticles.push({
                                articleId: nextArticle.FMS_article_id,
                                completedDateMs: 0,
                            });
                        }
                        else {
                            // We need to add a blan
                            userMskArticles.focusStatuses[focusStatusIndex].completedArticles.push({
                                articleId: -1,
                                completedDateMs: 0,
                            });
                        }
                        console.log("pushing extra completedMs value of 0 for missedDay")
                    }
                }

                await UserMskArticlesService.update(userMskArticles!);
            }
        }
        else {
            return [];
        }
        console.log(wellnessProgram[0]);
        const todaysFocusAreas = ArticleUtils.getTodaysFocusAreas(
            userMskArticles!,
            dayToShow !== undefined ? dayToShow : wellnessProgram[0].currentDay,
        );
        if (todaysFocusAreas.length === 0) {
            return [];
        }

        const articlesResult: any = await ArticleUtils.getArticlesForFocuses(
            userMskArticles!,
            todaysFocusAreas,
            tagPriority,
            dayToShow,
        ).catch((error) => {
            throw ArticleUtils.logAndCreateError(
                error,
                "ArticleUtils.getArticlesForFocuses()",
                {
                    todaysFocusAreas: todaysFocusAreas,
                    tagPriority: tagPriority,
                    userMskArticlesId: userMskArticles?.id,
                }
            );
        });
        userMskArticles = articlesResult.userMskArticles;
        _log(
            "getTodaysArticlesFromRecord(): todaysFocusAreas:",
            todaysFocusAreas,
            "articlesResult:",
            articlesResult
        );
        if (articlesResult.wasChanged) {
            await UserMskArticlesService.update(userMskArticles!);
        }

        // if (articlesResult.userArticles.length === 0) {
        //     _log("getTodaysArticlesFromRecord(): reload");
        //     return await ArticleUtils.getTodaysArticlesFromRecord(
        //         userMskArticles
        //     ).catch((error) => {
        //         console.error(error);
        //         return [];
        //     });
        // }

        return articlesResult.userArticles;
    }

    private static async getArticlesForFocuses(
        userMskFocusArticles: UserMskFocusArticles,
        todaysFocusAreas: UserMskFocusStatus[],
        tagPriority?: string[],
        dayToShow?: number,
    ): Promise<any> {
        let result = {
            userArticles: [] as UserArticle[],
            userMskArticles: userMskFocusArticles as UserMskFocusArticles,
            wasChanged: false as Boolean,
        };

        for (let i = 0; i < todaysFocusAreas.length; i++) {
            const focusStatus: UserMskFocusStatus = todaysFocusAreas[i];

            // load current articles for a group
            const articlesGroup = await ArticleUtils.getCurrentArticlesGroup(
                focusStatus.mskFocusAreaGroupId
            ).catch((error) => {
                throw ArticleUtils.logAndCreateError(
                    error,
                    "ArticleUtils.getArticlesForFocuses()",
                    {
                        mskFocusAreaGroupId: focusStatus.mskFocusAreaGroupId,
                    }
                );
            });
            if (
                articlesGroup?.articles == null ||
                articlesGroup.articles.length === 0
            ) {
                _log("getArticlesForFocuses(): no articles");
                continue;
            }

            // update articles count
            const actualNumArticles = articlesGroup.articles.length;
            if (
                focusStatus.numArticles == null ||
                focusStatus.numArticles !== actualNumArticles
            ) {
                focusStatus.numArticles = actualNumArticles;
                focusStatus.readAllArticles =
                    focusStatus.completedArticles != null &&
                    focusStatus.completedArticles.length >= actualNumArticles;
                const index = result.userMskArticles.focusStatuses.findIndex(
                    (x) => x === focusStatus
                );
                _log(
                    `getArticlesForFocuses(): update article count to ${articlesGroup.articles.length} for index ${index} (readAllArticles=${focusStatus.readAllArticles})`
                );
                if (index >= 0) {
                    result.userMskArticles.focusStatuses[index] = focusStatus;
                    result.wasChanged = true;
                }
            }

            // completed today
            let completedToday = false;
            if (
                focusStatus.completedArticles != null &&
                focusStatus.completedArticles.length >= 1
            ) {
                completedToday = DateUtil.isToday(
                    focusStatus.completedArticles[
                        focusStatus.completedArticles.length - 1
                    ].completedDateMs
                );
            }
            console.log("is article completed today?", completedToday);
            console.log("this is the focusStatus", focusStatus);

            // determine article index
            let nextArticle: Article | null = null;
            if (completedToday) {
                const completedArticleId =
                    focusStatus.completedArticles[
                        focusStatus.completedArticles.length - 1
                    ].articleId;
                const articleIndex = articlesGroup.articles.findIndex(
                    (x) => x.FMS_article_id === completedArticleId
                );
                if (articleIndex >= 0) {
                    nextArticle = articlesGroup.articles[articleIndex];
                }
                _log(
                    `getArticlesForFocuses(): completed today: nextArticle:`,
                    nextArticle
                );
            } else {
                console.log("getting next article after last completed")
                nextArticle = this._getNextArticle(
                    articlesGroup.articles,
                    focusStatus.completedArticles,
                    articlesGroup.groupId,
                    tagPriority,
                );
                _log(`getArticlesForFocuses(): nextArticle:`, nextArticle);
            }

            // add article
            if (nextArticle != null) {
                const numCompleted =
                    focusStatus.completedArticles == null
                        ? 0
                        : focusStatus.completedArticles.length;
                _log(
                    `getArticlesForFocuses(): completedToday=${completedToday} numCompleted=${numCompleted}`
                );
                result.userArticles.push({
                    article: nextArticle,
                    day: dayToShow !== undefined ? dayToShow : (completedToday ? numCompleted : numCompleted + 1),
                    groupId: focusStatus.mskFocusAreaGroupId,
                    isComplete: (!dayToShow || dayToShow === numCompleted + 1) ? completedToday : false,
                });
            }
        }

        return result;
    }

    public static _getNextArticle(
        articles?: Article[],
        completedArticles?: UserMskFocusStatusCompleted[],
        groupId?: string,
        tagPriorities?: string[],
    ): Article | null {
        if (articles == null || articles.length === 0) {
            return null;
        }

        // unread articles
        let completedArticleIds: number[] = [];
        if (completedArticles != null && completedArticles.length !== 0) {
            // sometimes we are getting undefined articles in here, being a bit
            // defensive and removing them now before moving on
            completedArticles = completedArticles.filter(
                (x) => x.articleId !== undefined
            );
            completedArticleIds = completedArticles.map((x) => x.articleId!);
        }
        // removing articles that have already been completed
        const articleOptions = articles.filter(
            (x) => completedArticleIds.indexOf(x.FMS_article_id) === -1
        );
        if (articleOptions.length === 0) {
            return null;
        }

        // if movement health, intros, then specific corrective 
        if (groupId === GroupIds.MOVEMENT_HEALTH) {
            // match on "intro", check for this first since we want intro to be the first article they receive
            const introMatch = articleOptions.filter(
                (x) =>
                    x.tags != null &&
                    x.tags.map((tag) => tag.toLocaleLowerCase()).indexOf("intro") >=
                    0
            );
            if (introMatch.length >= 1) {
                return introMatch[0];
            }

            // match on tag
            if (tagPriorities != null && tagPriorities.length !== 0) {
                for (const tagPriority of tagPriorities) {
                    const tagMatch = articleOptions.filter(
                        (x) =>
                            x.tags != null &&
                            x.tags
                                .map((tag) => tag.toLocaleLowerCase())
                                .indexOf(tagPriority.toLocaleLowerCase()) >= 0
                    );
                    if (tagMatch.length >= 1) {
                        return tagMatch[0];
                    }
                }
            }
            console.log("returning null");
            return null;
        }
        // if movement health & breathing, mh intros, mh specific correctives, then breathing
        else if (groupId === GroupIds.MOVEMENT_HEALTH_BREATHING) {
            // match on "intro", check for this first since we want intro to be the first article they receive
            const introMatch = articleOptions.filter(
                (x) =>
                    x.tags != null &&
                    x.tags.map((tag) => tag.toLocaleLowerCase()).indexOf("intro") >=
                    0
            );
            if (introMatch.length >= 1) {
                return introMatch[0];
            }

            // match on tag
            if (tagPriorities != null && tagPriorities.length !== 0) {
                for (const tagPriority of tagPriorities) {
                    const tagMatch = articleOptions.filter(
                        (x) =>
                            x.tags != null &&
                            x.tags
                                .map((tag) => tag.toLocaleLowerCase())
                                .indexOf(tagPriority.toLocaleLowerCase()) >= 0
                    );
                    if (tagMatch.length >= 1) {
                        return tagMatch[0];
                    }
                }
            }

            // match on breathing tag
            const breathingMatch = articleOptions.filter(
                (x) =>
                    x.tags != null &&
                    x.tags
                        .map((tag) => tag.toLocaleLowerCase())
                        .indexOf(GroupIds.BREATHING.toLocaleLowerCase()) >= 0
            );
            if (breathingMatch.length >= 1) {
                return breathingMatch[0];
            }
            return null
        }
        // else this is for normal focus areas.
        else {
            // match on "intro", check for this first since we want intro to be the first article they receive
            const introMatch = articleOptions.filter(
                (x) =>
                    x.tags != null &&
                    x.tags.map((tag) => tag.toLocaleLowerCase()).indexOf("intro") >=
                    0
            );
            if (introMatch.length >= 1) {
                return introMatch[0];
            }

            // match on tag
            if (tagPriorities != null && tagPriorities.length !== 0) {
                for (const tagPriority of tagPriorities) {
                    const tagMatch = articleOptions.filter(
                        (x) =>
                            x.tags != null &&
                            x.tags
                                .map((tag) => tag.toLocaleLowerCase())
                                .indexOf(tagPriority.toLocaleLowerCase()) >= 0
                    );
                    if (tagMatch.length >= 1) {
                        return tagMatch[0];
                    }
                }
            }

            // first article
            return articleOptions[0];
        }

        
    }

    public static _getAllMovementArticlesCount(
        mskScore: MskScore,
        articles?: Article[],

    ): number {
        if (articles == null || articles.length === 0) {
            return 0;
        }
        let filteredArticles = []

        // match on "intro", check for this first since we want intro to be the first article they receive
        const introMatch = articles.filter(
            (x) =>
                x.tags != null &&
                x.tags
                    .map((tag) => tag.toLocaleLowerCase())
                    .indexOf("intro") >= 0
        );
        filteredArticles = introMatch;
        const tagPriorities = this.getTagPriority(mskScore)
        console.log(tagPriorities)
        for (const tagPriority of tagPriorities) {
            const tagMatch = articles.filter(
                (x) =>
                    x.tags != null &&
                    x.tags
                        .map((tag) => tag.toLocaleLowerCase())
                        .indexOf(tagPriority.toLocaleLowerCase()) >= 0
            );
            filteredArticles = [...filteredArticles, ...tagMatch];
        }
        return filteredArticles.length;
    }

    public static _getAllMovementBreathingArticlesCount(
        mskScore: MskScore,
        articles?: Article[],

    ): number {
        if (articles == null || articles.length === 0) {
            return 0;
        }
        let filteredArticles = []

        // match on "intro", check for this first since we want intro to be the first article they receive
        const introMatch = articles.filter(
            (x) =>
                x.tags != null &&
                x.tags
                    .map((tag) => tag.toLocaleLowerCase())
                    .indexOf("intro") >= 0
        );
        filteredArticles = introMatch;
        const tagPriorities = this.getTagPriority(mskScore)
        console.log(tagPriorities)
        for (const tagPriority of tagPriorities) {
            const tagMatch = articles.filter(
                (x) =>
                    x.tags != null &&
                    x.tags
                        .map((tag) => tag.toLocaleLowerCase())
                        .indexOf(tagPriority.toLocaleLowerCase()) >= 0
            );
            filteredArticles = [...filteredArticles, ...tagMatch];
        }

        // match on breathing tag
        const breathingMatch = articles.filter(
            (x) =>
                x.tags != null &&
                x.tags
                    .map((tag) => tag.toLocaleLowerCase())
                    .indexOf(GroupIds.BREATHING.toLocaleLowerCase()) >= 0
        );
        filteredArticles = [...filteredArticles, ...breathingMatch];

        return filteredArticles.length;
    }

    /**
     * Returns different areas that articles should be loaded for. Currently
     * only 1 focus area is returned per day.
     */
    // private static getTodaysFocusAreas(
    //     userMskFocusArticles: UserMskFocusArticles
    // ): UserMskFocusStatus[] {
    //     if (
    //         userMskFocusArticles?.focusStatuses == null ||
    //         userMskFocusArticles.focusStatuses.length === 0
    //     ) {
    //         return [];
    //     }

    //     // find completed today
    //     const results: UserMskFocusStatus[] = [];
    //     let focuses = userMskFocusArticles.focusStatuses;
    //     for (let i = 0; i < focuses.length; i++) {
    //         const focus = focuses[i];
    //         if (
    //             focus.completedArticles == null ||
    //             focus.completedArticles.length === 0
    //         ) {
    //             continue;
    //         }
    //         const lastCompleted =
    //             focus.completedArticles[focus.completedArticles.length - 1];
    //         if (DateUtil.isToday(lastCompleted.completedDateMs)) {
    //             results.push(focus);
    //         }
    //     }
    //     if (results.length >= 1) {
    //         return results;
    //     }

    //     // find first incomplete (complete A, then B, then C, then A....)
    //     focuses = userMskFocusArticles.focusStatuses.sort((a, b) =>
    //         (a.completedArticles ?? []).length <
    //             (b.completedArticles ?? []).length
    //             ? -1
    //             : 1
    //     );
    //     for (let i = 0; i < focuses.length; i++) {
    //         const focus = focuses[i];
    //         if (focus.numArticles <= 0) {
    //             continue;
    //         }
    //         if (focus.completedArticles == null) {
    //             return [focus];
    //         }
    //         if (focus.completedArticles.length < focus.numArticles) {
    //             return [focus];
    //         }
    //     }

    //     return [];
    // }

    private static getTodaysFocusAreas(
        userMskFocusArticles: UserMskFocusArticles,
        currentDay: number,
    ): UserMskFocusStatus[] {
        if (
            userMskFocusArticles?.focusStatuses == null ||
            userMskFocusArticles.focusStatuses.length === 0
        ) {
            return [];
        }

        // example, 1 article
        // day 1 % 2 = 1: grab 0th focusStatus
        // day 2 % 2 = 0: skip

        // example, 2 articles
        // day 1 % 2 = 1: grab 0th focusStatus
        // day 2 % 2 = 0: grab 1st index focusStatus

        // example, 3 articles
        // day 1 % 3 = 1: grab 0th index focusStatus
        // day 2 % 3 = 2: grab 1st index focusStatus
        // day 3 % 3 = 0: grab 2nd index focusStatus

        if (userMskFocusArticles.focusStatuses.length === 1 && currentDay % 2 === 0) {
            return [];
        }

        // (day - 1) % numberOfArticles
        const focusStatusIndex = (currentDay - 1) % userMskFocusArticles.focusStatuses.length;
        console.log(currentDay)
        console.log(focusStatusIndex);
        console.log(userMskFocusArticles);

        const todaysFocusStatus = userMskFocusArticles.focusStatuses[focusStatusIndex];

        return todaysFocusStatus.completedArticles.length < todaysFocusStatus.numArticles ? [todaysFocusStatus] : [];
    }

    // private static shouldRetakeLifestyleAssessments(
    //     focusStatuses: UserMskFocusStatus[]
    // ): boolean {
    //     if (focusStatuses == null || focusStatuses.length === 0) {
    //         return false;
    //     }
    //     for (let i = 0; i < focusStatuses.length; i++) {
    //         if (!focusStatuses[i].readAllArticles) {
    //             return false;
    //         }
    //     }
    //     return true;
    // }

    // private static async retakeLifestyleAssessments(
    //     userId: string,
    //     focusStatuses: UserMskFocusStatus[]
    // ): Promise<void> {
    //     if (focusStatuses == null || focusStatuses.length === 0) {
    //         return;
    //     }
    //     focusStatuses.forEach((focusStatus) => {
    //         if (focusStatus.mskFocusAreaGroupId !== GroupIds.MOVEMENT_HEALTH) {
    //             UserUtil.modifyRetakeLifestyleAssessments(
    //                 userId,
    //                 focusStatus.mskFocusAreaGroupId,
    //                 true
    //             );
    //         }
    //     });
    // }

    // private static async doesUserHaveRetakes(userId: string) {
    //     const user = await UserService.get(userId).catch((error) => {
    //         throw ArticleUtils.logAndCreateError(error, "UserService.get()", {
    //             userId: userId,
    //         });
    //     });

    //     if (user == null) {
    //         return false;
    //     }

    //     if (user.retakeMovementAssessment != null) {
    //         return true;
    //     }
    //     return (
    //         user.retakeLifestyleAssessments != null &&
    //         user.retakeLifestyleAssessments.length >= 1
    //     );
    // }

    public static _completedAllArticles(
        focusStatuses: UserMskFocusStatus[]
    ): boolean {
        if (focusStatuses == null || focusStatuses.length === 0) {
            return false;
        }

        for (let i = 0; i < focusStatuses.length; i++) {
            const focusStatus = focusStatuses[i];
            const completedFocusArea =
                ArticleUtils._completedFocusAreaArticles(focusStatus);
            if (!completedFocusArea) {
                return false;
            }
        }

        return true;
    }

    public static _completedFocusAreaArticles(
        userMskFocusStatus: UserMskFocusStatus
    ): boolean {
        if (userMskFocusStatus?.completedArticles == null) {
            return false;
        }
        return (
            userMskFocusStatus.completedArticles.length >=
            userMskFocusStatus.numArticles
        );
    }

    public static _allCompletedBeforeToday(
        focusStatuses: UserMskFocusStatus[]
    ): boolean {
        if (focusStatuses == null || focusStatuses.length === 0) {
            return false;
        }

        for (let i = 0; i < focusStatuses.length; i++) {
            const focusStatus = focusStatuses[i];
            if (!focusStatus.readAllArticles) {
                return false;
            }
            if (ArticleUtils.wasCompletedToday(focusStatus.completedArticles)) {
                return false;
            }
        }

        return true;
    }

    public static async getCurrentArticlesGroup(
        mskFocusAreaGroupId: string
    ): Promise<ArticlesGroup | null> {
        let articlesGroup = await ArticlesGroupService.getForGroupId(
            mskFocusAreaGroupId
        ).catch((error) => {
            throw ArticleUtils.logAndCreateError(
                error,
                "ArticlesGroupService.getForGroupId()",
                {
                    mskFocusAreaGroupId: mskFocusAreaGroupId,
                }
            );
        });

        // current existing articles
        const articlesAreCurrent = DateUtil.isFutureDate(
            articlesGroup?.expirationMs
        );
        if (articlesAreCurrent) {
            _log(
                `getCurrentArticlesGroup("${mskFocusAreaGroupId}"): current articlesGroup found:`,
                articlesGroup
            );
            return articlesGroup!;
        }

        // get new articles group
        const mskFocusAreaGroup: Assessment = { groupId: mskFocusAreaGroupId };
        let newArticlesGroup = await ArticleApiService.getArticlesGroup(
            mskFocusAreaGroup
        ).catch((error) => {
            const globalError = GlobalStateUtil.createError("", error);
            const errorMessage = `Existing articles were not found for ${GroupUtil.getName(
                mskFocusAreaGroupId
            )}, and articles could not be queried from ${globalError.url ?? "the server"
                }`;
            throw new Error(
                errorMessage +
                ": " +
                JSON.stringify({
                    error: error,
                    mskFocusAreaGroupId: mskFocusAreaGroup.groupId,
                })
            );
        });
        if (newArticlesGroup != null) {
            newArticlesGroup =
                ArticleUtils.setOriginalImageUrls(newArticlesGroup);
        }

        // update references
        let dataUpdated = false;
        if (newArticlesGroup == null) {
            _log(
                `getCurrentArticlesGroup("${mskFocusAreaGroupId}"): new articles were not found`
            );
        } else if (articlesGroup == null) {
            articlesGroup = newArticlesGroup;
            dataUpdated = true;
            _log(
                `getCurrentArticlesGroup("${mskFocusAreaGroupId}"): new newArticlesGroup:`,
                newArticlesGroup
            );
        } else {
            articlesGroup.articles = newArticlesGroup.articles;
            dataUpdated = true;
            _log(
                `getCurrentArticlesGroup("${mskFocusAreaGroupId}"): update articles`,
                newArticlesGroup.articles
            );
        }

        // save articles group
        if (dataUpdated && articlesGroup != null) {
            articlesGroup.expirationMs = DateUtil.getExpiration(
                ArticleUtils.EXPIRATION_HOURS * 60
            );
            ArticlesGroupService.addOrUpdate(articlesGroup);
        }

        return articlesGroup!;
    }

    private static setOriginalImageUrls(
        articlesGroup: ArticlesGroup
    ): ArticlesGroup {
        if (
            articlesGroup?.articles == null ||
            articlesGroup.articles.length === 0
        ) {
            return articlesGroup;
        }

        articlesGroup.articles.forEach((x) => {
            x.originalImageUrl = x.imageUrl;
        });

        return articlesGroup;
    }

    public static getArticleDuration(article: Article) {
        if (article?.duration == null || article.duration <= 0) {
            return 3; // default
        }
        return Math.round(Math.ceil(article.duration / 60));
    };


    // #endregion Private Methods
}

/* eslint-disable */
const _log = (message?: any, ...optionalParams: any[]): void => {
    if (false) {
        console.log(message, ...optionalParams);
    }
};
/* eslint-enable */
