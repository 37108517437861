import { AnswerIds } from "./enumerations/answer-ids";

export class AnswerConversionUtil {
    /**
     * AnswerId is handled by converting A, B, C, D or E to the numeric value in the options array.
     * For example, "A" is converted to the first instance in the array.
     *
     * @param {number[]} options - numeric options to convert the answers to, such as [1, 2, 3, 4]
     * @param {string} answerId - answer ID value, such as "A" or "B"
     * @returns {number} Number from the options that the answer was converted to
     */
    public static answerIdToNumber(options: number[], answerId?: string | null): number {
        if (options == null) {
            throw new Error("Answer options were not specified");
        }

        switch (answerId) {
            case AnswerIds.A:
                if (options.length < 1) {
                    throw new Error("Answer \"A\" was not expected");
                }
                return options[0];
            case AnswerIds.B:
                if (options.length < 2) {
                    throw new Error("Answer \"B\" was not expected");
                }
                return options[1];
            case AnswerIds.C:
                if (options.length < 3) {
                    throw new Error("Answer \"C\" was not expected");
                }
                return options[2];
            case AnswerIds.D:
                if (options.length < 4) {
                    throw new Error("Answer \"D\" was not expected");
                }
                return options[3];
            case AnswerIds.E:
                if (options.length < 5) {
                    throw new Error("Answer \"E\" was not expected");
                }
                return options[4];
            default:
                throw new Error(`Answer "${answerId}" was not expected`);
        }
    }

    /**
     * Rounds the percentage based on the current system results
     *
     * @param {number} value - Value to be rounded
     * @returns {number} Rounded number
     */
    public static getFinalPercentage(value: number): number {
        if (value == null) {
            return value;
        }
        if (value > 100) {
            return 100;
        }
        if (value < 0) {
            return 0;
        }

        // standard rounding (rounds 0.5 up)
        return Math.round(value);


        /* banker's rounding (rounds 0.5 to nearest even number)
        const remainder = value - Math.floor(value);

        // default rounding for non-0.5 values
        if (remainder != 0.5) {
            return Math.round(value);
        }

        // round to nearest even number
        const base = Math.floor(value);
        if (base % 2 === 0) {
            return base;
        }
        return base + 1;
        */
    }

    /**
     * Converts a string like "0:00" or "23:30" to 0 or 23.5
     *
     * @param {string} time - Time in the format "H:MM" where "H" is the 24-hour format (0-23)
     * @returns {number} Numeric value of the time, such as 23.5
     */
    public static timeToDecimal(time: string): number {
        const colonIndex = time.indexOf(":");
        if (colonIndex === -1) {
            throw new Error(`Time is expected to have a colon: ${time}`);
        }

        const hours = parseInt(time.substring(0, colonIndex));
        const minutes = parseInt(time.substring(colonIndex + 1));

        if (isNaN(hours) || isNaN(minutes)) {
            throw new Error(`Time is not in the correct format: ${time}`);
        }
        if (hours < 0 || hours > 23) {
            throw new Error(`Time hours should be 0-23: ${time}`);
        }
        if (minutes < 0 || minutes > 59) {
            throw new Error(`Time minutes should be 0-59: ${time}`);
        }

        const result = hours + minutes / 60;
        return result;
    }
}
