import FirestoreCondition from "../../models/interfaces/firestore-condition";
import FirestoreDocument from "../../models/interfaces/firestore-document";
import FirestoreOrder from "../../models/interfaces/firestore-order";
import { User } from "../../models/interfaces/user";
import getFirebase from "../firebase";
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    QueryConstraint,
    setDoc,
    Timestamp,
    where,
    getCountFromServer,
    and,
} from "firebase/firestore";

const { db } = getFirebase();
// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------'

/**
 * @param {string} collectionName - The name of the collection of documents in Firestore
 * @param {Type} document - The document that is going to be added to the collection
 * @param {firebase.User} currentUser - The user that is logged into the application
 * @returns {Promise<Type>} A promise to the newly added document
 */
const add = async <Type extends FirestoreDocument>(
    collectionName: string,
    document: Type,
    currentUser: User | null = null
): Promise<Type> => {
    document.id = doc(collection(db, collectionName)).id;
    document.created = Timestamp.now();

    if (currentUser != null) {
        document.createdBy = currentUser.id;
    }

    await setDoc(doc(db, collectionName, document.id), document);

    return document as Type;
};

/**
 * @param {string} collectionName - The name of the collection of documents in Firestore
 * @param {Type} document - The document that is going to be added to the collection
 * @param {firebase.User} currentUser - The user that is logged into the application
 * @returns {Promise<Type>} A promise to the newly added document
 */
const addWithId = async <Type extends FirestoreDocument>(
    collectionName: string,
    document: Type,
    currentUser: User | null = null,
    id: string
): Promise<Type> => {
    document.id = id
    document.created = Timestamp.now();

    if (currentUser != null) {
        document.createdBy = currentUser.id;
    }

    await setDoc(doc(db, collectionName, document.id), document);

    return document as Type;
};

/**
 * Delete a document collection by the Id
 * @param {string} collectionName - The name of the collection of documents in Firestore
 * @param {string} id - The Id of the document being deleted
 */
const deleteById = async (collectionName: string, id: string) => {
    return deleteDoc(doc(db, collectionName, id));
};

/**
 * Find the specific document by the id
 * @param {string} collectionName - The name of the collection of documents in Firestore
 * @param {string} id - The Id of the document that we are retrieving
 * @returns {Promise<Type | null>} A promise for the document
 */
const get = async <Type extends FirestoreDocument>(
    collectionName: string,
    id: string
): Promise<Type | null> => {
    const document = await getDoc(doc(db, collectionName, id));
    if (document.exists()) {
        return document.data() as Type;
    }

    return null;
};

/**
 * Get all of the documents stored in the firestore database
 * @param {string} collectionName - The name of the collection of documents in Firestore
 * @returns {Promise<Type[]>} A promise for the collection of documents
 */
const getAll = async <Type extends FirestoreDocument>(
    collectionName: string,
    orderValue: FirestoreOrder[] = [],
    limitValue?: number
): Promise<Type[]> => {
    const q: QueryConstraint[] = orderValue.map((o) =>
        orderBy(o.field, o.direction)
    );

    if (limitValue) {
        q.push(limit(limitValue));
    }
    const documents = await getDocs(
        query(collection(db, collectionName), ...q)
    );
    return documents.docs.map((doc) => doc.data() as Type);
};

/**
 * Get all of the documents stored in the firestore database
 * @param {string} collectionName - The name of the collection of documents in Firestore
 * @returns {Promise<Type[]>} A promise for the collection of documents
 */
const getBy = async <Type extends FirestoreDocument>(
    collectionName: string,
    conditions: FirestoreCondition[],
    orderValue: FirestoreOrder[] = [],
    limitValue?: number
): Promise<Type[]> => {

    if (conditions.length === 0) {
        return [] as Type[];
    }

    const q: QueryConstraint[] = conditions.map((c) =>
        where(c.field, c.operator, c.value)
    );

    q.push(...orderValue.map((o) => orderBy(o.field, o.direction)));

    if (limitValue != null && limitValue >= 1) {
        q.push(limit(limitValue));
    }

    try {
        const documents = await getDocs(
            query(collection(db, collectionName), ...q)
        );

        return documents.docs.map((doc) => doc.data() as Type);
    } catch (err) {
        console.log(
            `ERROR querying ${collectionName}:\nError: ${err}\nConditions:`,
            conditions
        );
        return [];
    }
};

/**
 * Get all of the documents stored in the firestore database
 * @param {string} collectionName - The name of the collection of documents in Firestore
 * @returns {Promise<Type[]>} A promise for the collection of documents
 */
const getByNotAsync = <Type extends FirestoreDocument>(
    collectionName: string,
    conditions: FirestoreCondition[],
    orderValue: FirestoreOrder[] = [],
    limitValue?: number
): Promise<Type[]> => {

    if (conditions.length === 0) {
        return Promise.resolve([]);
    }

    const q: QueryConstraint[] = conditions.map((c) =>
        where(c.field, c.operator, c.value)
    );

    q.push(...orderValue.map((o) => orderBy(o.field, o.direction)));

    if (limitValue != null && limitValue >= 1) {
        q.push(limit(limitValue));
    }

    try {
        return getDocs(
            query(collection(db, collectionName), ...q)
        ).then((documents) => {
            return documents.docs.map((doc) => doc.data() as Type);
        });

    } catch (err) {
        console.log(
            `ERROR querying ${collectionName}:\nError: ${err}\nConditions:`,
            conditions
        );
        return Promise.resolve([]);
    }
};

/**
 * Get count of all the documents stored in the firestore database
 * @param {string} collectionName - The name of the collection of documents in Firestore
 * @param {FirestoreCondition[]} conditions - The condition to query the collection of documents in Firestore
 * @returns {Promise<Type[]>} A promise for the collection of documents
 */
const getCountBy = async (collectionName: string, conditions: FirestoreCondition[]) => {

    if (conditions.length === 0) { return 0; }

    let queryConstraints = [];

    for (const condition of conditions) {
        queryConstraints.push(where(condition.field, condition.operator, condition.value));
    }

    const compositeFilter = and(...queryConstraints);

    const coll = collection(db, collectionName);
    const query_ = query(coll, compositeFilter);

    return (await getCountFromServer(query_)).data().count;
};

/**
 * Subscribe to the object to get realtime updates
 * @param {string} collectionName - Name of the collection
 * @param {string} id - ID of the document
 * @param {Function} listener - Function for the listener when updates have been received
 */
const getSnapshot = <Type extends FirestoreDocument>(
    collectionName: string,
    id: string,
    listener: Function
) => {

    return onSnapshot(doc(db, collectionName, id), (doc) => {
        listener(doc.data() as Type);
    });
};

/**
 * Subscribe to the query to get realtime updates
 * @param {string} collectionName - The name of the collection of documents in Firestore
 * @param {FirestoreCondition[]} conditions - The condition to query the collection of documents in Firestore
 * @param {Function} listener - Function for the listener when updates have been received
 */
const getSnapshotBy = <Type extends FirestoreDocument>(
    collectionName: string,
    conditions: FirestoreCondition[],
    orderValue: FirestoreOrder[],
    limitValue: number,
    listener: Function
) => {

    const q: QueryConstraint[] = conditions.map((c) =>
        where(c.field, c.operator, c.value)
    );

    q.push(...orderValue.map((o) => orderBy(o.field, o.direction)));

    if (limitValue != null && limitValue >= 1) {
        q.push(limit(limitValue));
    }

    return onSnapshot(
        query(collection(db, collectionName), ...q),
        (snapshot) => {
            listener(snapshot.docs.map((doc) => doc.data() as Type));
        }
    );
};

/**
 * Save the specified document in the database
 * @param {string} collectionName - The name of the collection of documents in Firestore
 * @param {Type} document - The document that is being updated
 * @param {Type} currentUser - The user that is logged into the application
 * @returns {Promise<Type>} A promise for the user that is being updated
 */
const save = async <Type extends FirestoreDocument>(
    collectionName: string,
    document: Type,
    currentUser: User | null = null
): Promise<Type> => {

    if (document.id == null) {
        return add(collectionName, document, currentUser);
    }
    return update(collectionName, document, currentUser);
};

/**
 * Update the specified document stored in the firestore database
 * @param {Type} document - The document that is being updated
 * @param {Type} currentUser - The user that is logged into the application
 * @returns {Promise<Type>} A promise for the document that is being updated
 */
const update = async <Type extends FirestoreDocument>(
    collectionName: string,
    document: Type,
    currentUser: User | null = null
) => {

    // Make this immutable and NEVER do any mutations; This can cause bugs and performance issues
    document.updated = Timestamp.now();

    if (currentUser !== null) {
        document.updatedBy = currentUser.id;
    } else if (document.updatedBy != null) {
        document.updatedBy = "";
    }
    await setDoc(doc(db, collectionName, document.id!), document, {
        merge: true,
    });
    return document as Type;
};

/**
 * Update the specified document stored in the firestore database
 * @param {Type} document - The document that is being updated
 * @param {Type} currentUser - The user that is logged into the application
 * @returns {Promise<Type>} A promise for the document that is being updated
 */
const updateById = async <Type extends FirestoreDocument>(
    collectionName: string,
    document: Type,
    currentUserId: string | null = null
) => {

    // Make this immutable and NEVER do any mutations; This can cause bugs and performance issues
    document.updated = Timestamp.now();

    if (currentUserId !== null) {
        document.updatedBy = currentUserId;
    } else if (document.updatedBy != null) {
        document.updatedBy = "";
    }
    await setDoc(doc(db, collectionName, document.id!), document, {
        merge: true,
    });
    return document as Type;
};

// #endregion Service Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const FirestoreService = {
    add: add,
    addWithId: addWithId,
    deleteById: deleteById,
    get: get,
    getAll: getAll,
    getBy: getBy,
    getByNotAsync: getByNotAsync,
    getCountBy: getCountBy,
    getSnapshot: getSnapshot,
    getSnapshotBy: getSnapshotBy,
    save: save,
    update: update,
    updateById: updateById
};

export default FirestoreService;

// #endregion Exports