import React, { useEffect, useState } from 'react';
import { Button } from '../button/button';
import { Modal } from '../modal/modal';
import SubscriptionService from '../../utilities/services/subscription-service';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import HubspotSubCancellationDataService from '../../utilities/services/hubspot-sub-cancellation-data-service';
import { HubspotSubCancellationData } from '../../models/interfaces/hubspot-sub-cancellation-data';
import { enqueueSnackbar } from 'notistack';
// @ts-ignore
import HubspotForm from "react-hubspot-form";
import { TextTypeInput } from '../forms';
import { useForm } from 'react-hook-form';
import { CheckboxTypeInput } from '../forms/checkbox-type-input';
import RadioTypeInput from '../forms/radio-type-input/radio-type-input';
interface CancellationDialogBoxProps {
  isOpen: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  isLoading?: boolean;
  cancelButtonAction?: Function;
}

const COMPONENT_CLASS = "c-cancellation-dialog-box";

const CancellationDialogBox: React.FC<CancellationDialogBoxProps> = (props) => {
  const {
    isOpen,
    onClose,
    title,
    isLoading,
    cancelButtonAction
  } = props;

  const methods = useForm<any>();

  const {
    register,
  } = methods;

  const { state } = useAuthState();
  const [openPlanCancellationAgreement, setOpenPlanCancellationAgreement] = useState(false);
  const [selectedReason, setSelectedReason] = useState<number | null>(null);
  const [details, setDetails] = useState('');
  const [openHubspotForm, setOpenHubspotForm] = useState(false);
  const [checkedBoxesNumber, setCheckedBoxesNumber] = useState(0);
  const [checkboxes, setCheckboxes] = useState([
    { firstLine: "Your user limit will be reduced to 5 users. All users will be deactivated automatically.", secondLine: "Starter plans can have a maximum of 5 users. You will be responsible for reactivating the 5 users.", isChecked: false },
    { firstLine: "Your license limit will be reduced to 5 licenses. All licenses will be removed from users.", secondLine: "Starter plans can have a maximum of 5 licenses. You will be responsible for reassigning the 5 licenses to your users.", isChecked: false },
  ])

  const resetCheckboxes = () => {
    setCheckboxes([
      { firstLine: "Your user limit will be reduced to 5 users. All users will be deactivated automatically.", secondLine: "Starter plans can have a maximum of 5 users. You will be responsible for reactivating the 5 users.", isChecked: false },
      { firstLine: "Your license limit will be reduced to 5 licenses. All licenses will be removed from users.", secondLine: "Starter plans can have a maximum of 5 licenses. You will be responsible for reassigning the 5 licenses to your users.", isChecked: false },
    ])
  }

  const reasons = [
    { value: 'We intend to use a different tool', text: 'We’d love to know which tool you’re switching to and why. This will help us improve Symmio.', placeholder: 'Details about which tool and why' },
    { value: 'We need features Symmio doesn’t have', text: 'We’d love to know which features you’re missing. This will help us improve Symmio.', placeholder: 'Details about which features are missing' },
    { value: 'Symmio is too expensive', text: 'We’d love to figure out the best plan for you.', placeholder: 'Contact Us' },
    { value: 'Other', text: 'We’d love to know why. This will help us improve Symmio.', placeholder: 'Details about why you’re cancelling' }];

  const handleRadioChange = (reason: number) => {
    setSelectedReason((prev) => prev === reason ? null : reason);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { checked } = e.target;
    const newCheckBoxes = [...checkboxes]
    newCheckBoxes[index].isChecked = checked
    setCheckboxes(newCheckBoxes);

    if (checked) {
      setCheckedBoxesNumber(checkedBoxesNumber + 1);
    }
    else {
      setCheckedBoxesNumber(checkedBoxesNumber - 1);
    }
  };

  // Modal closes - reset everything;
  useEffect(() => {
    if (!openPlanCancellationAgreement || !isOpen) {
      setCheckedBoxesNumber(0);
      setSelectedReason(null);
      setDetails('');
      setOpenHubspotForm(false);
    }
  }, [openPlanCancellationAgreement, isOpen]);

  const buttonOpenCancellationAgreement = (
    <Button
      type="default"
      buttonText="Cancel Subscription"
      onClick={() => setOpenPlanCancellationAgreement(true)} />
  );

  const planCancellationAgreement = (
    <Modal
      isOpen={openPlanCancellationAgreement}
      isLoading={isLoading || false}
      onClose={(closeModal) => {
        resetCheckboxes();
        setOpenPlanCancellationAgreement(closeModal);
        onClose(closeModal);
      }}
      defaultModalActions={true}
      submitButtonText='Agree and Cancel Subscription'
      cancelButtonText='Keep my subscription'
      onSubmit={async () => {
        if (state.user) {
          try {
            cancelButtonAction ? cancelButtonAction() : await SubscriptionService.handleCancelSubscription(state.user?.organizationId, state.user);

            const hubspotSubCanellationData: HubspotSubCancellationData = {
              organizationName: state.organization.name,
              accountHolderEmail: state.user?.email,
              accountHolderName: `${(state.user?.firstName || "")} ${(state.user?.lastName || "")}`,
              response: selectedReason !== null ? reasons[selectedReason].value : undefined,
              details: details || undefined,
            }

            await HubspotSubCancellationDataService.add(hubspotSubCanellationData, state.user);
            !cancelButtonAction && enqueueSnackbar("Subscription Cancelled", { variant: "toast", width: "450px" });
          } catch (err: any) {
            enqueueSnackbar(err);
          }

          setOpenPlanCancellationAgreement(false);
          onClose(false);
        }
      }}
      onCancel={() => {
        setOpenPlanCancellationAgreement(false);
        onClose(false);
      }}
      submitDisabled={checkedBoxesNumber !== checkboxes.length}>
      <div className={COMPONENT_CLASS}>
        <h3>Cancelling your subscription will cause some changes to your account.</h3>

        <p className={`${COMPONENT_CLASS}__description`}>Your subscription will be cancelled and you will be downgraded at the end of your billing cycle to the <strong>Free Starter Plan</strong>. The following changes will happen:</p>

        <div className={`${COMPONENT_CLASS}__checkboxes`}>
          {checkboxes.map((checkbox, index) => {
            return (
              <div key={index} className={`${COMPONENT_CLASS}__checkbox-row`}>
                <div className={`${COMPONENT_CLASS}__checkbox-wrapper`}>
                  <CheckboxTypeInput
                    id={`${COMPONENT_CLASS}__checkbox-${index}`}
                    inputClassName={`${COMPONENT_CLASS}__cancel-subscription-checkbox`}
                    registerHook={register}
                    registerOptions={{
                      onChange: (e) => handleCheckboxChange(e, index)
                    }}
                    checked={checkbox.isChecked} />
                </div>
                <label htmlFor={`${COMPONENT_CLASS}__checkbox-${index}`}>
                  <p><strong>{checkbox.firstLine}</strong></p>
                  <p>{checkbox.secondLine}</p>
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );

  return (
    openPlanCancellationAgreement ? planCancellationAgreement :
      <Modal
        isOpen={isOpen}
        isLoading={isLoading || false}
        onClose={onClose}
        title={title}
      >
        <div className={COMPONENT_CLASS}>
          <div className="my-8">We’re sad to see you go. Tell us why you’re cancelling, maybe we can help!</div>
          <div className={`${COMPONENT_CLASS}__reasons`}>
            {reasons.map((reason, index) => (
              index === 2 ? (

                <div key={index}>
                  <div className={`reason ${selectedReason === index ? 'visible' : ''}`} onClick={() => handleRadioChange(index)} >
                    <div>
                      <RadioTypeInput
                        id='cancellationReason'
                        checked={selectedReason === index}
                        registerHook={register}
                        registerOptions={{
                          value: reason.value,
                        }}
                      />
                    </div>
                    <label>{reason.value}</label>
                  </div>
                  <div className={`cancellation-details ${selectedReason === index ? 'visible' : ''}`}>
                    <p style={{
                      fontSize: '14px', fontWeight: 'bold',
                    }}>{reason.text}</p>
                    <div className={'contact-us'}>
                      <Button
                        type="default"
                        buttonText="Contact Us"
                        onClick={() => setOpenHubspotForm(!openHubspotForm)} />
                      <div className='cancellation-button-white'>
                        {buttonOpenCancellationAgreement}
                      </div>
                      {openHubspotForm &&
                        <div className={`${COMPONENT_CLASS}__hubspot-form`}>
                          <HubspotForm
                            portalId='5419630'
                            formId='6dc531b3-6919-46fe-982b-af93fa7a0a04'
                            onFormSubmitted={() => {
                              enqueueSnackbar("Your inquiry is important to us. We have received your information, and a representative from Symmio will be in touch with you shortly.", { variant: "toast", width: "550px" });
                              setOpenHubspotForm(false);
                              setOpenPlanCancellationAgreement(false);
                              onClose(false);
                            }}
                            loading={<div>Loading...</div>}
                          />
                        </div>
                      }
                    </div>

                  </div>
                </div>
              ) : (<div key={index}>
                <div className={`reason ${selectedReason === index ? 'visible' : ''}`} onClick={() => handleRadioChange(index)} >
                  <div>
                    <RadioTypeInput
                      id='cancellationReason'
                      checked={selectedReason === index}
                      registerHook={register}
                      registerOptions={{
                        value: reason.value,
                      }} />
                  </div>
                  <label>{reason.value}</label>
                </div>
                <div className={`cancellation-details ${selectedReason === index ? 'visible' : ''}`}>
                  <p style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '5px' }}>{reason.text}</p>
                  {/* TODO: this input field onChange doesn't work with our TextInput, we need to fix this and swap to TextTypeInput */}
                  <TextTypeInput
                    id='test'
                    type='text'
                    label={reason.placeholder}
                    hideLabel={true}
                    registerHook={register}
                    registerOptions={{
                      value: details,
                      onChange: event => { setDetails(event.target.value) },
                    }} />
                  <div className='cancellation-button'>
                    {buttonOpenCancellationAgreement}
                  </div>
                </div>
              </div>)
            ))}
          </div>
        </div>
      </Modal>
  );

};

export default CancellationDialogBox;