
export default class QuestionResponseDto {
    categoryId?: string;

    /** Question Group that this response is associated to */
    groupId?: string;

    questionId?: string;
    answerId?: string;
    answerResponse?: any;

    /** @internal */
    constructor(partial?: Partial<QuestionResponseDto>) {
        if (partial != null) {
            Object.assign(this, partial);
        }
    }
}
