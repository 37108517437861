/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { MskScore } from "../../models/interfaces/msk-score";
import { User } from "../../models/interfaces/user";
import MskScoreService from "../../utilities/services/msk-score-service";
import moment from "moment";
import UserProfileAssessmentHeader from "./user-profile-assessment-header";
import LineChartJS from "../charts/line-chart-js";

const COMPONENT_CLASS = "c-user-profile-assessments";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfileNutritionProps {
    mskScore?: MskScore;
    user: User;
    assessment?: any;
    // assessmentResponses: AssessmentResponse[];
    allMskScores: MskScore[];
    setAllMskScores: Function;
    setSpecificAssessment: Function;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfileNutrition: React.FC<UserProfileNutritionProps> = (props: UserProfileNutritionProps) => {
    const { setSpecificAssessment, mskScore, assessment, allMskScores, setAllMskScores, user } = props;
    const [nutritionHistory, setNutritionHistory] = useState<{ x: string, y: number }[]>();

    // const [isLoading, setIsLoading] = useState<boolean>(true)

    // useEffect(() => {
    //     setIsLoading(false);
    // }, [])
    useEffect(() => {
        const getAllMskScores = async () => {
            if (!allMskScores) {
                const mskScores = await MskScoreService.getBy([{
                    field: "userId",
                    operator: "==",
                    value: user.id
                }], [{
                    field: "created",
                    direction: "asc"
                }])

                setAllMskScores(mskScores);
            }
        }
        getAllMskScores();
    }, [])
    useEffect(() => {
        const getHistory = () => {
            if (allMskScores) {
                let tempNutritionHistory = []

                for (const mskScore of allMskScores) {
                    tempNutritionHistory.push({
                        x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                        y: mskScore.lifestyleScore?.nutritionScore?.percentage ?? 0
                    })
                }
                setNutritionHistory(tempNutritionHistory);
            }
        }
        getHistory();
    }, [allMskScores])
    if (nutritionHistory) {
        console.log(nutritionHistory);
    }
    return (
        <>
            <div>
                <UserProfileAssessmentHeader
                    assessment={assessment}
                    mskScore={mskScore}
                    setSpecificAssessment={setSpecificAssessment}
                    mainTextHeader="How We Screen Nutritional Awareness"
                    mainText="We assess Nutritional awareness through a <strong>comprehensive nutrition quiz</strong> that evaluates your users understanding of basic nutritional principles. A score of 100% indicates a strong knowledge base, empowering you to make informed and healthy dietary choices. Lower scores highlight areas where additional education or support may be beneficial."
                    whyImportantText="Nutritional awareness empowers individuals to make informed dietary choices that support overall health. Understanding the role of food in the body helps prevent chronic conditions like obesity, diabetes, and heart disease. Beyond physical health, good nutrition boosts mental clarity, energy levels, and emotional balance. By fostering better eating habits, nutritional awareness lays the foundation for sustained well-being and improved quality of life."
                />
            </div>

            {nutritionHistory && (
                <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper mt-5`}>
                    <LineChartJS
                        data={nutritionHistory.map(history => history.y)}
                        title="Nutrition Awareness Score History"
                        labels={nutritionHistory.map(history => history.x)}
                        height={"200px"}
                        horizontalScrolling={true}
                        pointRadius={7}
                        pointBorderWidth={3}
                        borderWidth={5}
                        pointFontSize={16}
                        fontFamily={'pragmatica-extended'}
                        pointLabelOffset={0}
                        xLabelFontSize={14}
                        paddingTop={30}
                        paddingBottom={20}
                        maintainAspectRatio={false}
                    />
                </div>
            )}
        </>
    );
}

export default UserProfileNutrition;
