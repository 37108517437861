/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { BiologicalSexOptions } from "../../models/biological-sex";
import FirestoreCondition from "../../models/interfaces/firestore-condition";
import { Group } from "../../models/interfaces/group";
import { ListOptions } from "../../models/interfaces/list-options";
import { Tag } from "../../models/interfaces/tag";
import { User } from "../../models/interfaces/user";
import { UserTag } from "../../models/interfaces/user-tag";
import UserRoles from "../../models/user-roles";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import FunctionService from "../../utilities/services/function-service";
import GroupService from "../../utilities/services/group-service";
import OrganizationService from "../../utilities/services/organization-service";
import TagService from "../../utilities/services/tag-service";
import UserService from "../../utilities/services/user-service";
import UserUtil from "../../utilities/user-util";
import { Utils } from "../../utilities/utils";
import { Button } from "../button/button";
import { Loader } from "../loader/loader";
import { Modal } from "../modal/modal";
import * as TagComponent from "../tag/tag";
import { Organization } from "../../models/interfaces/organization";
import ChangeAccountHolderModal from "../user-list/change-account-holder-modal";
import RemoveAccountHolderModal from "../user-list/remove-account-holder-modal";
import { ReactComponent as ProfileIcon } from "../../assets/icons/nav/icon_profile.svg";
import { UserStatus } from "../../models/enumerations/user-status";
import OrganizationSubscriptionService from "../../utilities/services/organization-subscription-service";
import { AddOns } from "../../models/enumerations/add-ons";
import { SymmioAccessType } from "../../models/enumerations/symmio-access-type";
import { AddOnId } from "../../models/enumerations/add-on-id";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { SymmioLicenseCheckbox } from "../symmio-license-checkbox";
import DateInputOption from "../account-information-date-input-option/account-information-date-input-option";
import dayjs from "dayjs";
import { UserStatusBadge } from "../user-status-badge/user-status-badge";
import { TextTypeInput, ToggleButton } from "../forms";
import { useForm } from "react-hook-form";
import { MskScore } from "../../models/interfaces/msk-score";
import { DateTime } from "luxon";
import { AssessmentUtils } from "../../utilities/assessments/assessment-utils";
import { ReportType } from "../../models/enumerations/report-type";
import ReportService from "../../utilities/services/report-service";
import { Report } from "../../models/interfaces/report";


const COMPONENT_CLASS = "c-user-profile-header";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfileHeaderProps {
    user: User,
    greenToast: Function,
    products: any[],
    latestMskData?: MskScore,
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfileHeader: React.FC<UserProfileHeaderProps> = (props: UserProfileHeaderProps) => {
    const stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);
    const { user, greenToast, products, latestMskData } = props;

    const [editUser, setEditUser] = useState<User>();
    const [isEditing, setIsEditing] = useState(false);
    const [isLoadingGroups, setIsLoadingGroups] = useState(false);
    const [isLoadingTags, setIsLoadingTags] = useState(false);
    const [tagsLoaded, setTagsLoaded] = useState<boolean>(false);
    const [isLoadingOrganizations, setIsLoadingOrganizations] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [organization, setOrganization] = useState<Organization>();
    const [groups, setGroups] = useState<Group[]>([]);
    const [tags, setTags] = useState<Tag[]>([]);
    const [userOrgName, setUserOrgName] = useState<string>("(Loading Organization...)");
    const [isUserLimitReached, setIsUserLimitReached] = useState<boolean>(false);
    const [isBirthdateValid, setIsBirthdateValid] = useState<boolean>(dayjs(user.birthdate).isValid());
    const [isSymmioAccess, setIsSymmioAccess] = useState<boolean>(false);
    const hadSymmioAccessBefore = user.symmioAccess === SymmioAccessType.AppLicense;
    const wasDeactivatedBefore = user.status === UserStatus.Disabled;
    const [isUserDeactivated, setIsUserDeactivated] = useState<boolean>(wasDeactivatedBefore);
    const originalStatus = user.status;
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [canEditStatus, setCanEditStatus] = useState<boolean>(false);
    const [downloadingReportSnackBar, setDownloadingReportSnackBar] = useState<any>();
    const [, setReportError] = useState<string | undefined>();

    // account holder state variables
    const [editUserOrg, setEditUserOrg] = useState<Organization>();
    const [currentAccountHolder, setCurrentAccountHolder] = useState<User>();
    const [changeAccountHolderModalOpen, setChangeAccountHolderModalOpen] = useState<boolean>(false);
    const [overwritingAccountHolder, setOverwritingAccountHolder] = useState<boolean>(false);
    const [removingAccountHolder, setRemovingAccountHolder] = useState<boolean>(false);
    const [removeAccountHolderModalOpen, setRemoveAccountHolderModalOpen] = useState<boolean>(false);
    const [roleDropdownOptions, setRoleDropdownOptions] = useState<ListOptions[]>();
    const [editUserCurrentOrg, setEditUserCurrentOrg] = useState<Organization | undefined>();
    const [noHolderOrgSwitch, setNoHolderOrgSwitch] = useState<boolean>(false);
    const [isAccountHolder, setIsAccountHolder] = useState<boolean>(false);
    const [overLicenseLimit, setOverLicenseLimit] = useState<boolean>(false);
    const { state, setState } = useAuthState();

    const methods = useForm<any>();

    const {
        register,
    } = methods;

    useEffect(() => {
        const getOrg = async () => {
            if (editUser && editUser.organizationId) {
                const org = await OrganizationService.get(editUser.organizationId);
                if (org) {
                    setIsUserLimitReached(await OrganizationService.isUserLimitReached(org));
                    setOrganization(org);
                }
            };
        }
        getOrg();
    }, [editUser?.organizationId, isEditing]);

    useEffect(() => {
        if (editUser && editUser.roles && editUser.roles[0]) {
            if (editUser.roles[0] === UserRoles.ACCOUNT_HOLDER_ID) {
                setIsAccountHolder(true);
            }
        } else if (user && user.roles && user.roles[0]) {
            if (user.roles[0] === UserRoles.ACCOUNT_HOLDER_ID) {
                setIsAccountHolder(true);
            }
        } else {
            setIsAccountHolder(false);
        }
    }, [editUser?.roles, editUser]);

    useEffect(() => {
        const getOrg = async () => {
            if (editUser && editUser.organizationId) {
                // don't bother getting an org we already have
                if (editUserCurrentOrg && editUser.organizationId === editUserCurrentOrg.id) return;
                const org = await OrganizationService.get(editUser.organizationId);
                if (org) {
                    setEditUserCurrentOrg(org);
                }
                return;
            }

            if (user && user.organizationId) {
                // don't bother getting an org we already have
                if (editUserCurrentOrg && user.organizationId === editUserCurrentOrg.id) return;
                const org = await OrganizationService.get(user.organizationId);
                if (org) {
                    setEditUserCurrentOrg(org);
                }
                return;
            }
        };
        if (isAccountHolder) {
            getOrg();
        }
    }, [editUser?.organizationId, isAccountHolder]);

    useEffect(() => {
        getRolesDropdownOptions()
            .then((response) => {
                // we need to check if the role selected by the user is still
                // present in the new role options
                if (editUser && editUser.roles && editUser.roles[0]) {
                    const editUserRole = editUser.roles[0];
                    const userRoleInOptions = !!response.find((r: any) => r.value === editUserRole);
                    // if the role selected by the user is not in the new set of
                    // role options, we need to clear the field
                    if (!userRoleInOptions && canEditRoles()) {
                        setEditUser(Object.assign({}, editUser, { roles: [] }));
                    }
                }
                setRoleDropdownOptions(response);
            })
    }, [user, editUser?.organizationId, editUserCurrentOrg]);

    useEffect(() => {
        if (editUser?.id !== user.id) {
            let clonedUser = Object.assign({}, user);
            setEditUser(clonedUser);
            setIsSymmioAccess(clonedUser?.symmioAccess === SymmioAccessType.AppLicense);
            setIsUserDeactivated(clonedUser.status === UserStatus.Disabled);
        }
    }, [editUser, user]);

    useEffect(() => {
        // set org name if different than globally selected org (super admin only)
        const loadOrg = async (orgId: string) => {
            const org = await OrganizationService.get(orgId);
            setUserOrgName(org?.name ?? "(org was not found)");
        }

        if (
            state.user?.isSuperAdmin
            && user?.organizationId != null
            && state.organization?.id != null
            && state.organization.id !== user.organizationId
        ) {
            loadOrg(user.organizationId);
        }
    }, [user?.organizationId, state.user?.isSuperAdmin, state.organization?.id]);

    useEffect(() => {
        if (isEditing && editUser && !tagsLoaded) {
            setIsLoadingTags(true);

            TagService.getBy([{
                field: "organizationId",
                operator: "==",
                value: user.organizationId
            }], [{
                field: "name",
                direction: "asc",
            }]).then((tags: Tag[]) => {
                setTags(tags);
                setIsLoadingTags(false);
                setTagsLoaded(true);
            });

            OrganizationService.getAll().then((organizations: Organization[]) => {
                if (organizations) {
                    organizations = organizations.sort((a, b) => !!a.name ? a.name.localeCompare(!!b.name ? b.name : "undefined") : "undefined".localeCompare(!!b.name ? b.name : "undefined"));
                }

                setOrganizations(organizations);
                setIsLoadingOrganizations(false);
            });
        }
    }, [editUser, isEditing, user.organizationId]);

    useEffect(() => {
        if (state.user == null || user?.organizationId == null) {
            return;
        }

        setIsLoadingGroups(true);

        const loadGroups = async () => {
            let conditions: FirestoreCondition[] = [
                {
                    field: "organizationId",
                    operator: "==",
                    value: user.organizationId
                }
            ];

            if (UserUtil.isManager(state.user)) {
                const groupIds = await UserUtil.getHierarchicalGroupIds(state.user?.managedGroupIds);
                conditions.push({
                    field: "id",
                    operator: "in",
                    value: groupIds,
                });
            }

            GroupService.getBy(
                conditions,
                [{
                    field: "name",
                    direction: "asc",
                }]
            ).then((groups: Group[]) => {
                setGroups(groups);
                setIsLoadingGroups(false);
            });
        }

        loadGroups();
    }, [user.organizationId, state.user]);

    useEffect(() => {
        if (!isEditing) {
            let clonedUser = Object.assign({}, user);
            setEditUser(clonedUser);
            setIsSymmioAccess(clonedUser?.symmioAccess === SymmioAccessType.AppLicense);
            setIsUserDeactivated(clonedUser.status === UserStatus.Disabled);
        }
    }, [isEditing, user]);

    const checkAndUpdateHiddenLimit = async () => {
        await OrganizationSubscriptionService.getByOrganizationId(state.organization.id!).then(async (orgSubs) => {

            // Check if they're at the hidden limit
            // Check if they have any licenses listed to be removed.
            if (orgSubs[0] && orgSubs[0].addOnsToBeRemoved && orgSubs[0].addOnsToBeRemoved.find((item: any) => item.name === AddOns.License)) {
                // We need to grab the absolute latest organization object.
                // If a client tries to add users twice in a row, we can't reliably update the organization state object as
                // The organization count is updated based on a function
                // It's possible that on the 2nd "add user" flow, when we tried to update the organization state right after the 1st "add user" flow
                // the function hadn't updated the organization object yet. Therefore, we're comparing the wrong userCount
                await OrganizationService.get(orgSubs[0].organizationId).then(async (org) => {
                    const sub = await stripe.subscriptions.retrieve(orgSubs[0].stripeAddOnSubscription);
                    const addOnsToBeRemoved = orgSubs[0].addOnsToBeRemoved!.find((item: any) => item.name === AddOns.License)
                    const hiddenLimit = org && org.mySymmioLicense && addOnsToBeRemoved && addOnsToBeRemoved.amountToBeRemoved && (addOnsToBeRemoved.amountManaged || addOnsToBeRemoved.amountManaged === 0) ? org.mySymmioLicense - (addOnsToBeRemoved.amountToBeRemoved - addOnsToBeRemoved.amountManaged) : null
                    if (org && org.mySymmioLicenseCount && hiddenLimit && addOnsToBeRemoved?.amountToBeRemoved && hiddenLimit < org.mySymmioLicenseCount + 1) {
                        // Figure out how much over the limit they are
                        const amountOver = (org.mySymmioLicenseCount + 1) - (hiddenLimit)
                        // Update OrgSub
                        addOnsToBeRemoved.amountToBeRemoved = addOnsToBeRemoved.amountToBeRemoved - amountOver;
                        await OrganizationSubscriptionService.save(orgSubs[0]);

                        // Update subscription schedule phase
                        // Retrieve existing subscription schedule
                        const subscriptionSchedule = await stripe.subscriptionSchedules.retrieve(sub.schedule);

                        // Check if subscription schedule has already been updated for the future month
                        // To do so, take last index of phase array and check if phase.start/end === subSched.current_phase
                        // If === then no future phase isn't created
                        // If !== then future phase is created and we want to update
                        const futurePhaseExists = !((subscriptionSchedule.phases.at(-1).start_date === subscriptionSchedule.current_phase.start_date) &&
                            (subscriptionSchedule.phases.at(-1).end_date === subscriptionSchedule.current_phase.end_date));
                        // We should always get in this as the only way we would have "AddOnsToBeRemoved" is if they've modified add-ons and created a subscription schedule
                        if (futurePhaseExists) {
                            // Update existing phase quantity
                            const index = subscriptionSchedule.phases.at(-1).items.findIndex((item: any) => item.price === products.find((item) => item.product.id === AddOnId.User).prices.find((item: any) => item.data.active === true).id);
                            subscriptionSchedule.phases.at(-1).items[index].quantity = subscriptionSchedule.phases.at(-1).items[index].quantity + amountOver
                            // Create new phases object
                            let phases: any[] = [];
                            subscriptionSchedule.phases.forEach(function (phase: any, index: any, array: any) {
                                let phaseItems: any[] = [];
                                phase.items.forEach(function (item: any) {
                                    phaseItems.push({
                                        price: item.price,
                                        quantity: item.quantity,
                                    })
                                })

                                // Check if we're on last iteration
                                if (index === array.length - 1) {
                                    phases.push({
                                        items: phaseItems,
                                        iterations: 1,
                                    })
                                }
                                else {

                                    phases.push({
                                        items: phaseItems,
                                        start_date: phase.start_date,
                                        end_date: phase.end_date,
                                    })
                                }
                            })

                            // Update existing phase            
                            await stripe.subscriptionSchedules.update(

                                sub.schedule,
                                {
                                    phases: phases
                                },
                            )
                        }
                    }
                })
            }
        })
    }

    const saveUser = async () => {
        if (!editUser) {
            return;
        }

        if (isSymmioAccess) {
            setEditUser(Object.assign({}, editUser, { symmioAccess: SymmioAccessType.AppLicense }));
            editUser.symmioAccess = SymmioAccessType.AppLicense;
        }
        else {
            setEditUser(Object.assign({}, editUser, { symmioAccess: SymmioAccessType.WebAccess }));
            editUser.symmioAccess = SymmioAccessType.WebAccess;
        }

        // If deactivated - remove license
        if (editUser.status === UserStatus.Disabled) {
            setEditUser(Object.assign({}, editUser, { symmioAccess: SymmioAccessType.WebAccess }));
            editUser.symmioAccess = SymmioAccessType.WebAccess;
            setIsSymmioAccess(false);
        }

        try {
            editUser.tagIds = editUser.tags?.map(t => t.id) || [];
            // If user was given an app license, we must update addOns tracker if needed
            if (user.symmioAccess !== SymmioAccessType.AppLicense && editUser.symmioAccess === SymmioAccessType.AppLicense) {
                await checkAndUpdateHiddenLimit();
            }

            // If user's app license was taken, we must update addOns tracker if needed
            await UserService.update(editUser, state.user);
            await updateTagUserCount(user, editUser, state.user);

            if (editUser.authenticationId && editUser.roles && editUser.roles.length > 0) {
                await FunctionService.updateUserRoles(editUser);
            }

        } catch (err: any) {
            // TODO : Global exception handling
            // https://app.clickup.com/t/2219993/FMS-1236
            console.error(err);
            enqueueSnackbar("There was an error saving the user");
        }

        // if the edit user is set to be saved as an account holder, we need to
        // update their organization and set their id as the accountHolderId
        if (editUser.roles && editUser.roles[0] === UserRoles.ACCOUNT_HOLDER_ID && editUser.organizationId) {
            const org = await OrganizationService.get(editUser.organizationId);
            if (org) {
                org.accountHolderId = editUser.id;
                await OrganizationService.update(org, state.user);
            }
            const stateOrg = state.organization;
            stateOrg.accountHolderId = editUser.id;
            setState((state) => ({
                ...state, ...{ organization: stateOrg }
            }));
        }

        setOverLicenseLimit(false);
        setIsEditing(false);
        setIsSaving(false);
        setOverwritingAccountHolder(false);
        setRemovingAccountHolder(false);
        const info = { orgName: organizations.find(({ id }) => id === editUser?.organizationId)?.name, accountOwnerEmail: state.user?.email };
        if (isSymmioAccess !== hadSymmioAccessBefore && isSymmioAccess) {
            FunctionService.sendMySymmioAccessAssigned(editUser, info);
        } else if (isSymmioAccess !== hadSymmioAccessBefore && !isSymmioAccess) {
            FunctionService.sendMySymmioAccessRemoved(editUser, info);
        }
        greenToast();
    };

    const handleSaveUserClick = async () => {
        if (!editUser) {
            return;
        }
        setIsSaving(true);

        // if the user has the account holder role, check to see if there is
        // already an account holder for their organization.
        if (editUser.roles && editUser?.roles.length > 0 && editUser.organizationId) {
            if (editUser?.roles.indexOf(UserRoles.ACCOUNT_HOLDER_ID) !== -1) {
                const userOrg = await OrganizationService.get(editUser.organizationId);

                const alreadyHolder = userOrg?.accountHolderId === editUser.id;

                // if the organization has an account holder, open the change
                // account holder modal
                if (!alreadyHolder && userOrg?.accountHolderId) {
                    const currentHolder = await UserService.get(userOrg?.accountHolderId);
                    setEditUserOrg(userOrg);
                    setCurrentAccountHolder(currentHolder!);
                    setChangeAccountHolderModalOpen(true);
                    return;
                }
            }
        }

        if (removingAccountHolder && user.organizationId) {
            const userOrg = await OrganizationService.get(user.organizationId);
            if (userOrg) {
                setEditUserOrg(userOrg);
            }
            setRemoveAccountHolderModalOpen(true);
            return;
        }


        await saveUser();
    }

    const isManagingGroups = (roles?: string[]): boolean => {
        if (roles == null || roles.length === 0) {
            return false;
        }
        return (roles.indexOf(UserRoles.GROUP_MANAGER_ID) >= 0 || roles.indexOf(UserRoles.TEAM_MEMBER_ID) >= 0)
            && roles.indexOf(UserRoles.ADMIN_ID) === -1;
    }

    const handleOrganizationChange = (newValue: any) => {
        // Remove the group and tag values from the editUser
        // assign new organization to editUser
        if (editUser) {
            // Note: Had to change the interface to accept null values. Passing undefined to firebase wasn't persisting the new data change.
            editUser.groupId = null;
            editUser.groupName = null;
            if (editUser.tags && editUser.tagIds) {
                editUser.tags = [];
                editUser.tagIds = [];
            }
            editUser.organizationName = newValue.label;
            editUser.organizationId = newValue.value;
            setEditUserCurrentOrg(newValue.label)
        }

        // If the organization is changed back to the original one
        // We want to set the editUser's group and tags values to what they currently are
        if (newValue.value === user.organizationId && editUser) {
            editUser.groupId = user.groupId;
            editUser.groupName = user.groupName;
            if (editUser.tags && editUser.tagIds) {
                editUser.tags = user.tags;
                editUser.tagIds = user.tagIds;
            }
        }

        // We now need to change the values stored in our tags/groups list
        // We want to grab all tags/groups that have the organizationId of the editUser object
        // Grabbing new Tags
        if (isEditing && editUser) {
            setIsLoadingTags(true);

            TagService.getBy([{
                field: "organizationId",
                operator: "==",
                value: newValue.value
            }], [{
                field: "name",
                direction: "asc",
            }]).then((tags: Tag[]) => {
                setTags(tags);
                setIsLoadingTags(false);
            }).then(() => {
                setIsLoadingGroups(true);

                // Grabbing new Groups
                let conditions: FirestoreCondition[] = [
                    {
                        field: "organizationId",
                        operator: "==",
                        value: newValue.value
                    }
                ];

                // !Not sure if this is needed!
                // if (UserUtil.isManager(state.user)) {
                //     const groupIds = await UserUtil.getHierarchicalGroupIds(state.user?.managedGroupIds);
                //     conditions.push({
                //         field: "id",
                //         operator: "in",
                //         value: groupIds,
                //     });
                // }

                GroupService.getBy(
                    conditions,
                    [{
                        field: "name",
                        direction: "asc",
                    }]
                ).then((groups: Group[]) => {
                    setGroups(groups);
                    setIsLoadingGroups(false);
                });
            });
        }
    };

    const handleSymmioAccessCheckbox = () => {
        setIsSymmioAccess(!isSymmioAccess);
    }

    const statusBadge = (
        <div className="flex items-center">
            <UserStatusBadge status={user.status} />
        </div>
    );

    let birthdate = "Not Provided";
    if (user.birthdate) {
        birthdate = user.birthdate;
        const date = user.birthdate.split('-');
        if (date.length === 3) {
            birthdate = `${date[1]}/${date[2]}/${date[0]}`
        }
    }

    const managedGroupOptions: ListOptions[] = groups.map((g) => ({ label: g.name, value: g.id }));
    const isPortalUserManager = UserUtil.isManager(state.user);
    const isSuperAdmin = UserUtil.isSuperAdmin(state.claims);
    const isUserAccountHolder = UserUtil.isAccountHolder(state.user);
    const isUserAdmin = UserUtil.isAdmin(state.user);

    const canEditRoles = () => {
        // account holders can only have their role changed by a super admin
        if (UserUtil.isAccountHolder(user) && !isSuperAdmin) {
            return false;
        }
        if (UserUtil.isAccountHolder(user) && isSuperAdmin) {
            return true;
        }
        if (!isPortalUserManager || UserUtil.hasNoRole(user) || UserUtil.isTeamMember(user)) {
            return true;
        }
        return false;
    };

    // Role - Allow to Edit:
    // Account Holders - Everyone
    // Admins - Admins, Group Managers, Team Members
    // Group Managers - Group Managers, Team Members
    useEffect(() => {
        const checkCanEdit = async () => {
            // Account Holders can be edited by Super Admins and Account Holders
            if (UserUtil.isAccountHolder(user) && (isSuperAdmin || isUserAccountHolder)) {
                setCanEdit(true);
            }
            // Admins can be edited by Super Admins, Acc Holders and Admins
            else if (UserUtil.isAdmin(user) && (isSuperAdmin || isUserAccountHolder || isUserAdmin)) {
                setCanEdit(true);
                setCanEditStatus(true);
            }
            // Group Managers, Team Members and Clients can be edited by Super Admins, Acc Holders, Admins and
            // Group Managers if they're in their group
            else if ((UserUtil.isManagerOrTeamMember(user) || UserUtil.hasNoRole(user) || UserUtil.isClient(user)) && (isSuperAdmin || isUserAccountHolder || isUserAdmin)) {
                setCanEdit(true);
                setCanEditStatus(true);
            }
            else if ((UserUtil.isManagerOrTeamMember(user) || UserUtil.hasNoRole(user) || UserUtil.isClient(user)) && isPortalUserManager) {
                const groupIds = await UserUtil.getHierarchicalGroupIds(state.user?.managedGroupIds);

                if (user?.groupId && groupIds.includes(user.groupId)) {
                    setCanEdit(true);
                    setCanEditStatus(true);
                }
            }
            else {
                setCanEdit(false);
                setCanEditStatus(false);
            }

            //Check can edit status for Acc Holders
            if (UserUtil.isAccountHolder(user) && isSuperAdmin) {
                setCanEditStatus(true);
            }
        }

        checkCanEdit();
    }, [isPortalUserManager, isSuperAdmin, isUserAccountHolder, isUserAdmin, state.user?.managedGroupIds, user]);

    const roles = editUser?.roles?.filter(r => r).map(r => UserRoles.ALL_ROLES.find(o => o.value === r));
    const role = roles == null || roles.length === 0 ? [] : roles[0];
    // let symmioAccess = SymmioAccessTypeOptions.find((x) => x.value === editUser?.symmioAccess);

    const getRolesDropdownOptions = async () => {
        if (isSuperAdmin) {
            setNoHolderOrgSwitch(false);
            // we don't want to allow account holder if the edit user is not in
            // their original organization
            if (editUser?.organizationId !== user.organizationId) {
                setNoHolderOrgSwitch(true);
                // we need to make a copy without reference or the disabled
                // option will always be disabled
                const roles = JSON.parse(JSON.stringify(UserRoles.ALL_ROLES));
                const holderIndex = roles.findIndex((x: any) => x.value === UserRoles.ACCOUNT_HOLDER_ID);
                roles[holderIndex].isDisabled = true;
                return roles;
            }

            // we don't want to allow account holders in sub-organizations
            if (editUser?.organizationId) {
                // get the current org selected on the edit screen
                const currentOrg = await OrganizationService.get(editUser?.organizationId);
                // ensure we have an org and it has an account holder
                if (currentOrg && currentOrg.accountHolderId) {
                    // get the account holder for the current org
                    const currentOrgHolder = await UserService.get(currentOrg?.accountHolderId);
                    // if the account holder for the current org is not in that
                    // org, then it is a sub-organization and we don't want to
                    // have the account holder role to be in the dropdown
                    if (currentOrgHolder && currentOrgHolder.organizationId) {
                        if (currentOrgHolder.organizationId !== currentOrg.id) {
                            return UserRoles.ADMIN_ROLES;
                        }
                    }
                }
            }

            // we don't want to allow the user to be an account holder if they
            // aren't in an organization (not sure if this is possible, but to
            // be safe...)
            if (!user?.organizationId) {
                return UserRoles.ADMIN_ROLES;
            }

            // if we get this far, it's okay to include the account holder role
            return UserRoles.ALL_ROLES;
        }
        if (isPortalUserManager) {
            return UserRoles.MANAGER_ROLES;
        }
        return UserRoles.ADMIN_ROLES
    };

    /**
     * Handles changing of the role dropdown. Mostly logic for how to check for
     * and handle account holders being overwritten or removed.
     *
     * @param newValue The role selected by the user.
     * @returns Nothing
     */
    const handleRoleChange = async (newValue: any) => {
        setOverwritingAccountHolder(false);
        setRemovingAccountHolder(false);

        let newRole = null;
        if (newValue && Object.hasOwn(newValue, 'value')) {
            newRole = newValue.value;
        }

        let newValues = {};

        // if the user is being changed to be an account holder, we need to make
        // sure they stay in their original organization
        if (newRole === UserRoles.ACCOUNT_HOLDER_ID) {
            newValues = { organizationId: user.organizationId };
        }

        // if the user is being changed to be an account holder, we need to get their starting limits
        if (newRole === UserRoles.ACCOUNT_HOLDER_ID && user?.organizationId) {
            const org = await OrganizationService.get(user?.organizationId);
            const alreadyHolder = org?.accountHolderId === user.id;

            if (!alreadyHolder) {
                // if the users organization has an account holder, we need to
                // warn that they are overwriting it
                if (org?.accountHolderId && !alreadyHolder) {
                    const accHolder = await UserService.get(org.accountHolderId);
                    if (accHolder) {
                        newValues = {
                            ...newValues,
                            roles: newValue == null ? [] : [newRole],
                        };
                        setEditUser(Object.assign({}, editUser, newValues));
                        setOverwritingAccountHolder(true);
                        return;
                    }
                    // otherwise just default the limits and counts to the edit user's organization's counts
                }
            } else {
                setOverwritingAccountHolder(false);
            }
        }

        if (user.roles && (user.roles[0] === UserRoles.ACCOUNT_HOLDER_ID)) {
            if (editUser && editUser.roles && (newRole !== UserRoles.ACCOUNT_HOLDER_ID)) {
                setRemovingAccountHolder(true);
            } else {
                setRemovingAccountHolder(false);
            }
        }

        setEditUser(Object.assign({}, editUser, { roles: newValue == null ? [] : [newRole] }));
    };

    const handleAccountHolderModalClose = (save: boolean) => {
        setChangeAccountHolderModalOpen(false);
        setRemoveAccountHolderModalOpen(false);
        if (save) {
            saveUser();
        }
        setIsSaving(false);
        return;
    };

    const handleCloseUserEditModal = () => {
        setOverLicenseLimit(false);
        setRemovingAccountHolder(false);
        setOverwritingAccountHolder(false);
        setIsEditing(false);
    }

    const handleToggleSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;

        setIsUserDeactivated(checked);
        if (!checked && wasDeactivatedBefore) {
            setEditUser(Object.assign({}, editUser, { status: UserStatus.Inactive }));
        }
        else if (checked && !wasDeactivatedBefore) {
            setEditUser(Object.assign({}, editUser, { status: UserStatus.Disabled }));
            setIsSymmioAccess(false);
        }
        else {
            setEditUser(Object.assign({}, editUser, { status: originalStatus }));
        }
    }

    const handleBirthdateChange = (date: string) => {
        setEditUser(Object.assign({}, editUser, { birthdate: dayjs(date).format('YYYY-MM-DD') }));
        setIsBirthdateValid(true);
    }

    const checkMskScore = (latestMskData?: MskScore) => {
        if (!latestMskData) {
            return false;
        }
        console.log(latestMskData);
        if (latestMskData.movementScore &&
            latestMskData.lifestyleScore &&
            "frontToeLeft" in latestMskData.movementScore.toeTouchScore &&
            "backToeLeft" in latestMskData.movementScore.toeTouchScore &&
            "frontToeRight" in latestMskData.movementScore.toeTouchScore &&
            "backToeRight" in latestMskData.movementScore.toeTouchScore &&
            "painSeverity" in latestMskData.movementScore.toeTouchScore &&
            "paperPassLeft" in latestMskData.movementScore.shoulderMobilityScore &&
            "paperPassRight" in latestMskData.movementScore.shoulderMobilityScore &&
            "fingerTipsLeft" in latestMskData.movementScore.shoulderMobilityScore &&
            "fingerTipsRight" in latestMskData.movementScore.shoulderMobilityScore &&
            "painSeverity" in latestMskData.movementScore.shoulderMobilityScore &&
            "feetTogetherLeft" in latestMskData.movementScore.rotationScore &&
            "feetTogetherRight" in latestMskData.movementScore.rotationScore &&
            "rightFootBackLeft" in latestMskData.movementScore.rotationScore &&
            "rightFootBackRight" in latestMskData.movementScore.rotationScore &&
            "painSeverity" in latestMskData.movementScore.rotationScore &&
            "squat" in latestMskData.movementScore.squatScore &&
            "fingerTips" in latestMskData.movementScore.squatScore &&
            "fists" in latestMskData.movementScore.squatScore &&
            "painSeverity" in latestMskData.movementScore.squatScore &&
            "oneFootRight" in latestMskData.movementScore.balanceScore &&
            "oneFootLeft" in latestMskData.movementScore.balanceScore &&
            "attempts" in latestMskData.movementScore.balanceScore.twoFeetLeft &&
            "success" in latestMskData.movementScore.balanceScore.twoFeetLeft &&
            "attempts" in latestMskData.movementScore.balanceScore.twoFeetRight &&
            "success" in latestMskData.movementScore.balanceScore.twoFeetRight &&
            "attempts" in latestMskData.movementScore.balanceScore.threeFeetLeft &&
            "success" in latestMskData.movementScore.balanceScore.threeFeetRight &&
            "painSeverity" in latestMskData.movementScore.balanceScore &&
            "shoulderClearingPainSeverity" in latestMskData.movementScore &&
            "spineClearingPainSeverity" in latestMskData.movementScore &&
            latestMskData.lifestyleScore.bodyCompositionScore &&
            "bmi" in latestMskData.lifestyleScore.bodyCompositionScore &&
            latestMskData.lifestyleScore.breathingScore &&
            "breathHoldTime" in latestMskData.lifestyleScore.breathingScore &&
            "breathingQuestionnaireScore" in latestMskData.lifestyleScore.breathingScore &&
            "isSmoking" in latestMskData.lifestyleScore.breathingScore &&
            latestMskData.lifestyleScore.injuryHistoryScore &&
            "previousInjury" in latestMskData.lifestyleScore.injuryHistoryScore &&
            "currentPain" in latestMskData.lifestyleScore.injuryHistoryScore &&
            "seenPhysician" in latestMskData.lifestyleScore.injuryHistoryScore &&
            "recoveryScore" in latestMskData.lifestyleScore.injuryHistoryScore &&
            latestMskData.lifestyleScore.sleepScore &&
            "psqiScore" in latestMskData.lifestyleScore.sleepScore
        ) {
            return true;
        }
        return false;
    }

    const handleDownloadReport = async (emailResults: boolean) => {
        if (!user.id) return;
        const userInFirebase = await UserService.get(user.id);
        if (!userInFirebase || !userInFirebase.organizationId) return;
        const latestOrgId = userInFirebase.organizationId;
        const userId = userInFirebase.id;

        if (!userId) {
            return;
        }
        // Get assessmentResponses
        const assessmentResponseCheck = await AssessmentUtils.fetchAssessmentResponsesById(userId)
        // Get latestMskScore
        const validMskScore = checkMskScore(latestMskData);
        let type = ReportType.Assessment
        if ((assessmentResponseCheck && assessmentResponseCheck.length > 7) || validMskScore) {
            type = ReportType.Wellness
        }
        const date = DateTime.utc().toISO();
        const report = await ReportService.getBy(
            [
                {
                    field: "userId",
                    operator: "==",
                    value: userId,
                },
                {
                    field: "organizationId",
                    operator: "==",
                    value: latestOrgId,
                },
                {
                    field: "status",
                    operator: "==",
                    value: "complete",
                },
                {
                    field: "type",
                    operator: "==",
                    value: type,
                },
                {
                    field: "expiration",
                    operator: ">",
                    value: date,
                },
            ], [
            {
                field: "expiration",
                direction: "desc",
            },
            {
                field: "updated",
                direction: "desc",
            }]);

        if (report.length <= 0) {
            setDownloadingReportSnackBar(enqueueSnackbar("Downloading your Symmio Report. Please wait while we generate it.", { variant: "info", autoHideDuration: 30000 }));
            if (validMskScore) {
                handleGenerateWellnessReport(userId, latestOrgId, type, latestMskData);
            }
            else {
                handleGenerateWellnessReport(userId, latestOrgId, type);
            }
            return;
        }

        if (!report[0].id) {
            return;
        }

        const reportUnsubscribe = ReportService.getSnapshot(
            report[0].id,
            (r: Report) => {
                if (r.status === "complete" && r.url) {
                    reportUnsubscribe();

                    //Loads in the System browser
                    closeSnackbar(downloadingReportSnackBar);
                    window.open(r.url);
                }
                if (r.status === "error") {
                    closeSnackbar(downloadingReportSnackBar);
                    setReportError(r.errorMessage);
                    reportUnsubscribe();
                }
            }
        );
    };

    const handleGenerateWellnessReport = async (userId: string, organizationId: string, type: number, latestMskData?: MskScore) => {
        const date = DateTime.now().toISODate();

        const report: Report = {
            dateEnd: date,
            dateStart: date,
            emailResults: false,
            organizationId: organizationId,
            status: "scheduled",
            type: type,
            userId: userId,
            mskScoreId: latestMskData ? latestMskData.id : undefined,
        };

        const user = await UserService.get(userId);

        const scheduledReport = await ReportService.save(
            report,
            user
        );

        if (!scheduledReport.id) {
            setReportError(
                "Report was not saved successfully. Please try again."
            );
            return;
        }

        const reportUnsubscribe = ReportService.getSnapshot(
            scheduledReport.id,
            (r: Report) => {
                if (r.status === "complete" && r.url) {
                    reportUnsubscribe();

                    //Loads in the System browser
                    window.open(r.url);
                }
                if (r.status === "error") {
                    setReportError(r.errorMessage);
                    reportUnsubscribe();
                }
            }
        );
    };

    return (
        <div className={COMPONENT_CLASS}>
            <Loader isVisible={isSaving} />
            <div className={`${COMPONENT_CLASS}__actions`}>
                <h1><span>{user.firstName} {user.lastName ?? "Not Available"}</span></h1>
                <div className="flex flex-row flex-wrap-reverse gap-4 justify-center">
                    <div className="flex items-center">
                        {statusBadge}
                    </div>
                    {latestMskData && (
                        <Button
                            onClick={() => handleDownloadReport(false)}
                            buttonText="Download Latest Report" />
                    )}
                    {canEdit &&
                        <Button
                            onClick={() => setIsEditing(true)}
                            buttonText="Edit" />
                    }
                </div>
            </div>
            <div className={`${COMPONENT_CLASS}__col-container`}>
                <div className={`${COMPONENT_CLASS}__col`}>
                    <div className={`${COMPONENT_CLASS}__photo`}>
                        {user.profileImage ?
                            <img
                                src={user.profileImage}
                                alt="Profile" />
                            :
                            <ProfileIcon />
                        }
                    </div>
                </div>
                <div className={`${COMPONENT_CLASS}__col`}>
                    <div className={`${COMPONENT_CLASS}__fieldgroup`}>
                        <label>Biological Sex</label>
                        <p>{user.biologicalSex || "Not Provided"}</p>
                    </div>
                    <div className={`${COMPONENT_CLASS}__fieldgroup`}>
                        <label>Birthdate</label>
                        <p>{birthdate}</p>
                    </div>

                </div>
                <div className={`${COMPONENT_CLASS}__col`}>
                    {/* <div className={`${COMPONENT_CLASS}__fieldgroup`}>
                        <label>Wellness Program</label>
                        <p>{user.symmioAccess === SymmioAccessType.AppLicense ? "Yes" : "No"}</p>
                    </div> */}
                    <div className={`${COMPONENT_CLASS}__fieldgroup`}>
                        <label>Group</label>
                        <p>{user.groupName || "No Group"}</p>
                    </div>
                    { // if
                        user.roles && user.roles.length > 0 &&
                        <div className={`${COMPONENT_CLASS}__fieldgroup`}>
                            <label>Role</label>
                            <p>{user.roles.map(role => Utils.getRoleNameFromValue(role)).join(', ')}</p>
                        </div>
                    }
                    <div className={`${COMPONENT_CLASS}__fieldgroup`}>
                        <label>Email</label>
                        <p style={{ wordWrap: 'break-word' }}>{user.email}</p>
                    </div>
                </div>
                <div className={`${COMPONENT_CLASS}__col`}>
                    <div className={`${COMPONENT_CLASS}__fieldgroup`}>
                        <label>Tags</label>
                        <p className="flex flex-wrap gap-2 mt-4">
                            { // if
                                (user.tags != null && user.tags.length >= 1) &&
                                user.tags?.map((tag: UserTag, tagIndex: number) => (<TagComponent.Tag
                                    id={tag.id}
                                    key={`tag-${tagIndex}`}
                                    bgColor="#1216220D"
                                >{tag.name}</TagComponent.Tag>))
                            }
                            { // if
                                (user?.tags == null || user.tags.length === 0) &&
                                <p>--</p>
                            }
                        </p>
                    </div>
                    { // if
                        isManagingGroups(user.roles) &&
                        <div className={`${COMPONENT_CLASS}__fieldgroup`}>
                            <label>Managed Groups</label>
                            <p>
                                { // if
                                    user?.managedGroupIds != null && user.managedGroupIds.length >= 1 &&
                                    user.managedGroupIds.filter(r => groups.find(g => g.id === r) != null)
                                        .map((groupId: string, index: number) => (<TagComponent.Tag
                                            id={groupId}
                                            key={`manage-group-${index}`}
                                            bgColor="#1216220D"
                                        >{groups.find(g => g.id === groupId)!.name}</TagComponent.Tag>))
                                }
                                { // if
                                    (user?.managedGroupIds == null || user.managedGroupIds.length === 0) &&
                                    <p>--</p>
                                }
                            </p>
                        </div>
                    }
                    { // if
                        state?.organization?.id != null &&
                        user.organizationId != null &&
                        user.organizationId !== state.organization.id &&
                        <div className={`${COMPONENT_CLASS}__fieldgroup ${COMPONENT_CLASS}__highlighted`}>
                            <label style={{ backgroundColor: "rgb(255, 255, 204)" }}>Organization</label>
                            <p>{userOrgName}</p>
                        </div>
                    }
                </div>
            </div>
            <Modal
                isOpen={isEditing}
                isLoading={isSaving || isLoadingGroups || isLoadingTags || isLoadingOrganizations}
                onClose={handleCloseUserEditModal}
                defaultModalActions={true}
                submitButtonText="Save Changes"
                submitDisabled={!isBirthdateValid}
                onSubmit={() => handleSaveUserClick()}
                onCancel={handleCloseUserEditModal}>
                <ChangeAccountHolderModal
                    open={changeAccountHolderModalOpen}
                    onClose={handleAccountHolderModalClose}
                    currentAccountHolder={currentAccountHolder}
                    newAccountHolderId={editUser?.id}
                    organization={editUserOrg}
                />
                <RemoveAccountHolderModal
                    open={removeAccountHolderModalOpen}
                    onClose={handleAccountHolderModalClose}
                    accountHolder={user}
                    organization={editUserOrg}
                />

                <div className="flex flex-wrap items-center justify-between gap-4">
                    <h2 className="mt-2 mb-4">Edit Profile</h2>
                    {statusBadge}
                </div>

                {isUserLimitReached &&
                    <div className={`${COMPONENT_CLASS}__user-limit-reached`}>
                        You have reached the user limit
                    </div>
                }

                {canEditStatus &&
                    <>
                        <div className={`${COMPONENT_CLASS}__edit -full`}>
                            <ToggleButton
                                id={"toggleUserStatus"}
                                isChecked={isUserDeactivated}
                                onClick={handleToggleSwitch}
                                mainLabelText="Deactivate Account"
                                subLabelText="Deactivating this user will eliminate access to Symmio."
                            />
                        </div>

                        <hr style={{ borderColor: "rgba(18, 22, 34, 0.05)", margin: "1.5rem 0", maxWidth: "100%" }} />
                    </>
                }

                <h4 style={{ marginBottom: "1.5rem" }}>User Details</h4>
                <div className={`${COMPONENT_CLASS}__edit`}>
                    <div>
                        <label htmlFor="firstName">First Name</label>
                        <TextTypeInput id="firstName"
                            type="text"
                            registerHook={register}
                            registerOptions={{
                                value: editUser?.firstName,
                                onChange: (e) => { setEditUser(Object.assign({}, editUser, { firstName: e.target.value })); }
                            }} />
                    </div>
                    <div>
                        <label htmlFor="lastName">Last Name</label>
                        <TextTypeInput
                            id="lastName"
                            type="text"
                            registerHook={register}
                            registerOptions={{
                                value: editUser?.lastName,
                                onChange: (e) => { setEditUser(Object.assign({}, editUser, { lastName: e.target.value })) }
                            }} />

                    </div>
                </div>

                <div className={`${COMPONENT_CLASS}__edit`}>
                    <div>
                        <label htmlFor="biologicalSex">Biological Sex</label>
                        <Select
                            id="biologicalSex"
                            name="biologicalSex"
                            classNamePrefix={COMPONENT_CLASS}
                            placeholder="Biological Sex"
                            options={BiologicalSexOptions}
                            onChange={(newValue: any, actionMeta: any) => setEditUser(Object.assign({}, editUser, { biologicalSex: newValue.value }))}
                            value={BiologicalSexOptions.find(o => o.value === editUser?.biologicalSex)}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width: "100%",
                                }),
                            }}
                        />
                    </div>
                    <div>
                        <label htmlFor="birthdate">Birthdate</label>
                        <DateInputOption answer={dayjs(editUser?.birthdate)} onChange={handleBirthdateChange} onInvalidDate={() => setIsBirthdateValid(false)} ></DateInputOption>

                        {!isBirthdateValid &&
                            <div className={`${COMPONENT_CLASS}__error-message`}>
                                The birthdate is not valid.
                            </div>
                        }
                    </div>
                </div>

                <hr style={{ borderColor: "rgba(18, 22, 34, 0.05)", margin: "1.5rem 0", maxWidth: "100%" }} />

                <h4 style={{ marginBottom: "1.5rem" }}>Organization</h4>

                <div className={`${COMPONENT_CLASS}__edit ${!UserUtil.isSuperAdmin(state.claims) && "-full"}`}>
                    {
                        UserUtil.isSuperAdmin(state.claims) &&
                        <div>
                            <label htmlFor="organization">Organization</label>
                            {
                                (user.roles && user.roles[0] === UserRoles.ACCOUNT_HOLDER_ID) &&
                                <span className={`${COMPONENT_CLASS}__note`}>
                                    Account Holders cannot change organizations. If you want to change this user's organization, you must first transfer the Account Holder role to a different user.
                                </span>
                            }
                            {
                                !(user.roles && user.roles[0] === UserRoles.ACCOUNT_HOLDER_ID)
                                && (editUser && editUser?.roles && editUser?.roles[0] === UserRoles.ACCOUNT_HOLDER_ID) &&
                                <span className={`${COMPONENT_CLASS}__note`}>
                                    Account Holders cannot change organizations. If you want to change this user's organization, you must do so (and save) before assigning the Account Holder role.
                                </span>
                            }
                            <Select
                                id="organization"
                                name="organization"
                                classNamePrefix={COMPONENT_CLASS}
                                placeholder="Organization"
                                options={organizations.map((o) => ({ label: o.name, value: o.id }))}
                                onChange={handleOrganizationChange}
                                value={editUser?.organizationId == null ? null : { label: organizations.find(({ id }) => id === editUser?.organizationId)?.name, value: organizations.find(({ id }) => id === editUser?.organizationId)?.id }}
                                isDisabled={
                                    (user.roles && user.roles[0] === UserRoles.ACCOUNT_HOLDER_ID)
                                    || (editUser && editUser?.roles && editUser?.roles[0] === UserRoles.ACCOUNT_HOLDER_ID)
                                }
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        width: "100%",
                                    }),
                                }}
                            />
                        </div>
                    }
                    <div>
                        <label htmlFor="role">Role</label>
                        {
                            noHolderOrgSwitch &&
                            <span className={`${COMPONENT_CLASS}__note`}>
                                Note: The Account Holder option is disabled because the user's organization was changed. You must save the organization change to enable the Account Holder option.
                            </span>
                        }
                        { // if
                            canEditRoles() &&
                            <Select
                                isClearable={true}
                                id="role"
                                name="role"
                                classNamePrefix={COMPONENT_CLASS}
                                placeholder="Role"
                                options={roleDropdownOptions}
                                onChange={handleRoleChange}
                                value={role}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        width: "100%",
                                    }),
                                }} />
                        }
                        { // if
                            !canEditRoles() &&
                            <p>{(user.roles ?? []).map(role => Utils.getRoleNameFromValue(role)).join(", ")}</p>
                        }
                        {
                            removingAccountHolder &&
                            <span className={`${COMPONENT_CLASS}__form-error`}>
                                Warning: You are removing the Account Holder for this organization. You will be prompted to select a new Account Holder before saving.
                            </span>
                        }
                        {
                            (UserUtil.isAccountHolder(editUser)) &&
                            <div style={{ display: 'block', marginLeft: '1.5em' }}>
                                {
                                    overwritingAccountHolder &&
                                    <span className={`${COMPONENT_CLASS}__form-error`}>
                                        Warning: You are replacing current Account Holder for this organization. You will be prompted to assign a new role to the current Account Holder before saving.
                                    </span>
                                }
                            </div>
                        }
                    </div>
                </div>

                { // if
                    (UserUtil.isManager(editUser) || UserUtil.isTeamMember(editUser)) &&
                    <div className={`${COMPONENT_CLASS}__edit -full`}>
                        <div>
                            <label htmlFor="managerGroups">Managed Groups</label>
                            { // if
                                canEditRoles() &&
                                <Select
                                    isMulti
                                    isClearable={false}
                                    name="managerGroups"
                                    classNamePrefix={COMPONENT_CLASS}
                                    placeholder="Managed Groups"
                                    options={managedGroupOptions}
                                    onChange={(newValue: any, actionMeta: any) => setEditUser(Object.assign({}, editUser, { managedGroupIds: newValue.map((v: any) => v.value) }))}
                                    value={editUser?.managedGroupIds?.filter(r => r).map(r => managedGroupOptions.find(o => o.value === r))}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            width: "100%",
                                        }),
                                    }} />
                            }
                            { // if
                                !canEditRoles() &&
                                <p>
                                    {
                                        user?.managedGroupIds == null ||
                                            user.managedGroupIds.length === 0 ||
                                            groups == null ||
                                            groups.filter((g) => user.managedGroupIds!.indexOf(g.id) >= 0).length === 0
                                            ? "--"
                                            : groups.filter((g) => user.managedGroupIds!.indexOf(g.id) >= 0).map(g => g.name).join(", ")
                                    }
                                </p>
                            }
                        </div>
                    </div>
                }

                <div className={`${COMPONENT_CLASS}__edit`}>
                    <div>
                        <label htmlFor="group">Group</label>
                        <Select
                            id="group"
                            name="group"
                            classNamePrefix={COMPONENT_CLASS}
                            placeholder="Group"
                            options={groups.map((g) => ({ label: g.name, value: g.id }))}
                            onChange={(newValue: any, actionMeta: any) => setEditUser(Object.assign({}, editUser, { groupName: newValue.label, groupId: newValue.value }))}
                            value={editUser?.groupId == null ? null : { label: editUser?.groupName, value: editUser?.groupId }}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width: "100%",
                                }),
                            }} />
                    </div>
                    <div>
                        <label htmlFor="tag">Tag</label>
                        <Select
                            isMulti
                            isClearable={true}
                            id="tag"
                            name="tag"
                            classNamePrefix={COMPONENT_CLASS}
                            placeholder="Tag"
                            options={tags.map((t) => ({ label: t.name, value: t.id }))}
                            onChange={(newValue: any, actionMeta: any) => setEditUser(Object.assign({}, editUser, { tags: newValue.map((v: ListOptions) => ({ id: v.value, name: v.label })) }))}
                            value={editUser?.tags?.filter(t => t).map(t => ({ label: t.name, value: t.id }))}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width: "100%",
                                }),
                            }} />
                    </div>
                </div>

                { // if
                    (UserUtil.isAdmin(state.user) || UserUtil.isManager(state.user) || UserUtil.isAccountHolder(state.user) || UserUtil.isSuperAdmin(state.claims)) && (
                        <div className={`${COMPONENT_CLASS}__edit -full ${overLicenseLimit ? `${COMPONENT_CLASS}__fieldgroup-error` : ''}`}>

                            {organization &&
                                <SymmioLicenseCheckbox
                                    organization={organization}
                                    onClick={handleSymmioAccessCheckbox}
                                    isChecked={isSymmioAccess}
                                    numberOfAddedLicenses={!hadSymmioAccessBefore && isSymmioAccess ? 1 : 0}
                                    numberOfRemovedLicenses={hadSymmioAccessBefore && !isSymmioAccess ? 1 : 0}
                                    isDisabled={editUser?.status === UserStatus.Disabled}
                                />
                            }
                        </div>
                    )
                }
                {
                    overLicenseLimit && (
                        <div className={`${COMPONENT_CLASS}__license-error`}>
                            You've reached the limit for licenses to be added/assigned.
                        </div>
                    )
                }
            </Modal >
        </div >
    );
}

/**
 * TODO: FMS-1261 - This method can be entirely removed when this tech debt item is addressed.
 * Update the user count on any tags where the user may have been added or removed
 * @param user Current state of the user
 * @param editUser User with changes
 * @param currentUser (Optional) User that made the change
 */
async function updateTagUserCount(user: User, editUser: User, currentUser?: User | null) {
    let deletedTagIds: string[] = [];
    if (user.tagIds != null && user.tagIds.length > 0) {
        const newTagIds = editUser.tagIds || [];
        deletedTagIds = user.tagIds.filter(id => newTagIds.indexOf(id) === -1);
    }

    const isTagChange = deletedTagIds.length > 0 || (editUser.tagIds && editUser.tagIds.length > 0);
    if (isTagChange && editUser.tagIds != null && editUser.tagIds.length > 0) {
        for (const id of editUser.tagIds) {
            const tag = await TagService.get(id);

            if (tag) {
                tag.userCount = await UserService.getCountBy([{
                    field: "tagIds",
                    operator: "array-contains",
                    value: id,
                }]);
                await TagService.save(tag, currentUser);
            }
        }
    }

    for (const id of deletedTagIds) {
        const tag = await TagService.get(id);

        if (tag) {
            tag.userCount = await UserService.getCountBy([{
                field: "tagIds",
                operator: "array-contains",
                value: id,
            }]);
            await TagService.save(tag, currentUser);
        }
    }
}

export default UserProfileHeader;

