import { Timestamp } from "@firebase/firestore";
import FirestoreDocument from "./firestore-document";
import UserImportantMessage from "./user-important-message";
import UserRetakeAssessment from "./user-retake-assessment";

export interface IUser extends FirestoreDocument {
    authenticationId: string;
    biologicalSex?: string;
    birthdate?: string;
    dayStreak: number;
    bestDayStreak?: number;
    email: string;
    enablePushNotifications?: boolean;
    firstName: string;
    groupId?: string;
    id?: string;
    importantMessages?: UserImportantMessage[];
    introDismissed?: boolean;
    importantMessageDismissed?: boolean;
    congratulatoryMessageDismissed?: boolean;
    isAccountSetup?: boolean;
    isAuthenticated?: boolean;
    isOnboarded?: boolean;
    isSuperAdmin?: boolean;
    lastName: string;
    organizationId?: string;
    profileImage?: string;
    retakeLifestyleAssessments?: UserRetakeAssessment[];
    retakeMovementAssessment?: UserRetakeAssessment | null;
    roles?: string[];
    administeredRetake?: boolean;
    lastActivityCompleted: Timestamp;
}

export class User implements FirestoreDocument {
    public authenticationId: string = "";
    public biologicalSex?: string;
    public birthdate?: string;
    public dayStreak: number = 0;
    public bestDayStreak?: number;
    public email: string = "";
    public enablePushNotifications?: boolean;
    public firstName: string = "";
    public groupId?: string;
    public id?: string;
    public importantMessages?: UserImportantMessage[];
    public introDismissed?: boolean;
    public congratulatoryMessageDismissed?: boolean;
    public isAccountSetup?: boolean;
    public isAuthenticated?: boolean;
    public isOnboarded?: boolean;
    public lastName: string = "";
    public organizationId?: string;
    public profileImage?: string;
    public retakeLifestyleAssessments?: UserRetakeAssessment[];
    public retakeMovementAssessment?: UserRetakeAssessment | null;
    public administeredRetake?: boolean;
    public lastActivityCompleted: Timestamp = new Timestamp(
        new Date(0).getSeconds(),
        0
    );

    constructor(values: Partial<IUser> = {}) {
        Object.assign(this, values);
    }
}
