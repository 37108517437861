/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { MskScore } from "../../models/interfaces/msk-score";
import { User } from "../../models/interfaces/user";
import AssessmentResponse from "../../models/interfaces/assessment-response.entity";
import { Loader } from "../loader/loader";
import MskScoreService from "../../utilities/services/msk-score-service";
import { GroupIds } from "../../models/enumerations/group-ids";
import UserProfileBehavioral from "./user-profile-behavioral";
import UserProfileNutrition from "./user-profile-nutrition";
import UserProfileMovement from "./user-profile-movement";
import UserProfileInjuryHistory from "./user-profile-injury-history";
import UserProfileBodyComposition from "./user-profile-body-composition";
import UserProfilePhysicalActivity from "./user-profile-physical-activity";
import UserProfileBreathing from "./user-profile-breathing";
import UserProfileSleep from "./user-profile-sleep";
import { Utils } from "../../utilities/utils";
import AssessmentBarChart from "../assessment-bar-chart/assessment-bar-chart";
import { ResultLevel } from "../../models/enumerations/result-level";
import { ReactComponent as ScoreUpIcon } from "../../assets/icons/solid/stat-icon-up.svg";
import { ReactComponent as ScoreDownIcon } from "../../assets/icons/solid/stat-icon-down.svg";
import { ReactComponent as CheckmarkIcon } from "../../assets/icons/nav/icon_checkmark.svg";
import { ReactComponent as DashIcon } from "../../assets/icons/solid/dash.svg";
import { ReactComponent as ExclamationIcon } from "../../assets/icons/solid/exclamation.svg";
import { UserProfileAssessment } from "../../models/interfaces/user-profile-asssessment";
import { UserProfileAssessmentPriority } from "../../models/enumerations/user-profile-assessment-priority";
import MskScoreUtil from "../../utilities/msk-score-util";

const COMPONENT_CLASS = "c-user-profile-assessments";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfileAssessmentsProps {
    mskScore?: MskScore;
    validMskScore: boolean;
    allMskScores: MskScore[];
    setAllMskScores: Function;
    user: User;
    assessmentResponses: AssessmentResponse[];
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfileAssessments: React.FC<UserProfileAssessmentsProps> = (props: UserProfileAssessmentsProps) => {
    const { validMskScore, user, mskScore, assessmentResponses, allMskScores, setAllMskScores } = props;
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [assessments, setAssessments] = useState<{ [key: string]: UserProfileAssessment }>();
    const [specificAssessment, setSpecificAssessment] = useState<GroupIds>();

    useEffect(() => {
        const getAllMskScores = async () => {
            if (!allMskScores) {
                const mskScores = await MskScoreService.getBy([{
                    field: "userId",
                    operator: "==",
                    value: user.id
                }], [{
                    field: "created",
                    direction: "asc"
                }])

                setAllMskScores(mskScores);
            }
        }
        getAllMskScores();
    }, [])

    useEffect(() => {
        const initialLoad = async () => {
            // Load Assessment Groups
            let previousMskScore;

            // Get previous mskScore
            const lastTwoMskScores = await MskScoreService.getBy([{
                field: "userId",
                operator: "==",
                value: user.id
            }], [{
                field: "created",
                direction: "desc"
            }], 2)

            if (lastTwoMskScores && lastTwoMskScores.length <= 1) {
                previousMskScore = undefined;
            }
            else {
                previousMskScore = lastTwoMskScores[1];
            }

            setAssessments({
                [GroupIds.BEHAVIORAL_HEALTH]: {
                    groupId: GroupIds.BEHAVIORAL_HEALTH,
                    assessmentName: "Behavioral Health",
                    previous: previousMskScore ? previousMskScore.lifestyleScore?.behavioralScore?.percentage : undefined,
                    current: mskScore?.lifestyleScore?.behavioralScore?.percentage,
                    progress: previousMskScore && mskScore?.lifestyleScore?.behavioralScore?.percentage !== undefined && previousMskScore.lifestyleScore?.behavioralScore?.percentage !== undefined ? mskScore?.lifestyleScore?.behavioralScore?.percentage - previousMskScore.lifestyleScore?.behavioralScore?.percentage : 0,
                    priority: getPriority(mskScore?.lifestyleScore?.behavioralScore?.resultLevel),
                    priorityPrevious: getPriority(previousMskScore?.lifestyleScore?.behavioralScore?.resultLevel),
                },
                [GroupIds.MOVEMENT_HEALTH]: {
                    groupId: GroupIds.MOVEMENT_HEALTH,
                    assessmentName: "Movement Health",
                    hasPain: mskScore?.movementScore?.hasSpineClearingPain || mskScore?.movementScore?.hasShoulderClearingPain,
                    previous: previousMskScore ? previousMskScore.movementScore?.percentage : undefined,
                    current: mskScore?.movementScore?.percentage,
                    progress: previousMskScore && mskScore?.movementScore?.percentage !== undefined && previousMskScore.movementScore?.percentage !== undefined ? mskScore?.movementScore?.percentage - previousMskScore.movementScore?.percentage : 0,
                    priority: getPriority(mskScore?.movementScore?.resultLevel),
                    priorityPrevious: getPriority(previousMskScore?.movementScore?.resultLevel),
                },
                [GroupIds.INJURY_HISTORY]: {
                    groupId: GroupIds.INJURY_HISTORY,
                    assessmentName: "Injury History",
                    previous: previousMskScore ? previousMskScore.lifestyleScore?.injuryHistoryScore?.percentage : undefined,
                    current: mskScore?.lifestyleScore?.injuryHistoryScore?.percentage,
                    progress: previousMskScore && mskScore?.lifestyleScore?.injuryHistoryScore?.percentage !== undefined && previousMskScore.lifestyleScore?.injuryHistoryScore?.percentage !== undefined ? mskScore?.lifestyleScore?.injuryHistoryScore?.percentage - previousMskScore.lifestyleScore?.injuryHistoryScore?.percentage : 0,
                    priority: getPriority(mskScore?.lifestyleScore?.injuryHistoryScore?.resultLevel),
                    priorityPrevious: getPriority(previousMskScore?.lifestyleScore?.injuryHistoryScore?.resultLevel),
                },
                [GroupIds.SLEEP]: {
                    groupId: GroupIds.SLEEP,
                    assessmentName: "Sleep Wellness",
                    previous: previousMskScore ? previousMskScore.lifestyleScore?.sleepScore?.percentage : undefined,
                    current: mskScore?.lifestyleScore?.sleepScore?.percentage,
                    progress: previousMskScore && mskScore?.lifestyleScore?.sleepScore?.percentage !== undefined && previousMskScore.lifestyleScore?.sleepScore?.percentage !== undefined ? mskScore?.lifestyleScore?.sleepScore?.percentage - previousMskScore.lifestyleScore?.sleepScore?.percentage : 0,
                    priority: getPriority(mskScore?.lifestyleScore?.sleepScore?.resultLevel),
                    priorityPrevious: getPriority(previousMskScore?.lifestyleScore?.sleepScore?.resultLevel),
                },
                [GroupIds.NUTRITION]: {
                    groupId: GroupIds.NUTRITION,
                    assessmentName: "Nutrition Awareness",
                    previous: previousMskScore ? previousMskScore.lifestyleScore?.nutritionScore?.percentage : undefined,
                    current: mskScore?.lifestyleScore?.nutritionScore?.percentage,
                    progress: previousMskScore && mskScore?.lifestyleScore?.nutritionScore?.percentage !== undefined && previousMskScore.lifestyleScore?.nutritionScore?.percentage !== undefined ? mskScore?.lifestyleScore?.nutritionScore?.percentage - previousMskScore.lifestyleScore?.nutritionScore?.percentage : 0,
                    priority: getPriority(mskScore?.lifestyleScore?.nutritionScore?.resultLevel),
                    priorityPrevious: getPriority(previousMskScore?.lifestyleScore?.nutritionScore?.resultLevel),
                },
                [GroupIds.BODY_COMPOSITION]: {
                    groupId: GroupIds.BODY_COMPOSITION,
                    assessmentName: "Body Composition",
                    previous: previousMskScore ? previousMskScore.lifestyleScore?.bodyCompositionScore?.percentage : undefined,
                    current: mskScore?.lifestyleScore?.bodyCompositionScore?.percentage,
                    progress: previousMskScore && mskScore?.lifestyleScore?.bodyCompositionScore?.percentage !== undefined && previousMskScore.lifestyleScore?.bodyCompositionScore?.percentage !== undefined ? mskScore?.lifestyleScore?.bodyCompositionScore?.percentage - previousMskScore.lifestyleScore?.bodyCompositionScore?.percentage : 0,
                    priority: getPriority(mskScore?.lifestyleScore?.bodyCompositionScore?.resultLevel),
                    priorityPrevious: getPriority(previousMskScore?.lifestyleScore?.bodyCompositionScore?.resultLevel),
                },
                [GroupIds.BREATHING]: {
                    groupId: GroupIds.BREATHING,
                    assessmentName: "Breathing Quality",
                    previous: previousMskScore ? previousMskScore.lifestyleScore?.breathingScore?.percentage : undefined,
                    current: mskScore?.lifestyleScore?.breathingScore?.percentage,
                    progress: previousMskScore && mskScore?.lifestyleScore?.breathingScore?.percentage !== undefined && previousMskScore.lifestyleScore?.breathingScore?.percentage !== undefined ? mskScore?.lifestyleScore?.breathingScore?.percentage - previousMskScore.lifestyleScore?.breathingScore?.percentage : 0,
                    priority: getPriority(mskScore?.lifestyleScore?.breathingScore?.resultLevel),
                    priorityPrevious: getPriority(previousMskScore?.lifestyleScore?.breathingScore?.resultLevel),
                },
                [GroupIds.PHYSICAL_ACTIVITY]: {
                    groupId: GroupIds.PHYSICAL_ACTIVITY,
                    assessmentName: "Physical Activity",
                    previous: previousMskScore ? previousMskScore.lifestyleScore?.physicalActivityScore?.percentage : undefined,
                    current: mskScore?.lifestyleScore?.physicalActivityScore?.percentage,
                    progress: previousMskScore && mskScore?.lifestyleScore?.physicalActivityScore?.percentage !== undefined && previousMskScore.lifestyleScore?.physicalActivityScore?.percentage !== undefined ? mskScore?.lifestyleScore?.physicalActivityScore?.percentage - previousMskScore.lifestyleScore?.physicalActivityScore?.percentage : 0,
                    priority: getPriority(mskScore?.lifestyleScore?.physicalActivityScore?.resultLevel),
                    priorityPrevious: getPriority(previousMskScore?.lifestyleScore?.physicalActivityScore?.resultLevel),
                },
            });

            setIsLoading(false);
        }

        initialLoad()
    }, [mskScore])

    const getPriority = (resultLevel?: string) => {
        if (!resultLevel) {
            return undefined;
        }
        return resultLevel === ResultLevel.Mid ? UserProfileAssessmentPriority.Moderate : resultLevel === ResultLevel.Low ? UserProfileAssessmentPriority.High : UserProfileAssessmentPriority.Low
    }

    const getSpecificAssessmentPage = (assessment: GroupIds) => {
        switch (assessment) {
            case GroupIds.BEHAVIORAL_HEALTH:
                return <UserProfileBehavioral
                    setSpecificAssessment={setSpecificAssessment}
                    mskScore={mskScore}
                    assessment={assessments ? assessments[GroupIds.BEHAVIORAL_HEALTH] : undefined}
                    allMskScores={allMskScores}
                    setAllMskScores={setAllMskScores}
                    user={user} />
            case GroupIds.NUTRITION:
                return <UserProfileNutrition
                    setSpecificAssessment={setSpecificAssessment}
                    mskScore={mskScore}
                    assessment={assessments ? assessments[GroupIds.NUTRITION] : undefined}
                    allMskScores={allMskScores}
                    setAllMskScores={setAllMskScores}
                    user={user} />
            case GroupIds.MOVEMENT_HEALTH:
                return <UserProfileMovement
                    setSpecificAssessment={setSpecificAssessment}
                    mskScore={mskScore}
                    validMskScore={validMskScore}
                    assessment={assessments ? assessments[GroupIds.MOVEMENT_HEALTH] : undefined}
                    allMskScores={allMskScores}
                    setAllMskScores={setAllMskScores}
                    user={user}
                    assessmentResponses={assessmentResponses} />
            case GroupIds.INJURY_HISTORY:
                return <UserProfileInjuryHistory
                    setSpecificAssessment={setSpecificAssessment}
                    mskScore={mskScore}
                    assessment={assessments ? assessments[GroupIds.INJURY_HISTORY] : undefined}
                    allMskScores={allMskScores}
                    setAllMskScores={setAllMskScores}
                    user={user}
                    assessmentResponses={assessmentResponses} />
            case GroupIds.BODY_COMPOSITION:
                return <UserProfileBodyComposition
                    setSpecificAssessment={setSpecificAssessment}
                    mskScore={mskScore}
                    assessment={assessments ? assessments[GroupIds.BODY_COMPOSITION] : undefined}
                    allMskScores={allMskScores}
                    setAllMskScores={setAllMskScores}
                    user={user}
                    assessmentResponses={assessmentResponses} />
            case GroupIds.SLEEP:
                return <UserProfileSleep
                    setSpecificAssessment={setSpecificAssessment}
                    mskScore={mskScore}
                    assessment={assessments ? assessments[GroupIds.SLEEP] : undefined}
                    allMskScores={allMskScores}
                    setAllMskScores={setAllMskScores}
                    user={user}
                    assessmentResponses={assessmentResponses}
                />
            case GroupIds.BREATHING:
                return <UserProfileBreathing
                    setSpecificAssessment={setSpecificAssessment}
                    mskScore={mskScore}
                    assessment={assessments ? assessments[GroupIds.BREATHING] : undefined}
                    allMskScores={allMskScores}
                    setAllMskScores={setAllMskScores}
                    user={user}
                    assessmentResponses={assessmentResponses} />
            case GroupIds.PHYSICAL_ACTIVITY:
                return <UserProfilePhysicalActivity
                    setSpecificAssessment={setSpecificAssessment}
                    mskScore={mskScore}
                    assessment={assessments ? assessments[GroupIds.PHYSICAL_ACTIVITY] : undefined}
                    allMskScores={allMskScores}
                    setAllMskScores={setAllMskScores}
                    user={user}
                    assessmentResponses={assessmentResponses} />
        }
    }

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <Loader isVisible={isLoading} />
            {specificAssessment ? getSpecificAssessmentPage(specificAssessment) :
                <div className="mt-8">
                    <table
                        role={"table"}
                        className={"dark list__table-container"}
                        data-testid="deeplinks-list">
                        <thead className="hide-on-mobile">
                            <tr className="uppercase">
                                <th>Assessment</th>
                                <th>Previous</th>
                                <th>Current</th>
                                <th>Progress</th>
                                <th>Priority</th>
                            </tr>
                        </thead>
                        <tbody>
                            {assessments && Object
                                .keys(assessments)
                                .sort(function (a, b) { return (assessments[a].current || 0) - (assessments[b].current || 0) }) // sort by the current score in a descending order
                                .map((key, i) => {
                                    return (
                                        <tr
                                            className="table-row-container"
                                            key={key}
                                            onClick={() => {
                                                setSpecificAssessment(assessments[key].groupId);
                                            }}
                                        >
                                            <td>
                                                <div className="flex gap-2 items-center">
                                                    <div className={`${COMPONENT_CLASS}__icon-container`}>
                                                        {Utils.getFocusAreaData(assessments[key].groupId).icon}
                                                    </div>
                                                    <div>
                                                        {assessments[key].assessmentName}
                                                    </div>
                                                    {assessments[key].groupId === GroupIds.MOVEMENT_HEALTH &&
                                                        <div>
                                                            {mskScore
                                                                ?
                                                                Utils.getPainBadge(MskScoreUtil.getNumberOfPainLocationsMovementAssessmentFromMsk(mskScore) > 0)
                                                                :
                                                                assessmentResponses && Utils.getPainBadge(MskScoreUtil.getNumberOfPainLocationsMovementAssessment(assessmentResponses) > 0)
                                                            }
                                                        </div>
                                                    }
                                                    {assessments[key].groupId === GroupIds.INJURY_HISTORY &&
                                                        <div>
                                                            {mskScore && mskScore.lifestyleScore?.injuryHistoryScore?.currentPain !== undefined
                                                                ?
                                                                Utils.getPainBadge(mskScore.lifestyleScore?.injuryHistoryScore?.currentPain)
                                                                :
                                                                assessmentResponses && Utils.getPainBadge(MskScoreUtil.getNumberOfPainLocationsInjuryHistoryAssessment(assessmentResponses) > 0)
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                <strong className="show-on-mobile mr-1">PREVIOUS:</strong>
                                                <span className={`${COMPONENT_CLASS} ${assessments[key].priorityPrevious === UserProfileAssessmentPriority.High ? "text-red-dark" : ""} text-xl font-bold leading-8`}>{assessments[key].previous}</span>
                                            </td>
                                            <td>
                                                <strong className="show-on-mobile mr-1">CURRENT:</strong>
                                                <div className="flex items-center gap-4">
                                                    <span className={`${COMPONENT_CLASS} ${assessments[key].priority === UserProfileAssessmentPriority.High ? "text-red-dark" : ""} text-xl font-bold leading-8 min-w-12 max-w-fit`}>{assessments[key].current}</span>
                                                    <div className="hide-on-tablet" style={{ width: "250px" }}>
                                                        <AssessmentBarChart percentage={assessments[key].current || 0} />
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <strong className="show-on-mobile mr-1">PROGRESS:</strong>
                                                <div className="flex gap-2 items-center">
                                                    {assessments[key].progress > 0 ? "+" : ""}{assessments[key].progress}pts
                                                    {assessments[key].progress > 0 ? <ScoreUpIcon /> :
                                                        assessments[key].progress < 0 ? < ScoreDownIcon /> : <></>}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="flex gap-2 items-center">
                                                    <strong className="show-on-mobile mr-1">PRIORITY:</strong>
                                                    <div className={`${COMPONENT_CLASS}__icon-container-20`}>
                                                        {assessments[key].priority === UserProfileAssessmentPriority.Low ? <CheckmarkIcon className="checkmark-icon" /> :
                                                            assessments[key].priority === UserProfileAssessmentPriority.Moderate ? <DashIcon /> :
                                                                assessments[key].priority === UserProfileAssessmentPriority.High ? <ExclamationIcon /> : <></>}
                                                    </div>
                                                    {assessments[key].priority}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </table>
                </div>
            }
        </div>
    );
}

export default UserProfileAssessments;
