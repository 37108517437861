import React from "react";

const COMPONENT_CLASS = "c-badge";

interface UsersBadgeProps {
    text: string;
    bgColor?: string;
    textColor?: string;
}

/**
 * A small badge representing the status of an organization.
 */
export const Badge: React.FC<UsersBadgeProps> = (props) => {
    const {
        text,
        bgColor,
        textColor,
    } = props;

    return (
        <div className={COMPONENT_CLASS} style={{ backgroundColor: bgColor, color: textColor }}>
            {text}
        </div>
    )
};

export default Badge;

