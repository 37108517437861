export const AnswerIds = {
    A: "A",
    B: "B",
    C: "C",
    D: "D",
    E: "E",
    F: "F",
    G: "G",
    FAIL: "FAIL",
    PASS: "PASS",
};
