import { UserCorrectiveExercise } from "../../../models/interfaces/corrective-exercises/user-corrective-exercise";
import FirestoreCondition from "../../../models/interfaces/firestore-condition";
import FirestoreService from "../firestore-service";

const COLLECTION_NAME = "userCorrectiveExercises";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

/**
 * Add a new user corrective exercises to the userCorrectiveExercises collection
 * @param {UserCorrectiveExercise} exercise - The corrective exercise that is being added to the collection
 * @param {firebase.User} currentUser - The user that is logged into the application
 * @returns {Promise<UserCorrectiveExercise>} A promise to the newly added corrective exercise
 */
const add = async (
    exercise: UserCorrectiveExercise,
    currentUser: any | null = null
) => {
    return FirestoreService.add<UserCorrectiveExercise>(
        COLLECTION_NAME,
        exercise,
        currentUser
    );
};

/**
 * Delete a user collection by the Id
 * @param {string} id - The Id of the user being deleted
 */
const deleteByUser = async (userId: string) => {
    const conditions: FirestoreCondition[] = [
        {
            field: "userId",
            operator: "==",
            value: userId,
        },
    ];

    const records = await FirestoreService.getBy(COLLECTION_NAME, conditions);

    records.forEach((record) => {
        FirestoreService.deleteById(COLLECTION_NAME, record.id!);
    });
};

/**
 * Find the specific user by the id
 * @param {string} id - The Id of the user that we are retrieving
 * @returns {Promise<User>} A promise for the user we are retrieving
 */
const getCurrentByUserId = async (userId: string) => {
    const conditions: FirestoreCondition[] = [
        {
            field: "userId",
            operator: "==",
            value: userId,
        },
        {
            field: "isCurrent",
            operator: "==",
            value: true,
        },
        {
            field: "routineId",
            operator: "!=",
            value: "EXTRA",
        },
    ];

    return FirestoreService.getBy<UserCorrectiveExercise>(
        COLLECTION_NAME,
        conditions,
        [
            {
                field: "routineId",
                direction: "desc",
            },
            {
                field: "created",
                direction: "desc",
            },
        ],
        1
    );
};

/**
 * Find the specific user by the id
 * @param {string} id - The Id of the user that we are retrieving
 * @returns {Promise<User>} A promise for the user we are retrieving
 */
const getCurrentExtraByUserId = async (userId: string) => {
    const conditions: FirestoreCondition[] = [
        {
            field: "userId",
            operator: "==",
            value: userId,
        },
        {
            field: "isCurrent",
            operator: "==",
            value: true,
        },
        {
            field: "routineId",
            operator: "==",
            value: "EXTRA",
        },
    ];

    return FirestoreService.getBy<UserCorrectiveExercise>(
        COLLECTION_NAME,
        conditions,
        [
            {
                field: "created",
                direction: "desc",
            },
        ],
        1
    );
};

const getAllCurrentByUserId = async (userId: string) => {
    const conditions: FirestoreCondition[] = [
        {
            field: "userId",
            operator: "==",
            value: userId,
        },
        {
            field: "isCurrent",
            operator: "==",
            value: true,
        },
    ];

    return FirestoreService.getBy<UserCorrectiveExercise>(
        COLLECTION_NAME,
        conditions,
        [
            {
                field: "created",
                direction: "desc",
            },
        ],
    );
};

/**
 * Update the user corrective exercise
 * @param {UserCorrectiveExercise} userCorrectiveExercise - The user corrective exercise that is being updated
 * @param {firebase.User} currentUser - The user that is logged into the application
 * @returns {Promise<UserCorrectiveExercise>} A promise for the collection of user corrective exercises
 */
const update = async (
    userCorrectiveExercise: UserCorrectiveExercise,
    currentUser: any | null = null
) => {
    return FirestoreService.update<UserCorrectiveExercise>(
        COLLECTION_NAME,
        userCorrectiveExercise,
        currentUser
    );
};

/**
 * Update the user corrective exercise
 * @param {UserCorrectiveExercise} userCorrectiveExercise - The user corrective exercise that is being updated
 * @param {firebase.User} currentUser - The user that is logged into the application
 * @returns {Promise<UserCorrectiveExercise>} A promise for the collection of user corrective exercises
 */
const updateById = async (
    userCorrectiveExercise: UserCorrectiveExercise,
    currentUserId: string | null = null
) => {
    return FirestoreService.updateById<UserCorrectiveExercise>(
        COLLECTION_NAME,
        userCorrectiveExercise,
        currentUserId
    );
};

// #endregion Service Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const UserCorrectiveExercisesService = {
    add,
    deleteByUser,
    getCurrentByUserId,
    getCurrentExtraByUserId,
    getAllCurrentByUserId,
    update,
    updateById,
};

export default UserCorrectiveExercisesService;

// #endregion Exports
