/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { MskScore } from "../../models/interfaces/msk-score";
import { User } from "../../models/interfaces/user";
import MskScoreService from "../../utilities/services/msk-score-service";
import moment from "moment";
import { UserProfileAssessment } from "../../models/interfaces/user-profile-asssessment";
import UserProfileAssessmentHeader from "./user-profile-assessment-header";
import LineChartJS from "../charts/line-chart-js";

const COMPONENT_CLASS = "c-user-profile-assessments";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfileBehavioralProps {
    mskScore?: MskScore;
    user: User;
    assessment?: UserProfileAssessment;
    // assessmentResponses: AssessmentResponse[];
    allMskScores: MskScore[];
    setAllMskScores: Function;
    setSpecificAssessment: Function;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfileBehavioral: React.FC<UserProfileBehavioralProps> = (props: UserProfileBehavioralProps) => {
    const { setSpecificAssessment, mskScore, assessment, allMskScores, setAllMskScores, user } = props;
    const [behavioralHistory, setBehavioralHistory] = useState<{ x: string, y: number }[]>();

    // const [isLoading, setIsLoading] = useState<boolean>(true)

    // useEffect(() => {
    //     setIsLoading(false);
    // }, [])
    useEffect(() => {
        const getAllMskScores = async () => {
            console.log("trying to set allMskScores", allMskScores)
            if (!allMskScores) {
                console.log("setting allMskScores");
                const mskScores = await MskScoreService.getBy([{
                    field: "userId",
                    operator: "==",
                    value: user.id
                }], [{
                    field: "created",
                    direction: "asc"
                }])

                setAllMskScores(mskScores);
            }
        }
        getAllMskScores();
    }, [])
    useEffect(() => {
        const getHistory = () => {
            if (allMskScores) {
                let tempBehavioralHistory = []

                for (const mskScore of allMskScores) {
                    tempBehavioralHistory.push({
                        x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                        y: mskScore.lifestyleScore?.behavioralScore?.percentage ?? 0
                    })
                }
                setBehavioralHistory(tempBehavioralHistory);
            }
        }
        getHistory();
    }, [allMskScores])
    return (
        <>
            <div>
                <UserProfileAssessmentHeader
                    assessment={assessment}
                    mskScore={mskScore}
                    setSpecificAssessment={setSpecificAssessment}
                    mainTextHeader="How We Screen Behavioral Health"
                    mainText="We assess behavioral health using two evidence-based tools widely recognized by specialists: the <strong>Whooley Questionnaire</strong> and the <strong>Patient Health Questionnaire-9 (PHQ-9)</strong>. These tools are designed to screen for common mental health concerns, such as depression and anxiety."
                    whyImportantText="Mental and physical health are deeply interconnected—each influencing the other. Chronic stress, anxiety, and depression can impact sleep quality, immune function, and even lead to physical ailments like cardiovascular issues and chronic pain. Early identification of mental health issues is crucial, as it allows for effective, timely interventions. Left unaddressed, conditions like anxiety, depression, or burnout can worsen, leading to more significant health, social, and economic consequences."
                />
            </div>

            {behavioralHistory && (
                <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper mt-5`}>
                    <LineChartJS
                        data={behavioralHistory.map(history => history.y)}
                        title="Behavioral Score History"
                        labels={behavioralHistory.map(history => history.x)}
                        height={"200px"}
                        horizontalScrolling={true}
                        pointRadius={7}
                        pointBorderWidth={3}
                        borderWidth={5}
                        pointFontSize={16}
                        fontFamily={'pragmatica-extended'}
                        pointLabelOffset={0}
                        xLabelFontSize={14}
                        paddingTop={30}
                        paddingBottom={20}
                        maintainAspectRatio={false}
                    />
                </div>
            )}
        </>
    );
}

export default UserProfileBehavioral;
