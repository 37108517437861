export default class QuestionResponsesSummaryExpectations {
    nameForError?: string;

    /**
     * Total number of questions (with and without options)
     */
    numQuestions: number = 0;

    /**
     * Number of questions with answer inputs (no options)
     */
    numQuestionsWithInputs: number = 0;

    /**
     * Number of questions with answer inputs that are optional
     */
    numOptionalQuestionsWithInputs: number = 0;

    /**
     * Number of questions with answer options (not inputs) that are optional
     */
    numOptionalQuestionsWithAnswerOptions: number = 0;

    /**
     * Number of options per question with options
     */
    numOptionsPerQuestion: number = 0;

    constructor(partial?: Partial<QuestionResponsesSummaryExpectations>) {
        if (partial != null) {
            Object.assign(this, partial);
        }
    }

    getNumQuestionsWithOptions(): number {
        return this.numQuestions - this.numQuestionsWithInputs;
    }
}
