import { ReactComponent as TallyMark } from "../../assets/icons/outline/tally-mark.svg";
import { WellnessProgramActivity } from "../../models/enumerations/wellness-program-activity";

// const COMPONENT_CLASS = "c-tally-chart";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface TallyChartProps {
    tallyMarks: WellnessProgramActivity[];
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const TallyChart: React.FC<TallyChartProps> = (props: TallyChartProps) => {
    const {
        tallyMarks,
    } = props;
    return (
        <div className="flex justify-between gap-1">
            {tallyMarks.map((status, index) => {
                return (
                    <div>
                        {status === WellnessProgramActivity.Complete ? <TallyMark color="#43B5F8" /> : status === WellnessProgramActivity.Incomplete ? <TallyMark color="#e7514f" /> : <TallyMark color="#12162233" />}
                    </div >
                )
            })}
        </div >
    );
}

export default TallyChart;
