const COMPONENT_CLASS = "c-gray-footer-card";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface GrayFooterCardProps {
    mainText: string;
    subText: string;
    footerText: string;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const GrayFooterCard: React.FC<GrayFooterCardProps> = (props) => {
    const {
        mainText,
        subText,
        footerText,
    } = props;

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <div className="font-bold text-2xl">{mainText}</div>
            <div style={{ fontSize: "10px" }} className="font-bold pt-2 pb-6">{subText}</div>
            <div className="bg-black-gray-5 w-full py-2 font-light text-xs text-center"
                style={{ borderRadius: "0 0 10px 10px" }}>
                {footerText}
            </div>
        </div>
    );
}

export default GrayFooterCard;
