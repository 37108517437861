import { GroupIds } from "../models/enumerations/group-ids";
import { QuestionIds } from "../models/enumerations/question-ids";
import { MskScore } from "../models/interfaces/scores/msk-score";
import AssessmentResponseService from "./services/assessment-response-service/assessment-response-service";
import MskScoreServiceAssessments from "./services/msk-score-service-assessments/msk-score-service-assessments";

export default class MskScoreUtil {
    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    public static async getLatest(
        userId: string
    ): Promise<MskScore | undefined> {
        const mskScores = await MskScoreServiceAssessments.getBy(
            // filter
            [
                {
                    field: "userId",
                    operator: "==",
                    value: userId,
                },
            ],
            // order
            [
                {
                    field: "created",
                    direction: "desc",
                },
            ],
            // limit
            1
        );

        if (mskScores == null || mskScores.length === 0) {
            return undefined;
        }

        return mskScores[0];
    }

    public static getNumberOfPainLocations(mskScore?: MskScore, assessmentResponses?: any) {
        if (!mskScore && !assessmentResponses) {
            return 0;
        }
        let numOfPainCount = 0;
        if (mskScore) {
            numOfPainCount = this.getNumberOfPainLocationsMovementAssessmentFromMsk(mskScore);
        }
        let numOfInjuryCount = 0;
        if (assessmentResponses) {
            numOfInjuryCount = this.getNumberOfPainLocationsInjuryHistoryAssessment(assessmentResponses);
        }

        return numOfPainCount + numOfInjuryCount;
    }

    public static getNumberOfPainLocationsMovementAssessmentFromMsk(mskScore: MskScore) {
        let numOfPainCount = 0;

        if (mskScore.movementScore?.balanceScore.score === 0) {
            numOfPainCount++;
        }
        if (mskScore.movementScore?.hasShoulderClearingPain) {
            numOfPainCount++;
        }
        if (mskScore.movementScore?.hasSpineClearingPain) {
            numOfPainCount++;
        }
        if (mskScore.movementScore?.rotationScore.score === 0) {
            numOfPainCount++;
        }
        if (mskScore.movementScore?.shoulderMobilityScore.score === 0) {
            numOfPainCount++;
        }
        if (mskScore.movementScore?.squatScore.score === 0) {
            numOfPainCount++;
        }
        if (mskScore.movementScore?.toeTouchScore.score === 0) {
            numOfPainCount++;
        }

        return numOfPainCount;
    }

    public static getNumberOfPainLocationsMovementAssessment(assessmentResponses: any) {
        let numOfPainCount = 0;

        if (assessmentResponses) {
            const movementResponse: any = assessmentResponses!.find((assessmentResponse: any) => assessmentResponse[0].groupId === GroupIds.MOVEMENT_HEALTH)
            const movementAssessmentResponse = AssessmentResponseService.getMovementAssessmentResponses(movementResponse);

            if (movementAssessmentResponse.balanceQPain) {
                numOfPainCount++;
            }
            if (movementAssessmentResponse.shoulderClearingQPain) {
                numOfPainCount++;
            }
            if (movementAssessmentResponse.spineClearingQPain) {
                numOfPainCount++;
            }
            if (movementAssessmentResponse.rotationQPain) {
                numOfPainCount++;
            }
            if (movementAssessmentResponse.shoulderMobilityQPain) {
                numOfPainCount++;
            }
            if (movementAssessmentResponse.squatQPain) {
                numOfPainCount++;
            }
            if (movementAssessmentResponse.toeTouchQPain) {
                numOfPainCount++;
            }
        }

        return numOfPainCount;
    }

    public static getNumberOfPainLocationsInjuryHistoryAssessment(assessmentResponses: any) {
        let numOfInjuryCount = 0;

        const injuryHistory = assessmentResponses.find((response: any) => response[0].groupId === GroupIds.INJURY_HISTORY)
        if (injuryHistory) {
            const response = injuryHistory[0].responses.find((response: any) => response.questionId === QuestionIds.INJURY_HISTORY_PAIN_AREAS);
            if (response) {
                let temp = response.answerResponse.split(",").length;
                numOfInjuryCount += temp;
            }
        }

        return numOfInjuryCount;
    }

    public static checkMskScore = (latestMskData?: MskScore) => {
        if (!latestMskData) {
            return false;
        }
        console.log(latestMskData);
        if (latestMskData.movementScore &&
            latestMskData.lifestyleScore &&
            "frontToeLeft" in latestMskData.movementScore.toeTouchScore &&
            "backToeLeft" in latestMskData.movementScore.toeTouchScore &&
            "frontToeRight" in latestMskData.movementScore.toeTouchScore &&
            "backToeRight" in latestMskData.movementScore.toeTouchScore &&
            "painSeverity" in latestMskData.movementScore.toeTouchScore &&
            "paperPassLeft" in latestMskData.movementScore.shoulderMobilityScore &&
            "paperPassRight" in latestMskData.movementScore.shoulderMobilityScore &&
            "fingerTipsLeft" in latestMskData.movementScore.shoulderMobilityScore &&
            "fingerTipsRight" in latestMskData.movementScore.shoulderMobilityScore &&
            "painSeverity" in latestMskData.movementScore.shoulderMobilityScore &&
            "feetTogetherLeft" in latestMskData.movementScore.rotationScore &&
            "feetTogetherRight" in latestMskData.movementScore.rotationScore &&
            "rightFootBackLeft" in latestMskData.movementScore.rotationScore &&
            "rightFootBackRight" in latestMskData.movementScore.rotationScore &&
            "painSeverity" in latestMskData.movementScore.rotationScore &&
            "squat" in latestMskData.movementScore.squatScore &&
            "fingerTips" in latestMskData.movementScore.squatScore &&
            "fists" in latestMskData.movementScore.squatScore &&
            "painSeverity" in latestMskData.movementScore.squatScore &&
            "oneFootRight" in latestMskData.movementScore.balanceScore &&
            "oneFootLeft" in latestMskData.movementScore.balanceScore &&
            "attempts" in latestMskData.movementScore.balanceScore.twoFeetLeft &&
            "success" in latestMskData.movementScore.balanceScore.twoFeetLeft &&
            "attempts" in latestMskData.movementScore.balanceScore.twoFeetRight &&
            "success" in latestMskData.movementScore.balanceScore.twoFeetRight &&
            "attempts" in latestMskData.movementScore.balanceScore.threeFeetLeft &&
            "success" in latestMskData.movementScore.balanceScore.threeFeetRight &&
            "painSeverity" in latestMskData.movementScore.balanceScore &&
            "shoulderClearingPainSeverity" in latestMskData.movementScore &&
            "spineClearingPainSeverity" in latestMskData.movementScore &&
            latestMskData.lifestyleScore.bodyCompositionScore &&
            "bmi" in latestMskData.lifestyleScore.bodyCompositionScore &&
            latestMskData.lifestyleScore.breathingScore &&
            "breathHoldTime" in latestMskData.lifestyleScore.breathingScore &&
            "breathingQuestionnaireScore" in latestMskData.lifestyleScore.breathingScore &&
            "isSmoking" in latestMskData.lifestyleScore.breathingScore &&
            latestMskData.lifestyleScore.injuryHistoryScore &&
            "previousInjury" in latestMskData.lifestyleScore.injuryHistoryScore &&
            "currentPain" in latestMskData.lifestyleScore.injuryHistoryScore &&
            "seenPhysician" in latestMskData.lifestyleScore.injuryHistoryScore &&
            "recoveryScore" in latestMskData.lifestyleScore.injuryHistoryScore &&
            latestMskData.lifestyleScore.sleepScore &&
            "psqiScore" in latestMskData.lifestyleScore.sleepScore
        ) {
            return true;
        }
        return false;
    }

    // #endregion Public Methods

    // -----------------------------------------------------------------------------------------
    // #region Private Methods
    // -----------------------------------------------------------------------------------------

    // #endregion Private Methods
}
