import { Assessment } from "../../models/interfaces/assessment";
import { Utils } from "../../utilities/utils";

const COMPONENT_CLASS = "c-focus-areas-card";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface FocusAreaCardProps {
    focusAreas: Assessment[];
    title?: string;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const FocusAreaCard: React.FC<FocusAreaCardProps> = (props) => {
    const {
        focusAreas,
        title,
    } = props;

    return (
        <div className={`${COMPONENT_CLASS}`}>
            {title &&
                <h3>{title}</h3>
            }

            {focusAreas.map((focusArea) => {
                if (focusArea.groupId) {
                    const focusAreaData = Utils.getFocusAreaData(focusArea.groupId, false);

                    return (
                        <div key={focusArea.groupId} className={`${COMPONENT_CLASS}__focus-area`}>
                            {focusAreaData.icon}
                            {focusAreaData.title}
                        </div>
                    );
                }

                return <></>;
            })}
        </div>
    );
}

export default FocusAreaCard;
