import { useEffect, useState } from "react";
import { MskScore } from "../../models/interfaces/msk-score";
import { User } from "../../models/interfaces/user";
import moment from "moment";
import { MovementHealthResponse } from "../../models/interfaces/reports/movement-health-response";
import NonLinearLineChart from "../charts/non-linear-line-chart";
import { Utils } from "../../utilities/utils";

const COMPONENT_CLASS = "c-user-profile-assessments";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfileBalanceReachProps {
    mskScore?: MskScore;
    validMskScore: boolean;
    user: User;
    allMskScores: MskScore[];
    setAllMskScores: Function;
    movementResponses?: MovementHealthResponse;

}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfileBalanceReach: React.FC<UserProfileBalanceReachProps> = (props: UserProfileBalanceReachProps) => {
    const { validMskScore, mskScore, allMskScores, movementResponses } = props;
    const [balanceReachHistory, setBalanceReachHistory] = useState<{ x: string, y: number }[]>();
    useEffect(() => {
        const getHistory = () => {
            if (allMskScores) {
                let tempBalanceReachHistory = []

                for (const mskScore of allMskScores) {
                    tempBalanceReachHistory.push({
                        x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                        y: mskScore.movementScore?.balanceScore.percentage ?? 0
                    })
                }
                setBalanceReachHistory(tempBalanceReachHistory);
            }
        }
        getHistory();
    }, [allMskScores])

    return (
        <div className="flex gap-5">
            <div className={`${COMPONENT_CLASS}__score-section-card gap-4 items-center`}>
                <div className="text-base font-normal">Balance & Reach</div>

                <div className={`${COMPONENT_CLASS}__movement-group-score-card ${Utils.getGreenOrRedColor(mskScore?.movementScore?.balanceScore.percentage, 75)}`}>
                    <div className={`${COMPONENT_CLASS}__movement-group-score-card__main-text`}>{mskScore?.movementScore?.balanceScore.percentage}</div>
                    <div className={`${COMPONENT_CLASS}__movement-group-score-card__sub-text`}>Goal: 75 - 100</div>
                </div>
                {validMskScore ?
                    <>
                        <div className="w-full flex flex-col p-4 gap-1 bg-black-gray-5" style={{ borderRadius: "10px" }}>
                            <span className="text-10 font-bold">1 FOOT:</span>
                            {Utils.getAssessmentBreakdownCell("Right", mskScore?.movementScore?.balanceScore.oneFootRight)}
                            {Utils.getAssessmentBreakdownCell("Left", mskScore?.movementScore?.balanceScore.oneFootLeft)}
                        </div>
                        <div className="flex gap-4">
                            <div className="flex flex-col p-4 gap-1 bg-black-gray-5" style={{ borderRadius: "10px" }}>
                                <span className="text-10 font-bold">2 FEET:</span>
                                {Utils.getAssessmentBreakdownCell(`Right: <b>${mskScore?.movementScore?.balanceScore.twoFeetRight.attempts}</b> Attempts`, mskScore?.movementScore?.balanceScore.twoFeetRight.success)}
                                {Utils.getAssessmentBreakdownCell(`Left: <b>${mskScore?.movementScore?.balanceScore.twoFeetLeft.attempts}</b> Attempts`, mskScore?.movementScore?.balanceScore.twoFeetLeft.success)}
                            </div>
                            <div className="flex flex-col p-4 gap-1 bg-black-gray-5" style={{ borderRadius: "10px" }}>
                                <span className="text-10 font-bold">2.5 FEET:</span>
                                {Utils.getAssessmentBreakdownCell(`Right: <b>${mskScore?.movementScore?.balanceScore.threeFeetRight.attempts}</b> Attempts`, mskScore?.movementScore?.balanceScore.threeFeetRight.success)}
                                {Utils.getAssessmentBreakdownCell(`Left: <b>${mskScore?.movementScore?.balanceScore.threeFeetLeft.attempts}</b> Attempts`, mskScore?.movementScore?.balanceScore.threeFeetLeft.success)}
                            </div>
                        </div>
                        <div>
                            {Utils.getPainBadge(mskScore?.movementScore?.balanceScore.painSeverity!)}
                        </div>
                    </>
                    : movementResponses ?
                        <>
                            <div className="w-full flex flex-col p-4 gap-1 bg-black-gray-5" style={{ borderRadius: "10px" }}>
                                <span className="text-10 font-bold">1 FOOT:</span>
                                {Utils.getAssessmentBreakdownCell("Right", movementResponses.firstBalanceReach.itemOne)}
                                {Utils.getAssessmentBreakdownCell("Left", movementResponses.firstBalanceReach.itemTwo)}
                            </div>
                            <div className="flex gap-4">
                                <div className="flex flex-col p-4 gap-1 bg-black-gray-5" style={{ borderRadius: "10px" }}>
                                    <span className="text-10 font-bold">2 FEET:</span>
                                    {Utils.getAssessmentBreakdownCell(`Right: <b>${movementResponses.secondBalanceReachRight.attempts}</b> Attempts`, movementResponses.secondBalanceReachRight.success)}
                                    {Utils.getAssessmentBreakdownCell(`Left: <b>${movementResponses.secondBalanceReachLeft.attempts}</b> Attempts`, movementResponses.secondBalanceReachLeft.success)}
                                </div>
                                <div className="flex flex-col p-4 gap-1 bg-black-gray-5" style={{ borderRadius: "10px" }}>
                                    <span className="text-10 font-bold">2.5 FEET:</span>
                                    {Utils.getAssessmentBreakdownCell(`Right: <b>${movementResponses.thirdBalanceReachRight.attempts}</b> Attempts`, movementResponses.thirdBalanceReachRight.success)}
                                    {Utils.getAssessmentBreakdownCell(`Left: <b>${movementResponses.thirdBalanceReachLeft.attempts}</b> Attempts`, movementResponses.thirdBalanceReachLeft.success)}
                                </div>
                            </div>
                            <div>
                                {Utils.getPainBadge(movementResponses.balanceQPain)}
                            </div>
                        </>
                        :
                        <div></div>
                }

            </div>

            {balanceReachHistory && (
                <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper grow`}>
                    <NonLinearLineChart
                        dataPoints={balanceReachHistory.map(history => history.y)}
                        labels={balanceReachHistory.map(history => history.x)}
                        title="Balance & Reach History"
                        sectionDetails={{
                            sections: 2,
                            rangeOne: { start: 0, end: 74 },
                            rangeTwo: { start: 75, end: 100 },
                            backgroundColorOrder: { first: "#FFA998", second: "#8BE3CE" },
                            dataPointColorOrder: { first: "#E7514F", second: "#00DFBC" },
                            yAxisLabels: { first: "BELOW", second: "ABOVE" },
                            decimals: false
                        }}
                        toolTipLabel={true} />
                </div>
            )}
        </div>
    );
}

export default UserProfileBalanceReach;
