import { useEffect, useState } from "react";
import { MskScore } from "../../models/interfaces/msk-score";
import { User } from "../../models/interfaces/user";
import moment from "moment";
import { MovementHealthResponse } from "../../models/interfaces/reports/movement-health-response";
import NonLinearLineChart from "../charts/non-linear-line-chart";
import { Utils } from "../../utilities/utils";

const COMPONENT_CLASS = "c-user-profile-assessments";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfileShoulderMobilityProps {
    mskScore?: MskScore;
    validMskScore: boolean;
    user: User;
    allMskScores: MskScore[];
    setAllMskScores: Function;
    movementResponses?: MovementHealthResponse;

}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfileShoulderMobility: React.FC<UserProfileShoulderMobilityProps> = (props: UserProfileShoulderMobilityProps) => {
    const { mskScore, allMskScores, movementResponses, validMskScore } = props;
    const [shoulderMobilityHistory, setShoulderMobilityHistory] = useState<{ x: string, y: number }[]>();
    useEffect(() => {
        const getHistory = () => {
            if (allMskScores) {
                let tempShoulderMobilityHistory = []

                for (const mskScore of allMskScores) {
                    tempShoulderMobilityHistory.push({
                        x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                        y: mskScore.movementScore?.shoulderMobilityScore.percentage ?? 0
                    })
                }
                setShoulderMobilityHistory(tempShoulderMobilityHistory);
            }
        }
        getHistory();
    }, [allMskScores])

    return (
        <div className="flex gap-5">
            <div className={`${COMPONENT_CLASS}__score-section-card gap-4 items-center`}>
                <div className="text-base font-normal">Shoulder Mobility</div>

                <div className={`${COMPONENT_CLASS}__movement-group-score-card ${Utils.getGreenOrRedColor(mskScore?.movementScore?.shoulderMobilityScore.percentage, 75)}`}>
                    <div className={`${COMPONENT_CLASS}__movement-group-score-card__main-text`}>{mskScore?.movementScore?.shoulderMobilityScore.percentage}</div>
                    <div className={`${COMPONENT_CLASS}__movement-group-score-card__sub-text`}>Goal: 75 - 100</div>
                </div>
                {validMskScore ?
                    <>
                        <div className="flex gap-4">
                            <div className="flex flex-col p-4 gap-1 bg-black-gray-5" style={{ borderRadius: "10px" }}>
                                <span className="text-10 font-bold">LEFT SIDE:</span>
                                {Utils.getAssessmentBreakdownCell("Paper Pass", mskScore?.movementScore?.shoulderMobilityScore.paperPassLeft)}
                                {Utils.getAssessmentBreakdownCell("Fingertips Touch", mskScore?.movementScore?.shoulderMobilityScore.fingerTipsLeft)}
                            </div>
                            <div className="flex flex-col p-4 gap-1 bg-black-gray-5" style={{ borderRadius: "10px" }}>
                                <span className="text-10 font-bold">RIGHT SIDE:</span>
                                {Utils.getAssessmentBreakdownCell("Paper Pass", mskScore?.movementScore?.shoulderMobilityScore.paperPassRight)}
                                {Utils.getAssessmentBreakdownCell("Fingertips Touch", mskScore?.movementScore?.shoulderMobilityScore.fingerTipsRight)}
                            </div>
                        </div>
                        <div>
                            {Utils.getPainBadge(mskScore?.movementScore?.shoulderMobilityScore.painSeverity!)}
                        </div>
                    </>
                    : movementResponses ?
                        <>
                            <div className="flex gap-4">
                                <div className="flex flex-col p-4 gap-1 bg-black-gray-5" style={{ borderRadius: "10px" }}>
                                    <span className="text-10 font-bold">LEFT SIDE:</span>
                                    {Utils.getAssessmentBreakdownCell("Paper Pass", movementResponses.shoulderMobilityReachLeft.itemOne)}
                                    {Utils.getAssessmentBreakdownCell("Fingertips Touch", movementResponses.shoulderMobilityReachLeft.itemTwo)}
                                </div>
                                <div className="flex flex-col p-4 gap-1 bg-black-gray-5" style={{ borderRadius: "10px" }}>
                                    <span className="text-10 font-bold">RIGHT SIDE:</span>
                                    {Utils.getAssessmentBreakdownCell("Paper Pass", movementResponses.shoulderMobilityReachRight.itemOne)}
                                    {Utils.getAssessmentBreakdownCell("Fingertips Touch", movementResponses.shoulderMobilityReachRight.itemTwo)}
                                </div>
                            </div>
                            <div>
                                {Utils.getPainBadge(movementResponses.shoulderMobilityQPain)}
                            </div>
                        </>
                        :
                        <div></div>
                }

            </div>
            {shoulderMobilityHistory && (
                <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper grow`}>
                    <NonLinearLineChart
                        dataPoints={shoulderMobilityHistory.map(history => history.y)}
                        labels={shoulderMobilityHistory.map(history => history.x)}
                        title="Shoulder Mobility History"
                        sectionDetails={{
                            sections: 2,
                            rangeOne: { start: 0, end: 74 },
                            rangeTwo: { start: 75, end: 100 },
                            backgroundColorOrder: { first: "#FFA998", second: "#8BE3CE" },
                            dataPointColorOrder: { first: "#E7514F", second: "#00DFBC" },
                            yAxisLabels: { first: "BELOW", second: "ABOVE" },
                            decimals: false
                        }}
                        toolTipLabel={true} />
                </div>
            )}
        </div>
    );
}

export default UserProfileShoulderMobility;
