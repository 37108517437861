import FirestoreCondition from "../../models/interfaces/firestore-condition";
import FirestoreOrder from "../../models/interfaces/firestore-order";
import { User } from "../../models/interfaces/user";
import { WellnessProgram } from "../../models/interfaces/wellness-program";
import FirestoreService from "./firestore-service";

const COLLECTION_NAME = "wellnessPrograms";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

/**
 * Add a new wellnessProgram to the wellnessPrograms collection
 * @param {WellnessProgram} wellnessProgram - The wellnessProgram that is being added to the collection
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<WellnessProgram>} A promise to the newly added wellnessProgram
 */
const add = async (wellnessProgram: WellnessProgram, currentUser: User | null = null) => {
    return FirestoreService.add<WellnessProgram>(COLLECTION_NAME, wellnessProgram, currentUser);
};

/**
 * Delete a wellnessProgram collection by the Id
 * @param {string} id - The Id of the wellnessProgram being deleted
 */
const deleteById = async (id: string) => {
    FirestoreService.deleteById(COLLECTION_NAME, id);
};

/**
 * Find the specific wellnessProgram by the id
 * @param {string} id - The Id of the wellnessProgram that we are retrieving
 * @returns {Promise<WellnessProgram>} A promise for the Tag we are retrieving
 */
const get = async (id: string) => {
    return FirestoreService.get<WellnessProgram>(COLLECTION_NAME, id);
};

/**
 * Find the specific user by the id
 * @param {string} id - The Id of the user that we are retrieving
 * @returns {Promise<User>} A promise for the User we are retrieving
 */
const getBy = async (conditions: FirestoreCondition[], order: FirestoreOrder[] = [], limit?: number) => {
    return FirestoreService.getBy<WellnessProgram>(COLLECTION_NAME, conditions, order, limit);
};

const getCurrentByUserId = async (userId: string) => {
    return getBy(
        [
            {
                field: 'userId',
                operator: '==',
                value: userId,
            },
            {
                field: 'isCurrent',
                operator: '==',
                value: true,
            },
        ],
        [],
        1
    );
}

/**
 * Get all of the wellnessPrograms stored in the database
 * @returns {Promise<Tag[]>} A promise for the collection of wellnessPrograms
 */
const getAll = async (order: FirestoreOrder[] = []) => {
    return FirestoreService.getAll<WellnessProgram>(COLLECTION_NAME, order);
};

/**
 * Get all of the wellnessPrograms stored in the database
 * @returns {Promise<Tag[]>} A promise for the collection of wellnessPrograms
 */
const getAllCurrent = async (order: FirestoreOrder[] = []) => {
    return FirestoreService.getBy<WellnessProgram>(COLLECTION_NAME, [{
        field: "isCurrent",
        operator: "==",
        value: true,
    }], order);
};

/**
 * Get a snapshot of the specific wellnessProgram to see when it changes
 * @param {string} id - Id of the wellnessProgram document
 * @param {Function} listener - Function to listen to the changes to the document
 */
const getSnapshot = (id: string, listener: Function) => {
    return FirestoreService.getSnapshot<WellnessProgram>(COLLECTION_NAME, id, listener);
};

/**
 * Get a snapshot of the wellnessPrograms to see when it changes
 * @param {string} id - Id of the wellnessProgram document
 * @param {Function} listener - Function to listen to the changes to the document
 */
const getSnapshotBy = (conditions: FirestoreCondition[], order: FirestoreOrder[] = [], listener: Function) => {
    return FirestoreService.getSnapshotBy<WellnessProgram>(COLLECTION_NAME, conditions, order, -1, listener);
};

/**
 * Save the specified wellnessProgram in the database
 * @param {WellnessProgram} wellnessProgram - The wellnessProgram that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<WellnessProgram>} A promise for the wellnessProgram that is being updated
 */
const save = async (wellnessProgram: WellnessProgram, currentUser: User | null = null) => {
    return FirestoreService.save<WellnessProgram>(COLLECTION_NAME, wellnessProgram, currentUser);
};

/**
 * Update the specified wellnessProgram stored in the database
 * @param {WellnessProgram} wellnessProgram - The wellnessProgram that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<WellnessProgram>} A promise for the wellnessProgram that is being updated
 */
const update = async (wellnessProgram: WellnessProgram, currentUser: User | null = null) => {
    return FirestoreService.update<WellnessProgram>(COLLECTION_NAME, wellnessProgram, currentUser);
};

// #endregion Service Methods


// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const WellnessProgramService = {
    add,
    deleteById,
    get,
    getBy,
    getCurrentByUserId,
    getAll,
    getAllCurrent,
    getSnapshot,
    getSnapshotBy,
    save,
    update,
};

export default WellnessProgramService;

// #endregion Exports