import QuestionResponseDto from "../utilities/question-response.dto";


export default class QuestionResponse {
    questionId?: string;
    answerId?: string;
    answerResponse?: string;

    /** @internal */
    constructor(partial?: Partial<QuestionResponse>) {
        if (partial != null) {
            Object.assign(this, partial);
        }
    }

    // /**
    //  * Determines if the question is a pain question
    //  * @returns {boolean} True if the question is a pain question
    //  * @internal
    //  */
    // public isPainQuestion(): boolean | null {
    //     return this.questionId != null &&
    //         this.questionId.endsWith(QuestionIdSuffixes.PAIN);
    // }

    // /**
    //  * Determines if the question is a pain question, and if so, returns true when the answer corresponds with pain
    //  * @returns {boolean} True if the answer is set and it is "YES"
    //  * @internal
    //  */
    // public hasPain(): boolean | null {
    //     if (!this.isPainQuestion()) {
    //         return false;
    //     }
    //     return this.answerId === AnswerIds.C ||
    //         this.answerId === AnswerIds.D;
    // }

    // /**
    //  * Determines if the question response is a case-insensitive "YES"
    //  * @returns {boolean} True if the answer is set and it is "YES"
    //  * @internal
    //  */
    // public isAnswerYes(): boolean | null {
    //     return this.answerResponse != null &&
    //         this.answerResponse.toLocaleUpperCase() == "YES";
    // }

    // /**
    //  * Converts a question response to PASS or FAIL based on comparison of the answer to the number.
    //  * @param {number} minNumberToPass - Minimum value that is considered a pass, based on the answerResponse
    //  * @param {string} newQuestionId - Question ID to use for the response (or not using this.questionId)
    //  * @returns {QuestionResponse} Question response with answerId of PASS or FAIL
    //  * @internal
    //  */
    // public convertToPassFail(minNumberToPass: number, newQuestionId?: string): QuestionResponse {
    //     const result: QuestionResponse = new QuestionResponse({ questionId: newQuestionId ?? this.questionId });
    //     const answer: number = Number(this.answerResponse);

    //     if (answer >= minNumberToPass) {
    //         result.answerId = AnswerIds.PASS;
    //     } else {
    //         result.answerId = AnswerIds.FAIL;
    //     }

    //     return result;
    // }

    /**
     * Find a question response within the collection matching the question suffix.
     * @param {QuestionResponse[]} questionResponses - Target to search
     * @param {string} questionIdSuffix - Suffix to search for on question IDs
     * @returns {QuestionResponse} Matching response, or null if not found
     * @internal
     */
    public static find(questionResponses: QuestionResponse[], questionIdSuffix: string): QuestionResponse {
        let result: QuestionResponse = {};
        if (questionResponses == null || questionIdSuffix == null) {
            return result;
        }

        questionResponses.forEach((questionResponse) => {
            if (questionResponse.questionId != null && questionResponse.questionId.endsWith(questionIdSuffix)) {
                result = questionResponse;
            }
        });

        return result;
    }

    /**
     * Find a question response answer within the collection matching the question suffix.
     * @param {QuestionResponse[]} questionResponses - Target to search
     * @param {string} questionIdSuffix - Suffix to search for on question IDs
     * @returns {string} AnswerId or AnswerResponse, or null if not found
     * @internal
     */
    public static findAnswer(questionResponses: QuestionResponse[], questionIdSuffix: string): string | null{
        const questionResponse = QuestionResponse.find(questionResponses, questionIdSuffix);
        if (questionResponse == null) {
            return null;
        }

        const answer = questionResponse.answerId ?? questionResponse.answerResponse ?? null;
        return answer;
    }

    /**
     * Converts the responses from DTO objects to base question responses
     * @param {QuestionResponseDto[]} questionResponseDtos - Values to convert
     * @returns {QuestionResponse[]} Converted collection of values. This is never null, even if the input is.
     * @internal
     */
    public static toArray(questionResponseDtos: QuestionResponseDto[]): QuestionResponse[] {
        const questionResponses: QuestionResponse[] = [];
        if (questionResponseDtos != null) {
            questionResponseDtos.forEach((questionResponseDto) => {
                questionResponses.push(new QuestionResponse(questionResponseDto));
            });
        }
        return questionResponses;
    }
}
