import { AnswerIds } from "../models/enumerations/answer-ids";
import QuestionResponsesSummaryExpectations from "../models/exceptions/question-responses-summary-expectations";
import QuestionResponse from "../models/question-response.entity";

export default class QuestionResponsesSummary {
    numA: number = 0;
    numB: number = 0;
    numC: number = 0;
    numD: number = 0;
    numE: number = 0;
    numQuestions: number = 0;

    constructor(partial?: Partial<QuestionResponsesSummary>) {
        if (partial != null) {
            Object.assign(this, partial);
        }
    }

    /**
     * Summarizes list of responses into counts
     *
     * @param {QuestionResponse[]} questionResponses - Responses to questions
     * @returns {QuestionResponsesSummary} Populated object
     */
    public static summarize(questionResponses: QuestionResponse[]): QuestionResponsesSummary {
        const result: QuestionResponsesSummary = new QuestionResponsesSummary();

        if (questionResponses == null || questionResponses.length === 0) {
            return result;
        }

        for (let i=0; i<questionResponses.length; i++) {
            const response = questionResponses[i];
            if (response == null) {
                continue;
            }
            switch (response.answerId) {
                case AnswerIds.A:
                    result.numA++;
                    break;
                case AnswerIds.B:
                    result.numB++;
                    break;
                case AnswerIds.C:
                    result.numC++;
                    break;
                case AnswerIds.D:
                    result.numD++;
                    break;
                case AnswerIds.E:
                    result.numE++;
                    break;
            }
            
                result.numQuestions++;
        }

        return result;
    }

    public validate(expectations: QuestionResponsesSummaryExpectations) : boolean {
        // total responses validation
        const expectedMax = expectations.numQuestions;
        const expectedMin = expectedMax - expectations.numOptionalQuestionsWithInputs - expectations.numOptionalQuestionsWithAnswerOptions;
        if (this.numQuestions < expectedMin || this.numQuestions > expectedMax) {
            if (expectations.numQuestions === 1) {
                throw new Error(`${expectations.numQuestions} ${expectations.nameForError} question was expected (found ${this.numQuestions})`);
            } else {
                throw new Error(`${expectations.numQuestions} ${expectations.nameForError} questions were expected (found ${this.numQuestions})`);
            }
        }

        // options counts
        let numAnswerOptionsFound: number = 0;
        let invalidOption: string | null = null;
        switch (expectations.numOptionsPerQuestion) {
            case 2:
                numAnswerOptionsFound = this.numA + this.numB;
                if (this.numC > 0) {
                    invalidOption = "C";
                } else if (this.numD > 0) {
                    invalidOption = "D";
                } else if (this.numE > 0) {
                    invalidOption = "E";
                }
                break;
            case 3:
                numAnswerOptionsFound = this.numA + this.numB + this.numC;
                if (this.numD > 0) {
                    invalidOption = "D";
                } else if (this.numE > 0) {
                    invalidOption = "E";
                }
                break;
            case 4:
                numAnswerOptionsFound = this.numA + this.numB + this.numC + this.numD;
                if (this.numE > 0) {
                    invalidOption = "E";
                }
                break;
            case 5:
                numAnswerOptionsFound = this.numA + this.numB + this.numC + this.numD + this.numE;
                break;
            default:
                if (expectations.numOptionsPerQuestion > 0) {
                    throw new Error(`numOptionsPerRegularQuestion is not valid: ${expectations.numOptionsPerQuestion}`);
                }
                break;
        }
        if (invalidOption != null) {
            throw new Error(`${expectations.nameForError} questions were expected to have ${expectations.numOptionsPerQuestion} options (found "${invalidOption}")`);
        }

        // answer options count validation
        const numAnswerOptionsMax = expectations.getNumQuestionsWithOptions();
        const numAnswerOptionsMin = numAnswerOptionsMax - expectations.numOptionalQuestionsWithAnswerOptions;
        if (numAnswerOptionsFound < numAnswerOptionsMin || numAnswerOptionsFound > numAnswerOptionsMax) {
            if (numAnswerOptionsMin !== numAnswerOptionsMax) {
                throw new Error(`${expectations.nameForError} option questions were expected to have ${numAnswerOptionsMin}-${numAnswerOptionsMax} options selected (found ${numAnswerOptionsFound})`);
            } else {
                throw new Error(`${expectations.nameForError} option questions were expected to have ${numAnswerOptionsMin} options selected (found ${numAnswerOptionsFound})`);
            }
        }

        // answer inputs count validation
        const numAnswerInputsMax = expectations.numQuestionsWithInputs;
        const numAnswerInputsMin = numAnswerInputsMax - expectations.numOptionalQuestionsWithInputs;
        const numAnswerInputsFound = this.numQuestions - numAnswerOptionsFound;
        if (numAnswerInputsFound < numAnswerInputsMin || numAnswerInputsFound > numAnswerInputsMax) {
            if (numAnswerInputsMin !== numAnswerInputsMax) {
                throw new Error(`${expectations.nameForError} input questions were expected to have ${numAnswerInputsMin}-${numAnswerInputsMax} options selected (found ${numAnswerInputsFound})`);
            } else {
                throw new Error(`${expectations.nameForError} input questions were expected to have ${numAnswerInputsMin} options selected (found ${numAnswerInputsFound})`);
            }
        }

        return true;
    }
}
