import React, { useEffect, useRef, useState } from "react";
import { Line } from 'react-chartjs-2';
import { CategoryScale, Chart, LineElement, LinearScale, PointElement, Title, Tooltip } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import NonLinearGraphUtil from "../../utilities/non-linear-graph-util";

// const COMPONENT_CLASS = "c-line-chart";

export interface NonLinearLineChartProps {
    dataPoints: number[];
    customDataPointLabels?: number[]
    title?: string;
    labels: string[];
    height?: string;
    sectionDetails: {
        sections: 2,
        rangeOne: { start: number, end: number },
        rangeTwo: { start: number, end: number },
        backgroundColorOrder: { first: string, second: string }
        dataPointColorOrder: { first: string, second: string }
        yAxisLabels: { first: string, second: string }
        decimals: boolean,
    } | {
        sections: 3
        rangeOne: { start: number, end: number },
        rangeTwo: { start: number, end: number },
        rangeThree: { start: number, end: number },
        backgroundColorOrder: { first: string, second: string, third: string }
        dataPointColorOrder: { first: string, second: string, third: string }
        yAxisLabels: { first: string, second: string, third: string }
        decimals: boolean,
    } | {
        sections: 4
        rangeOne: { start: number, end: number },
        rangeTwo: { start: number, end: number },
        rangeThree: { start: number, end: number },
        rangeFour: { start: number, end: number },
        backgroundColorOrder: { first: string, second: string, third: string, fourth: string }
        dataPointColorOrder: { first: string, second: string, third: string, fourth: string }
        yAxisLabels: { first: string, second: string, third: string, fourth: string }
        decimals: boolean,
    };
    toolTipLabel: boolean,
    toolTipLabelAbstracted?: boolean,
    binaryGraph?: { isTrue: number, isFalse: number, trueLabel: string, falseLabel: string }; // This means if the graph is a true or false graph (They have pain or don't have pain) 
}
// Define your custom labels for each 1/3 of the graph
Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Title,
    ChartDataLabels,
    annotationPlugin,
);

const NonLinearLineChart: React.FC<NonLinearLineChartProps> = (props) => {

    const { binaryGraph, dataPoints, title, labels, sectionDetails, customDataPointLabels, toolTipLabel, toolTipLabelAbstracted } = props
    const [scaledData, setScaledData] = useState<any[]>()
    const [pointColors, setPointColors] = useState<any[]>()
    const containerRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.scrollLeft = container.scrollWidth; // Scroll to the far right
        }
    }, []);

    const getTwoSectionYAxisLabels = (value: any, narrowedSectionDetails: {
        sections: 2,
        rangeOne: { start: number, end: number },
        rangeTwo: { start: number, end: number },
        backgroundColorOrder: { first: string, second: string }
        dataPointColorOrder: { first: string, second: string }
        yAxisLabels: { first: string, second: string },
        decimals: boolean,
    }) => {
        const firstSectionLabelValue = NonLinearGraphUtil.twoSectionDownScale((narrowedSectionDetails.rangeOne.end - narrowedSectionDetails.rangeOne.start) / 2, narrowedSectionDetails);
        const secondSectionLabelValue = NonLinearGraphUtil.twoSectionDownScale(((narrowedSectionDetails.rangeTwo.end - narrowedSectionDetails.rangeTwo.start) / 2) + narrowedSectionDetails.rangeTwo.start, narrowedSectionDetails);

        if (value === Math.round(firstSectionLabelValue * 100) / 100) {
            return narrowedSectionDetails.yAxisLabels.first;
        } else if (value === Math.round(secondSectionLabelValue * 100) / 100) {
            return narrowedSectionDetails.yAxisLabels.second;
        }
        return ''; // Don't show other labels
    }

    const getThreeSectionYAxisLabels = (value: any, narrowedSectionDetails: {
        sections: 3
        rangeOne: { start: number, end: number },
        rangeTwo: { start: number, end: number },
        rangeThree: { start: number, end: number },
        backgroundColorOrder: { first: string, second: string, third: string }
        dataPointColorOrder: { first: string, second: string, third: string }
        yAxisLabels: { first: string, second: string, third: string }
        decimals: boolean,
    }) => {
        // NOTE: we might need to round the label values here if the graph isn't decimal.
        const firstSectionLabelValue = NonLinearGraphUtil.threeSectionDownScale((narrowedSectionDetails.rangeOne.end - narrowedSectionDetails.rangeOne.start) / 2, narrowedSectionDetails);
        const secondSectionLabelValue = NonLinearGraphUtil.threeSectionDownScale(((narrowedSectionDetails.rangeTwo.end - narrowedSectionDetails.rangeTwo.start) / 2) + narrowedSectionDetails.rangeTwo.start, narrowedSectionDetails);
        const thirdSectionLabelValue = NonLinearGraphUtil.threeSectionDownScale(((narrowedSectionDetails.rangeThree.end - narrowedSectionDetails.rangeThree.start) / 2) + narrowedSectionDetails.rangeThree.start, narrowedSectionDetails);

        if (value === Math.round(firstSectionLabelValue * 100) / 100) {
            return narrowedSectionDetails.yAxisLabels.first;
        } else if (value === Math.round(secondSectionLabelValue * 100) / 100) {
            return narrowedSectionDetails.yAxisLabels.second;
        }
        else if (value === Math.round(thirdSectionLabelValue * 100) / 100) {
            return narrowedSectionDetails.yAxisLabels.third
        }
        return ''; // Don't show other labels
    }

    const getFourSectionYAxisLabels = (value: any, narrowedSectionDetails: {
        sections: 4
        rangeOne: { start: number, end: number },
        rangeTwo: { start: number, end: number },
        rangeThree: { start: number, end: number },
        rangeFour: { start: number, end: number },
        backgroundColorOrder: { first: string, second: string, third: string, fourth: string }
        dataPointColorOrder: { first: string, second: string, third: string, fourth: string }
        yAxisLabels: { first: string, second: string, third: string, fourth: string }
        decimals: boolean,
    }) => {
        const firstSectionLabelValue = NonLinearGraphUtil.fourSectionDownScale((narrowedSectionDetails.rangeOne.end - narrowedSectionDetails.rangeOne.start) / 2, narrowedSectionDetails);
        const secondSectionLabelValue = NonLinearGraphUtil.fourSectionDownScale(((narrowedSectionDetails.rangeTwo.end - narrowedSectionDetails.rangeTwo.start) / 2) + narrowedSectionDetails.rangeTwo.start, narrowedSectionDetails);
        const thirdSectionLabelValue = NonLinearGraphUtil.fourSectionDownScale(((narrowedSectionDetails.rangeThree.end - narrowedSectionDetails.rangeThree.start) / 2) + narrowedSectionDetails.rangeThree.start, narrowedSectionDetails);
        const fourthSectionLabelValue = NonLinearGraphUtil.fourSectionDownScale(((narrowedSectionDetails.rangeFour.end - narrowedSectionDetails.rangeFour.start) / 2) + narrowedSectionDetails.rangeFour.start, narrowedSectionDetails);

        if (value === Math.round(firstSectionLabelValue * 100) / 100) {
            return narrowedSectionDetails.yAxisLabels.first;
        } else if (value === Math.round(secondSectionLabelValue * 100) / 100) {
            return narrowedSectionDetails.yAxisLabels.second;
        }
        else if (value === Math.round(thirdSectionLabelValue * 100) / 100) {
            return narrowedSectionDetails.yAxisLabels.third
        }
        else if (value === Math.round(fourthSectionLabelValue * 100) / 100) {
            return narrowedSectionDetails.yAxisLabels.fourth
        }
        return ''; // Don't show other labels
    }


    const minimumPointDistance = 110; // Minimum distance between points in pixels

    useEffect(() => {
        let tempDp = []
        if (sectionDetails.sections === 2) {
            for (const d of dataPoints) {
                tempDp.push(NonLinearGraphUtil.twoSectionDownScale(d, sectionDetails));
            }
            setPointColors(tempDp.map((value) => getTwoSectionsPointColor(value, sectionDetails)))
            setScaledData(tempDp);
        }
        else if (sectionDetails.sections === 3) {
            for (const d of dataPoints) {
                tempDp.push(NonLinearGraphUtil.threeSectionDownScale(d, sectionDetails));
            }
            setPointColors(tempDp.map((value) => getThreeSectionsPointColor(value, sectionDetails)))
            setScaledData(tempDp);
        }
        else {
            for (const d of dataPoints) {
                tempDp.push(NonLinearGraphUtil.fourSectionDownScale(d, sectionDetails));
            }
            setPointColors(tempDp.map((value) => getFourSectionsPointColor(value, sectionDetails)))
            setScaledData(tempDp);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Function to get point color based on value
    const getFourSectionsPointColor = (value: any, narrowedSectionDetails: {
        sections: 4
        rangeOne: { start: number, end: number },
        rangeTwo: { start: number, end: number },
        rangeThree: { start: number, end: number },
        rangeFour: { start: number, end: number },
        backgroundColorOrder: { first: string, second: string, third: string, fourth: string }
        dataPointColorOrder: { first: string, second: string, third: string, fourth: string }
        yAxisLabels: { first: string, second: string, third: string, fourth: string }
        decimals: boolean,
    }) => {
        if (value <= 0.25) {
            return narrowedSectionDetails.dataPointColorOrder.first;
        } else if (value > 0.25 && value <= 0.50) {
            return narrowedSectionDetails.dataPointColorOrder.second;
        } else if (value > 0.50 && value <= 0.75) {
            return narrowedSectionDetails.dataPointColorOrder.third;
        } else {
            return narrowedSectionDetails.dataPointColorOrder.fourth;
        }
    };

    // Function to get point color based on value
    const getThreeSectionsPointColor = (value: any, narrowedSectionDetails: {
        sections: 3
        rangeOne: { start: number, end: number },
        rangeTwo: { start: number, end: number },
        rangeThree: { start: number, end: number },
        backgroundColorOrder: { first: string, second: string, third: string }
        dataPointColorOrder: { first: string, second: string, third: string }
        yAxisLabels: { first: string, second: string, third: string }
        decimals: boolean,
    }) => {
        if (value <= 0.33) {
            return narrowedSectionDetails.dataPointColorOrder.first;
        } else if (value > 0.33 && value <= 0.66) {
            return narrowedSectionDetails.dataPointColorOrder.second;
        } else {
            return narrowedSectionDetails.dataPointColorOrder.third;
        }
    };

    // Function to get point color based on value
    const getTwoSectionsPointColor = (value: any, narrowedSectionDetails: {
        sections: 2,
        rangeOne: { start: number, end: number },
        rangeTwo: { start: number, end: number },
        backgroundColorOrder: { first: string, second: string }
        dataPointColorOrder: { first: string, second: string }
        yAxisLabels: { first: string, second: string },
        decimals: boolean,
    }) => {
        if (value <= sectionDetails.rangeOne.end / sectionDetails.rangeTwo.end) {
            return narrowedSectionDetails.dataPointColorOrder.first;
        }
        return narrowedSectionDetails.dataPointColorOrder.second;
    }

    const chartOptions = {
        responsive: true,
        layout: {
            padding: {
                top: 0,
            }
        },
        maintainAspectRatio: false,
        scales: {
            x: {
                offset: true,
                ticks: {
                    display: true,
                    font: {
                        family: 'pragmatica-extended' as const,
                        size: 14,
                        weight: 'bold' as const,
                    },
                    color: "#12162280",
                },
                border: {
                    display: false,
                },
                grid: {
                    display: false,
                    border: {
                        display: false
                    }
                }
            },
            y: {
                // on the entire chart, there is a border on the Y axis, this removes the initial (0) scale line border
                border: {
                    display: false
                },
                // this removed the labels
                ticks: {
                    display: true,
                    font: {
                        family: 'pragmatica-extended' as const,
                        size: 14,
                        weight: 'bold' as const,
                    },
                    color: "#12162280",
                    // Customize the tick labels
                    callback: function (value: any) {
                        if (sectionDetails.sections === 2) {

                            return getTwoSectionYAxisLabels(value, sectionDetails);
                        } else if (sectionDetails.sections === 3) {
                            return getThreeSectionYAxisLabels(value, sectionDetails)
                        } else {
                            return getFourSectionYAxisLabels(value, sectionDetails)
                        }
                        // if (value === 0.17) {
                        //     return "Good"; // 'Good' in the middle of the first third
                        // } else if (value === 0.5) {
                        //     return "YOO"; // 'Okay' in the middle of the second third
                        // } else if (value === 0.83) {
                        //     return "NOOOO"; // 'Poor' in the middle of the final third
                        // }
                        // return ''; // Don't show other labels

                    },
                    autoSkip: false,
                    stepSize: 0.01, // Set the y-axis step size
                },
                min: 0,
                max: 1,

                grid: {
                    display: false,
                    border: {
                        display: false
                    }

                }
            }
        },
        plugins: {
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function (tooltipItem: any) {
                        if (binaryGraph) {
                            return `${dataPoints[tooltipItem.dataIndex] === binaryGraph.isTrue ? binaryGraph.trueLabel : binaryGraph.falseLabel}`
                        }
                        if (sectionDetails.sections === 2 && toolTipLabel) {
                            if ((customDataPointLabels ? customDataPointLabels[tooltipItem.dataIndex] : dataPoints[tooltipItem.dataIndex]) <= sectionDetails.rangeOne.end) {
                                return toolTipLabelAbstracted ? `${sectionDetails.yAxisLabels.first}` : customDataPointLabels ? `${sectionDetails.yAxisLabels.first}: ${customDataPointLabels[tooltipItem.dataIndex]}` : `${sectionDetails.yAxisLabels.first}: ${dataPoints[tooltipItem.dataIndex]}`;


                            }
                            else if ((customDataPointLabels ? customDataPointLabels[tooltipItem.dataIndex] : dataPoints[tooltipItem.dataIndex]) > sectionDetails.rangeOne.end) {
                                return toolTipLabelAbstracted ? `${sectionDetails.yAxisLabels.second}` : customDataPointLabels ? `${sectionDetails.yAxisLabels.second}: ${customDataPointLabels[tooltipItem.dataIndex]}` : `${sectionDetails.yAxisLabels.second}: ${dataPoints[tooltipItem.dataIndex]}`;
                            }
                        }
                        else if (sectionDetails.sections === 3 && toolTipLabel) {
                            if ((customDataPointLabels ? customDataPointLabels[tooltipItem.dataIndex] : dataPoints[tooltipItem.dataIndex]) <= sectionDetails.rangeOne.end) {
                                return toolTipLabelAbstracted ? `${sectionDetails.yAxisLabels.first}` : customDataPointLabels ? `${sectionDetails.yAxisLabels.first}: ${customDataPointLabels[tooltipItem.dataIndex]}` : `${sectionDetails.yAxisLabels.first}: ${dataPoints[tooltipItem.dataIndex]}`;

                            }
                            else if ((customDataPointLabels ? customDataPointLabels[tooltipItem.dataIndex] : dataPoints[tooltipItem.dataIndex]) <= sectionDetails.rangeTwo.end) {
                                return toolTipLabelAbstracted ? `${sectionDetails.yAxisLabels.second}` : customDataPointLabels ? `${sectionDetails.yAxisLabels.second}: ${customDataPointLabels[tooltipItem.dataIndex]}` : `${sectionDetails.yAxisLabels.second}: ${dataPoints[tooltipItem.dataIndex]}`;
                            }
                            else if ((customDataPointLabels ? customDataPointLabels[tooltipItem.dataIndex] : dataPoints[tooltipItem.dataIndex]) > sectionDetails.rangeTwo.end) {
                                return toolTipLabelAbstracted ? `${sectionDetails.yAxisLabels.third}` : customDataPointLabels ? `${sectionDetails.yAxisLabels.third}: ${customDataPointLabels[tooltipItem.dataIndex]}` : `${sectionDetails.yAxisLabels.third}: ${dataPoints[tooltipItem.dataIndex]}`;
                            }
                        }
                        else if (sectionDetails.sections === 4 && toolTipLabel) {
                            if ((customDataPointLabels ? customDataPointLabels[tooltipItem.dataIndex] : dataPoints[tooltipItem.dataIndex]) <= sectionDetails.rangeOne.end) {
                                return toolTipLabelAbstracted ? `${sectionDetails.yAxisLabels.first}` : customDataPointLabels ? `${sectionDetails.yAxisLabels.first}: ${customDataPointLabels[tooltipItem.dataIndex]}` : `${sectionDetails.yAxisLabels.first}: ${dataPoints[tooltipItem.dataIndex]}`;

                            }
                            else if ((customDataPointLabels ? customDataPointLabels[tooltipItem.dataIndex] : dataPoints[tooltipItem.dataIndex]) <= sectionDetails.rangeTwo.end) {
                                return toolTipLabelAbstracted ? `${sectionDetails.yAxisLabels.second}` : customDataPointLabels ? `${sectionDetails.yAxisLabels.second}: ${customDataPointLabels[tooltipItem.dataIndex]}` : `${sectionDetails.yAxisLabels.second}: ${dataPoints[tooltipItem.dataIndex]}`;
                            }
                            else if ((customDataPointLabels ? customDataPointLabels[tooltipItem.dataIndex] : dataPoints[tooltipItem.dataIndex]) <= sectionDetails.rangeThree.end) {
                                return toolTipLabelAbstracted ? `${sectionDetails.yAxisLabels.third}` : customDataPointLabels ? `${sectionDetails.yAxisLabels.third}: ${customDataPointLabels[tooltipItem.dataIndex]}` : `${sectionDetails.yAxisLabels.third}: ${dataPoints[tooltipItem.dataIndex]}`;
                            }
                            else if ((customDataPointLabels ? customDataPointLabels[tooltipItem.dataIndex] : dataPoints[tooltipItem.dataIndex]) > sectionDetails.rangeThree.end) {
                                return toolTipLabelAbstracted ? `${sectionDetails.yAxisLabels.fourth}` : customDataPointLabels ? `${sectionDetails.yAxisLabels.fourth}: ${customDataPointLabels[tooltipItem.dataIndex]}` : `${sectionDetails.yAxisLabels.fourth}: ${dataPoints[tooltipItem.dataIndex]}`;
                            }
                        }
                        return `${customDataPointLabels ? customDataPointLabels[tooltipItem.dataIndex] : dataPoints[tooltipItem.dataIndex]}`;
                    },
                },
            },
            annotation: {
                annotations: sectionDetails.sections === 2 ? {
                    firstRegion: {
                        type: 'box' as const,
                        yMin: 0,
                        yMax: sectionDetails.rangeOne.end / sectionDetails.rangeTwo.end,
                        backgroundColor: sectionDetails.backgroundColorOrder.first,
                        borderWidth: 0,
                        drawTime: 'beforeDatasetsDraw' as const, // Draw background before data points
                    },
                    secondRegion: {
                        type: 'box' as const,
                        yMin: sectionDetails.rangeOne.end / sectionDetails.rangeTwo.end,
                        yMax: 1,
                        backgroundColor: sectionDetails.backgroundColorOrder.second,
                        borderWidth: 0,
                        drawTime: 'beforeDatasetsDraw' as const,
                    },
                } : sectionDetails.sections === 3 ? {
                    firstRegion: {
                        type: 'box' as const,
                        yMin: 0,
                        yMax: 0.33,
                        backgroundColor: sectionDetails.backgroundColorOrder.first,
                        borderWidth: 0,
                        drawTime: 'beforeDatasetsDraw' as const, // Draw background before data points
                    },
                    secondRegion: {
                        type: 'box' as const,
                        yMin: 0.33,
                        yMax: 0.66,
                        backgroundColor: sectionDetails.backgroundColorOrder.second,
                        borderWidth: 0,
                        drawTime: 'beforeDatasetsDraw' as const,

                    },
                    thirdRegion: {
                        type: 'box' as const,
                        yMin: 0.66,
                        yMax: 1,
                        backgroundColor: sectionDetails.backgroundColorOrder.third,
                        borderWidth: 0,
                        drawTime: 'beforeDatasetsDraw' as const,
                    },
                } : {
                    firstRegion: {
                        type: 'box' as const,
                        yMin: 0,
                        yMax: 0.25,
                        backgroundColor: sectionDetails.backgroundColorOrder.first,
                        borderWidth: 0,
                        drawTime: 'beforeDatasetsDraw' as const, // Draw background before data points
                    },
                    secondRegion: {
                        type: 'box' as const,
                        yMin: 0.25,
                        yMax: 0.50,
                        backgroundColor: sectionDetails.backgroundColorOrder.second,
                        borderWidth: 0,
                        drawTime: 'beforeDatasetsDraw' as const,

                    },
                    thirdRegion: {
                        type: 'box' as const,
                        yMin: 0.50,
                        yMax: 0.75,
                        backgroundColor: sectionDetails.backgroundColorOrder.third,
                        borderWidth: 0,
                        drawTime: 'beforeDatasetsDraw' as const,
                    },
                    fourthRegion: {
                        type: 'box' as const,
                        yMin: 0.75,
                        yMax: 1,
                        backgroundColor: sectionDetails.backgroundColorOrder.fourth,
                        borderWidth: 0,
                        drawTime: 'beforeDatasetsDraw' as const,
                    },
                }
            },
            datalabels: {
                display: false,
                anchor: 'end' as const,
                align: 'top' as const,
                labels: {
                    title: {
                        font: {
                            weight: 'bold' as const,
                            size: 16,
                            family: 'pragmatica-extended' as const
                        },
                        color: '#121622'
                    }
                },
                formatter: (value: any, context: any) => {
                    // Use the original data to display in the labe
                    return dataPoints[context.dataIndex];
                },
            },
        }
    }

    const numberOfDataPoints = labels.length;
    const totalWidth = numberOfDataPoints * minimumPointDistance;
    const data = {
        labels,
        datasets: [
            {
                label: 'Dataset 1',
                data: scaledData,
                borderColor: '#121622', // border color of line
                backgroundColor: pointColors, // background color of data point
                pointBorderColor: '#F7F9FC', // point border color
                pointRadius: 7, // size of data point
                pointBorderWidth: 3, // border width for data points
                borderWidth: 5, //border width for line
                clip: false as const,
            }
        ]
    }

    return (
        <>
            {chartOptions && data && (
                <>
                    <h3>{title}</h3>
                    <div style={styles.wrapper}>
                        <div style={styles.chartWrapper} ref={containerRef}>
                            <div style={{ ...styles.chartContainer, width: `${totalWidth}px` }}>
                                <Line options={chartOptions} data={data} className="rounded-2xl" />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
const styles = {
    wrapper: {
        width: '100%',
        height: '288px',
        display: 'flex',
        // justifyContent: 'center', // Center the graph horizontally
        alignItems: 'center', // Center the graph vertically
    },
    chartWrapper: {
        width: '100%', // Adjust this based on how much of the screen you want the chart to take up
        height: '100%',
        overflowX: "auto" as const, // Enable horizontal scrolling within the chart area
        overflowY: "hidden" as const, // Prevent vertical scrolling
    },
    chartContainer: {
        height: '100%',
        minWidth: '100%', // Ensure it fits initially but grows dynamically
    }
};



export default NonLinearLineChart;