import { AnswerConversionUtil } from "../models/answer-conversion-util";
import { AnswerIds } from "../models/enumerations/answer-ids";
import { GroupIds } from "../models/enumerations/group-ids";
import IAssessmentSection from "../models/interfaces/assessment-section.interface";
import { IQuestionGroupScore } from "../models/interfaces/question-group-score.interface";
import GroupUtil from "./group-util";
import LifestyleQuestionGroupScore from "./lifestyle-question-group-score.entity";
import QuestionResponse from "../models/question-response.entity";
import QuestionResponseDto from "./question-response.dto";
import QuestionResponsesSummary from "./question-responses-summary";
import QuestionResponsesSummaryExpectations from "../models/exceptions/question-responses-summary-expectations";

const GROUP_ID: string = GroupIds.SLEEP_PSQI;

class LifestyleQuestionIds {
    /* eslint-disable no-multi-spaces */
    static BED_TIME    = `${GROUP_ID}_BED_TIME`;
    static FALL_ASLEEP = `${GROUP_ID}_FALL_ASLEEP`;
    static WAKE_TIME   = `${GROUP_ID}_WAKE_TIME`;
    static SLEEP_HOURS = `${GROUP_ID}_SLEEP_HOURS`;
    static Q5A         = `${GROUP_ID}_Q5A`;
    static Q5B         = `${GROUP_ID}_Q5B`;
    static Q5C         = `${GROUP_ID}_Q5C`;
    static Q5D         = `${GROUP_ID}_Q5D`;
    static Q5E         = `${GROUP_ID}_Q5E`;
    static Q5F         = `${GROUP_ID}_Q5F`;
    static Q5G         = `${GROUP_ID}_Q5G`;
    static Q5H         = `${GROUP_ID}_Q5H`;
    static Q5I         = `${GROUP_ID}_Q5I`;
    static Q6          = `${GROUP_ID}_Q6`;
    static Q7          = `${GROUP_ID}_Q7`;
    static Q8          = `${GROUP_ID}_Q8`;
    static Q9          = `${GROUP_ID}_Q9`;
    /* eslint-enable no-multi-spaces */
}

export class LifestyleSleepPsqi implements IAssessmentSection {
    groupId: string = GROUP_ID;
    groupName: string = GroupUtil.getName(GROUP_ID);

    private questionResponses?: QuestionResponse[];
    private summary5Bto5I?: QuestionResponsesSummary;

    private bedTime?: string | null;
    private fallAsleepAnswerId?: string | null;
    private wakeTime?: string | null;
    private sleepHours?: number;
    private answer5AId?: string | null;
    /**
     * Number of records from 5B-5I with the answer "A"
     */
    private answer5NumA: number = 0;
    /**
     * Number of records from 5B-5I with the answer "B"
     */
    private answer5NumB: number = 0;
    /**
     * Number of records from 5B-5I with the answer "C"
     */
    private answer5NumC: number = 0;
    /**
     * Number of records from 5B-5I with the answer "D"
     */
    private answer5NumD: number = 0;

    private answer6Id?: string | null;
    private answer7Id?: string | null;
    private answer8Id?: string | null;
    private answer9Id?: string | null;
    

    constructor(partial?: Partial<LifestyleSleepPsqi>) {
        if (partial != null) {
            Object.assign(this, partial);
        }
    }
    getQuestions(nextGroupId: string) {
        throw new Error("Method not implemented.");
    }

    public static PASS_RESPONSES: QuestionResponseDto[] = [
        /* eslint-disable no-multi-spaces */
        new QuestionResponseDto({ groupId: GROUP_ID, answerResponse: "23:00", questionId: LifestyleQuestionIds.BED_TIME }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.A,   questionId: LifestyleQuestionIds.FALL_ASLEEP }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerResponse: "6:30",  questionId: LifestyleQuestionIds.WAKE_TIME }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerResponse: "7.01",  questionId: LifestyleQuestionIds.SLEEP_HOURS }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.A,   questionId: LifestyleQuestionIds.Q5A }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.A,   questionId: LifestyleQuestionIds.Q5B }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.A,   questionId: LifestyleQuestionIds.Q5C }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.A,   questionId: LifestyleQuestionIds.Q5D }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.A,   questionId: LifestyleQuestionIds.Q5E }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.A,   questionId: LifestyleQuestionIds.Q5F }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.A,   questionId: LifestyleQuestionIds.Q5G }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.A,   questionId: LifestyleQuestionIds.Q5H }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.A,   questionId: LifestyleQuestionIds.Q5I }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.A,   questionId: LifestyleQuestionIds.Q6 }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.A,   questionId: LifestyleQuestionIds.Q7 }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.A,   questionId: LifestyleQuestionIds.Q8 }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.A,   questionId: LifestyleQuestionIds.Q9 }),
        /* eslint-enable no-multi-spaces */
    ];

    public static FAIL_RESPONSES: QuestionResponseDto[] = [
        /* eslint-disable no-multi-spaces */
        new QuestionResponseDto({ groupId: GROUP_ID, answerResponse: "22:00", questionId: LifestyleQuestionIds.BED_TIME }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.D,   questionId: LifestyleQuestionIds.FALL_ASLEEP }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerResponse: "7:00",  questionId: LifestyleQuestionIds.WAKE_TIME }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerResponse: "4.99",  questionId: LifestyleQuestionIds.SLEEP_HOURS }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.D,   questionId: LifestyleQuestionIds.Q5A }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.D,   questionId: LifestyleQuestionIds.Q5B }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.D,   questionId: LifestyleQuestionIds.Q5C }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.D,   questionId: LifestyleQuestionIds.Q5D }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.D,   questionId: LifestyleQuestionIds.Q5E }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.D,   questionId: LifestyleQuestionIds.Q5F }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.D,   questionId: LifestyleQuestionIds.Q5G }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.D,   questionId: LifestyleQuestionIds.Q5H }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.D,   questionId: LifestyleQuestionIds.Q5I }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.D,   questionId: LifestyleQuestionIds.Q6 }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.D,   questionId: LifestyleQuestionIds.Q7 }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.D,   questionId: LifestyleQuestionIds.Q8 }),
        new QuestionResponseDto({ groupId: GROUP_ID, answerId: AnswerIds.D,   questionId: LifestyleQuestionIds.Q9 }),
        /* eslint-enable no-multi-spaces */
    ];

    public getScore(): IQuestionGroupScore {
        const component1 = this.toNumber(this.answer6Id);

        const component2a =
            this.toNumber(this.fallAsleepAnswerId) +
            this.toNumber(this.answer5AId);
        const component2 = (
            component2a === 0 ? 0 :
                component2a <= 2 ? 1 :
                    component2a <= 4 ? 2 :
                        3
        );

        const component3 = (
            this.sleepHours! > 7 ? 0 :
                this.sleepHours! > 6 ? 1 :
                    this.sleepHours! > 5 ? 2 :
                        3
        );

        const component4aBed = AnswerConversionUtil.timeToDecimal(this.bedTime!);
        const component4aWake = AnswerConversionUtil.timeToDecimal(this.wakeTime!);
        const component4a = (
            component4aWake > component4aBed ?
                component4aWake - component4aBed : // ex: bed "1:00" & wake "7:00"
                component4aWake + 24 - component4aBed // ex: bed "23:00" & wake "7:00"
        );
        const component4b = this.sleepHours! / component4a;
        const component4 = (
            component4b < 0.65 ? 3 :
                component4b < 0.75 ? 2 :
                    component4b < 0.85 ? 1 :
                        0
        );

        const component5a =
            this.answer5NumA * this.toNumber(AnswerIds.A) +
            this.answer5NumB * this.toNumber(AnswerIds.B) +
            this.answer5NumC * this.toNumber(AnswerIds.C) +
            this.answer5NumD * this.toNumber(AnswerIds.D);
        const component5 = (
            component5a === 0 ? 0 :
                component5a <= 9 ? 1 :
                    component5a <= 18 ? 2 :
                        3
        );

        const component6 = this.toNumber(this.answer7Id);

        const component7a =
            this.toNumber(this.answer8Id) +
            this.toNumber(this.answer9Id);
        const component7 = (
            component7a === 0 ? 0 :
                component7a <= 2 ? 1 :
                    component7a <= 4 ? 2 :
                        3
        );

        const total = component1 + component2 + component3 + component4 + component5 + component6 + component7;
        const percent = 100 * (21-total) / 21;

        return new LifestyleQuestionGroupScore({
            /* eslint-disable no-multi-spaces */
            score: total,
            percentage: AnswerConversionUtil.getFinalPercentage(percent),
            groupId: GROUP_ID,
            groupName: this.groupName,
            /* eslint-enable no-multi-spaces */
        });
    }

    private toNumber(answerId?: string | null): number {
        return AnswerConversionUtil.answerIdToNumber([0, 1, 2, 3], answerId);
    }

    public load(questionResponses: QuestionResponse[]): void {

        this.questionResponses = questionResponses;

        /* eslint-disable-next-line */
        function is5Bto5I(questionResponse: QuestionResponse): boolean {
            return questionResponse != null &&
                questionResponse.questionId != null &&
                questionResponse.questionId.indexOf("_Q5") > 0 &&
                questionResponse.questionId.indexOf(LifestyleQuestionIds.Q5A) === -1;
        }
        const response5Bto5I = questionResponses.filter(is5Bto5I);
        this.summary5Bto5I = QuestionResponsesSummary.summarize(response5Bto5I);

        /* eslint-disable no-multi-spaces */
        this.bedTime            = QuestionResponse.findAnswer(questionResponses, LifestyleQuestionIds.BED_TIME);
        this.fallAsleepAnswerId = QuestionResponse.findAnswer(questionResponses, LifestyleQuestionIds.FALL_ASLEEP);
        this.wakeTime           = QuestionResponse.findAnswer(questionResponses, LifestyleQuestionIds.WAKE_TIME);
        this.sleepHours         = parseFloat(QuestionResponse.findAnswer(questionResponses, LifestyleQuestionIds.SLEEP_HOURS) ?? "0");
        this.answer5AId         = QuestionResponse.findAnswer(questionResponses, LifestyleQuestionIds.Q5A);
        this.answer5NumA        = this.summary5Bto5I.numA;
        this.answer5NumB        = this.summary5Bto5I.numB;
        this.answer5NumC        = this.summary5Bto5I.numC;
        this.answer5NumD        = this.summary5Bto5I.numD;
        this.answer6Id          = QuestionResponse.findAnswer(questionResponses, LifestyleQuestionIds.Q6);
        this.answer7Id          = QuestionResponse.findAnswer(questionResponses, LifestyleQuestionIds.Q7);
        this.answer8Id          = QuestionResponse.findAnswer(questionResponses, LifestyleQuestionIds.Q8);
        this.answer9Id          = QuestionResponse.findAnswer(questionResponses, LifestyleQuestionIds.Q9);
        /* eslint-enable no-multi-spaces */
    }

    public loadForTesting(settings: any): void {
        this.summary5Bto5I = new QuestionResponsesSummary({
            numA: settings.answer5NumA,
            numB: settings.answer5NumB,
            numC: settings.answer5NumC,
            numD: settings.answer5NumD,
        });

        /* eslint-disable no-multi-spaces */
        this.bedTime            = settings.bedTime;
        this.fallAsleepAnswerId = settings.fallAsleepAnswerId;
        this.wakeTime           = settings.wakeTime;
        this.sleepHours         = settings.sleepHours;
        this.answer5AId         = settings.answer5AId;
        this.answer5NumA        = settings.answer5NumA;
        this.answer5NumB        = settings.answer5NumB;
        this.answer5NumC        = settings.answer5NumC;
        this.answer5NumD        = settings.answer5NumD;
        this.answer6Id          = settings.answer6Id;
        this.answer7Id          = settings.answer7Id;
        this.answer8Id          = settings.answer8Id;
        this.answer9Id          = settings.answer9Id;
        /* eslint-enable no-multi-spaces */
    }

    public validate(): void {
        const summary = QuestionResponsesSummary.summarize(this.questionResponses!);
        const expectations = new QuestionResponsesSummaryExpectations({
            nameForError: this.groupName,
            numQuestions: 17,
            numQuestionsWithInputs: 3,
            numOptionsPerQuestion: 4,
        });
        summary.validate(expectations);

        const expectations5Bto5I = new QuestionResponsesSummaryExpectations({
            nameForError: this.groupName,
            numQuestions: 8,
            numOptionsPerQuestion: 4,
        });
        this.summary5Bto5I!.validate(expectations5Bto5I);
    }
}
