import { useHistory, useParams } from "react-router";
import BaseCardLayout from "../../components/base-card-layout/base-card-layout";
import { Button } from '../../components/button/button';
import logo from "../../assets/images/symmio-logos/symmio-logo-text-white-side.png"
import headerImage from "../../assets/images/header-landing.png"
import landingMskScoreImage from "../../assets/images/landing-msk-score.png"
import landingFocusAreaImage from "../../assets/images/landing-focus-area.png"
import landingImprovementPlanImage from "../../assets/images/landing-improvement-plan.png"
import { useEffect, useState } from "react";
import DeeplinkService from "../../utilities/services/deeplink-service";
import { DateTime } from "luxon";
import { Deeplink } from "../../models/interfaces/deeplink";
import { DeeplinkStatus } from "../../models/enumerations/deeplink-status";
import OrganizationService from "../../utilities/services/organization-service";
import { SymmioAccessType } from "../../models/enumerations/symmio-access-type";
import errorMessageLogo from "../../assets/images/symmio-logos/symmio-logo-horizontal-dark.png";

export interface LandingPageProps {

}

interface LandingPageParams {
    id: string;
}

const COMPONENT_CLASS = "p-landing";

const LandingPage: React.FunctionComponent<LandingPageProps> = () => {
    const { id } = useParams<LandingPageParams>();
    const history = useHistory();
    const [isDeeplinkValid, setIsDeeplinkValid] = useState(true);
    const [linkErrorMessageHeader, setLinkErrorMessageHeader] = useState("Invalid Link");
    const [linkErrorMessageBody, setLinkErrorMessageBody] = useState("The link is not valid.");

    useEffect(() => {
        const getDeeplink = async () => {
            let fetchedDeeplink: Deeplink | null = null;

            try {
                fetchedDeeplink = await DeeplinkService.get(id);
            } catch (err) {
                console.error("Cannot fetch the deeplink ID");
                setIsDeeplinkValid(false);
            } finally {
                await checkDeeplinkValidity(fetchedDeeplink);
            }
        }

        getDeeplink();
    }, [id]);

    const checkDeeplinkValidity = async (deeplink: Deeplink | null) => {
        const nowUtc = DateTime.now().toUTC();

        if (!deeplink) {
            setIsDeeplinkValid(false);
            return;
        }

        const startDate = deeplink.startDate ? DateTime.fromISO(deeplink.startDate) : null;
        const endDate = deeplink.endDate ? DateTime.fromISO(deeplink.endDate) : null;

        const errorFutureHeader = "Opening Soon";
        const errorFutureBody = `The enrollment date is set to start ${startDate?.toLocaleString(DateTime.DATE_FULL)}.\nPlease return at a later date to perform your functional wellness assessment.`;

        const errorExpiredHeader = "Link Expired";
        const errorExpiredBody = "The enrollment date has passed.\nPlease contact your Symmio administrator to request a new link.";

        const errorInactiveHeader = "Link Deactivated";
        const errorInactiveBody = "The enrollment link has been deactivated.\nPlease contact your Symmio administrator to request a new link.";

        const errorLimitHeader = "Enrollment Closed";
        const errorLimitBody = "This enrollment link has reached its response limit.\nPlease contact your Symmio administrator to request a new invite.";

        const errorOrganizationLimitHeader = "Enrollment Closed";
        const errorOrganizationLimitBody = "The organization has reached its user limit.\nPlease contact your Symmio administrator to increase the user limit";

        const errorLicenseLimitHeader = "Enrollment Closed";
        const errorLicenseLimitBody = "The organization has reached its limit for available Wellness Programs.\nPlease contact your Symmio administrator to request additional Wellness Programs";


        // If start date is a future date
        if (startDate && nowUtc <= startDate.toUTC().startOf("day")) {
            setLinkErrorMessageHeader(errorFutureHeader);
            setLinkErrorMessageBody(errorFutureBody);
            setIsDeeplinkValid(false);
            return;
        }

        // If end date has passed
        else if (endDate && nowUtc >= endDate.toUTC().endOf("day")) {
            setLinkErrorMessageHeader(errorExpiredHeader);
            setLinkErrorMessageBody(errorExpiredBody);
            setIsDeeplinkValid(false);
            return;
        }

        // If the deeplink is not active
        else if (deeplink.status !== DeeplinkStatus.Active) {
            setLinkErrorMessageHeader(errorInactiveHeader);
            setLinkErrorMessageBody(errorInactiveBody);
            setIsDeeplinkValid(false);
            return;
        }

        // If the link has reached its usage limit
        else if (deeplink.used && deeplink.limit && deeplink.used >= deeplink.limit) {
            setLinkErrorMessageHeader(errorLimitHeader);
            setLinkErrorMessageBody(errorLimitBody);
            setIsDeeplinkValid(false);
            return;
        }

        else if (deeplink.organizationId) {
            const org = await OrganizationService.get(deeplink.organizationId);

            // If the organization has reached its user limit
            if (org && org.userLimit && org.userCount && org.userCount >= org.userLimit) {
                setLinkErrorMessageHeader(errorOrganizationLimitHeader);
                setLinkErrorMessageBody(errorOrganizationLimitBody);
                setIsDeeplinkValid(false);
                return;
            }

            // If the organization has reached its license limit
            else if (deeplink.linkType === SymmioAccessType.AppLicense && org && org.mySymmioLicense && org.mySymmioLicenseCount && org.mySymmioLicenseCount >= org.mySymmioLicense) {
                setLinkErrorMessageHeader(errorLicenseLimitHeader);
                setLinkErrorMessageBody(errorLicenseLimitBody);
                setIsDeeplinkValid(false);
                return;
            }
        }

        setIsDeeplinkValid(true);
    }

    const errorMessage = (
        <BaseCardLayout>
            <div className={`${COMPONENT_CLASS}__error`}>
                <div className={`${COMPONENT_CLASS}__error-content-container`}>
                    <div className={`${COMPONENT_CLASS}__error-image-container`}>
                        <img src={errorMessageLogo} alt="Symmio Logo" />
                    </div>

                    <div className={`${COMPONENT_CLASS}__error-message-container`}>
                        <h1>{linkErrorMessageHeader}</h1>
                        <p className="whitespace-pre-line">{linkErrorMessageBody}</p>
                    </div>
                </div>
            </div>
        </BaseCardLayout>
    );

    const assessmentButton = (
        <Button
            type="default"
            buttonText="Start My Assessments"
            onClick={() => history.push(`/link-account-creation/${id}`)} />
    );

    return (
        <div className={`${COMPONENT_CLASS}`}>
            {isDeeplinkValid
                ?
                <BaseCardLayout>
                    <header>
                        <div className={`${COMPONENT_CLASS}__logo`}>
                            <img src={logo} alt="Symmio Logo" />
                        </div>
                        <div className={`${COMPONENT_CLASS}__header`}>
                            <img src={headerImage} alt="Header" />
                        </div>
                    </header>
                    <main>
                        <div className={`${COMPONENT_CLASS}__welcome-text-container`}>
                            <h1>Welcome to Symmio</h1>
                            <p>
                                <strong>Symmio guides you to better health and wellness by providing you with your greatest opportunities for improvement.</strong>
                                <br /><br />
                                Improving your Musculoskeletal (MSK) health can reduce pain, boost productivity, improve mental health, and reduce the amount of time you spend on the sidelines missing out on life.
                            </p>
                            <p>
                                <span className={`${COMPONENT_CLASS}__paragraph-header`}>
                                    Assessments:
                                </span>
                                <br />
                                Movement Assessment
                                <br />
                                Lifestyle Assessment
                            </p>
                            <p>
                                <span className={`${COMPONENT_CLASS}__paragraph-header`}>
                                    Duration:
                                </span>
                                <br />
                                About 15 minutes
                            </p>
                            {assessmentButton}
                        </div>
                        <div className={`${COMPONENT_CLASS}__cards-container`}>
                            <div className={`${COMPONENT_CLASS}__card`}>
                                <img className={`${COMPONENT_CLASS}__card-image`} src={landingMskScoreImage} alt="MSK Score" />
                                <div className={`${COMPONENT_CLASS}__card-text`}>
                                    <h4>Your Wellness Score</h4>
                                    <p>After your assessments, you’ll get a cumulative score based on 8 health & wellness risk factors to see where you are with your overall health and well-being.</p>
                                </div>
                            </div>
                            <div className={`${COMPONENT_CLASS}__card right`}>
                                <div className={`${COMPONENT_CLASS}__card-text`}>
                                    <h4>Identify Your Priorities</h4>
                                    <p>Symmio pinpoints your two most crucial areas for improvement based on the assessment, targeting an enhanced quality of life.</p>
                                </div>
                                <img className={`${COMPONENT_CLASS}__card-image`} src={landingFocusAreaImage} alt="Assessment Score" />
                            </div>
                            <div className={`${COMPONENT_CLASS}__card`}>
                                <img className={`${COMPONENT_CLASS}__card-image`} src={landingImprovementPlanImage} alt="Focus Areas" />
                                <div className={`${COMPONENT_CLASS}__card-text`}>
                                    <h4>Start Your Wellness Journey</h4>
                                    <p>Get a personalized action plan from Symmio, featuring exercise routines and professional educational materials designed just for you.</p>
                                </div>
                            </div>
                        </div>
                    </main>
                    <footer>
                        <div className={`${COMPONENT_CLASS}__welcome-text-container`}>
                            <h4>
                                Discover your full potential and stay focused on meeting your wellness goals with Symmio.
                            </h4>
                            <p>
                                <span className={`${COMPONENT_CLASS}__paragraph-header`}>
                                    Assessments:
                                </span>
                                <br />
                                Movement Assessment
                                <br />
                                Lifestyle Assessment
                            </p>
                            <p>
                                <span className={`${COMPONENT_CLASS}__paragraph-header`}>
                                    Duration:
                                </span>
                                <br />
                                About 15 minutes
                            </p>
                            {assessmentButton}
                        </div>
                    </footer>
                </BaseCardLayout >
                :
                errorMessage
            }
        </div >
    );
};

export default LandingPage;
