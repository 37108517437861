/**
 * @returns {number} 0 to 1 - percentage
 */
const twoSectionDownScale = (value: any, narrowedSectionDetails: {
    sections: 2,
    rangeOne: { start: number, end: number },
    rangeTwo: { start: number, end: number }
}) => {
    if (value >= narrowedSectionDetails.rangeTwo.start) {
        // check if value is over the last range end and assign max value
        if (value > narrowedSectionDetails.rangeTwo.end) {
            value = narrowedSectionDetails.rangeTwo.end;
        }
        return ((value - (narrowedSectionDetails.rangeTwo?.start - 0.5)) / (narrowedSectionDetails.rangeTwo.end - narrowedSectionDetails.rangeTwo.start + 0.5)) * (1 - (narrowedSectionDetails.rangeOne.end / narrowedSectionDetails.rangeTwo.end)) + ((narrowedSectionDetails.rangeOne.end / narrowedSectionDetails.rangeTwo.end));
    }
    return (value / (narrowedSectionDetails.rangeOne.end + 0.5)) * (narrowedSectionDetails.rangeOne.end / narrowedSectionDetails.rangeTwo.end);
};

/**
 * @returns {number} 0 to 1 - percentage
 */
const threeSectionDownScale = (value: any, narrowedSectionDetails: {
    sections: 3
    rangeOne: { start: number, end: number },
    rangeTwo: { start: number, end: number },
    rangeThree: { start: number, end: number },
    decimals: boolean,
}) => {
    if (value >= narrowedSectionDetails.rangeThree.start) {
        // check if value is over the last range end and assign max value
        if (value > narrowedSectionDetails.rangeThree.end) {
            value = narrowedSectionDetails.rangeThree.end;
        }
        // Map range three to upper 1/3
        return ((value - (narrowedSectionDetails.rangeThree?.start - (narrowedSectionDetails.decimals ? 0.05 : 0.5))) / (narrowedSectionDetails.rangeThree.end - narrowedSectionDetails.rangeThree.start + (narrowedSectionDetails.decimals ? 0.05 : 0.5))) * (1 / 3) + (2 / 3);
    }
    if (value >= narrowedSectionDetails.rangeTwo.start) {
        // Map range two to middle 1/3
        return ((value - (narrowedSectionDetails.rangeTwo.start - (narrowedSectionDetails.decimals ? 0.05 : 0.5))) / (narrowedSectionDetails.rangeTwo.end - narrowedSectionDetails.rangeTwo.start + (narrowedSectionDetails.decimals ? 0.1 : 1))) * (1 / 3) + (1 / 3);
    }
    // Map range one to lower 1/3
    return (value / (narrowedSectionDetails.rangeOne.end + (narrowedSectionDetails.decimals ? 0.05 : 0.5))) * (1 / 3);
};

/**
 * @returns {number} 0 to 1 - percentage
 */
const fourSectionDownScale = (value: any, narrowedSectionDetails: {
    sections: 4
    rangeOne: { start: number, end: number },
    rangeTwo: { start: number, end: number },
    rangeThree: { start: number, end: number },
    rangeFour: { start: number, end: number },
    decimals: boolean,
}) => {
    if (value >= narrowedSectionDetails.rangeFour.start) {
        // check if value is over the last range end and assign max value
        if (value > narrowedSectionDetails.rangeFour.end) {
            value = narrowedSectionDetails.rangeFour.end;
        }
        // Map range three to upper 1/3
        return ((value - (narrowedSectionDetails.rangeFour?.start - (narrowedSectionDetails.decimals ? 0.05 : 0.5))) / (narrowedSectionDetails.rangeFour.end - narrowedSectionDetails.rangeFour.start + (narrowedSectionDetails.decimals ? 0.05 : 0.5))) * (1 / 4) + (3 / 4);
    }
    if (value >= narrowedSectionDetails.rangeThree.start) {
        // Map range 3rd quarter 3/4 eg 24.95-29.95
        return ((value - (narrowedSectionDetails.rangeThree?.start - (narrowedSectionDetails.decimals ? 0.05 : 0.5))) / (narrowedSectionDetails.rangeThree.end - narrowedSectionDetails.rangeThree.start + (narrowedSectionDetails.decimals ? 0.1 : 1))) * (1 / 4) + (2 / 4);
    }
    if (value >= narrowedSectionDetails.rangeTwo.start) {
        // Map range 2nd quarter 2/4 eg 18.45-24.95
        return ((value - (narrowedSectionDetails.rangeTwo.start - (narrowedSectionDetails.decimals ? 0.05 : 0.5))) / (narrowedSectionDetails.rangeTwo.end - narrowedSectionDetails.rangeTwo.start + (narrowedSectionDetails.decimals ? 0.1 : 1))) * (1 / 4) + (1 / 4);
    }
    // Map range one to lower 1/4 eg 0-18.45
    return (value / (narrowedSectionDetails.rangeOne.end + (narrowedSectionDetails.decimals ? 0.05 : 0.5))) * (1 / 4);
};

const NonLinearGraphUtil = {
    twoSectionDownScale,
    threeSectionDownScale,
    fourSectionDownScale,
};

export default NonLinearGraphUtil;