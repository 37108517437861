import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useGlobalState } from "../../utilities/hooks/use-global-state";
import { AnswerFormats } from "../../models/enumerations/answer-formats";
import { CategoryIds } from "../../models/enumerations/category-ids";
import { GroupIds } from "../../models/enumerations/group-ids";
import { SlideType } from "../../models/enumerations/slide-types";
import AssessmentSettings from "../../models/interfaces/assessment-settings";
import { Question } from "../../models/interfaces/questions/question";
import { LifestyleAssessmentResponse } from "../../models/interfaces/scores/lifestyle-assessment-response";
import { LifestyleScore } from "../../models/interfaces/scores/lifestyle-score";
import { MskScore } from "../../models/interfaces/scores/msk-score";
import { UserInfo } from "../../models/interfaces/scores/user-info";
import { AssessmentUtils } from "../../utilities/assessments/assessment-utils";
import usePrevious from "../../utilities/hooks/use-previous";
import MskScoreUtil from "../../utilities/msk-score-util";
import UserMskFocusArticlesUtil from "../../utilities/user-msk-focus-articles-util";
import UserUtilAssessments from "../../utilities/user-util-assessments";
import AppModal from "../app-modal/app-modal";
import LoaderAssessments from "../loader-assessments/loader-assessments";
import ScreenSlide from "../screen-slide/screen-slide";
import { useStoreAssessments } from "../../store/useStoreAssessments";
// import TodayService from "../../../utilities/services/today-service/today-service";
import { ActivityType } from "../../models/enumerations/activity-type";
// import { sadSharp } from "ionicons/icons";
// import IToday from "../../../models/interfaces/today";
// import { LocalStorageUtils } from "../../../utilities/local-storage-utils";
import UserActivityService from "../../utilities/services/user-activity-service";
import { DateTime } from "luxon";
import UserActivity from "../../models/interfaces/user-activity";
import UserService from "../../utilities/services/user-service";
import ReportService from "../../utilities/services/report-service-assessments/report-service-assessments";
import { ReportType } from "../../models/enumerations/report-type";
import { Report } from "../../models/interfaces/report";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import BaseCardLayoutActions from "../base-card-layout/base-card-layout-actions";
import { useIntercom } from "react-use-intercom";
import { CorrectiveExerciseUtils } from "../../utilities/corrective-exercise-utils";
import { WellnessProgram } from "../../models/interfaces/wellness-program";
import ArticleUtils from "../../utilities/article-utils";
import WellnessProgramService from "../../utilities/services/wellness-program-service";
import { SymmioAccessType } from "../../models/enumerations/symmio-access-type";
import UserCorrectiveExercisesService from "../../utilities/services/corrective-exercises-service/user-corrective-exercises-service";
import UserMskFocusArticlesService from "../../utilities/services/article-service/user-msk-articles-service";

const COMPONENT_CLASS = "c-movement-screen";

export interface LifestyleScreenProps {
    screenSettings: AssessmentSettings;
    setScreenSettings: React.Dispatch<AssessmentSettings>;
    settingsOnBack?: Function;
}

const LifestyleScreen: React.FC<LifestyleScreenProps> = (props) => {
    const { screenSettings, setScreenSettings } = props;
    const { trackEvent } = useIntercom();
    const { globalState, setGlobalError, setHasMskScore } = useGlobalState();
    const { state, setState } = useAuthState();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingNextGroup, setLoadingNextGroup] = useState(false);
    const [lifestyleScore, setLifestyleScore] = useState<
        LifestyleScore | undefined | null
    >(undefined);
    const [errors, setErrors] = useState<string[]>([]);
    const [errorIntro, setErrorIntro] = useState<string | undefined>();
    const [mskScore, setMskScore] = useState<MskScore>();
    const [userInfo, setUserInfo] = useState<UserInfo>();
    const [sendTrackEventFirstScore, setSendTrackEventFirstScore] = useState<boolean>(false);
    // const [today, setToday] = useState<IToday>();
    const previousGroupIndex = usePrevious<number>(
        screenSettings.currentGroupIndex
    );
    const retryMs: number[] = [500, 1000, 1500, 3000, 5000];
    const setLastActivityCompletedDate = useStoreAssessments(
        (state) => state.setLastActivityCompleted
    );
    useEffect(() => {
        if (!state.user || !state.user.id) {
            new Error("Please log in.")
            return;
        }
        const userId = state.user.id;
        MskScoreUtil.getLatest(userId).then((mskScore) => {
            if (mskScore && mskScore.percentage == null) {
                setState((state) => ({
                    ...state, ...{ temp: { baselineAssessment: true } }
                }));
            } else {
                setState((state) => ({
                    ...state, ...{ temp: { baselineAssessment: false } }
                }));
            }
            console.log("this is the latest mskScore", mskScore);
            setMskScore(mskScore);
        });
        UserUtilAssessments.getUserInfo(userId).then((userInfo) => {
            setUserInfo(userInfo);
        });

        const params = new URLSearchParams(window.location.search);
        const groupId = params.get("groupId");
        AssessmentUtils.fetchQuestions(
            screenSettings,
            CategoryIds.LIFESTYLE,
            groupId
        ).then((settings) => {
            settings.specificGroupId = groupId;
            setScreenSettings(settings);
            setIsLoading(false);
        }).catch((e) => {
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const shouldScore = AssessmentUtils.shouldScoreGroup(
            screenSettings,
            previousGroupIndex,
            screenSettings.currentGroupIndex
        );
        if (!shouldScore) {
            return;
        }

        setErrors([]);
        setIsLoading(true);
        scoreGroup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenSettings.currentGroupIndex]);

    const scoreGroup = async (attemptIndex: number = 0): Promise<void> => {
        let assessmentResponse: LifestyleAssessmentResponse | undefined =
            undefined;
        try {
            assessmentResponse = await AssessmentUtils.scoreGroup(
                userInfo || {},
                screenSettings,
                previousGroupIndex,
                mskScore
            );
        } catch (err: any) {
            if (attemptIndex === 0) {
                setGlobalError("Lifestyle: Calculate", err);
            }
            if (attemptIndex < retryMs.length) {
                setTimeout(() => {
                    return scoreGroup(attemptIndex + 1);
                }, retryMs[attemptIndex]);
                return;
            } else {
                await handleError([
                    "Your assessment could not be scored. Please submit your response again.",
                ]);
                return;
            }
        }

        // error handling
        if (assessmentResponse == null) {
            await handleError([
                "Your assessment was not calculated properly. Please submit your response again.",
            ]);
            return;
        }
        if (
            assessmentResponse.errors != null &&
            assessmentResponse.errors.length >= 1
        ) {
            await handleError(
                assessmentResponse.errors,
                "Your assessment had errors. Please review the error and submit again."
            );
            return;
        }

        // save group
        saveGroup(assessmentResponse);
    };

    const saveGroup = async (
        lifestyleAssessmentResponse: LifestyleAssessmentResponse,
        attemptIndex: number = 0
    ): Promise<void> => {
        let newMskScore: MskScore | undefined = undefined;
        try {
            if (!state.user || !state.user.id) {
                return;
            }
            newMskScore = await AssessmentUtils.saveGroupByUserId(
                lifestyleAssessmentResponse,
                state.user.id,
                (hasMskScore: boolean) => setHasMskScore(hasMskScore)
            );
        } catch (err: any) {
            if (attemptIndex === 0) {
                setGlobalError("Lifestyle: Save", err);
            }
            if (attemptIndex < retryMs.length) {
                setTimeout(() => {
                    return saveGroup(
                        lifestyleAssessmentResponse,
                        attemptIndex + 1
                    );
                }, retryMs[attemptIndex]);
                return;
            } else {
                await handleError([
                    "Your assessment could not be saved. Please submit your response again.",
                ]);
                return;
            }
        }
        if (!mskScore?.percentage && newMskScore?.percentage) {
            setSendTrackEventFirstScore(true);
        }
        // error handling
        if (newMskScore == null) {
            await handleError([
                "Your assessment was not saved properly. Please submit your response again.",
            ]);
            return;
        }
        if (newMskScore.errors != null && newMskScore.errors.length >= 1) {
            await handleError(
                newMskScore.errors,
                "Your assessment had errors. Please try submitting your response again."
            );
            return;
        }

        // handle changes
        if (!state.user || !state.user.id) {
            return
        }
        UserMskFocusArticlesUtil.handleFocusChanges(
            state.user.id,
            newMskScore
        );
        setMskScore(newMskScore);
        setIsLoading(false);
        setErrors([]);
    };

    // NOTE: this throws warnings in the tests for some reason, but works functionally
    const handleError = async (
        errors: string[],
        instructions?: string
    ): Promise<void> => {
        if (errors == null || errors.length === 0) {
            setErrors([]);
            return;
        }

        setScreenSettings(
            await AssessmentUtils.moveToPreviousPage(screenSettings)
        );
        setErrorIntro(instructions);
        setErrors(errors);
        setIsLoading(false);
    };

    const getToggleLoadingNextGroup = (settings: AssessmentSettings) => {
        let toggleLoadingNextGroup = false;
        const isLastSlideInGroup = AssessmentUtils.isLastSlide(settings);
        if (isLastSlideInGroup) {
            const group = settings.slideGroups[settings.currentGroupIndex];
            toggleLoadingNextGroup = group.nextGroupIdDeterminedByApi === true;
        }
        return toggleLoadingNextGroup;
    };

    const getNextPage = async () => {
        const toggleLoadingNextGroup =
            getToggleLoadingNextGroup(screenSettings);
        if (toggleLoadingNextGroup) {
            setLoadingNextGroup(true);
        }
        setScreenSettings(await AssessmentUtils.moveToNextPage(screenSettings));
        if (toggleLoadingNextGroup) {
            setLoadingNextGroup(false);
        }
    };

    const recordAnswer = async (selectedAnswer: string, question: Question) => {
        let settings = AssessmentUtils.recordAnswer(
            screenSettings,
            question,
            selectedAnswer
        );

        if (
            slide?.slideType === SlideType.Question &&
            slide.questions != null
        ) {
            let autoSubmit = false;
            slide.questions.forEach((question) => {
                if (AssessmentUtils.autoSubmitQuestion(question)) {
                    autoSubmit = true;
                }
            });
            if (autoSubmit) {
                const toggleLoadingNextGroup =
                    getToggleLoadingNextGroup(settings);
                if (toggleLoadingNextGroup) {
                    setLoadingNextGroup(true);
                }
                settings = await AssessmentUtils.moveToNextPage(settings);
                if (toggleLoadingNextGroup) {
                    setLoadingNextGroup(false);
                }
            }
        }

        setScreenSettings(settings);
    };

    const submit = async () => {
        const queryParams = new URLSearchParams(window.location.search);

        setIsLoading(true);
        setLifestyleScore(mskScore?.lifestyleScore);
        // already submitted
        setErrors(mskScore?.errors ?? []);
        if (mskScore?.errors && mskScore.errors.length) {
            setIsLoading(false);
        }
        if (queryParams.get("retake") === "true") {
            setLastActivityCompletedDate(
                new Date(),
                ActivityType.FullMskRetake
            );
            const userActivity: UserActivity = {
                date: DateTime.now().toISODate(),
                type: ActivityType.FullMskRetake,
                userId: state.user?.id ?? ""
            }
            await UserActivityService.add(userActivity, state.user)
        }
        else {
            if (!state.temp.baselineAssessment) {
                const userActivity: UserActivity = {
                    date: DateTime.now().toISODate(),
                    type: ActivityType.WebLifestyleAssessment,
                    userId: state.user?.id ?? ""
                }

                await UserActivityService.add(userActivity, state.user)
                setLastActivityCompletedDate(
                    new Date(),
                    ActivityType.LifestyleAssessment
                );
            }
            setState((state) => ({
                ...state, ...{ temp: null }
            }));
        }


        // TODO : refactor into zustand
        // await TodayService.deleteToday(globalState.currentIdentity?.id!);
        // TodayService.deleteTodayCache();

        if (sendTrackEventFirstScore) {
            trackEvent('first-time-assessments-completed');
        }
        // If the user hasn't completed their last assessment
        if (mskScore?.percentage == null) {
            history.push("/wellness-assessment");
        }
        // Else, the user has completed their last assessment
        else {
            // if they have administeredRetake we need to set it to false
            if (state.user && state.user.administeredRetake) {
                const stateUser = state.user
                stateUser.administeredRetake = false
                // updating FB user
                await UserService.update(stateUser);
                // update state
                // Set the state to prepare everything for logging in.
                setState((state) => ({
                    ...state, ...{ user: stateUser }
                }));
            }
            // We are creating a CE instead of checking if one exists because after a retest, there must be a new CE routine
            // Logic in today is to create CE and ECE
            // checking mskScore to ensure correctives and articles should be created.
            console.log("starting", state.user)
            console.log("mskScore in lifestyle screen", mskScore)
            if (state.user && state.user.id && state.user.symmioAccess === SymmioAccessType.AppLicense && mskScore.lifestyleScore?.percentage != null && mskScore.focusAreas != null &&
                mskScore.focusAreas.length >= 1) {
                let wellnessProgram: WellnessProgram = {
                    createdAtLocal: DateTime.now().toISO(),
                    lengthInDays: 0,
                    focusAreas: undefined,
                    movementFocusAreas: undefined,
                    currentDay: 1,
                    correctiveExerciseId: undefined,
                    focusArticleId: undefined,
                    isCurrent: true,
                    userId: state.user.id,
                    currentProgress: [],
                    numOfAdherenceAccountedFor: 0,
                }
                // Before we create correctives/articles, we must set any isCurrent = true to false
                const currentCorrectiveExercises = await UserCorrectiveExercisesService.getCurrentByUserId(state.user.id)
                const currentExtraCorrectiveExercises = await UserCorrectiveExercisesService.getCurrentExtraByUserId(state.user.id)
                const currentArticles = await UserMskFocusArticlesService.getAllCurrentByUserId(state.user.id)
                console.log(currentCorrectiveExercises);
                console.log(currentExtraCorrectiveExercises);
                console.log(currentArticles);
                if (currentCorrectiveExercises && currentCorrectiveExercises.length > 0) {
                    for (const correctiveExercise of currentCorrectiveExercises) {
                        correctiveExercise.isCurrent = false;
                        await UserCorrectiveExercisesService.update(correctiveExercise);
                    }
                }
                if (currentExtraCorrectiveExercises && currentExtraCorrectiveExercises.length > 0) {
                    for (const correctiveExercise of currentExtraCorrectiveExercises) {
                        correctiveExercise.isCurrent = false;
                        await UserCorrectiveExercisesService.update(correctiveExercise);
                    }
                }
                if (currentArticles && currentArticles.length > 0) {
                    for (const article of currentArticles) {
                        article.isCurrent = false;
                        await UserMskFocusArticlesService.update(article);
                    }
                }
                // Create correctiveExercise
                // For some reason old team made it so if there is no extra corrective, still create a corrective of 100 days and no routine
                const ce = await CorrectiveExerciseUtils._createUserCorrectiveExercise(state.user.id);
                const ece = await CorrectiveExerciseUtils._createUserExtraCorrectiveExercise(state.user.id);
                const articles = await ArticleUtils.createUserMskArticles(state.user.id, mskScore)

                if (ece.hasRoutine) {
                    wellnessProgram.correctiveExerciseId = ece.userCorrectiveExercise.id;
                    wellnessProgram.lengthInDays = ece.userCorrectiveExercise.routineDays;
                } else if (ce.hasRoutine) {
                    wellnessProgram.correctiveExerciseId = ce.userCorrectiveExercise.id;
                    wellnessProgram.lengthInDays = ce.userCorrectiveExercise.routineDays;
                }
                // Create article
                if (articles && articles.id) {
                    wellnessProgram.focusArticleId = articles.id;
                    let longestArticleLength = 0
                    for (let i = 0; i < articles.focusStatuses.length; i++) {
                        let currentCheckArticleLength = 0
                        if (articles.focusStatuses.length === 1) {
                            // User currently cannot get movement_health without a corrective. And the amount of articles
                            // the user gets from movement_health or movement_health_breathing cannot be > length from corrective
                            // Therefore we ignore checking if length is more since it becomes complicated to determine exact 
                            // movement_health articles
                            // IF in the future, movement_health article length can be greater than corrective, we need to change
                            // THIS logic to factor in potential length of numOfArticles from movement_health
                            if (articles.focusStatuses[i].mskFocusAreaGroupId !== GroupIds.MOVEMENT_HEALTH && articles.focusStatuses[i].mskFocusAreaGroupId !== GroupIds.MOVEMENT_HEALTH_BREATHING) {
                                currentCheckArticleLength = (i + 1) + (2 * (articles.focusStatuses[i].numArticles - 1))
                            }
                        }
                        else {
                            if (articles.focusStatuses[i].mskFocusAreaGroupId !== GroupIds.MOVEMENT_HEALTH && articles.focusStatuses[i].mskFocusAreaGroupId !== GroupIds.MOVEMENT_HEALTH_BREATHING) {
                                currentCheckArticleLength = (i + 1) + (articles.focusStatuses.length * (articles.focusStatuses[i].numArticles - 1))
                            }
                        }
                        if (currentCheckArticleLength > longestArticleLength) {
                            longestArticleLength = currentCheckArticleLength;
                        }
                    }
                    if (longestArticleLength > wellnessProgram.lengthInDays) {
                        wellnessProgram.lengthInDays = longestArticleLength
                    }
                }
                if (mskScore.focusAreas) {
                    wellnessProgram.focusAreas = mskScore.focusAreas;
                }
                if (mskScore.movementScore && mskScore.movementScore.focusArea) {
                    wellnessProgram.movementFocusAreas = mskScore.movementScore.focusArea
                }
                console.log("?", ce)
                console.log("??", ece);

                // Create wellness Program
                const savedWellnessProgram = await WellnessProgramService.add(wellnessProgram);
                const stateUser = state.user
                stateUser.wellnessProgramId = savedWellnessProgram.id
                setState((state) => ({
                    ...state, ...{ user: stateUser }
                }));
                await UserService.update(state.user);
            }

            console.log("ending")
            handleGenerateReport(true);
            history.push("/wellness-assessment");
        }
    };

    const currentGroup =
        screenSettings?.slideGroups == null
            ? null
            : screenSettings.slideGroups[screenSettings.currentGroupIndex];
    const slide = AssessmentUtils.getSlide(screenSettings);
    const actionsModifier =
        slide?.slideType === SlideType.Video ? "-video" : "";

    let hasContinueButton =
        AssessmentUtils.hasData(screenSettings) &&
        (!AssessmentUtils.isLastGroup(screenSettings) ||
            !AssessmentUtils.isLastSlide(screenSettings));

    if (
        hasContinueButton &&
        slide &&
        slide?.slideType === SlideType.Question &&
        slide.questions != null
    ) {
        slide.questions.forEach((question) => {
            if (AssessmentUtils.autoSubmitQuestion(question)) {
                hasContinueButton = false;
            }
        });
    }

    let continueButtonText = "Continue";
    if (hasContinueButton) {
        switch (slide?.slideType) {
            case SlideType.AssessmentIntro:
            case SlideType.Tests:
                continueButtonText = "Let's Do It";
                break;
            case SlideType.GroupIntro:
                continueButtonText = "Start";
                break;
            case SlideType.Video:
                const questions = AssessmentUtils.getSlide(
                    props.screenSettings
                )?.questions;
                if (
                    questions != null &&
                    questions.length >= 1 &&
                    questions[0].questionId === "BREATHING_BREATH"
                ) {
                    continueButtonText = "Go to Timer";
                } else {
                    continueButtonText = "Enter Results";
                }
                break;
        }
    }

    const hasSubmitButtton =
        AssessmentUtils.isLastGroup(screenSettings) &&
        AssessmentUtils.isLastSlide(screenSettings);

    const noProgressBarSlideTypes = [
        SlideType.AssessmentIntro,
        SlideType.Complete,
        SlideType.GroupIntro,
        SlideType.Tests,
        SlideType.Tips,
    ];
    const hasProgressBar =
        slide == null ||
        noProgressBarSlideTypes.indexOf(slide.slideType) === -1;

    const collapseScrollTypes = [
        AnswerFormats.INTEGER,
        AnswerFormats.TIME,
        AnswerFormats.DECIMAL,
    ];
    const shouldCollapseScroll =
        slide?.slideType === SlideType.Question &&
        slide.questions &&
        slide.questions.filter(
            (q) => collapseScrollTypes.indexOf(q.answerFormat) > -1
        ).length > 0;

    const conditionalGroups = [
        GroupIds.BEHAVIORAL_QUESTIONNAIRE,
        GroupIds.SLEEP_PSQI,
        GroupIds.NUTRITION_QUESTIONNAIRE,
    ];
    const questionGroupNames = AssessmentUtils.getAssessmentGroups(
        screenSettings
    )
        .filter(
            (group) =>
                conditionalGroups.indexOf(
                    GroupIds[group.groupId as keyof typeof GroupIds]
                ) === -1
        )
        .map((group) => group.groupName!);

    const handleGenerateReport = async (emailResults: boolean) => {
        if (!globalState.currentIdentity?.id) return;

        // we need to get the user from the firebase in case their organization
        // was switched (in the portal) and no longer matches the data in the app.
        const userInFirebase = await UserService.get(globalState.currentIdentity?.id);

        if (!userInFirebase || !userInFirebase.organizationId) return;

        const latestOrgId = userInFirebase.organizationId;

        //setIsLoading(true);

        const date = DateTime.now().toISODate();
        const report: Report = {
            dateEnd: date,
            dateStart: date,
            emailResults: emailResults,
            organizationId: latestOrgId,
            status: "scheduled",
            type: ReportType.Wellness,
            userId: globalState.currentIdentity!.id!,
        };

        const identity = globalState.currentIdentity;

        if (identity.organizationId !== latestOrgId) {
            identity.organizationId = latestOrgId;
        }

        const scheduledReport = await ReportService.save(
            report,
            identity
        );

        if (!scheduledReport.id) {
            // setIsLoading(false);
            // setReportError(
            //     "Report was not saved successfully. Please try again."
            // );
            return;
        }

        if (scheduledReport.emailResults === true) {
            // setShowReportEmailConfirmation(true);
            // setIsLoading(false);
            return;
        }

        // const reportUnsubscribe = ReportService.getSnapshot(
        //     scheduledReport.id,
        //     (r: Report) => {
        //         if (r.status === "complete" && r.url) {
        //             setIsLoading(false);
        //             reportUnsubscribe();

        //             //Loads in the System browser
        //             window.open(r.url, "_self");
        //         }
        //         if (r.status === "error") {
        //             setReportError(r.errorMessage);
        //             setIsLoading(false);
        //             reportUnsubscribe();
        //         }
        //     }
        // );
    };

    return (
        <div
            className={`${COMPONENT_CLASS} -lifestyle ${shouldCollapseScroll ? "-collapse-scroll" : ""
                }`}>
            <LoaderAssessments
                isLoading={isLoading || isLoadingNextGroup} />
            {
                // if
                !isLoading && currentGroup != null && (
                    <div
                        className={`${COMPONENT_CLASS}__wrapper ${slide?.slideType === SlideType.GroupIntro
                            ? "-intro"
                            : ""
                            }`}>
                        {
                            // if
                            AssessmentUtils.hasData(screenSettings) &&
                            lifestyleScore == null && (
                                <div
                                    className={`${COMPONENT_CLASS}__content ${hasContinueButton ||
                                        hasSubmitButtton
                                        ? "has-button"
                                        : ""
                                        }`}>
                                    <AppModal
                                        isOpen={errors?.length >= 1}
                                        onDismiss={() => {
                                            setErrors([]);
                                        }}>
                                        <div
                                            style={{
                                                padding: "10px",
                                                color: "#990000",
                                            }}>
                                            {
                                                // if
                                                errorIntro != null && (
                                                    <div>
                                                        {errorIntro}
                                                        <br />
                                                        <br />
                                                    </div>
                                                )
                                            }
                                            <div>{errors.join("; ")}</div>
                                        </div>
                                    </AppModal>
                                    {
                                        // if
                                        slide != null && (
                                            <ScreenSlide
                                                hasProgressBar={hasProgressBar}
                                                screenSettings={screenSettings}
                                                questionGroupNames={
                                                    questionGroupNames
                                                }
                                                recordedAnswers={AssessmentUtils.getAllResponses(
                                                    screenSettings
                                                )}
                                                recordAnswer={recordAnswer}
                                                page={slide}
                                                errors={errors}
                                                currentProgress={AssessmentUtils.getProcessCurrent(
                                                    screenSettings
                                                )}
                                                totalProgress={AssessmentUtils.getProcessTotal(
                                                    screenSettings
                                                )}
                                                handleOptionalQuestion={() =>
                                                    getNextPage()
                                                }
                                                settingsOnBack={props.settingsOnBack}
                                                isActionsWithContinue={hasContinueButton}
                                                skipButton={slide?.questions &&
                                                    slide.questions.length >
                                                    0 &&
                                                    slide.questions[0]
                                                        .optional === true}
                                                onSkip={getNextPage}
                                                onBack={props.settingsOnBack}
                                                onSubmit={getNextPage}
                                                submitButtonText={continueButtonText}
                                                headerText="Lifestyle Assessment"
                                            />
                                        )
                                    }
                                    <div
                                        className={`${COMPONENT_CLASS}__actions ${actionsModifier}`}>
                                        {/* {
                                            // if
                                            hasContinueButton && (
                                                <BaseCardLayoutActions backButton={true} onBack={props.settingsOnBack} submitButton={true} onSubmit={getNextPage} submitButtonText={continueButtonText} skipButton={slide?.questions &&
                                                    slide.questions.length >
                                                    0 &&
                                                    slide.questions[0]
                                                        .optional === true}
                                                    onSkip={() => {
                                                        getNextPage();
                                                    }}></BaseCardLayoutActions>

                                            )
                                        } */}
                                        {
                                            // if
                                            hasSubmitButtton && (
                                                <div className={`c-screen-slide__actions`}>
                                                    <BaseCardLayoutActions backButton={true} onBack={props.settingsOnBack} submitButton={true} onSubmit={submit} submitButtonText={errors.length === 0
                                                        ? "Submit Results"
                                                        : "Resubmit"}></BaseCardLayoutActions>
                                                </div>
                                                // <IonButton
                                                //     color="primary"
                                                //     expand="block"
                                                //     onClick={() =>
                                                //         submit()
                                                //     }>
                                                //     Submit Results
                                                // </IonButton>
                                            )
                                        }

                                        {/* {
                                            // if
                                            slide?.questions &&
                                            slide.questions.length >
                                            0 &&
                                            slide.questions[0]
                                                .optional === true && (
                                                <IonButton
                                                    size="small"
                                                    fill="clear"
                                                    className={`${COMPONENT_CLASS}__optional-text`}
                                                    onClick={() =>
                                                        getNextPage()
                                                    }>
                                                    Skip, I'll fill it
                                                    in later.
                                                </IonButton>
                                            )
                                        } */}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div>
    );
};

export default LifestyleScreen;
