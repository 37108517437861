import { ListOptions } from "./interfaces/list-options";

export const MovementFocusOptions: ListOptions[] = [
    {
        value: "TOE_TOUCH",
        label: "Toe Touch",
        shortLabel: "T. Touch",
    }, {
        value: "SQUAT",
        label: "Squat",
        shortLabel: "Squat",
    }, {
        value: "SPINE_CLEARING",
        label: "Spine Clearing",
        shortLabel: "Spine C."
    }, {
        value: "SHOULDER_MOBILITY",
        label: "Shoulder Mobility",
        shortLabel: "Shoulder M."
    }, {
        value: "SHOULDER_CLEARING",
        label: "Shoulder Clearing",
        shortLabel: "Shoulder C."
    }, {
        value: "ROTATION",
        label: "Rotation",
        shortLabel: "Rotation"
    }, {
        value: "BALANCE",
        label: "Balance",
        shortLabel: "Balance"
    }
];

export const MovementFocusOptionsWithBreathing: ListOptions[] = [
    {
        value: "TOE_TOUCH",
        label: "Toe Touch",
        shortLabel: "T. Touch",
    }, {
        value: "SQUAT",
        label: "Squat",
        shortLabel: "Squat",
    }, {
        value: "SPINE_CLEARING",
        label: "Spine Clearing",
        shortLabel: "Spine C."
    }, {
        value: "SHOULDER_MOBILITY",
        label: "Shoulder Mobility",
        shortLabel: "Shoulder M."
    }, {
        value: "SHOULDER_CLEARING",
        label: "Shoulder Clearing",
        shortLabel: "Shoulder C."
    }, {
        value: "ROTATION",
        label: "Rotation",
        shortLabel: "Rotation"
    }, {
        value: "BALANCE",
        label: "Balance",
        shortLabel: "Balance"
    },
    {
        value: "TOE_TOUCH_BREATHING",
        label: "Toe Touch + Breathing",
        shortLabel: "T. Touch + B.",
    }, {
        value: "SQUAT_BREATHING",
        label: "Squat + Breathing",
        shortLabel: "Squat + B.",
    }, {
        value: "SPINE_CLEARING_BREATHING",
        label: "Spine Clearing + Breathing",
        shortLabel: "Spine C. + B."
    }, {
        value: "SHOULDER_MOBILITY_BREATHING",
        label: "Shoulder Mobility + Breathing",
        shortLabel: "Shoulder M. + B."
    }, {
        value: "SHOULDER_CLEARING_BREATHING",
        label: "Shoulder Clearing + Breathing",
        shortLabel: "Shoulder C. + B."
    }, {
        value: "ROTATION_BREATHING",
        label: "Rotation + Breathing",
        shortLabel: "Rotation + B."
    }, {
        value: "BALANCE_BREATHING",
        label: "Balance + Breathing",
        shortLabel: "Balance + B."
    }
];