import { Redirect, useHistory } from "react-router";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import logo from "../../assets/images/symmio-logos/symmio-logo-horizontal-light.png";
import { TextTypeInput } from "../../components/forms";
import { useForm } from "react-hook-form";
import { EmailTypeInput } from "../../components/forms/email-type-input";
import getFirebase from "../../utilities/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import OrganizationService from "../../utilities/services/organization-service";
import UserService from "../../utilities/services/user-service";
import { User } from "../../models/interfaces/user";
import { OrganizationStatus } from "../../models/enumerations/organization-status";
import { useEffect, useState } from "react";
import { LoginError } from "../../models/enumerations/login-error";
import LoginModal from "../../components/login/login-modal/login-modal";
import ReactivationRequestsService from "../../utilities/services/reactivation-request-service";
import TrialUpgradeRequestsService from "../../utilities/services/trial-upgrade-request-service";
import { ReactivationRequest } from "../../models/interfaces/reactivation-request";
import { TrialUpgradeRequest } from "../../models/interfaces/trial-upgrade-request";
import { Timestamp } from "firebase/firestore";
import RequestModal from "../../components/login/request-modal/request-modal";
import ResetPassword from "../../components/login/reset-password/reset-password";
import BaseCardLayout from "../../components/base-card-layout/base-card-layout";
import { enqueueSnackbar } from "notistack";
import { OrganizationType } from "../../models/enumerations/organization-type";
import UserUtil from "../../utilities/user-util";
import UserRoles from "../../models/user-roles";
import { UserStatus } from "../../models/enumerations/user-status";
import { Button } from "../../components/button/button";

export interface LoginPageProps {
    hideInactivityPrompt: Function,
    showInactivityPrompt: boolean
}

const COMPONENT_CLASS = "p-login";

const LoginPage: React.FunctionComponent<LoginPageProps> = (props) => {
    const params = new URLSearchParams(window.location.search);
    const redirect = params.get("redirect");

    const {
        hideInactivityPrompt,
    } = props;
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm();

    const { state } = useAuthState();
    const { auth } = getFirebase();
    const [passedAdditionalValidation, setPassedAdditionalValidation] = useState(false);
    const [loginError, setLoginError] = useState<LoginError>();
    const [organizationName, setOrganizationName] = useState<string>("");
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [loginData, setLoginData] = useState<any>();
    const [requestExpired, setRequestExpired] = useState<boolean>();
    const [requestModalOpen, setRequestModalOpen] = useState<boolean>(false);
    const [resettingPassword, setResettingPassword] = useState<boolean>(false);
    const [, setPasswordResetMessage] = useState<boolean>(false);
    const [userLoggingIn, setUserLoggingIn] = useState<User>();
    const history = useHistory();

    useEffect(() => {
        if (state.authenticated) {
            if (redirect === "highRisk") {
                history.replace("/wellness-assessment?highRisk=open");
            }
            else if (redirect === "AmX1xDfz") {
                history.replace("/settings/upgrade?couponId=AmX1xDfz")
            }
            else if (redirect === "rOTkCm3F") {
                history.replace("/settings/upgrade?couponId=rOTkCm3F");
            }
            else {
                history.replace("/");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmit = async (data: any) => {
        setLoginData(data);
        signInWithEmailAndPassword(auth, data.email, data.password)
            .then((result: any) => {
                runAdditionalValidation(data.email);
                hideInactivityPrompt();
            })
            .catch((error: any) => {
                handleError(error);
            });
    }

    const runAdditionalValidation = async (email: string) => {
        // get orgId
        let users: User[] = await UserService.getBy([{
            field: "email",
            operator: "==",
            value: email,
        }]);
        let user = users[0];
        setUserLoggingIn(user);
        // because the email address typed into the field will be compared to
        // what is in the database, we need to make sure it's all lower case.
        // The reason we are checking without setting the email to lower case is
        // that I am not 100% certain that there are no email addresses in the
        // database that have uppercase characters.
        if (!user) {
            users = await UserService.getBy([{
                field: "email",
                operator: "==",
                value: email.toLowerCase(),
            }]);
            user = users[0];
        }
        // This checker is for users that have an org, but the id is missing for some reason
        // This is related to the weird signUpFlow issue where we couldn't diagnose how a minority of users don't get assigned
        // their respective org Id
        if (user?.organizationId == null) {
            // check if an organization has an accountHolderId
            const organization = await OrganizationService.getBy([{
                field: "accountHolderId",
                operator: "==",
                value: user.id
            }])
            if (organization.length === 1) {
                console.log("setting org id")
                user.organizationId = organization[0].id
                await UserService.save(user);
            }
        }

        // if org id is still null, they just dont have an org.
        if (user?.organizationId == null) {
            console.warn("User organization was not set.");
            setError("formErrors", {
                type: "custom",
                message: "User Organization was not set."
            });
            auth.signOut();
            return;
        }

        if (user?.status === UserStatus.Disabled) {
            console.warn("This user has been deactivated.");
            setError("formErrors", {
                type: "custom",
                message: "This user has been deactivated."
            });
            auth.signOut();
            return;
        }

        // get org
        console.log(user);
        const organization = await OrganizationService.get(user.organizationId);
        setOrganizationName(organization?.name ?? "");
        if (organization == null) {
            console.warn("User organization was not found.");
            setError("formErrors", {
                type: "custom",
                message: "Organization was not found."
            });
            auth.signOut();

            return;
        }

        // ensure the user's organization's trial period has not expired
        if (organization.expiration && parseInt(organization.status.toString()) === OrganizationType.Trial) {
            const orgExpirationMillis = new Date(organization.expiration).valueOf();
            const loginMillis = Timestamp.now().toMillis();
            if (orgExpirationMillis <= loginMillis) {
                const err = user?.roles && (user.roles[0] === "admin")
                    ? LoginError.AdminTrialExpired
                    : LoginError.TrialExpired;
                setLoginError(err);
                setModalOpen(true);
                auth.signOut();
                return;
            }
        }

        // ensure not deactivated (must be a string)
        const orgIsDeactivated = organization.status.toString() === OrganizationStatus.Deactivated.toString();
        const isAccountHolder = UserUtil.isAccountHolder(user);

        if (orgIsDeactivated) {
            let err: LoginError;

            // If the Org is deactivated we check if it was deactivated due to
            // cancelled subscription (failed payment) - allow only account holder to log in
            if (isAccountHolder && (organization.showDeactivatedAccountPlanModal || organization.showDeactivatedAccountAddOnModal)) {
                err = LoginError.SubscriptionPaymentFailed;
            }
            else {
                err = user?.roles && (user.roles[0] === "admin")
                    ? LoginError.AdminDeactivated
                    : LoginError.Deactivated;

                auth.signOut();
            }

            setLoginError(err);
            setModalOpen(true);
            return;
        }

        setPassedAdditionalValidation(true);
    }

    const handleError = (error: any) => {
        if (error?.code == null) {
            setError("formErrors", {
                type: "custom",
                message: "Something went wrong. We are working on fixing the problem. Please try again later."
            });
        }

        switch (error.code) {
            case "auth/user-not-found":
                setError("formErrors", {
                    type: "custom",
                    message: "Incorrect Username or Password."
                });
                break;
            case "auth/wrong-password":
                setError("formErrors", {
                    type: "custom",
                    message: "Incorrect Username or Password."
                });
                break;
            case "auth/too-many-requests":
                setError("formErrors", {
                    type: "custom",
                    message: "Too many log-ins have been attempted on this account. Apologies for any inconvenience."
                });
                break;
            case "auth/internal-error":
                setError("formErrors", {
                    type: "custom",
                    message: "An error occurred. Please try again later."
                });
                break;
            default:
                setError("formErrors", {
                    type: "custom",
                    message: "Something went wrong. We are working on fixing the problem. Please try again later."
                });
                break;
        }
    }

    if (state.authenticated && passedAdditionalValidation) {
        // Assessment Check

        if (userLoggingIn?.leadLinkId && userLoggingIn.organizationId === process.env.REACT_APP_LEAD_CAPTURE_ORG_ID) {
            return <Redirect push to="/lc-assessment-results" />
        }
        else if (userLoggingIn?.roles && userLoggingIn.roles.find((role) => role === UserRoles.CLIENT_ID)) {
            if (!userLoggingIn.isAccountSetup) {
                return <Redirect push to="/setup" />
            }
            if (redirect === "highRisk") {
                return <Redirect push to="/wellness-assessment?highRisk=open" />
            }
            return <Redirect push to="/wellness-assessment" />
        }
        else {
            if (redirect === "highRisk") {
                return <Redirect push to="/wellness-assessment?highRisk=open" />
            }
            else if (redirect === "AmX1xDfz") {
                return <Redirect push to="/settings/upgrade?couponId=AmX1xDfz" />
            }
            else if (redirect === "rOTkCm3F") {
                return <Redirect push to="/settings/upgrade?couponId=rOTkCm3F" />
            }
            return <Redirect push to="/dashboard" />
        }

    }

    /**
     * Determines whether to create a new Reactivation Request based on the
     * timestamp on the last submitted Reactivation Request if it exists.
     * 
     * @param {ReactivationRequest} request The reactivation request to be created.
     */
    const handleReactivationRequest = async (request: ReactivationRequest) => {
        const prevRequest: ReactivationRequest[] | null | void = await ReactivationRequestsService.getBy(
            [{ field: 'emailAddress', operator: '==', value: request.emailAddress }],
            [{ field: 'created', direction: 'desc' }]
        );
        // request expired represents the fact that 24 hours has past since the
        // last request and so it has "expired", allowing a new request to be
        // made
        const prevRequestExpired = prevRequest[0].created?.seconds
            && prevRequest[0].created?.seconds! - Timestamp.now().seconds >= 86400;
        if (prevRequestExpired) {
            setRequestExpired(true);
            ReactivationRequestsService.add(request, state.user)
                .finally(() => auth.signOut());
        } else {
            setRequestExpired(false);
        }
    };

    /**
     * Determines whether to create a new Trial Upgrade Request based on the
     * timestamp on the last submitted Trial Upgrade Request if it exists.
     * 
     * @param {TrialUpgradeRequest} request The trial upgrade request to be created.
     */
    const handleTrialUpgradeRequest = async (request: TrialUpgradeRequest) => {
        const prevRequest: ReactivationRequest[] | null | void = await TrialUpgradeRequestsService.getBy(
            [{ field: 'emailAddress', operator: '==', value: request.emailAddress }],
            [{ field: 'created', direction: 'desc' }]
        );
        // request expired represents the fact that 24 hours has past since the
        // last request and so it has "expired", allowing a new request to be
        // made
        const prevRequestExpired = prevRequest[0].created?.seconds
            && prevRequest[0].created?.seconds! - Timestamp.now().seconds >= 86400;
        if (prevRequestExpired) {
            setRequestExpired(true);
            TrialUpgradeRequestsService.add(request, state.user)
                .finally(() => auth.signOut());
        } else {
            setRequestExpired(false);
        }
    };

    /**
     * Handles the closing of the login error modal.
     * 
     * @param {boolean} buttonClicked Whether or not the modal was closed using
     * a button (true) or by clicking outside the modal / pressing Esc (false).
     */
    const handleLoginErrorModalClose = async (buttonClicked: boolean) => {
        setModalOpen(false);
        if (buttonClicked) {
            let reactivationRequest: ReactivationRequest;
            let trialUpgradeRequest: TrialUpgradeRequest;
            // we need to sign the user back in temporarily to create a new request in the firestore
            signInWithEmailAndPassword(auth, loginData.email, loginData.password)
                .then(() => {
                    if (loginError === LoginError.AdminDeactivated) {
                        reactivationRequest = {
                            orgName: organizationName,
                        };
                        reactivationRequest.firstName = state.user?.firstName ?? '';
                        reactivationRequest.lastName = state.user?.lastName ?? '';
                        reactivationRequest.emailAddress = state.user?.email ?? 'no email on record';
                    }
                    if (loginError === LoginError.AdminTrialExpired) {
                        trialUpgradeRequest = {
                            orgName: organizationName,
                        };
                        trialUpgradeRequest.firstName = state.user?.firstName ?? '';
                        trialUpgradeRequest.lastName = state.user?.lastName ?? '';
                        trialUpgradeRequest.emailAddress = state.user?.email ?? 'no email on record';
                    }
                })
                .catch((error: any) => {
                    handleError(error);
                })
                .finally(() => {
                    // note: the user will be signed out in the handler methods below
                    if (reactivationRequest) {
                        handleReactivationRequest(reactivationRequest);
                        setRequestModalOpen(true);
                    } else if (trialUpgradeRequest) {
                        handleTrialUpgradeRequest(trialUpgradeRequest);
                        setRequestModalOpen(true);
                    } else {
                        // if no new request is made, sign the user out
                        auth.signOut()
                    }
                });
        }
    }

    const handleRequestConfirmationModalClose = () => {
        setRequestModalOpen(false);
    };

    // const handleOnRecentlyInactiveClosed = () => {
    //     hideInactivityPrompt();
    // }

    const handlePasswordReset = () => {
        setResettingPassword(false);
        setPasswordResetMessage(true);
        enqueueSnackbar("Password reset email sent!", { variant: "toast", width: "450px" });
    }

    const handleCancelPasswordReset = () => {
        setResettingPassword(false);
    }

    if (resettingPassword) {
        return (
            <ResetPassword onReset={handlePasswordReset} onCancelReset={handleCancelPasswordReset} />
        );
    }

    return (
        <BaseCardLayout cardColor="#121622">
            <div className="flex justify-center">
                <div className={`${COMPONENT_CLASS}`}>
                    <img src={logo} alt="Logo" />
                    <form className={"login-form"}
                        onSubmit={handleSubmit(onSubmit)}>
                        <EmailTypeInput
                            hideLabel
                            id="email"
                            label="Email"
                            registerHook={register}
                            errorState={errors.email}
                            style={{ backgroundColor: "#121622", border: "2px solid #D0D0D3", color: "#FFFFFF" }}
                        />
                        <div style={{ height: '13px' }} />
                        <TextTypeInput
                            hideLabel
                            id="password"
                            label="Password"
                            type="password"
                            registerHook={register}
                            registerOptions={{ required: "Enter your password" }}
                            errorState={errors.password}
                            style={{ backgroundColor: "#121622", border: "2px solid #D0D0D3", color: "#FFFFFF" }}
                        />
                        <div {...register("formErrors")}>
                            {errors.formErrors &&
                                <span className="error-style" role="alert">
                                    {`${errors.formErrors.message}`}
                                </span>
                            }
                        </div>
                        <p className={'forgot-password'} onClick={() => { setResettingPassword(true) }} style={{ color: "#FFFFFF" }}>I forgot my password.</p>
                        <div className={'flex justify-center'}>
                            <Button
                                buttonType="submit"
                                buttonStyle="white"
                                buttonText="Login"
                                inputClassName="login-button" />
                        </div>
                        {
                            (loginError) && (
                                <>
                                    <LoginModal
                                        open={modalOpen}
                                        onClose={handleLoginErrorModalClose}
                                        error={loginError}
                                        organization={organizationName}
                                    />
                                    {
                                        requestExpired != null && (
                                            <RequestModal
                                                error={loginError}
                                                open={requestModalOpen}
                                                onClose={handleRequestConfirmationModalClose}
                                                newRequest={requestExpired}
                                            />
                                        )
                                    }
                                </>
                            )
                        }
                    </form>
                    {/* {showInactivityPrompt && <InfoPrompt onClose={handleOnRecentlyInactiveClosed} message={"You have been logged out due to inactivity"} />}
                    {passwordResetMessage && <InfoPrompt onClose={() => { setPasswordResetMessage(false) }} message={"Password reset email sent"} />} */}
                </div>
            </div>
        </BaseCardLayout >
    );
};

export default LoginPage;
