import { ActivityType } from "../../models/enumerations/activity-type";
import FirestoreCondition from "../../models/interfaces/firestore-condition";
import FirestoreOrder from "../../models/interfaces/firestore-order";
import UserActivity from "../../models/interfaces/user-activity";
import FirestoreService from "./firestore-service";

const COLLECTION_NAME = "userActivity";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

/**
 * Add a new userActivity to the userActivitys collection
 * @param {User} userActivity - The userActivity that is being added to the collection
 * @param {firebase.UserActivity} currentUser - The user that is logged into the application
 * @returns {Promise<UserActivity>} A promise to the newly added userActivity
 */
const add = async (
    userActivity: UserActivity,
    currentUser: any | null = null
) => {
    return FirestoreService.add<UserActivity>(
        COLLECTION_NAME,
        userActivity,
        currentUser
    );
};

/**
 * Delete a userActivity collection by the Id
 * @param {string} id - The Id of the userActivity being deleted
 */
const deleteById = async (id: string) => {
    FirestoreService.deleteById(COLLECTION_NAME, id);
};

/**
 * Delete a user collection by the Id
 * @param {string} userId - The Id of the user being deleted
 */
const deleteByUser = async (userId: string) => {
    const conditions: FirestoreCondition[] = [
        {
            field: "userId",
            operator: "==",
            value: userId,
        },
    ];

    const records = await FirestoreService.getBy(COLLECTION_NAME, conditions);
    records.forEach((record) => {
        FirestoreService.deleteById(COLLECTION_NAME, record.id!);
    });
};

/**
 * Find the specific userActivity by the id
 * @param {string} id - The Id of the userActivity that we are retrieving
 * @returns {Promise<UserActivity>} A promise for the userActivity we are retrieving
 */
const get = async (id: string) => {
    return FirestoreService.get<UserActivity>(COLLECTION_NAME, id);
};

const getBy = async (
    conditions: FirestoreCondition[],
    order: FirestoreOrder[] = [],
    limit?: number
) => {
    return FirestoreService.getBy<UserActivity>(
        COLLECTION_NAME,
        conditions,
        order,
        limit
    );
};

const getLatestRetake = async (
    userId: string
) => {
    return getBy(
        [
            {
                field: "userId",
                operator: "==",
                value: userId,
            },

            {
                field: "type",
                operator: "in",
                value: [ActivityType.FullMskRetake,ActivityType.FullMskRetakePortal],
            },
        ],
        [
            {
                field: "created",
                direction: "desc"
            }
        ],
        10
    );
};

/**
 * Get all of the userActivitys stored in the database
 * @returns {Promise<UserActivity[]>} A promise for the collection of userActivitys
 */
const getAll = async () => {
    return FirestoreService.getAll<UserActivity>(COLLECTION_NAME);
};

/**
 * Get a snapshot of the specific userActivity to see when it changes
 * @param {string} id - Id of the userActivity document
 * @param {Function} listener - Function to listen to the changes to the document
 */
const getSnapshot = (id: string, listener: Function) => {
    return FirestoreService.getSnapshot<UserActivity>(
        COLLECTION_NAME,
        id,
        listener
    );
};

/**
 * Update the specified userActivity stored in the database
 * @param {UserActivity} userActivity - The userActivity that is being updated
 * @param {firebase.User} currentUser - The user that is logged into the application
 * @returns {Promise<UserActivity>} A promise for the userActivity that is being updated
 */
const update = async (
    userActivity: UserActivity,
    currentUser: any | null = null
) => {
    return FirestoreService.update<UserActivity>(
        COLLECTION_NAME,
        userActivity,
        currentUser
    );
};

// #endregion Service Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const UserActivityService = {
    add,
    deleteById,
    deleteByUser,
    get,
    getAll,
    getBy,
    getLatestRetake,
    getSnapshot,
    update,
};

export default UserActivityService;

// #endregion Exports
