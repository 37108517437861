export enum CardTypes {
    Article = "Article",
    Error = "Error",
    Flow = "Flow",
    Rest = "Rest",
    Intro = "Intro",
    Lifestyle = "Lifestyle",
    Movement = "Movement",
    Nutrition = "Nutrition",
    Video = "Video",
    /**
     * A special card type to denote that the card module is loading data and
     * not yet ready
     */
    Loading = "Loading",
    Routine = "Routine",
    /**
     * Displays a generic card for use with ResourceUrl with routing
     */
    Generic = "Generic",
    Retake = "Retake",
}
