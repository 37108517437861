import React, { useEffect, useRef } from "react";
import { Line } from 'react-chartjs-2';
import { CategoryScale, Chart, LineElement, LinearScale, PointElement, Title } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';

// const COMPONENT_CLASS = "c-line-chart";

export interface LineChartJSProps {
    data?: number[];
    title?: string;
    labels?: string[];
    height?: string;
    width?: string;
    responsive?: boolean;
    maintainAspectRatio?: boolean;
    borderRadius?: string;
    backgroundColor?: string;
    horizontalScrolling?: boolean;
    pointRadius?: number;
    pointBorderWidth?: number;
    borderWidth?: number;
    pointFontSize?: number;
    fontFamily?: string;
    pointLabelOffset?: number;
    devicePixelRatio?: number;
    xLabelFontSize?: number;
    paddingLeft?: number;
    paddingRight?: number;
    paddingTop?: number;
    paddingBottom?: number;
    lineHeight?: number;
    showToolTips?: boolean;
}

const LineChartJS: React.FC<LineChartJSProps> = (props) => {
    const { showToolTips, lineHeight, paddingBottom, paddingLeft, paddingRight, paddingTop, xLabelFontSize, pointLabelOffset, fontFamily, pointFontSize, horizontalScrolling, pointRadius, pointBorderWidth, borderWidth, labels, title, data, responsive, maintainAspectRatio, borderRadius, backgroundColor, height, width } = props
    const minimumPointDistance = 110; // Minimum distance between points in pixels
    const containerRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.scrollLeft = container.scrollWidth; // Scroll to the far right
        }
    }, []);
    Chart.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        ChartDataLabels,
        // plugin,
    );
    const chartOptions = {
        responsive: responsive ?? true,
        maintainAspectRatio: maintainAspectRatio ?? true,
        devicePixelRatio: devicePixelRatio ? devicePixelRatio : 1,
        layout: {
            // adding padding since custom scale is on the chart itself. We forced it's positioning to be 25 px below chart
            // but then need more padding to accommodate the new scale
            padding: {
                left: paddingLeft ?? 0,
                right: paddingRight ?? 0,
                top: paddingTop ?? 0,
                bottom: paddingBottom ?? 0,
            }
        },
        scales: {
            x: {
                ticks: {
                    display: true,
                    font: {
                        weight: 'bold' as const,
                        size: xLabelFontSize,
                        ...(fontFamily && { family: fontFamily }),
                        // family: 'pragmatica-extended' as const,
                        ...(lineHeight && { lineHeight: lineHeight })
                    },
                    color: "#12162280",
                },
                border: {
                    display: false,
                },
                grid: {
                    display: false,
                    border: {
                        display: false
                    }
                    // color: (context: any) => {
                    //     if (context.index === 0) {
                    //         return '';
                    //     }
                    //     else {
                    //         return 'rbga(102,102,102,0.2)'
                    //     }
                    // }
                }
            },
            y: {
                beginAtZero: true,
                suggestedMax: 100,
                // on the entire chart, there is a border on the Y axis, this removes the initial (0) scale line border
                border: {
                    display: false
                },
                // this removed the labels
                ticks: {
                    display: false,
                },
                grid: {
                    // Grid lines shoot out of the Y axis including the 0th index (0), we must remove border and this grid line at the
                    // 0th index to remove initial scale line border completely
                    color: (context: any) => {
                        const value = context.tick.value;
                        return typeof value !== 'undefined' && value === 0 ? 'transparent' : "#12162215";
                    },
                    lineWidth: 2 // increased the line width of those coming out of the Y axis
                }
            }
        },
        plugins: {
            tooltip: {
                enabled: showToolTips ?? false,
                // callbacks: {
                //     label: function (tooltipItem: any) {
                //         return `${dataPoints[tooltipItem.dataIndex]}`;
                //     },
                // },
            },
            datalabels: {
                anchor: 'end' as const,
                align: 'top' as const,
                offset: pointLabelOffset,
                labels: {
                    title: {
                        font: {
                            weight: 'bold' as const,
                            size: pointFontSize,
                            ...(fontFamily && { family: fontFamily })
                            // family: 'pragmatica-extended' as const
                        },
                        color: '#121622'
                    }
                }
            },
            title: {
                display: !horizontalScrolling,
                text: title,
                align: 'start' as const,
                padding: {
                    top: 10,
                    bottom: 15
                },
                font: {
                    weight: 'bold' as const,
                    size: 10,
                    // family: 'pragmatica-extended' as const,
                },
                color: "#121622"
            }
        }

    }

    const numberOfDataPoints = labels ? labels.length : 0;
    const totalWidth = numberOfDataPoints * minimumPointDistance;

    const chartData = {
        labels,
        datasets: [
            {
                label: 'Dataset 1',
                data: data,
                borderColor: '#43B5F8', // border color of line
                backgroundColor: 'rgb(18, 22, 34)', // background color of data point
                pointBorderColor: '#F7F9FC', // point border color
                pointRadius: pointRadius ?? 4, // size of data point
                pointBorderWidth: pointBorderWidth ?? 2, // border width for data points
                borderWidth: borderWidth ?? 3 //border width for line
            }
        ]
    }


    // const [data, setData] = useState<HTMLDivElement>();

    useEffect(() => {
    }, []);

    return (
        <>
            {
                horizontalScrolling
                    ?
                    <>
                        <h3>{title}</h3>
                        <div style={styles.wrapper}>
                            <div style={styles.chartWrapper} ref={containerRef}>
                                <div style={{ ...styles.chartContainer, width: `${totalWidth}px` }}>
                                    <Line options={chartOptions} data={chartData} className="rounded-2xl" />
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <div style={{ width: width, height: height, borderRadius: `${borderRadius ?? 0}`, backgroundColor: backgroundColor }}>
                        <Line options={chartOptions} data={chartData} />
                    </div>
            }
        </>

    );
};
const styles = {
    wrapper: {
        width: '100%',
        height: '224px',
        display: 'flex',
        // justifyContent: 'center', // Center the graph horizontally
        alignItems: 'center', // Center the graph vertically
    },
    chartWrapper: {
        width: '100%', // Adjust this based on how much of the screen you want the chart to take up
        height: '100%',
        overflowX: "auto" as const, // Enable horizontal scrolling within the chart area
        overflowY: "hidden" as const, // Prevent vertical scrolling
    },
    chartContainer: {
        height: '100%',
        minWidth: '100%', // Ensure it fits initially but grows dynamically
    }
};



export default LineChartJS;