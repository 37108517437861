import { DateTime } from "luxon";
import { MskHealthRiskOptions } from "../models/msk-health-risk";
import UserRoles from "../models/user-roles";
import { OrganizationSubscription } from "../models/interfaces/organization-subscription";
import { ReactComponent as BehavioralHealthIcon } from "../assets/icons/solid/behavioral-health.svg";
import { ReactComponent as MovementIcon } from "../assets/icons/outline/movement.svg";
import { ReactComponent as MovementBreathingIcon } from "../assets/icons/solid/movement-and-breathing.svg";
import { ReactComponent as PhysicalActivityIcon } from "../assets/icons/solid/activity-level.svg";
import { ReactComponent as BodyCompIcon } from "../assets/icons/solid/body-comp.svg";
import { ReactComponent as BreathingIcon } from "../assets/icons/solid/breathing-qual.svg";
import { ReactComponent as InjuryHistoryIcon } from "../assets/icons/solid/injury-history.svg";
import { ReactComponent as NutritionIcon } from "../assets/icons/solid/nutrition-aware.svg";
import { ReactComponent as SleepIcon } from "../assets/icons/solid/sleep-well.svg";
import { ReactComponent as RoadToWellnessIcon } from "../assets/icons/solid/road-to-wellness.svg";
import { ReactComponent as CrossIcon } from "../assets/icons/general-icons/close.svg";
import { ReactComponent as CheckIcon } from "../assets/icons/solid/check.svg";
import { FocusArea } from "../models/enumerations/focus-area";
import moment from "moment";
import Badge from "../components/badge/badge";
import { ReportAnswer } from "../models/enumerations/report-answer";
import { PainSeverity } from "../models/enumerations/pain-severity";

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

function capitalizeFirstLetter(input: string): string {
    if (input == null || input.length === 0) {
        return input;
    }
    return input.charAt(0).toUpperCase() + input.slice(1);
}

function getAge(dob: string | undefined): number | undefined {
    if (dob == null || dob.length === 0) {
        return undefined;
    }

    const years = DateTime.fromISO(dob).diffNow("years").years;
    return Math.floor(Math.abs(years)); // convert -68.814 to 68
}

function getRoleNameFromValue(input: string): string {
    if (input == null || input.length === 0) {
        return "";
    }
    return UserRoles.ALL_ROLES.find((r) => r.value === input)?.label || "";
}

function getHealthRiskName(riskCategory: string | undefined): string | undefined {
    return MskHealthRiskOptions.find((x) => x.value === riskCategory)?.label;
}

function getHealthRiskNames(riskCategories: string[]): string[] {
    if (riskCategories == null || riskCategories.length === 0) {
        return [];
    }
    return riskCategories.map((x) => {
        return getHealthRiskName(x)!;
    });
}

function parseQueryString(querystring: string) {
    // parse query string
    const params = new URLSearchParams(querystring);

    const obj: any = {};

    // iterate over all keys
    for (const key of params.keys()) {
        if (params.getAll(key).length > 1) {
            obj[key] = params.getAll(key);
        } else {
            obj[key] = params.get(key);
        }
    }

    return obj;
}

function validateEmailAddress(address: string): boolean {
    const regex = new RegExp(
        "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
        "i"
    );
    return regex.test(address);
}

// Used only for dates where the timezone doesn't matter (e.g. get only the yyyy-mm-dd part of a birth date)
// Since it doesn't account for the time zone offset DO NOT use in situations where the time matters (e.g. subscription start date/time)
function convertToISO(dateString: string) {
    const [month, day, year] = dateString.split('/');
    const date = new Date(Number(year), Number(month) - 1, Number(day));
    return date.toISOString().split('T')[0];
}

function getDiscountBadgeText(orgSub: OrganizationSubscription | undefined | null) {
    let badgeText = "";

    if (orgSub && orgSub.mySymmioMonthlyPrice !== undefined && orgSub.mySymmioYearlyPrice !== undefined) {
        const percentageDiff = (orgSub.mySymmioMonthlyPrice * 12 - orgSub.mySymmioYearlyPrice) / (orgSub.mySymmioMonthlyPrice * 12) * 100;

        if (percentageDiff >= 1) {
            const percentageDiffStr = percentageDiff.toFixed(0);
            badgeText = `Save ${percentageDiffStr}%`;
        }
    }

    return badgeText;
}

function getPainBadge(painAnswer: string | boolean) {
    if (painAnswer && painAnswer !== ReportAnswer.A && painAnswer !== "NONE") {
        let text = "";
        switch (painAnswer) {
            case true:
                text = "PAIN";
                break;
            case ReportAnswer.B:
            case PainSeverity.MILD:
                text = "MILD PAIN";
                break;
            case ReportAnswer.C:
            case PainSeverity.MODERATE:
                text = "MODERATE PAIN";
                break;
            case ReportAnswer.D:
            case PainSeverity.SEVERE:
                text = "SEVERE PAIN";
                break;
        }
        return (
            <Badge text={text} bgColor="#FFE9E5" textColor="#E7514F" />
        );
    }
}

function hasPain(painAnswer: string | boolean | undefined | null) {
    let hasPain = false;

    if (painAnswer && painAnswer !== ReportAnswer.A && painAnswer !== "NONE") {
        switch (painAnswer) {
            case true:
                hasPain = true;
                break;
            case ReportAnswer.B:
            case PainSeverity.MILD:
                hasPain = true;
                break;
            case ReportAnswer.C:
            case PainSeverity.MODERATE:
                hasPain = true;
                break;
            case ReportAnswer.D:
            case PainSeverity.SEVERE:
                hasPain = true;
                break;
        }
    }

    return hasPain;
}

function getFocusAreaData(focusArea: string, movementBreathingFullName: boolean = true) {
    let focusAreaData = {
        icon: <></>,
        title: "",
    }

    switch (focusArea) {
        case FocusArea.MOVEMENT_HEALTH:
            focusAreaData.icon = (
                <div style={{ backgroundColor: "#ADDFFD" }
                }>
                    <span>
                        <MovementIcon style={{ backgroundColor: "#ADDFFD" }} />
                    </span>
                </div>
            );
            focusAreaData.title = "Movement Health";
            break;
        case FocusArea.BEHAVIORAL_HEALTH:
            focusAreaData.icon = (
                <div style={{ backgroundColor: "#8BE3CE" }
                }>
                    <span>
                        <BehavioralHealthIcon style={{ backgroundColor: "#8BE3CE" }} />
                    </span>
                </div>);
            focusAreaData.title = "Behavioral Health";
            break;
        case FocusArea.INJURY_HISTORY:
            focusAreaData.icon = (
                <div style={{ backgroundColor: "#FFA998" }}>
                    <span>
                        <InjuryHistoryIcon style={{ backgroundColor: "#FFA998" }} />
                    </span>
                </div>
            );
            focusAreaData.title = "Injury History";
            break;
        case FocusArea.BREATHING:
            focusAreaData.icon = (
                <div style={{ backgroundColor: "#ADDFFD" }}>
                    <span>
                        <BreathingIcon style={{ backgroundColor: "#ADDFFD" }} />
                    </span>
                </div>
            );
            focusAreaData.title = "Breathing Quality";
            break;
        case FocusArea.SLEEP:
            focusAreaData.icon = (
                <div style={{ backgroundColor: "#CEBAF8" }}>
                    <span>
                        <SleepIcon style={{ backgroundColor: "#CEBAF8" }} />
                    </span>
                </div>
            );
            focusAreaData.title = "Sleep Wellness";
            break;
        case FocusArea.PHYSICAL_ACTIVITY:
            focusAreaData.icon = (
                <div style={{ backgroundColor: "#FFA998" }}>
                    <span>
                        <PhysicalActivityIcon style={{ backgroundColor: "#FFA998" }} />
                    </span>
                </div>
            );
            focusAreaData.title = "Physical Activity";
            break;
        case FocusArea.BODY_COMPOSITION:
            focusAreaData.icon = (
                <div style={{ backgroundColor: "#FFC79C" }}>
                    <span>
                        <BodyCompIcon style={{ backgroundColor: "#FFC79C" }} />
                    </span>
                </div>
            );
            focusAreaData.title = "Body Composition";
            break;
        case FocusArea.NUTRITION:
            focusAreaData.icon = (
                <div style={{ backgroundColor: "#8BE3CE" }}>
                    <span>
                        <NutritionIcon style={{ backgroundColor: "#8BE3CE" }} />
                    </span>
                </div>
            );
            focusAreaData.title = "Nutritional Awareness";
            break;
        case FocusArea.MOVEMENT_HEALTH_BREATHING:
            // focusAreaData.icon = (
            //     <span className="flex flex-row" >
            //         <div className="" style={{ backgroundColor: "#ADDFFD", padding: "0.3rem", display: "flex" }}>
            //             <MovementIcon style={{ backgroundColor: "#ADDFFD" }} />
            //         </div>
            //         < div className="" style={{ backgroundColor: "#ADDFFD", padding: "0.3rem", display: "flex", marginLeft: "-0.3rem" }}>
            //             <BreathingIcon style={{ backgroundColor: "#ADDFFD" }} />
            //         </div>
            //     </span>
            // );
            focusAreaData.icon = (
                <div style={{ backgroundColor: "#ADDFFD" }}>
                    <span>
                        <MovementBreathingIcon style={{ backgroundColor: "#ADDFFD" }} />
                    </span>
                </div>
            );
            focusAreaData.title = movementBreathingFullName ? "Movement Health & Breathing" : "Movement & Breathing";
            break;
        case FocusArea.ROAD_TO_WELLNESS:
            focusAreaData.icon = (
                <div style={{ backgroundColor: "#ADDFFD" }}>
                    <span>
                        <RoadToWellnessIcon style={{ backgroundColor: "#ADDFFD" }} />
                    </span>
                </div>
            );
            focusAreaData.title = "Road to Wellness";
            break;
    }

    return focusAreaData;
}

function getAssessmentBreakdownCell(text: string, showCheckMark?: boolean, width: string = "10px", height: string = "10px", fontSize: string = "12px", fontWeight: string = "300") {
    if (showCheckMark === undefined) {
        return;
    }
    const iconStyle = { minWidth: width, maxWidth: width, minHeight: height, maxHeight: height }

    return (
        <span className="flex flex-row items-center gap-1">
            <span>
                {showCheckMark
                    ? <CheckIcon style={iconStyle} />
                    : <CrossIcon style={iconStyle} />}
            </span>
            <span style={{ fontSize: fontSize, fontWeight: fontWeight }} dangerouslySetInnerHTML={{ __html: text }}></span>
        </span>
    );
}

function getGreenOrRedColor(score: number | undefined, goalScore: number) {
    if (score !== undefined && score >= goalScore) {
        return "bg-green-medium";
    }
    else {
        return "bg-red-medium";
    }
}

function getHowManyDaysAgo(date: Date) {
    const todayDate = new Date();

    const dateInMs = date.getTime();
    const todayDateInMs = todayDate.getTime();

    const differenceBtwDates = todayDateInMs - dateInMs;

    const aDayInMs = 24 * 60 * 60 * 1000;

    const daysDiff = Math.round(differenceBtwDates / aDayInMs);

    let daysDiffString = "";

    if (daysDiff === 0) {
        daysDiffString = "Today";
    }
    else if (daysDiff === 1) {
        daysDiffString = "1 day ago";
    }
    else {
        daysDiffString = `${daysDiff} days ago`;
    }

    return daysDiffString;
}

function getHowManyDaysAgoUsingMoment(dateStr: string | undefined) {
    if (!dateStr) {
        return "";
    }

    const now = moment();
    const lastAssessmentDate = moment(dateStr)
    const daysDiff = now.diff(lastAssessmentDate, "days");

    let daysDiffString = "";

    if (daysDiff === 0) {
        daysDiffString = "Today";
    }
    else if (daysDiff === 1) {
        daysDiffString = "1 day ago";
    }
    else {
        daysDiffString = `${daysDiff} days ago`;
    }

    return daysDiffString;
}

function formatDate(date: Date | null | undefined): string {
    if (!date || date.getFullYear() <= 1900) {
        return "Not Available";
    }
    return `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`;
}

// previously, the FMS API would use a method capable of passing parameters
// like "&w=800", but now it points directly to the image. So having url
// params is breaking the image urls and causing them not to display in app.
// This method removes "&w=800" from the image url so that it displays
// properly.
function cleanImageUrl(url: string | undefined) {
    if (typeof (url) === 'string' && url.endsWith("&w=800")) {
        return url.replace("&w=800", "");
    }
    return url;
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export const Utils = {
    capitalizeFirstLetter,
    getAge,
    getHealthRiskName,
    getHealthRiskNames,
    getRoleNameFromValue,
    parseQueryString,
    validateEmailAddress,
    convertToISO,
    getDiscountBadgeText,
    getPainBadge,
    hasPain,
    getFocusAreaData,
    getAssessmentBreakdownCell,
    getGreenOrRedColor,
    getHowManyDaysAgo,
    getHowManyDaysAgoUsingMoment,
    formatDate,
    cleanImageUrl,
};

// #endregion Exports
