import UserArticle from '../../models/interfaces/articles/user-article';
import ArticleUtils from '../../utilities/article-utils';
import { Utils } from '../../utilities/utils';
import { Modal } from '../modal/modal';
import { ReactComponent as ClockIcon } from "../../assets/icons/outline/icon_clock.svg";
import VideoService from '../../utilities/services/video-service/video-service';
import { VideoSize } from '../../models/enumerations/video-size';
import { useEffect, useState } from 'react';
import { ControlBar, Player } from 'video-react';
import FirebaseStorage from '../../utilities/firebase-storage';

const COMPONENT_CLASS = "c-article-modal";

interface ArticleModalProps {
    userArticle: UserArticle;
    openArticleModal: boolean;
    setOpenArticleModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ArticleModal: React.FC<ArticleModalProps> = (props) => {
    const {
        userArticle,
        openArticleModal,
        setOpenArticleModal,
    } = props;

    const storageUtil = new FirebaseStorage();
    const [videoUrl, setVideoUrl] = useState<string>();

    useEffect(() => {
        const getVideoUrl = async () => {
            if (userArticle.article?.videoId) {
                const videoUrl = await VideoService.getUrlFromId({
                    videoId: userArticle.article.videoId,
                    videoSize: VideoSize.LANDSCAPE_406P,
                });
                setVideoUrl(videoUrl);
            }
        }

        if (userArticle.article?.videoId && !videoUrl) {
            getVideoUrl();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userArticle.article?.videoId])

    return (
        <Modal
            isOpen={openArticleModal}
            isLoading={false}
            onClose={() => {
                setOpenArticleModal(false);
                setVideoUrl("");
            }}
        >
            <div className={COMPONENT_CLASS}>
                <div className='flex flex-wrap gap-6'>
                    <div className={`${COMPONENT_CLASS}__header-image-wrapper`}>
                        <img src={
                            userArticle.article.originalImageUrl ? Utils.cleanImageUrl(userArticle.article.originalImageUrl)
                                : userArticle.article.imageUrl ? storageUtil.getMediaUrl(userArticle.article.imageUrl) : undefined} alt={userArticle.article.title} />
                    </div>

                    <div className='flex flex-col grow gap-4 justify-center'>
                        <div className='flex justify-between items-center gap-4'>
                            <div>
                                <h1 style={{ margin: "0" }}>
                                    {userArticle.article.title}
                                </h1>
                            </div>

                            <div className={`${COMPONENT_CLASS}__focus-icon`}>
                                {Utils.getFocusAreaData(userArticle.groupId).icon}
                            </div>
                        </div>

                        <div className='flex justify-between items-center gap-4'>
                            {userArticle.article.author &&
                                <div className='text-xs font-medium'>
                                    By: {userArticle.article.author}
                                </div>
                            }
                            <div className='flex items-center gap-1 text-xs font-light'>
                                <div><ClockIcon /></div>
                                {userArticle.article.readTimeMin || ArticleUtils.getArticleDuration(userArticle.article)} min {userArticle.article.videoId ? "watch" : "read"}
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                {videoUrl &&
                    <div className='xl:w-1/2 w-full overflow-hidden' style={{ maxWidth: "430px", borderRadius: "10px" }}>
                        <Player aspectRatio="16:9">
                            <source src={videoUrl} type="video/mp4" />
                            <source src={videoUrl} type="video/ogg" />
                            <ControlBar />
                        </Player>
                    </div>
                }

                {userArticle.article.content &&
                    <div className='overflow-auto text-xs font-light' style={{ maxHeight: "50vh" }}>
                        <div className={`${COMPONENT_CLASS}__content-wrapper`} style={{ lineHeight: "18px" }} dangerouslySetInnerHTML={{ __html: userArticle.article.content }}></div>
                    </div>
                }
            </div>
        </Modal>
    );
};

export default ArticleModal;