/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { MskScore } from "../../models/interfaces/msk-score";
import { User } from "../../models/interfaces/user";
import AssessmentResponse from "../../models/interfaces/assessment-response.entity";
import MskScoreService from "../../utilities/services/msk-score-service";
import moment from "moment";
import { GroupIds } from "../../models/enumerations/group-ids";
import { QuestionIds } from "../../models/enumerations/question-ids";
import UserProfileAssessmentHeader from "./user-profile-assessment-header";
import NonLinearLineChart from "../charts/non-linear-line-chart";
import LineChartJS from "../charts/line-chart-js";
import RiskChartVertical from "../charts/risk-chart-vertical";

const COMPONENT_CLASS = "c-user-profile-assessments";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfileBodyCompositionProps {
    mskScore?: MskScore;
    user: User;
    assessment?: any;
    assessmentResponses: AssessmentResponse[];
    allMskScores: MskScore[];
    setAllMskScores: Function;
    setSpecificAssessment: Function;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfileBodyComposition: React.FC<UserProfileBodyCompositionProps> = (props: UserProfileBodyCompositionProps) => {
    const { setSpecificAssessment, mskScore, assessment, allMskScores, setAllMskScores, user, assessmentResponses } = props;
    const [bodyCompositionHistory, setBodyCompositionHistory] = useState<{ x: string, y: number }[]>();
    const [bmiHistory, setBmiHistory] = useState<{ x: string, y: number }[]>();
    const [hipToWaistRatioHistory, setHipToWaistRatioHistory] = useState<{ x: string, y: number }[]>();
    const isFemale = mskScore?.averageScores?.isFemale;

    let breakpointsHipToWaist = [];

    if (isFemale) {
        breakpointsHipToWaist = [
            { min: 0.86, max: 0.89, label: "high\nhealth risk", bgColor: "#FFA998", subLabel: "0.86 or Above" },
            { min: 0.80, max: 0.85, label: "moderate\nhealth risk", bgColor: "#FFC79C", subLabel: "0.80 - 0.85" },
            { min: 0, max: 0.79, label: "low\nhealth risk", bgColor: "#8BE3CE", subLabel: "Below 0.79" },
        ];
    }
    else {
        breakpointsHipToWaist = [
            { min: 1.0, max: 1.3, label: "high\nhealth risk", bgColor: "#FFA998", subLabel: "1.0 or Above" },
            { min: 0.90, max: 0.99, label: "moderate\nhealth risk", bgColor: "#FFC79C", subLabel: "0.90 - 0.99" },
            { min: 0, max: 0.89, label: "low\nhealth risk", bgColor: "#8BE3CE", subLabel: "Below 0.89" },
        ];
    }

    // const [isLoading, setIsLoading] = useState<boolean>(true)

    // useEffect(() => {
    //     setIsLoading(false);
    // }, [])
    useEffect(() => {
        const getAllMskScores = async () => {
            if (!allMskScores) {
                const mskScores = await MskScoreService.getBy([{
                    field: "userId",
                    operator: "==",
                    value: user.id
                }], [{
                    field: "created",
                    direction: "asc"
                }])

                setAllMskScores(mskScores);
            }
        }
        getAllMskScores();
    }, [])
    useEffect(() => {
        const getHistory = () => {
            if (allMskScores) {
                let tempBodyCompositionHistory = []
                let tempBmiHistory = []
                let tempHipToWaistRatioHistory = [];

                for (const mskScore of allMskScores) {
                    tempBodyCompositionHistory.push({
                        x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                        y: mskScore.lifestyleScore?.bodyCompositionScore?.percentage ?? 0
                    })
                    tempBmiHistory.push({
                        x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                        y: mskScore.lifestyleScore?.bodyCompositionScore?.bmi ?? 0
                    })

                    if (mskScore.lifestyleScore?.bodyCompositionScore?.hipToWaistRatio) {
                        tempHipToWaistRatioHistory.push({
                            x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                            y: mskScore.lifestyleScore?.bodyCompositionScore?.hipToWaistRatio
                        })
                    }
                }
                if (tempHipToWaistRatioHistory.length === 0) {
                    console.log("responses");
                    const hipToWaist = getbodyCompositionResponses()
                    if (hipToWaist) {
                        tempHipToWaistRatioHistory.push(hipToWaist);
                    }
                    console.log(hipToWaist)
                }
                console.log(tempHipToWaistRatioHistory);
                setBodyCompositionHistory(tempBodyCompositionHistory);
                setBmiHistory(tempBmiHistory);
                setHipToWaistRatioHistory(tempHipToWaistRatioHistory);
            }
        }
        getHistory();
    }, [allMskScores])

    const getbodyCompositionResponses = () => {
        if (!assessmentResponses) {
            return;
        }
        const bodyCompResponse: any = assessmentResponses.find((assessmentResponse: any) => assessmentResponse[0].groupId === GroupIds.BODY_COMPOSITION)
        if (bodyCompResponse.length > 0) {
            // find waist/hip question
            const hips = bodyCompResponse && bodyCompResponse.length > 0 ? bodyCompResponse[0].responses.find((response: any) =>
                response.questionId === QuestionIds.BODY_COMPOSITION_HIPS) : undefined
            const waist = bodyCompResponse && bodyCompResponse.length > 0 ? bodyCompResponse[0].responses.find((response: any) =>
                response.questionId === QuestionIds.BODY_COMPOSITION_WAIST) : undefined
            if (hips?.answerResponse && waist?.answerResponse) {
                const hipToWaistRatioCalc = parseFloat(waist.answerResponse) / parseFloat(hips.answerResponse)
                return {
                    x: moment(bodyCompResponse[0] && bodyCompResponse[0].created?._seconds ? bodyCompResponse[0].created._seconds * 1000 : NaN).format("MMM DD, YY"),
                    y: hipToWaistRatioCalc
                }
            }
        }
    }
    return (
        <>
            <div>
                <UserProfileAssessmentHeader
                    assessment={assessment}
                    mskScore={mskScore}
                    setSpecificAssessment={setSpecificAssessment}
                    mainTextHeader="How We Screen Body Composition"
                    mainText="We use two key metrics: <strong>Body Mass Index (BMI)</strong> and <strong>hip-to-waist ratio</strong>. These measurements are compared against established healthy norms for your age, sex, height, and weight. A score of 100% indicates that your measurements fall within optimal ranges, supporting overall health and wellness."
                    whyImportantText="Excess body weight, particularly fat rather than muscle, places stress on the body, mimicking premature aging. It increases the risk of chronic conditions like osteoarthritis, type 2 diabetes, and heart disease, while reducing joint health and overall quality of life. Poor body composition is a leading contributor to musculoskeletal and metabolic disorders, making it essential to address for long-term wellness."
                />
            </div>

            {bodyCompositionHistory && (
                <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper my-5`}>
                    <LineChartJS
                        data={bodyCompositionHistory.map(history => history.y)}
                        title="Body Composition Score History"
                        labels={bodyCompositionHistory.map(history => history.x)}
                        height={"200px"}
                        horizontalScrolling={true}
                        pointRadius={7}
                        pointBorderWidth={3}
                        borderWidth={5}
                        pointFontSize={16}
                        fontFamily={'pragmatica-extended'}
                        pointLabelOffset={0}
                        xLabelFontSize={14}
                        paddingTop={30}
                        paddingBottom={20}
                        maintainAspectRatio={false}
                    />
                </div>
            )}

            {bmiHistory && bmiHistory.length > 0 && (
                <div className="flex gap-5 mb-5">
                    <div className={`${COMPONENT_CLASS}__score-section-card min-w-72 max-w-72`}>
                        <h3 className="flex items-center justify-center mb-4">
                            BMI (Body Mass Index)
                        </h3>
                        <div className="w-full h-64">
                            <RiskChartVertical
                                score={bmiHistory[bmiHistory.length - 1].y}
                                breakpoints={[
                                    { min: 30, max: 39, label: "Obesity", bgColor: "#FFA998", subLabel: "30.0 and above" },
                                    { min: 25, max: 29.9, label: "Overweight", bgColor: "#FFC79C", subLabel: "25.0 - 29.9" },
                                    { min: 18.5, max: 24.9, label: "Healthy Weight", bgColor: "#8BE3CE", subLabel: "18.5 - 24.9" },
                                    { min: 0, max: 18.4, label: "Underweight", bgColor: "#ADDFFD", subLabel: "Below 18.5" },
                                ]}
                                decimals={true}
                            />
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper grow`}>
                        <NonLinearLineChart
                            dataPoints={bmiHistory.map(history => history.y)}
                            labels={bmiHistory.map(history => history.x)}
                            title="BMI (Body Mass Index) History"
                            sectionDetails={{
                                sections: 4,
                                rangeOne: { start: 0, end: 18.4 },
                                rangeTwo: { start: 18.5, end: 24.9 },
                                rangeThree: { start: 25, end: 29.9 },
                                rangeFour: { start: 30, end: 60 },
                                backgroundColorOrder: { first: "#ADDFFD", second: "#8BE3CE", third: "#FFE9CC", fourth: "#FFA998" },
                                dataPointColorOrder: { first: "#43B5F8", second: "#00DFBC", third: "#FFC79C", fourth: "#E7514F", },
                                yAxisLabels: { first: "UNDERWEIGHT", second: "HEALTHY", third: "OVERWEIGHT", fourth: "OBESITY" },
                                decimals: true
                            }}
                            toolTipLabel={true}
                        />
                    </div>
                </div>

            )}

            {hipToWaistRatioHistory && hipToWaistRatioHistory.length > 0 && mskScore && (
                <div className="flex gap-5">
                    <div className={`${COMPONENT_CLASS}__score-section-card min-w-72 max-w-72`}>
                        <h3 className="flex items-center justify-center mb-4">
                            Hip-to-Waist Ratio
                        </h3>
                        <div className="w-full h-64">
                            <RiskChartVertical
                                score={hipToWaistRatioHistory[hipToWaistRatioHistory.length - 1].y}
                                breakpoints={breakpointsHipToWaist}
                                decimals={true}
                            />
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper grow`}>
                        <NonLinearLineChart
                            dataPoints={hipToWaistRatioHistory.map(history => history.y)}
                            labels={hipToWaistRatioHistory.map(history => history.x)}
                            title="Hip-to-Waist Ratio History"
                            sectionDetails={{
                                sections: 3,
                                rangeOne: { start: 0, end: mskScore.averageScores?.isFemale ? 0.79 : 0.89 },
                                rangeTwo: { start: mskScore.averageScores?.isFemale ? 0.80 : 0.90, end: mskScore.averageScores?.isFemale ? 0.85 : 0.99 },
                                rangeThree: { start: mskScore.averageScores?.isFemale ? 0.86 : 1.0, end: 1.5 },
                                backgroundColorOrder: { first: "#8BE3CE", second: "#FFE9CC", third: "#FFA998" },
                                dataPointColorOrder: { first: "#00DFBC", second: "#FFC79C", third: "#E7514F" },
                                yAxisLabels: { first: "LOW", second: "MODERATE", third: "HIGH" },
                                decimals: true,
                            }}
                            toolTipLabel={true}
                        />
                    </div>
                </div>

            )}
        </>
    );
}

export default UserProfileBodyComposition;
