import symmioAcademyImg from "../../assets/images/resources/welcome-academy.jpg"
import marketingMaterialsImg from "../../assets/images/resources/marketing-materials.jpg"
import helpCenterImg from "../../assets/images/resources/help-center.jpg"
// import wellnessCourseImg from "../../assets/images/resources/wellness-course.jpg"
// import wellnessCourseLockedImg from "../../assets/images/resources/wellness-course-locked.png"
import { useAuthState } from "../../utilities/contexts/auth-state-context";
// import { PlanType } from "../../models/enumerations/plan-type"

const COMPONENT_CLASS = "p-resources";

const ResourcesPage: React.FC = () => {
    const { state } = useAuthState();

    const academyLink = state.user ? `https://functionalmovement.com/education/OnlineCourseSymmio/81?userId=${state.user.id}` : 'https://functionalmovement.com';

    // const getWellnessCourseCard = (image: string) => {
    //     return (
    //         <div className={`${COMPONENT_CLASS}__card`}>
    //             <div className={`${COMPONENT_CLASS}__image-wrapper`}>
    //                 <img src={image} alt="Functional Wellness Course" />
    //             </div>
    //             <div className="p-8">
    //                 <div className="font-bold text-xs uppercase mb-2">
    //                     Functional Wellness Course
    //                 </div>
    //                 <div className="font-light text-sm leading-6">
    //                     Comprehensive 4-hour deep dive designed to equip you and your team for success.
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // };

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <div className={`${COMPONENT_CLASS}__header`}>
                <h1>Resources</h1>
            </div>

            <div className="flex flex-wrap gap-5">
                <a className="max-w-full" href={academyLink} target="_blank" rel="noreferrer">
                    <div className={`${COMPONENT_CLASS}__card`}>
                        <div className={`${COMPONENT_CLASS}__image-wrapper`}>
                            <img src={symmioAcademyImg} alt="Symmio Academy" />
                        </div>
                        <div className="p-8">
                            <div className="font-bold text-xs uppercase mb-2">
                                Symmio Academy
                            </div>
                            <div className="font-light text-sm leading-6">
                                Start the Symmio Academy to get hands-on training & tutorials.
                            </div>
                        </div>
                    </div>
                </a>

                <a className="max-w-full" href="https://www.symmio.com/marketing-resources" target="_blank" rel="noreferrer">
                    <div className={`${COMPONENT_CLASS}__card`}>
                        <div className={`${COMPONENT_CLASS}__image-wrapper`}>
                            <img src={marketingMaterialsImg} alt="Marketing Materials" />
                        </div>
                        <div className="p-8">
                            <div className="font-bold text-xs uppercase mb-2">
                                Marketing Materials
                            </div>
                            <div className="font-light text-sm leading-6">
                                Use logos, product images and flyers to effectively promote Symmio.
                            </div>
                        </div>
                    </div>
                </a>

                <a className="max-w-full" href="https://help.symmio.com/en/" target="_blank" rel="noreferrer">
                    <div className={`${COMPONENT_CLASS}__card`}>
                        <div className={`${COMPONENT_CLASS}__image-wrapper`}>
                            <img src={helpCenterImg} alt="Help Center" />
                        </div>
                        <div className="p-8">
                            <div className="font-bold text-xs uppercase mb-2">
                                Help Center
                            </div>
                            <div className="font-light text-sm leading-6">
                                Visit our help center for helpful articles and FAQ’s.
                            </div>
                        </div>
                    </div>
                </a>

                {/* {state.organization.plan === PlanType[PlanType.Starter]
                    ?
                    getWellnessCourseCard(wellnessCourseLockedImg)
                    :
                    <a className="max-w-full" href="https://www.functionalmovement.com/Store/250/functional_wellness_course" target="_blank" rel="noreferrer">
                        {getWellnessCourseCard(wellnessCourseImg)}
                    </a>
                } */}
            </div>

        </div>
    );
}

export default ResourcesPage;
