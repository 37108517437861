import { ResultLevel } from "../models/enumerations/result-level";
import { IQuestionGroupScore } from "../models/interfaces/question-group-score.interface";


export default class LifestyleQuestionGroupScore implements IQuestionGroupScore {
    score?: number;
    percentage: number = 0;

    /** Question Group that this score is associated to */
    groupId: string = "";

    /** Question Group name that this score is associated to */
    groupName: string = "";

    resultDescription?: string;
    resultLevel?: ResultLevel;

    /*
     * Group that was actually tested if different than groupId
     */
    testedGroupId?: string;

    /** @internal */
    constructor(partial?: Partial<LifestyleQuestionGroupScore>) {
        if (partial != null) {
            Object.assign(this, partial);
        }
    }
}
