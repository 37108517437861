import { UserMskFocusArticles } from "../../../models/interfaces/articles/user-msk-focus-articles";
import FirestoreCondition from "../../../models/interfaces/firestore-condition";
import FirestoreOrder from "../../../models/interfaces/firestore-order";
import FirestoreService from "../firestore-service";

const COLLECTION_NAME = "userMskFocusArticles";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

/**
 * Add a new user articles to the userMskFocusArticles collection
 * @param {UserMskFocusArticles} userMskFocusArticles - The user article status that is being added to the collection
 * @param {firebase.User} currentUser - The user that is logged into the application
 * @returns {Promise<UserMskFocusArticles>} A promise to the newly added corrective exercise
 */
const add = async (
    userMskFocusArticles: UserMskFocusArticles,
    currentUser: any | null = null
) => {
    return FirestoreService.add<UserMskFocusArticles>(
        COLLECTION_NAME,
        userMskFocusArticles,
        currentUser
    );
};

/**
 * Delete a user collection by the Id
 * @param {string} id - The Id of the user being deleted
 */
const deleteByUser = async (userId: string) => {
    const conditions: FirestoreCondition[] = [
        {
            field: "userId",
            operator: "==",
            value: userId,
        },
    ];

    const records = await FirestoreService.getBy(COLLECTION_NAME, conditions);

    records.forEach((record) => {
        FirestoreService.deleteById(COLLECTION_NAME, record.id!);
    });
};

const getBy = async (
    conditions: FirestoreCondition[],
    order: FirestoreOrder[] = [],
    limit?: number
) => {
    return FirestoreService.getBy<UserMskFocusArticles>(
        COLLECTION_NAME,
        conditions,
        order,
        limit
    );
};

/**
 * Update the user corrective exercise
 * @param {UserMskFocusArticles} userMskFocusArticles - The user corrective exercise that is being updated
 * @param {firebase.User} currentUser - The user that is logged into the application
 * @returns {Promise<UserMskFocusArticles>} A promise for the collection of user articles
 */
const update = async (
    userMskFocusArticles: UserMskFocusArticles,
    currentUser: any | null = null
) => {
    return FirestoreService.update<UserMskFocusArticles>(
        COLLECTION_NAME,
        userMskFocusArticles,
        currentUser
    );
};

/**
 * Find the specific user by the id
 * @param {string} id - The Id of the user that we are retrieving
 * @returns {Promise<User>} A promise for the user we are retrieving
 */
const getAllCurrentByUserId = async (userId: string) => {
    const conditions: FirestoreCondition[] = [
        {
            field: "userId",
            operator: "==",
            value: userId,
        },
        {
            field: "isCurrent",
            operator: "==",
            value: true,
        },
    ];

    return FirestoreService.getBy<UserMskFocusArticles>(
        COLLECTION_NAME,
        conditions,
        [
            {
                field: "created",
                direction: "desc",
            },
        ],
    );
};

// #endregion Service Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const UserMskFocusArticlesService = {
    add,
    deleteByUser,
    getBy,
    update,
    getAllCurrentByUserId,
};

export default UserMskFocusArticlesService;

// #endregion Exports
