import { GroupIds } from "../enumerations/group-ids";

export const AssociatedMovementAndBreathing: { [x: string]: GroupIds } = {
    [GroupIds.BALANCE]: GroupIds.BALANCE_BREATHING,
    [GroupIds.ROTATION]: GroupIds.ROTATION_BREATHING,
    [GroupIds.SHOULDER_CLEARING]: GroupIds.SHOULDER_CLEARING_BREATHING,
    [GroupIds.SHOULDER_MOBILITY]: GroupIds.SHOULDER_MOBILITY_BREATHING,
    [GroupIds.SPINE_CLEARING]: GroupIds.SPINE_CLEARING_BREATHING,
    [GroupIds.SQUAT]: GroupIds.SQUAT_BREATHING,
    [GroupIds.TOE_TOUCH]: GroupIds.TOE_TOUCH_BREATHING,
};
