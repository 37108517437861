/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { MskScore } from "../../models/interfaces/msk-score";
import { User } from "../../models/interfaces/user";
import AssessmentResponse from "../../models/interfaces/assessment-response.entity";
import MskScoreService from "../../utilities/services/msk-score-service";
import moment from "moment";
import { GroupIds } from "../../models/enumerations/group-ids";
import { QuestionIds } from "../../models/enumerations/question-ids";
import UserProfileAssessmentHeader from "./user-profile-assessment-header";
import NonLinearLineChart from "../charts/non-linear-line-chart";
import LineChartJS from "../charts/line-chart-js";
import RiskChartVertical from "../charts/risk-chart-vertical";

const COMPONENT_CLASS = "c-user-profile-assessments";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfilePhysicalActivityProps {
    mskScore?: MskScore;
    user: User;
    assessment?: any;
    assessmentResponses: AssessmentResponse[];
    allMskScores: MskScore[];
    setAllMskScores: Function;
    setSpecificAssessment: Function;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfilePhysicalActivity: React.FC<UserProfilePhysicalActivityProps> = (props: UserProfilePhysicalActivityProps) => {
    const { setSpecificAssessment, mskScore, assessment, allMskScores, setAllMskScores, user, assessmentResponses } = props;
    const [physicalActivityHistory, setPhysicalActivityHistory] = useState<{ x: string, y: number }[]>();
    const [moderateActivityHistory, setModerateActivityHistory] = useState<{ x: string, y: number }[]>();
    const [vigorousActivityHistory, setVigorousActivityHistory] = useState<{ x: string, y: number }[]>();
    const [muscleStrengthTrainingHistory, setMuscleStrengthTrainingHistory] = useState<{ x: string, y: number }[]>();
    const [customMuscleStrengthTrainingHistory, setCustomMuscleStrengthTrainingHistory] = useState<{ x: string, y: number }[]>();

    // const [isLoading, setIsLoading] = useState<boolean>(true)

    // useEffect(() => {
    //     setIsLoading(false);
    // }, [])
    useEffect(() => {
        const getAllMskScores = async () => {
            if (!allMskScores) {
                const mskScores = await MskScoreService.getBy([{
                    field: "userId",
                    operator: "==",
                    value: user.id
                }], [{
                    field: "created",
                    direction: "asc"
                }])

                setAllMskScores(mskScores);
            }
        }
        getAllMskScores();
    }, [])
    useEffect(() => {
        const getHistory = () => {
            if (allMskScores) {
                let tempPhysicalActivityHistory = []
                let tempModerateActivityHistory = []
                let tempVigorousActivityHistory = []
                let tempMuscleStrengthTrainingHistory = []
                let tempCustomMuscleStrengthTrainingHistoryLabels = []

                for (const mskScore of allMskScores) {
                    tempPhysicalActivityHistory.push({
                        x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                        y: mskScore.lifestyleScore?.physicalActivityScore?.percentage ?? 0
                    })
                    if (mskScore.lifestyleScore?.physicalActivityScore?.moderateActivity || mskScore.lifestyleScore?.physicalActivityScore?.moderateActivity === 0) {
                        tempModerateActivityHistory.push({
                            x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                            y: mskScore.lifestyleScore?.physicalActivityScore?.moderateActivity
                        })
                    }
                    if (mskScore.lifestyleScore?.physicalActivityScore?.vigorousActivity || mskScore.lifestyleScore?.physicalActivityScore?.vigorousActivity === 0)
                        tempVigorousActivityHistory.push({
                            x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                            y: mskScore.lifestyleScore?.physicalActivityScore?.vigorousActivity
                        })
                    if (mskScore.lifestyleScore?.physicalActivityScore?.muscleStrengthening || mskScore.lifestyleScore?.physicalActivityScore?.muscleStrengthening === 0) {
                        tempMuscleStrengthTrainingHistory.push({
                            x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                            y: mskScore.lifestyleScore?.physicalActivityScore?.muscleStrengthening === 0 ? 0.5 : mskScore.lifestyleScore?.physicalActivityScore?.muscleStrengthening === 1 ? 1.5 : mskScore.lifestyleScore?.physicalActivityScore?.muscleStrengthening ?? 0
                        })
                        tempCustomMuscleStrengthTrainingHistoryLabels.push({
                            x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                            y: mskScore.lifestyleScore?.physicalActivityScore?.muscleStrengthening
                        })
                    }
                }

                if (tempModerateActivityHistory.length === 0) {
                    const moderateActivity = getModerateActivityResponse();
                    if (moderateActivity) {
                        tempModerateActivityHistory.push(moderateActivity);
                    }
                }
                if (tempVigorousActivityHistory.length === 0) {
                    const vigorousActivity = getVigorousActivityResponse();
                    if (vigorousActivity) {
                        tempVigorousActivityHistory.push(vigorousActivity)
                    }
                }
                if (tempMuscleStrengthTrainingHistory.length === 0) {
                    const strengthTraining = getStrengthTrainingResponse();
                    if (strengthTraining) {
                        tempMuscleStrengthTrainingHistory.push(strengthTraining);
                    }
                }
                setPhysicalActivityHistory(tempPhysicalActivityHistory);
                setModerateActivityHistory(tempModerateActivityHistory);
                setVigorousActivityHistory(tempVigorousActivityHistory);
                setMuscleStrengthTrainingHistory(tempMuscleStrengthTrainingHistory);
                setCustomMuscleStrengthTrainingHistory(tempCustomMuscleStrengthTrainingHistoryLabels);
            }
        }
        getHistory();
    }, [allMskScores])

    const getStrengthTrainingResponse = () => {
        if (!assessmentResponses) {
            return;
        }
        const activityResponse: any = assessmentResponses.find((assessmentResponse: any) => assessmentResponse[0].groupId === GroupIds.PHYSICAL_ACTIVITY)
        if (activityResponse.length > 0) {
            const strength = activityResponse && activityResponse.length > 0 ? activityResponse[0].responses.find((response: any) => response.questionId === QuestionIds.PHYSICAL_ACTIVITY_STRENGTH_NUM) : undefined
            console.log(strength);
            if (strength) {
                return {
                    x: moment(activityResponse[0] && activityResponse[0].created?._seconds ? activityResponse[0].created._seconds * 1000 : NaN).format("MMM DD, YY"),
                    y: parseInt(strength.answerResponse) ?? 0
                }
            }
        }
    }

    const getModerateActivityResponse = () => {
        if (!assessmentResponses) {
            return;
        }
        console.log(assessmentResponses);
        const activityResponse: any = assessmentResponses.find((assessmentResponse: any) => assessmentResponse[0].groupId === GroupIds.PHYSICAL_ACTIVITY)
        if (activityResponse.length > 0) {
            const moderate = activityResponse && activityResponse.length > 0 ? activityResponse[0].responses.find((response: any) => response.questionId === QuestionIds.PHYSICAL_ACTIVITY_MODERATE) : undefined
            console.log(moderate)
            if (moderate) {
                return {
                    x: moment(activityResponse[0] && activityResponse[0].created?._seconds ? activityResponse[0].created._seconds * 1000 : NaN).format("MMM DD, YY"),
                    y: parseInt(moderate.answerResponse) ?? 0
                }
            }
        }
    }

    const getVigorousActivityResponse = () => {
        if (!assessmentResponses) {
            return;
        }
        const activityResponse: any = assessmentResponses.find((assessmentResponse: any) => assessmentResponse[0].groupId === GroupIds.PHYSICAL_ACTIVITY)
        if (activityResponse.length > 0) {
            const vigorous = activityResponse && activityResponse.length > 0 ? activityResponse[0].responses.find((response: any) => response.questionId === QuestionIds.PHYSICAL_ACTIVITY_VIGOROUS) : undefined
            if (vigorous) {
                return {
                    x: moment(activityResponse[0] && activityResponse[0].created?._seconds ? activityResponse[0].created._seconds * 1000 : NaN).format("MMM DD, YY"),
                    y: parseInt(vigorous.answerResponse) ?? 0
                }
            }
        }
    }

    return (
        <>
            <div>
                <UserProfileAssessmentHeader
                    assessment={assessment}
                    mskScore={mskScore}
                    setSpecificAssessment={setSpecificAssessment}
                    mainTextHeader="How We Screen Physical Activity"
                    mainText="We evaluate physical activity levels based on the <strong>American College of Sports Medicine's (ACSM)</strong> recommendations for healthy individuals. A score of 100% indicates you are meeting the recommended daily amount of physical activity to maintain or improve your health. Lower scores suggest a lack of sufficient activity, which may impact overall wellness and increase health risks."
                    whyImportantText="Physical activity is a cornerstone of health and longevity. Regular movement helps prevent chronic conditions such as heart disease, diabetes, and obesity while supporting bone, muscle, and joint health. It also encourages healthier habits, improves mood, boosts mental clarity, and enhances overall quality of life. Staying active fosters independence, reduces the risk of injury, and promotes long-term physical and mental resilience."
                />
            </div>

            {physicalActivityHistory && (
                <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper my-5`}>
                    <LineChartJS
                        data={physicalActivityHistory.map(history => history.y)}
                        title="Activity Level Score History"
                        labels={physicalActivityHistory.map(history => history.x)}
                        height={"200px"}
                        horizontalScrolling={true}
                        pointRadius={7}
                        pointBorderWidth={3}
                        borderWidth={5}
                        pointFontSize={16}
                        fontFamily={'pragmatica-extended'}
                        pointLabelOffset={0}
                        xLabelFontSize={14}
                        paddingTop={30}
                        paddingBottom={20}
                        maintainAspectRatio={false}
                    />
                </div>
            )}

            {moderateActivityHistory && moderateActivityHistory.length > 0 && (
                <div className="flex gap-5 mb-5">
                    <div className={`${COMPONENT_CLASS}__score-section-card min-w-72 max-w-72`}>
                        <h3 className="flex items-center justify-center mb-4">
                            Moderate Activity (Per Week)
                        </h3>
                        <div className="w-full h-64">
                            <RiskChartVertical
                                score={moderateActivityHistory[moderateActivityHistory.length - 1].y}
                                breakpoints={[
                                    { min: 150, max: 250, label: "Recommended\nActivity", bgColor: "#8BE3CE", subLabel: "150+ minutes" },
                                    { min: 75, max: 149, label: "Some\nActivity", bgColor: "#FFC79C", subLabel: "75 - 149 minutes" },
                                    { min: 0, max: 74, label: "Little\nActivity", bgColor: "#FFA998", subLabel: "0 - 74 minutes" },
                                ]}
                                decimals={false}
                            />
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper grow`}>
                        <NonLinearLineChart
                            dataPoints={moderateActivityHistory.map(history => history.y)}
                            labels={moderateActivityHistory.map(history => history.x)}
                            title="Moderate Activity (Per Week) History"
                            sectionDetails={{
                                sections: 3,
                                rangeOne: { start: 0, end: 74.9 },
                                rangeTwo: { start: 75, end: 149.9 },
                                rangeThree: { start: 150, end: 300 },
                                backgroundColorOrder: { first: "#FFA998", second: "#FFE9CC", third: "#8BE3CE", },
                                dataPointColorOrder: { first: "#E7514F", second: "#FFC79C", third: "#00DFBC" },
                                yAxisLabels: { first: "LITTLE", second: "SOME", third: "RECOMMENDED" },
                                decimals: false
                            }}
                            toolTipLabel={true}
                        />
                    </div>
                </div>
            )}
            {vigorousActivityHistory && vigorousActivityHistory.length > 0 && (
                <div className="flex gap-5 mb-5">
                    <div className={`${COMPONENT_CLASS}__score-section-card min-w-72 max-w-72`}>
                        <h3 className="flex items-center justify-center mb-4">
                            Vigorous Activity (Per Week)
                        </h3>
                        <div className="w-full h-64">
                            <RiskChartVertical
                                score={vigorousActivityHistory[vigorousActivityHistory.length - 1].y}
                                breakpoints={[
                                    { min: 75, max: 250, label: "Recommended\nActivity", bgColor: "#8BE3CE", subLabel: "75+ minutes" },
                                    { min: 50, max: 74, label: "Some\nActivity", bgColor: "#FFC79C", subLabel: "50 - 74 minutes" },
                                    { min: 0, max: 49, label: "Little\nActivity", bgColor: "#FFA998", subLabel: "0 - 49 minutes" },
                                ]}
                                decimals={false}
                            />
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper grow`}>
                        <NonLinearLineChart
                            dataPoints={vigorousActivityHistory.map(history => history.y)}
                            labels={vigorousActivityHistory.map(history => history.x)}
                            title="Vigorous Activity (Per Week) History"
                            sectionDetails={{
                                sections: 3,
                                rangeOne: { start: 0, end: 49.9 },
                                rangeTwo: { start: 50, end: 74.9 },
                                rangeThree: { start: 75, end: 150 },
                                backgroundColorOrder: { first: "#FFA998", second: "#FFE9CC", third: "#8BE3CE", },
                                dataPointColorOrder: { first: "#E7514F", second: "#FFC79C", third: "#00DFBC" },
                                yAxisLabels: { first: "LITTLE", second: "SOME", third: "RECOMMENDED" },
                                decimals: false
                            }}
                            toolTipLabel={true}
                        />
                    </div>
                </div>

            )}
            {muscleStrengthTrainingHistory && muscleStrengthTrainingHistory.length > 0 && (
                <div className="flex gap-5">
                    <div className={`${COMPONENT_CLASS}__score-section-card w-72`}>
                        <h3 className="flex items-center justify-center mb-4">
                            Muscle Strength Training
                        </h3>
                        <div className="w-full h-64">
                            <RiskChartVertical
                                score={muscleStrengthTrainingHistory[muscleStrengthTrainingHistory.length - 1].y}
                                breakpoints={[
                                    { min: 2, max: 7, label: "Recommended\nStrength Training", bgColor: "#8BE3CE", subLabel: "2+ days a week" },
                                    { min: 1, max: 1.9, label: "Some\nStrength Training", bgColor: "#FFC79C", subLabel: "1 day a week" },
                                    { min: 0, max: 0.9, label: "No\nStrength Training", bgColor: "#FFA998", subLabel: "0 days a week" },
                                ]}
                                decimals={true}
                            />
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper grow`}>
                        <NonLinearLineChart
                            dataPoints={muscleStrengthTrainingHistory.map(history => history.y)}
                            customDataPointLabels={customMuscleStrengthTrainingHistory ? customMuscleStrengthTrainingHistory.map(history => history.y) : undefined}
                            labels={muscleStrengthTrainingHistory.map(history => history.x)}
                            title="Muscle Strength Training History"
                            sectionDetails={{
                                sections: 3,
                                rangeOne: { start: 0, end: 0.9 },
                                rangeTwo: { start: 1, end: 1.9 },
                                rangeThree: { start: 2, end: 7 },
                                backgroundColorOrder: { first: "#FFA998", second: "#FFE9CC", third: "#8BE3CE", },
                                dataPointColorOrder: { first: "#E7514F", second: "#FFC79C", third: "#00DFBC" },
                                yAxisLabels: { first: "NONE", second: "SOME", third: "RECOMMENDED" },
                                decimals: true
                            }}
                            toolTipLabel={true}
                        />
                    </div>
                </div>

            )}
        </>
    );
}

export default UserProfilePhysicalActivity;
