/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { MskScore } from "../../models/interfaces/msk-score";
import { User } from "../../models/interfaces/user";
import AssessmentResponse from "../../models/interfaces/assessment-response.entity";
import MskScoreService from "../../utilities/services/msk-score-service";
import moment from "moment";
import { GroupIds } from "../../models/enumerations/group-ids";
import { ReportAnswer } from "../../models/enumerations/report-answer";
import { QuestionIds } from "../../models/enumerations/question-ids";
import { InjuryHistoryResponse } from "../../models/interfaces/reports/injury-history-response";
import UserProfileAssessmentHeader from "./user-profile-assessment-header";
import LineChartJS from "../charts/line-chart-js";
import { ReactComponent as DotIcon } from "../../assets/icons/solid/icon_dot.svg";
import { Utils } from "../../utilities/utils";
import MskScoreUtil from "../../utilities/msk-score-util";

const COMPONENT_CLASS = "c-user-profile-assessments";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfileInjuryHistoryProps {
    mskScore?: MskScore;
    user: User;
    assessment?: any;
    assessmentResponses: AssessmentResponse[];
    allMskScores: MskScore[];
    setAllMskScores: Function;
    setSpecificAssessment: Function;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfileInjuryHistory: React.FC<UserProfileInjuryHistoryProps> = (props: UserProfileInjuryHistoryProps) => {
    const { setSpecificAssessment, mskScore, assessment, allMskScores, setAllMskScores, user, assessmentResponses } = props;
    const [injuryHistory, setInjuryHistory] = useState<{ x: string, y: number }[]>();
    const [injuryResponses, setInjuryResponses] = useState<InjuryHistoryResponse>();
    const [currentPainAreas, setCurrentPainAreas] = useState<string[]>([]);

    // const [isLoading, setIsLoading] = useState<boolean>(true)

    // useEffect(() => {
    //     setIsLoading(false);
    // }, [])
    useEffect(() => {
        const getAllMskScores = async () => {
            if (!allMskScores) {
                const mskScores = await MskScoreService.getBy([{
                    field: "userId",
                    operator: "==",
                    value: user.id
                }], [{
                    field: "created",
                    direction: "asc"
                }])

                setAllMskScores(mskScores);
            }
        }
        getAllMskScores();
    }, [])
    useEffect(() => {
        const getHistory = () => {
            if (allMskScores) {
                let tempInjuryHistory = []

                for (const mskScore of allMskScores) {
                    tempInjuryHistory.push({
                        x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                        y: mskScore.lifestyleScore?.injuryHistoryScore?.percentage ?? 0
                    })
                }
                setInjuryHistory(tempInjuryHistory);
            }
        }
        getHistory();
    }, [allMskScores])
    useEffect(() => {
        if (assessmentResponses) {
            getInjuryResponses();
        }
    }, [assessmentResponses])

    const getInjuryResponses = () => {
        if (!assessmentResponses) {
            return;
        }
        let injuryHistoryInjury = true
        let injuryHistoryPain = false
        let injuryHistoryPainPhysician = true
        let injuryHistorySaneScore = 0
        let injuryHistoryPainAreas = ""
        const injuryResponses: any = assessmentResponses.find((assessmentResponse: any) => assessmentResponse[0].groupId === GroupIds.INJURY_HISTORY)
        const injuryHistoryInjuryResponse = injuryResponses && injuryResponses.length > 0 ? injuryResponses[0].responses.find((response: any) => response.questionId === QuestionIds.INJURY_HISTORY_INJURY) : undefined
        if (injuryHistoryInjuryResponse && injuryHistoryInjuryResponse.answerId === ReportAnswer.B) {
            injuryHistoryInjury = false
        }

        const injuryHistoryPainResponse = injuryResponses && injuryResponses.length > 0 ? injuryResponses[0].responses.find((response: any) => response.questionId === QuestionIds.INJURY_HISTORY_PAIN) : undefined
        if (injuryHistoryPainResponse && injuryHistoryPainResponse.answerId !== ReportAnswer.A) {
            injuryHistoryPain = true
        }

        const injuryHistoryPainPhysicianResponse = injuryResponses && injuryResponses.length > 0 ? injuryResponses[0].responses.find((response: any) => response.questionId === QuestionIds.INJURY_HISTORY_PAIN_PHYSICIAN) : undefined
        if (injuryHistoryPainPhysicianResponse && injuryHistoryPainPhysicianResponse.answerId === ReportAnswer.B) {
            injuryHistoryPainPhysician = false
        }

        const injuryHistorySaneScoreResponse = injuryResponses && injuryResponses.length > 0 ? injuryResponses[0].responses.find((response: any) => response.questionId === QuestionIds.INJURY_HISTORY_SANE_SCORE) : undefined
        if (injuryHistorySaneScoreResponse) {
            injuryHistorySaneScore = parseInt(injuryHistorySaneScoreResponse.answerResponse);
        }

        const injuryHistoryPainAreasResponse = injuryResponses && injuryResponses.length > 0 ? injuryResponses[0].responses.find((response: any) => response.questionId === QuestionIds.INJURY_HISTORY_PAIN_AREAS) : undefined
        if (injuryHistoryPainAreasResponse) {
            setCurrentPainAreas(injuryHistoryPainAreasResponse.answerResponse.split(","));

            let formattedPainAreas = injuryHistoryPainAreasResponse.answerResponse.replaceAll(",", ", ")
            injuryHistoryPainAreas = formattedPainAreas;
        }

        const injuryResponse = {
            percentage: mskScore?.lifestyleScore?.injuryHistoryScore?.percentage,
            injuryHistoryInjury: injuryHistoryInjury,
            injuryHistoryPain: injuryHistoryPain,
            injuryHistoryPainPhysician: injuryHistoryPainPhysician,
            injuryHistorySaneScore: injuryHistorySaneScore,
            injuryHistoryPainAreas: injuryHistoryPainAreas,
        }
        setInjuryResponses(injuryResponse);
    }
    return (
        <>
            <div>
                <UserProfileAssessmentHeader
                    assessment={assessment}
                    mskScore={mskScore}
                    setSpecificAssessment={setSpecificAssessment}
                    extraHeaderElement={
                        mskScore && mskScore.lifestyleScore?.injuryHistoryScore?.currentPain
                            ?
                            Utils.getPainBadge(mskScore.lifestyleScore?.injuryHistoryScore?.currentPain)
                            :
                            assessmentResponses
                                ?
                                Utils.getPainBadge(MskScoreUtil.getNumberOfPainLocationsInjuryHistoryAssessment(assessmentResponses) > 0)
                                :
                                undefined}
                    mainTextHeader="How We Assess Injury History"
                    mainText="We evaluate injury history using an <strong>evidence-based injury prediction algorithm</strong> to determine your risk level. Scores ranging from 88-100 indicate no prior injuries or current risk factors that could negatively impact health and wellness. Lower scores suggest the presence of risk factors that may increase vulnerability to future injuries or health issues."
                    whyImportantText="Injury history is a critical, yet often overlooked, factor in overall health and wellness. Previous injuries can leave lasting effects, such as reduced mobility, chronic pain, or compensation patterns that may increase stress on other parts of the body. These lingering issues not only heighten the risk of reinjury but can also lead to the development of new musculoskeletal problems over time."
                />
            </div>

            {injuryHistory && (
                <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper my-5`}>
                    <LineChartJS
                        data={injuryHistory.map(history => history.y)}
                        title="Injury History Score History"
                        labels={injuryHistory.map(history => history.x)}
                        height={"200px"}
                        horizontalScrolling={true}
                        pointRadius={7}
                        pointBorderWidth={3}
                        borderWidth={5}
                        pointFontSize={16}
                        fontFamily={'pragmatica-extended'}
                        pointLabelOffset={0}
                        xLabelFontSize={14}
                        paddingTop={30}
                        paddingBottom={20}
                        maintainAspectRatio={false}
                    />
                </div>
            )}
            {injuryResponses && (
                <div className="flex flex-wrap gap-5">
                    <div className={`${COMPONENT_CLASS}__score-section-card`}>
                        <div>
                            <h3 className="mb-4">Current Pain / Injury Location:</h3>
                        </div>
                        <div className="flex flex-col gap-2">
                            {currentPainAreas.length > 0
                                ?
                                currentPainAreas.map((painArea) => {
                                    return (
                                        <div className="flex gap-2 items-center text-sm font-light">
                                            <DotIcon color="#E7514F" />
                                            <div>{painArea}</div>
                                        </div>
                                    );
                                })
                                :
                                <div className="flex gap-2 items-center text-sm font-light">
                                    <DotIcon color="#00DFBC" />
                                    <div>None</div>
                                </div>
                            }

                        </div>
                    </div>

                    <div className={`${COMPONENT_CLASS}__score-section-card grow`} style={{ width: "567px" }}>
                        <div>
                            <h3 className="mb-4">Injury Recovery</h3>
                        </div>

                        <div className="w-full flex flex-row" style={{ gap: "2px" }}>
                            <div className="w-1/4 relative">
                                <div className="bg-black-gray-5 flex flex-col gap-1 justify-center items-center p-2 h-8" style={{ borderRadius: "10px 0 0 0" }}><span className="text-xs font-medium">Previous Injury</span></div>
                                <div className={`${injuryResponses?.injuryHistoryInjury ? "bg-red-medium" : "bg-green-medium"} flex flex-col gap-1 justify-center items-center p-2 h-8`} style={{ borderRadius: "0 0 0 10px" }}>
                                    <span className="text-xs font-light">{injuryResponses?.injuryHistoryInjury ? "Yes" : "No"}</span>
                                </div>
                            </div>

                            <div className="w-1/4 relative">
                                <div className="bg-black-gray-5 flex flex-col gap-1 justify-center items-center p-2 h-8"><span className="text-xs font-medium">Current Pain</span></div>
                                <div className={`${injuryResponses?.injuryHistoryPain ? "bg-red-medium" : "bg-green-medium"} flex flex-col gap-1 justify-center items-center p-2 h-8`}>
                                    <span className="text-xs font-light">{injuryResponses?.injuryHistoryPain ? "Yes" : "No"}</span>
                                </div>
                            </div>

                            <div className="w-1/4 relative">
                                <div className="bg-black-gray-5 flex flex-col gap-1 justify-center items-center p-2 h-8"><span className="text-xs font-medium">Seen a Physician</span></div>
                                <div className={`${injuryResponses?.injuryHistoryPainPhysician ? "bg-green-medium" : "bg-red-medium"} flex flex-col gap-1 justify-center items-center p-2 h-8`}>
                                    <span className="text-xs font-light">{injuryResponses?.injuryHistoryPainPhysician ? "Yes" : "No"}</span>
                                </div>
                            </div>

                            <div className="w-1/4 relative">
                                <div className="bg-black-gray-5 flex flex-col gap-1 justify-center items-center p-2 h-8" style={{ borderRadius: "0 10px 0 0" }}><span className="text-xs font-medium">Recovery Score</span></div>
                                <div className={`${injuryResponses?.injuryHistorySaneScore >= 88 ? "bg-green-medium" : "bg-red-medium"} flex flex-col gap-1 justify-center items-center p-2 h-8`} style={{ borderRadius: "0 0 10px 0" }}>
                                    <span className="text-xs font-light">{injuryResponses?.injuryHistorySaneScore}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }

        </ >
    );
}

export default UserProfileInjuryHistory;
