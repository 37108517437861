export enum QuestionIds {
    BODY_COMPOSITION_HIPS = "BODY_COMPOSITION_HIPS",
    BODY_COMPOSITION_WAIST = "BODY_COMPOSITION_WAIST",
    BREATHING_TENSE = "BREATHING_TENSE",
    BREATHING_COLD = "BREATHING_COLD",
    BREATHING_YAWNING = "BREATHING_YAWNING",
    BREATHING_MOUTH_BREATHE = "BREATHING_MOUTH_BREATHE",
    BREATHING_BREATH = "BREATHING_BREATH",
    BREATHING_SMOKE_NOW = "BREATHING_SMOKE_NOW",
    PHYSICAL_ACTIVITY_MODERATE = "PHYSICAL_ACTIVITY_MODERATE",
    PHYSICAL_ACTIVITY_VIGOROUS = "PHYSICAL_ACTIVITY_VIGOROUS",
    PHYSICAL_ACTIVITY_STRENGTH_NUM = "PHYSICAL_ACTIVITY_STRENGTH_NUM",
    TOE_TOUCH_REACH_LEFT = "TOE_TOUCH_REACH_LEFT",
    TOE_TOUCH_REACH_RIGHT = "TOE_TOUCH_REACH_RIGHT",
    TOE_TOUCH_QPAIN = "TOE_TOUCH_QPAIN",
    SHOULDER_MOBILITY_REACH_LEFT = "SHOULDER_MOBILITY_REACH_LEFT",
    SHOULDER_MOBILITY_REACH_RIGHT = "SHOULDER_MOBILITY_REACH_RIGHT",
    SHOULDER_MOBILITY_QPAIN = "SHOULDER_MOBILITY_QPAIN",
    ROTATION_REACH_LEFT = "ROTATION_REACH_LEFT",
    ROTATION_REACH_RIGHT = "ROTATION_REACH_RIGHT",
    ROTATION_QPAIN = "ROTATION_QPAIN",
    SQUAT_LOW = "SQUAT_LOW",
    SQUAT_QPAIN = "SQUAT_QPAIN",
    BALANCE_REACH_1 = "BALANCE_REACH_1",
    BALANCE_REACH_2_LEFT = "BALANCE_REACH_2_LEFT",
    BALANCE_REACH_2_RIGHT = "BALANCE_REACH_2_RIGHT",
    BALANCE_REACH_25_LEFT = "BALANCE_REACH_25_LEFT",
    BALANCE_REACH_25_RIGHT = "BALANCE_REACH_25_RIGHT",
    BALANCE_QPAIN = "BALANCE_QPAIN",
    SHOULDER_CLEARING_QPAIN = "SHOULDER_CLEARING_QPAIN",
    SPINE_CLEARING_QPAIN = "SPINE_CLEARING_QPAIN",
    INJURY_HISTORY_PAIN = "INJURY_HISTORY_PAIN",
    INJURY_HISTORY_PAIN_PHYSICIAN = "INJURY_HISTORY_PAIN_PHYSICIAN",
    INJURY_HISTORY_INJURY = "INJURY_HISTORY_INJURY",
    INJURY_HISTORY_SANE_SCORE = "INJURY_HISTORY_SANE_SCORE",
    INJURY_HISTORY_PAIN_AREAS = "INJURY_HISTORY_PAIN_AREAS",
    SLEEP_REST = "SLEEP_REST",
    SLEEP_ENERGY = "SLEEP_ENERGY",
    SLEEP_DROWSY = "SLEEP_DROWSY",

};
