import { DateTime, Zone } from "luxon";
import IToday from "../../models/interfaces/today";
import FirestoreService from "./firestore-service";

const COLLECTION_NAME = "today";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

/**
 * Find the specific today by parameters
 * @param {string} userId
 * @param {string} date
 */
const getToday = async (userId: string, timeZone?: Zone): Promise<IToday | null> => {
    const todays = await _listTodays(userId, 1);
    if (todays && todays.length === 1) {
        return todays[0];
    }
    return null;
};

const _listTodays = async (
    userId: string,
    limit: number,
    timeZone?: Zone,
): Promise<IToday[]> => {
    const queryResult = await FirestoreService.getBy<IToday>(
        COLLECTION_NAME,
        [
            {
                field: "userId",
                operator: "==",
                value: userId,
            },
            {
                field: "date",
                operator: "==",
                value: DateTime.now().setZone(timeZone).toISODate(),
            },
        ],
        [],
        limit
    );
    return queryResult;
};

// #endregion Service Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const TodayService = {
    getToday,
};

export default TodayService;

// #endregion Exports
